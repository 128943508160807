<div [class]="color" class="dialog">
  <div tabindex="0" id="dialog" class="dialogOverlay">
    <div class="dialog fadeIn" (click)="evento()" (edge)="checkEdge($event)" [style.display]="mostrar" [style.top]="top"
      [style.left]="left">
      <div class="dialogTitle" (mouseover)="draggable = true">
        <span>{{'puntosVenta.cantidad' | translate}}</span>
        <a (click)="cerrarPopup()" class="close">
          <i class="fas fa-times"></i>
        </a>
      </div>
      <div class="dialogContent flex popUpEditarCantidad" (mouseover)="draggable = false">
        <div class="form-inputs">
          <fieldset>
            <div class="contGridFluid">
              <div class="colGrid1">
                <div tabindex="-1" class="form-group">
                  <fieldset>
                    <i class="fas fa-minus-square" (click)="cambio('disminuir')"
                      [ngClass]="{'icoDisable': datosPopUp.cantidad <= '1'}"></i>
                    <wo-text-field-seg class="cantidad" [tipoInput]="'number'" [valorActual]="datosPopUp.cantidad"
                      [cantidadEnteros]="9" [cantidadDecimales]="8"
                      (respuesta)="datosPopUp.cantidad=$event;cambio('nuevoValor', datosPopUp.cantidad)">
                    </wo-text-field-seg>
                    <i class="fas fa-plus-square" (click)="cambio('aumentar')"></i>
                  </fieldset>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  </div>
</div>