import { NgModule } from '@angular/core';
import { PuntosDeVentaComponent } from './puntos-de-venta.component';
import { ProductosComponent } from './productos/productos.component';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { WoTextFieldModule } from '@wo/frontend/wo-text-field/wo-text-field.module';
import { EncabezadoComponent } from './encabezado/encabezado.component';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { TextAreaSegModule } from '@wo/frontend/text-area-seg/text-area-seg.module';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CantidadPopUpComponent } from './cantidad-pop-up/cantidad-pop-up.component';
import { PorcentajeProductoPopupComponent } from './porcentaje-producto-popup/porcentaje-producto-popup.component';
import { ListadoDocumentoEncabezadoComponent } from '@wo/frontend/documentos/listado/listado-documento-encabezado/listado-documento-encabezado.component';
import { AngularDraggableModule } from 'angular2-draggable';
import { DetallePopupComponent } from "@wo/pos/puntos-de-venta/detalle-pop-up/detalle-popup.component";
import { NotaPopUpComponent } from './nota-pop-up/nota-pop-up.component';
import { EncabezadoCompletoPipe } from './pipes/encabezado-completo.pipe';
import { CopiarDocumentoPosComponent } from './copiar-documento-pos/copiar-documento-pos.component';
import { PopupResolverExistenciasCopiaPosComponent } from './copiar-documento-pos/popup-resolver-existencias-copia-pos/popup-resolver-existencias-copia-pos.component';
import { OpcionesFinalesComponent } from './opciones-finales/opciones-finales.component';
import { AcordeonSelectSegModule } from '@wo/frontend/acordeon-select-seg/acordeon-select-seg.module';
import { ElegirRenglonesNotasComponent } from './copiar-documento-pos/elegir-renglones-notas/elegir-renglones-notas.component';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { FormatearNumeroModule } from './pipes/formatear-numero.module';
import { DetallePagoComponent } from './detalle-pago/detalle-pago.component';
import { AgregarFormaPagoComponent } from './detalle-pago/agregar-formapago-popup/agregar-formapago-popup.component';
import { ImprimirDocumentoComponent } from '@wo/frontend/documentos/detalle/documento-encabezado/imprimir-documento/imprimir-documento.component';
import { ImprimirPosComponent } from './imprimir/imprimir.component';
import { SinConexionComponent } from './sin-conexion/sin-conexion.component';
import { ContabilizacionComponent } from '@wo/frontend/documentos/detalle/documento-encabezado/contabilizacion/contabilizacion.component';
import { ContabilizacionBasicaComponent } from '@wo/frontend/documentos/detalle/documento-encabezado/contabilizacion/contabilizacion-basica/contabilizacion-basica.component';
import { ContabilizacionAvanzadaComponent } from '@wo/frontend/documentos/detalle/documento-encabezado/contabilizacion/contabilizacion-avanzada/contabilizacion-avanzada.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CompactarNumeroModule } from './pipes/compact-numero.module';
import { DetalleAbonaComponent } from '@wo/frontend/documentos/detalle/documento-encabezado/detalle-abona/detalle-abona.component';
import { BotonWoSegModule } from '@wo/frontend/boton-wo-seg/boton-wo-seg.module';
import { MenuContextualModule } from '@wo/frontend/menu-contextual/menu-contextual.module';
import { MasOpcionesComponent } from '@wo/frontend/documentos/detalle/documento-encabezado/mas-opciones/mas-opciones.component';
import { FechasModule } from '@wo/frontend/documentos/detalle/documento-encabezado/mas-opciones/fechas/fechas.module';
import { WoFileUploadModule } from '@wo/frontend/wo-file-upload/wo-file-upload.module';
import { TerceroExternoComponent } from '@wo/frontend/documentos/detalle/documento-encabezado/mas-opciones/tercero-externo/tercero-externo.component';
import { ListaContactosComponent } from '@wo/frontend/documentos/detalle/documento-encabezado/mas-opciones/lista-contactos/lista-contactos.component';
import { ElaboradoPorComponent } from '@wo/frontend/documentos/detalle/documento-encabezado/mas-opciones/elaborado-por/elaborado-por.component';
import { ListaClasificacionesComponent } from '@wo/frontend/documentos/detalle/documento-encabezado/mas-opciones/lista-clasificaciones/lista-clasificaciones.component';
import { TercerosMultiplesComponent } from '@wo/frontend/documentos/detalle/documento-encabezado/mas-opciones/terceros-multiples/terceros-multiples.component';
import { DocumentoPersonalizadoComponent } from '@wo/frontend/documentos/detalle/documento-encabezado/mas-opciones/documento-personalizado/documento-personalizado.component';
import { MasOtrosComponent } from '@wo/frontend/documentos/detalle/documento-encabezado/mas-opciones/mas-otros/mas-otros.component';
import { MultiselectSegModule } from '@wo/frontend/multiselect-seg/multiselect-seg.module';
import { PorcentajeFacturaPopupComponent } from './porcentaje-factura-popup/porcentaje-factura-popup.component';
import { PopUpListadoComponent } from '@wo/frontend/documentos/detalle/documento-encabezado/renglones-documento/movimiento-inventario/pop-up-listado/pop-up-listado.component';
import { CrearTerceroComponent } from './encabezado/crear-tercero/crear-tercero.component';
import { CrearDireccionTerceroModule } from './encabezado/crear-direccion-tercero/crear-direccion-tercero.module';
import { CopiarDocumentoModule } from "@wo/frontend/copiar-documento/copiar-documento.module";
import { CrearDocumentosComponent } from './crear-documentos/crear-documentos.component';
import { CruzarDocumentosComponent } from './cruzar-documentos/cruzar-documentos.component';
import { PopupResolverExistenciasCruceComponent } from "@wo/frontend/documentos/detalle/documento-encabezado/cruce-documentos/cruce-inventarios/popup-resolver-existencias-cruce/popup-resolver-existencias-cruce.component";
import { DisabledBotonVariasPipe } from '@wo/frontend/documentos/detalle/documento-encabezado/cruce-documentos/validaciones/disabled-boton-varias.pipe';
import { MatRadioModule } from '@angular/material/radio';
import { VerNumeroEnviarPipe } from './pipes/ver-numero-enviar.pipe';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MatSelectModule } from '@angular/material/select';
import { MultiSelectModule } from '@wo/frontend/multiselect/multiselect.module';
import { ResizableModule } from 'angular-resizable-element';
import { ObsequioPopupComponent } from './obsequio-pop-up/obsequio-pop-up.component';
import { ElegirResolucionesPopupComponent } from './elegirResolucionesDocElectronico/elegir-resoluciones-pop-up.component';
import { MasInventarioModule } from '@wo/frontend/documentos/detalle/documento-encabezado/mas-inventario/mas-inventario.module';
import { ViewerComponent } from '@wo/frontend/reporte/viewer/viewer.component';
import {FechasSinRefComponent} from '@wo/frontend/documentos/detalle/documento-encabezado/mas-opciones/fecha-sin-ref/fecha-sin-ref.component';
import { ValidacionDocumentosElectronicosPopUpComponent } from './validacion-documentos-electronicos-pop-up/validacion-documentos-electronicos-pop-up.component';
import { ValidacionesPipesModule } from '@wo/frontend/documentos/detalle/documento-encabezado/validaciones/validaciones-pipes.module';
import { PopUpLotesComponent } from './pop-up-lotes/pop-up-lotes.component';
import { PopUpSerialesComponent } from './pop-up-seriales/pop-up-seriales.component';
import { PopUpTallacolorComponent } from './pop-up-tallacolor/pop-up-tallacolor.component'
import { DocumentoPesonalizadoRenglonModule } from '@wo/frontend/documentos/detalle/documento-encabezado/renglones-documento/pesonalizado-renglon/documento-pesonalizado-renglon.module';

@NgModule({
  declarations: [
    PuntosDeVentaComponent,
    ProductosComponent,
    EncabezadoComponent,
    CantidadPopUpComponent,
    ListadoDocumentoEncabezadoComponent,
    PorcentajeProductoPopupComponent,
    PorcentajeFacturaPopupComponent,
    NotaPopUpComponent,
    CopiarDocumentoPosComponent,
    ElegirRenglonesNotasComponent,
    PopupResolverExistenciasCopiaPosComponent,
    DetallePopupComponent,
    EncabezadoCompletoPipe,
    OpcionesFinalesComponent,
    DetallePagoComponent,
    AgregarFormaPagoComponent,
    ImprimirDocumentoComponent,
    ImprimirPosComponent,
    ContabilizacionComponent,
    ContabilizacionBasicaComponent,
    ContabilizacionAvanzadaComponent,
    SinConexionComponent,
    DetalleAbonaComponent,
    MasOpcionesComponent,
    FechasSinRefComponent,
    TerceroExternoComponent,
    ListaContactosComponent,
    ElaboradoPorComponent,
    ListaClasificacionesComponent,
    TercerosMultiplesComponent,
    DocumentoPersonalizadoComponent,
    MasOtrosComponent,
    PopUpListadoComponent,
    CrearTerceroComponent,
    CrearDocumentosComponent,
    CruzarDocumentosComponent,
    PopupResolverExistenciasCruceComponent,
    DisabledBotonVariasPipe,
    VerNumeroEnviarPipe,
    ObsequioPopupComponent,
    ElegirResolucionesPopupComponent,
    PopUpLotesComponent,
    PopUpSerialesComponent,
    PopUpTallacolorComponent,
    ViewerComponent,
    ValidacionDocumentosElectronicosPopUpComponent
  ],
  imports: [
    AngularDraggableModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    WoTextFieldSegModule,
    WoTextFieldModule,
    SelectPagSegModule,
    TextAreaSegModule,
    AcordeonSelectSegModule,
    WoTablaModule,
    KeyboardShortcutsModule.forRoot(),
    FormatearNumeroModule,
    CompactarNumeroModule,
    MatSnackBarModule,
    BotonWoSegModule,
    MenuContextualModule,
    FechasModule,
    WoFileUploadModule,
    MultiselectSegModule,
    CrearDireccionTerceroModule,
    CopiarDocumentoModule,
    MatRadioModule,
    NgxIntlTelInputModule,
    MatSelectModule,
    CopiarDocumentoModule,
    MultiSelectModule,
    ResizableModule,
    MasInventarioModule,
    DocumentoPesonalizadoRenglonModule,
    ValidacionesPipesModule
  ],
  exports: [
    PuntosDeVentaComponent,
    ProductosComponent
  ]
})
export class PuntosDeVentaModule { }
