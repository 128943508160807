import { PlataformaEnum } from '@wo/modelo';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then} `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  plataforma: PlataformaEnum.POS,
  local: false,
  url: 'https://services-prod.worldoffice.cloud/',
  urlReporte: 'https://reportes-prod.worldoffice.cloud/',
  urlPOS: 'https://pos.worldoffice.cloud/',
  urlNotificaciones: 'https://fn-sync-wo-prod.azurewebsites.net/api/',
  urlWhatsapp: 'https://wo-whatsapp-prod.azurewebsites.net/'
};
