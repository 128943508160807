import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ColaService, DocumentoMovimientoInventarioService, FormatoUtilService, InventarioService, PosService, TabsService } from "@wo/servicios";
import { Bodega, BotonMenuContextual, OpcionTabla, PaginacionWo, SeveridadEnum, TipoDato } from "@wo/modelo";
import { ProductosDBService } from "@wo/pos/service/productos-db.service";
import { OnlineOfflineService } from "@wo/pos/service/online-offline.service";
import { take } from 'rxjs/operators';
import { DocumentoMovimientoInventario } from '@wo/pos/modelo/documentoMovimientoInventario';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { Subscription } from 'rxjs';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';
import { ImpuestoDocumentoMovimiento } from '@wo/pos/modelo/impuestoDocumentoMovimiento';
import { MenuContextualComponent } from '@wo/frontend/menu-contextual/menu-contextual.component';
import Decimal from 'decimal.js';

@Component({
  selector: 'app-detalle-popup',
  templateUrl: './detalle-popup.component.html',
  styleUrls: ['./detalle-popup.component.css']
})
export class DetallePopupComponent implements OnInit {

  @ViewChild(MenuContextualComponent, { static: false })
  private menuContextualComponent: MenuContextualComponent;

  @Input() detalle;
  @Input('color') color;
  @Input('moneda') moneda;
  @Input('editar') editar: boolean;
  @Input('documentoEncabezado') documentoEncabezado: any;
  @Input('sincronizando') sincronizando: any;
  @Input('permisosPOS') permisosPOS: any;

  @Output() cerrar = new EventEmitter<any>();
  @Output() verPersonalizados = new EventEmitter<any>();

  public subscriptions = new Subscription();
  draggable = true;
  newInnerWidth = 0;
  isDragging = false;
  positionA = { x: 0, y: 0 };
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };
  detallePedido: any = {};
  nota: string;
  dataBodega: any;
  valorActual: any;
  centro: any;
  unidad: number;
  cantidad: any;
  valorTotalDescuento: any;
  bodega: any;
  valorUnitarioAnterior: number;
  completo: boolean = true;
  descripcion: any
  CentroDeCostos: any = [];
  idProducto: any;
  dataUnidad: any = {};
  centroActual: any;
  numeroCantidadValido;
  bodegaCantidades: any;
  ingresoPrimeraVez: boolean;

  public onlineOffline: boolean = navigator.onLine;
  parametros: any;
  idDocumento: any;
  idPrFV: any;
  tipoDocumento: String = 'POS';
  creacionMensaje: any;
  unitarioConIva: any;
  datosInventario: DocumentoMovimientoInventario;
  copiaDatosInventario: DocumentoMovimientoInventario;
  idDocDB: any;
  movimientoInventarioDB: Promise<any>;
  listaCambios = [];
  favorable: boolean = true;
  loading: boolean = true;
  valorImpuestoPorcentaje = 1;
  monedaLocal = JSON.parse(sessionStorage.getItem('MONEDALOCAL'));

  openTotal: boolean = false;
  filaListaPrecio: any;
  arregloDocumento: any;
  idUsuario = sessionStorage.getItem('USUARIOSESION');
  inventario: any;
  mostrarPopupSerial: boolean = false;
  mostrarPopupLote: boolean = false;
  mostrarPopupTallaColor: boolean = false;
  dataPopError: any;

  esObsequioValor: boolean;
  edicionProductoEnCurso: boolean;
  atributo: string;
  clickCerrar: boolean;

  constructor(
    private bodegaService: DocumentoMovimientoInventarioService,
    private facturacion: IntegracionPosService,
    private unidadesM: InventarioService,
    protected serviceColas: ColaService,
    private onlineOfflineService: OnlineOfflineService,
    public popUpService: PopUpService,
    private tabsService: TabsService,
    private productosDBService: ProductosDBService,
    private posService: PosService,
    public _LoaderService: LoaderService,
    public formatoUtilService: FormatoUtilService
  ) {
    this.parametros = this.tabsService.activeTab ? this.tabsService.activeTab.tab : null;
    this.datosInventario = new DocumentoMovimientoInventario();
    this.datosInventario.cantidad = 1;
  }

  async ngOnInit() {

    this.draggable = sessionStorage.getItem('userAgent') != 'mobile';
    this.listaCambios = [];
    this.datosInventario = { ...this.detalle };
    if (!this.datosInventario.inventario) {
      this.datosInventario.inventario = { ...this.detalle };
    }
    this.inventario = await this.productosDBService.getInventarioById(this.datosInventario.inventario.id);

    if (!this.datosInventario.codigo) this.datosInventario.codigo = this.datosInventario.inventario.codigo;
    if (!this.datosInventario.nombre) this.datosInventario.nombre = this.datosInventario.inventario.descripcion;
    if (!this.datosInventario.descripcion) this.datosInventario.descripcion = this.datosInventario.inventario.descripcion;

    this.idProducto = this.datosInventario.inventario.id;
    this.idDocumento = this.documentoEncabezado.encabezadoId;
    this.onlineOfflineService.connectionChanged().subscribe(async online => {
      this.onlineOffline = navigator.onLine;
      this.datosInventario.enLinea = this.onlineOffline;
    });

    this.copiaDatosInventario = { ...this.datosInventario };

    if (this.editar && this.datosInventario.id || this.datosInventario.renglonId) {
      if (!this.datosInventario.id) {
        this.datosInventario = await this.productosDBService.getDocumentoMovimientoInventarioLast();
        this.copiaDatosInventario = { ...this.datosInventario };
      }
      this.idPrFV = this.datosInventario.renglonId;
      this.loading = false;
    } else {
      if (this.onlineOffline && this.documentoEncabezado.enLinea) {
        const mensaje = {
          id: this.idDocumento,
          accion: 'crearDocumentoMovimientoInventario',
          valor: '',
          clase: 'DocumentoMovimientoInventario',
          prioridad: true,
          atributo: '',
          permisoGrueso: this.tipoDocumento
        };
        this.serviceColas.agregarACola(mensaje).subscribe(async (resp) => {
          console.log('resp', resp);
          
          if(resp && resp[0] && ['968', '957', '1146'].includes(resp[0].codigoError)) {
            this.eliminarProducto(resp[0]);
            return;
          }

          if (resp && resp[0] && resp[0].id) {
            this.datosInventario.facturaId = this.idDocumento;
            this.datosInventario.renglonId = resp[0].id;
            this.datosInventario['idRenglon'] = resp[0].id;
            this.idPrFV = resp[0].id;
            if (this.sincronizando) this.datosInventario.modificadoEnSync = true;
            this.productosDBService.setDocumentoMovimimentoInventarioUno(this.datosInventario);
            if (!this.datosInventario.id) this.datosInventario = await this.productosDBService.getDocumentoMovimientoInventarioLast();
            this.copiaDatosInventario = { ...this.datosInventario };
            this.cambioEnProducto('inventario', this.detalle.inventario);
            
          }
        });
      } else {
        const bodegaPrincipal = this.detalle.bodegas.find(x => x.senPrincipal);
        this.documentoEncabezado.enLinea = false;
        if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
        this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
        const centroOld: any = await this.productosDBService.getCentros();
        this.datosInventario = {
          ...this.datosInventario,
          inventarioBodega: bodegaPrincipal,
          unidadMedida: this.detalle.unidadMedida,
          cantidad: 1,
          descuento: 0,
          porcentajeDescuento: 0,
          centroCosto: this.detalle.centroCosto && centroOld.some(({ id }) => id == this.detalle.centroCosto.id) ? this.detalle.centroCosto : null
        }


        setTimeout(() => {
          this.cambioEnProducto('unitario', this.detalle.listaPrecios && this.detalle.listaPrecios.precio ? this.detalle.listaPrecios.precio : 0);
        }, 500);
      }
    }

    if (!this.inventario) {
      const TERMINAL = JSON.parse(sessionStorage.getItem('TERMINAL'));
      this.posService.buscarInventariosPosByid(this.datosInventario.inventario.id, this.idUsuario, TERMINAL.empresa.id).subscribe(reps => {
        if (reps) {
          this.inventario = reps;
          if (this.editar) this.calcularImpuestos('');
        }
      });
    } else if (this.editar) this.calcularImpuestos('');
  }

  async cambioEnProducto(atributo, valor, dataObsequio?) {
    this.edicionProductoEnCurso = true;
    this.atributo = atributo;
    if (atributo != 'unitario' || (!this.copiaDatosInventario.valorUnitario || parseFloat(this.datosInventario.valorUnitario.toString()).toFixed(2) != parseFloat(this.copiaDatosInventario.valorUnitario.toString()).toFixed(2))) {
      let valorAnterior;
      let cantidadCero: boolean;
      const mensaje = {
        id: this.datosInventario.renglonId,
        accion: atributo === 'inventario' ? 'asociarDocMovInvInventario' : 'editarDocumentoMovimientoInventario',
        valor: atributo == 'centro' ? valor.data.id : ['inventario', 'bodega', 'unidad'].includes(atributo) ? valor.id : valor,
        clase: atributo === 'inventario' ? 'Inventario' : 'DocumentoMovimientoInventario',
        prioridad: atributo === 'inventario' ? true : false,
        atributo,
        tipoDato: TipoDato[TipoDato.NUMERIC],
        permisoGrueso: this.tipoDocumento
      };
      if (atributo === 'cantidad') {
        if (parseFloat(valor) === 0) {
          cantidadCero = true;
          this.popUpService.open({ codigoError: 'cantidadCeroAgregar', severidad: SeveridadEnum.ERROR });
          setTimeout(() => {
            valorAnterior = this.cantidad ? this.cantidad : null;
            this.datosInventario.cantidad = valorAnterior;
            if (!this.datosInventario.cantidad) this.datosInventario.cantidad = null;
          }, 100);
        } else {
          let cantidad = valor.replace(/^(0+)/g, '');
          mensaje.accion = 'editarCantidad';
          mensaje.atributo = 'cantidad';
          mensaje.prioridad = true;
          mensaje.valor = cantidad;
          this.cantidad = cantidad;
          this.datosInventario.cantidad = cantidad;
        }
      } else if (atributo === 'descuento') {
        mensaje.prioridad = true;
        mensaje.atributo = 'porcentajeDescuento';
        this.datosInventario.porcentajeDescuento = valor;
      } else if (atributo === 'unitario') {
        mensaje.accion = 'editarValorUnitario';
        mensaje.atributo = 'valorUnitario';
        mensaje.prioridad = true;
        valor = (valor === '' || valor === ' ') ? this.datosInventario.valorUnitario : valor;
        this.datosInventario.valorUnitario = valor;
        this.datosInventario.precio = valor;
      } else if (atributo === 'bodega') {
        mensaje.accion = 'asociarDocMovInvInventarioBodega';
        mensaje.atributo = 'inventarioBodega';
        mensaje.prioridad = true;
        this.datosInventario.inventarioBodega = valor;
      } else if (atributo === 'unidad') {
        valorAnterior = { ...this.datosInventario.unidadMedida };
        mensaje.accion = 'asociarDocMovInvUnidadMedida';
        mensaje.atributo = 'unidadMedida';
        mensaje.prioridad = true;
        this.datosInventario.unidadMedida = valor;
      } else if (atributo === 'centro') {
        mensaje.accion = 'asociarDocMovInvCentroCosto';
        mensaje.atributo = 'centroCosto';
        this.datosInventario.centroCosto = valor;
      } else if (atributo === 'nota') {
        mensaje.atributo = 'concepto';
        mensaje.tipoDato = TipoDato[TipoDato.STRING];
        this.datosInventario.concepto = valor;
      } else if (atributo === 'borrador') {
        mensaje.prioridad = true;
        mensaje.atributo = 'borrador';
        mensaje.tipoDato = TipoDato[TipoDato.BOOLEAN];
      }

      if (!cantidadCero) {
        if (this.onlineOffline && this.documentoEncabezado.enLinea) {
          await this.cambiosOnline(valorAnterior, mensaje, this.datosInventario.porcentajeDescuento, dataObsequio)
        } else {
          this.edicionProductoEnCurso = false;
          this.continuarCambioEnProductoOff(atributo);
        }
      } else {
        this.edicionProductoEnCurso = false;
      }
    } else {
      this.edicionProductoEnCurso = false;
    }
  }

  async continuarCambioEnProductoOff(atributo) {
    let cupoCredito;
    let descuentoMaximoExcedido: boolean;
    let cupoDisponible: any = 0;
    if (['cantidad', 'descuento', 'porcentajeDescuento', 'unitario'].includes(atributo) && (this.datosInventario.precio || this.datosInventario.precio === 0)) {

      const validarDescuentoMaximo = sessionStorage.getItem('validarMaximoDescuento') == 'true';
      if (validarDescuentoMaximo && ['descuento', 'porcentajeDescuento'].includes(atributo)) {
        const vendedor = await this.productosDBService.getListTerceroId(this.documentoEncabezado.terceroInterno.id);
        if (vendedor.descuentoMaximo && this.datosInventario.porcentajeDescuento > parseFloat(vendedor.descuentoMaximo)) {
          this.datosInventario.descuento = 0;
          this.datosInventario.porcentajeDescuento = 0;
          this.copiaDatosInventario.descuento = 0;
          this.copiaDatosInventario.porcentajeDescuento = 0;

          this.favorable = false;
          descuentoMaximoExcedido = true;
          this.cambiosOffline(atributo)
        }
      }

      const formaPago = sessionStorage.getItem('formaPago');
      const listTer = await this.productosDBService.getListTerceroId(this.documentoEncabezado.terceroExterno.id);
      if (['Crédito', 'Credito'].includes(formaPago) && listTer.senCupoCredito) {
        let utilizado = parseInt(listTer.credito.utilizado);
        let cupo = parseInt(listTer.credito.cupo);
        const idDB = sessionStorage.getItem('idDocDB');
        let renglones = await this.productosDBService.getDocMovimimentoInventarioByDocEncDB(parseInt(idDB !== '0' ? idDB : this.documentoEncabezado.id));
        const porcentajeCupoCredito = await this.productosDBService.getParametrosConexionById(2);
        let totalFacturaGeneral = 0;
        let totalFactura = 0;
        let resultadoAntesDeProducto = 0;
        if (renglones && renglones.length > 0 && this.datosInventario.id) {
          renglones.forEach(e => {
            if (e.id != this.datosInventario.id) totalFacturaGeneral += e.precioRenglon;
          });
          resultadoAntesDeProducto = totalFacturaGeneral;
          resultadoAntesDeProducto += utilizado;
          totalFactura = totalFacturaGeneral;
        }

        const precio = this.calcularPrecioConImpuestos();
        let resultado = totalFactura + (parseFloat(precio.toString()) * parseInt(this.datosInventario.cantidad.toString()));
        if (this.datosInventario.porcentajeDescuento > 0) {
          let desc = resultado * this.datosInventario.porcentajeDescuento;
          resultado -= desc;
        }

        resultado = resultado ? parseFloat(resultado.toFixed(2)) : resultado;
        resultado += utilizado;
        cupoCredito = cupo - (cupo * (1 - porcentajeCupoCredito.valor));
        this.favorable = resultado <= cupoCredito;
        cupoDisponible = cupoCredito - resultadoAntesDeProducto > 0 ? cupoCredito - resultadoAntesDeProducto : 0;
        cupoDisponible = this.formatoUtilService.formatoMoneda(cupoDisponible, this.monedaLocal);
      }
    }

    if (this.favorable || this.datosInventario.precio === 0) {
      if (atributo === 'unidad') {
        let calculoPrecio = this.datosInventario.precio;
        if (this.datosInventario.unidadMedida.id != this.copiaDatosInventario.unidadMedida.id) {
          if (this.copiaDatosInventario.unidadMedida.id != this.datosInventario.unidadMedida['unidadMedidaBaseId']) {
            const unidadMedidaAnterior = this.dataUnidad.find(unidad => unidad.id == this.copiaDatosInventario.unidadMedida.id);
            calculoPrecio = this.datosInventario.precio / unidadMedidaAnterior.factor;
          }
          calculoPrecio = calculoPrecio * (this.datosInventario.unidadMedida.factor ? parseFloat(this.datosInventario.unidadMedida.factor) : 1);
          this.datosInventario.precio = calculoPrecio;
        }
      }
      setTimeout(async () => {
        await this.cambiosOffline(atributo);
      }, 200);
    } else {
      this.edicionProductoEnCurso = false;
      this.atributo = '';
      this.loading = false;
      this.favorable = true;
      const atributoData = atributo === 'descuento' ? 'porcentajeDescuento' : atributo === 'unitario' ? 'valorUnitario' : atributo;
      this.datosInventario[atributoData] = this.copiaDatosInventario[atributoData];
      this.popUpService.open({ codigoError: cupoCredito ? '021CupoMaximo' : descuentoMaximoExcedido ? '011' : '021', severidad: SeveridadEnum.ERROR, detalles: [cupoDisponible] });
    };
  }

  eraBorrador(id) {
    let mensaje = {
      clase: 'DocumentoMovimientoInventario',
      id: id,
      accion: 'editarDocumentoMovimientoInventario',
      atributo: 'borrador',
      valor: false,
      prioridad: true,
      tipoDato: TipoDato[TipoDato.BOOLEAN],
      permisoGrueso: this.tipoDocumento
    };
    this.serviceColas.agregarACola(mensaje);
  }

  async obtenerBodegas() {
    if (this.onlineOffline) {
      const paginacion = new PaginacionWo('bodega.nombre', 0, 10, 'ASC', [], 0);
      this.bodegaService.getBodegasInventario(this.idProducto, paginacion).subscribe(data => {
        this.dataBodega = data.content;
      });
    } else {
      this.dataBodega = this.detalle.bodegas;
    }
  }

  async obtenerUnidades() {
    if (this.onlineOffline) {
      this.unidadesM.getUnidadesMedidaId(this.idProducto).subscribe(data => {
        this.dataUnidad = data;
      });
    } else {
      this.dataUnidad = [];
      this.datosInventario.unidadConversionList.forEach(e => {
        if (!e.factor && e['factorConversion']) e.factor = e['factorConversion'];
        if (!e.nombre && e['nombreUnidadConversion']) e.nombre = e['nombreUnidadConversion'];
        if (!e.codigo && e['unidadCodigo']) e.codigo = e['unidadCodigo'];
        e.factor = e.factor ? e.factor : '1';
        e.unidadMedidaBaseId = e.unidadMedidaBaseId ? e.unidadMedidaBaseId : e.id;
        this.dataUnidad.push({ ...e });
      });
    }

  }

  async obtenerCentroDeCostos(event) {
    if (!event || !event.target || event.target.nodeName == 'INPUT') {
      if (this.onlineOffline) {
        this.posService.getCentrosCostos2().subscribe(data => {
          this.CentroDeCostos = data.children;
        });
      } else {
        this.CentroDeCostos = await this.productosDBService.getCentros();
      }
    }
  }

  async cambiosOnline(valorAnterior, mensaje, descuento, dataObsequio?) {
    const descuentoFinal = descuento;
    let popUpEsperarRespuesta = false;
    let respuesta = this.serviceColas.agregarACola(mensaje);
    if (respuesta) {
      let sub = respuesta.pipe(take(1)).subscribe(response => {
        this.edicionProductoEnCurso = false;
        this.atributo = '';
        if (response && response.length > 0) {
          let sinError = true;
          response.forEach(resp => {
            if (resp.codigoError) {
              sinError = false;
              if (resp.codigoError == '030') {
                this.cantidad = null;
                this.datosInventario.cantidad = null;
                this.popUpService.open({ codigoError: resp.codigoError, severidad: resp.severidad });
              } else if (resp.codigoError == '368') {
                popUpEsperarRespuesta = true;
                const detalles = [resp.valor.existencias];
                this.bodegaCantidades = resp.valor.bodega;
                this.numeroCantidadValido = resp.valor.existencias.split(':')[0];
                const codigoError = Number(this.numeroCantidadValido) <= 0 ? 'cantidadCero' : resp.codigoError;
                const severidad = Number(this.numeroCantidadValido) <= 0 ? SeveridadEnum.ERROR : resp.severidad;
                this.esperarRespuestaCantidades(valorAnterior, descuento);
                this.popUpService.open({ codigoError, severidad, conBoton: null, detalle: '', detalles });
              } else {
                if (resp.codigoError == '011') {
                  this.datosInventario.descuento = 0;
                  this.datosInventario.porcentajeDescuento = 0;
                  descuento = 0;
                }
                if(['978', '1172'].includes(resp.codigoError)) {
                  this.popUpService.open({ codigoError: resp.codigoError, severidad: resp.severidad, detalles: [resp.valor[1]] });
                  this.eliminarProducto();
                }else{
                  this.popUpService.open({ codigoError: resp.codigoError, severidad: resp.severidad });
                }
                if (resp.atributo != 'cantidad' && resp.valor) {
                  this.datosInventario[resp.atributo] = resp.valor;
                  this.copiaDatosInventario[resp.atributo] = resp.valor;
                } else {
                  this.datosInventario = { ...this.copiaDatosInventario };
                }
              }
              if (resp.atributo === 'cantidad') {
                this.cantidad = valorAnterior;
                this.datosInventario.cantidad = valorAnterior;
              }
              this.loading = false;
            } else {
              this.datosInventario[resp.atributo] = resp.valor;
              this.copiaDatosInventario[resp.atributo] = resp.valor;
              response.forEach(element => {
                if (
                  element.atributo == 'valorTotalRenglon'
                ) {
                  if (!this.ingresoPrimeraVez &&
                    element.accion == 'editarValorUnitario' && element.valor != mensaje.valor) {
                    this.popUpService.open({ codigoError: 'manejaIvaIncluido', severidad: SeveridadEnum.INFO });
                    this.ingresoPrimeraVez = true;
                  }
                }
              });
            }
          });
          if (sinError) {
            if (mensaje.atributo === 'inventario') {
              this.cambioEnProducto('cantidad', '1');
            }
            this.copiaDatosInventario = { ...this.datosInventario };
            this.finalizarAgregarProducto(mensaje.atributo);
          } else {
            this.datosInventario = { ...this.copiaDatosInventario };
            if (this.clickCerrar && !popUpEsperarRespuesta) {
              this.posService.guardandoProductoPopUp.next();
            }
          }
        } else {
          if (mensaje.atributo === "porcentajeDescuento" && mensaje.valor != '1') {
            this.datosInventario.senObsequio = false;
            this.esObsequioValor = false;
          }

          if (dataObsequio) {
            this.continuarGuardarObsequio(dataObsequio);
          }
          this.copiaDatosInventario = { ...this.datosInventario };
          this.loading = false;
          this.finalizarAgregarProducto(mensaje.atributo);
        }
      }, () => {
        this.edicionProductoEnCurso = false;
        this.atributo = '';
      });
      this.subscriptions.add(sub);
    } else {
      this.edicionProductoEnCurso = false;
      this.atributo = '';
    }
  }

  esperarRespuestaCantidades(valorAnterior, descuento) {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.event) {
          // verdadero seleccion la cantidad disponible
          let value = Number(this.numeroCantidadValido);
          this.datosInventario.cantidad = value;
          this.datosInventario.inventarioBodega = this.bodegaCantidades;

          let mensajeCantidad = {
            clase: 'DocumentoMovimientoInventario',
            id: this.datosInventario.renglonId,
            accion: 'editarCantidad',
            atributo: 'cantidad',
            valor: this.numeroCantidadValido,
            prioridad: false,
            tipoDato: TipoDato[TipoDato.NUMERIC],
            permisoGrueso: this.tipoDocumento
          };

          let mensajeBodega = {
            clase: 'DocumentoMovimientoInventario',
            id: this.datosInventario.renglonId,
            accion: 'asociarDocMovInvInventarioBodega',
            atributo: 'inventarioBodega',
            valor: this.bodegaCantidades.id,
            prioridad: true,
            tipoDato: TipoDato[TipoDato.NUMERIC],
            permisoGrueso: this.tipoDocumento
          };
          if (this.clickCerrar) {
            this.posService.guardandoProductoPopUp.next({ renglonId: this.datosInventario.renglonId, valorAnterior, mensajeCantidad, mensajeBodega, value, bodegaCantidades: this.bodegaCantidades, popUpExistenciasMostrado: true });
          } else {
            this.cambiosOnline(valorAnterior, mensajeCantidad, descuento);
            this.cambiosOnline(valorAnterior, mensajeBodega, descuento);
          }
        } else {
          this.datosInventario = { ...this.copiaDatosInventario };
          if (this.clickCerrar) {
            this.posService.guardandoProductoPopUp.next({ cancelar: true, renglonId: this.datosInventario.renglonId, valorAnterior: this.copiaDatosInventario.cantidad, bodegaCantidades: this.copiaDatosInventario.inventarioBodega, popUpExistenciasMostrado: true });
          }
        }
      });
  }

  async finalizarAgregarProducto(atributo) {
    this.copiaDatosInventario = { ...this.datosInventario };
    this.facturacion.toggleFact(this.datosInventario);
    this.loading = false;
    if (atributo == 'unitario' || atributo == 'valorUnitario') {
      this.calcularImpuestos('');
    } else {
      this.calcularImpuestos(atributo);
    }
  }

  async cambiosOffline(atributo) {
    if (this.datosInventario.cantidad) {
      this.valorTotalDescuento = parseFloat(this.datosInventario.cantidad ? this.datosInventario.cantidad.toString() : '0') * this.datosInventario.precio;
      if (this.datosInventario.porcentajeDescuento > 0) {
        let desc = parseFloat(this.valorTotalDescuento) * this.datosInventario.porcentajeDescuento;
        this.valorTotalDescuento -= desc;
      }
    }

    this.datosInventario.enLinea = false;
    this.documentoEncabezado.enLinea = false;
    if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
    this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
    this.copiaDatosInventario = { ...this.datosInventario };
    this.loading = false;
    this.calcularImpuestos(atributo);
  }

  async calcularImpuestos(atributo) {
    let inventario = await this.productosDBService.getInventarioById(this.datosInventario.inventario.id);
    const terminal = JSON.parse(sessionStorage.getItem('TERMINAL'));
    const cantidad = Number(this.datosInventario.cantidad);
    const idEmpresa = parseInt(this.documentoEncabezado.empresa.id);
    const impuestoAplicablesEmpresa = inventario.impuestoInventario.filter(imp => imp.empresas && imp.empresas.includes(idEmpresa));
    const impuestoValor = impuestoAplicablesEmpresa.filter(e => e.tipoCobro.toUpperCase() == 'VALOR');
    const impuestoPorcentaje = impuestoAplicablesEmpresa.filter(e => e.tipoCobro.toUpperCase() == 'PORCENTAJE');
    const impuestos: ImpuestoDocumentoMovimiento[] = [];

    const listaPrecio = this.documentoEncabezado.terceroExterno && this.documentoEncabezado.terceroExterno.listaPrecioPredeterminada ? this.documentoEncabezado.terceroExterno.listaPrecioPredeterminada : terminal.listaPrecioPredeterminada;

    const listaPrecioInventario = inventario.listaPrecioPos.find(dato => dato.id == listaPrecio.id);
    const impuestosListaPrecio = listaPrecioInventario ? listaPrecioInventario.impuestos : [];

    if (atributo == 'unitario' || atributo == 'valorUnitario') {
      const valorOperaciones = new Decimal(this.datosInventario.valorUnitario);
      const valorInicial = this.datosInventario.valorUnitario;

      if (impuestoValor && impuestoValor.length > 0) {
      impuestoValor.forEach(element => {
        const existeEnListaPrecio = impuestosListaPrecio.some(imp => imp.id == element.id);
        if (existeEnListaPrecio) {
          valorOperaciones.minus(element.tarifa);
          this.datosInventario.valorUnitario = Number(valorOperaciones);
        }
      });
        const valor: any = Number(this.datosInventario.valorUnitario);
        this.datosInventario.valorUnitario = valor;
      }

      if (impuestoPorcentaje && impuestoPorcentaje.length > 0) {
        let valorImpuestoPorcentaje = 0;
        impuestoPorcentaje.forEach(element => {
          const existeEnListaPrecio = impuestosListaPrecio.some(imp => imp.id == element.id);
          if (existeEnListaPrecio) {
            valorImpuestoPorcentaje += Number(element.tarifa);
          }
        });

        const valorUnitario: any = valorOperaciones.div(new Decimal(1).plus(valorImpuestoPorcentaje))
          .toDecimalPlaces(10, Decimal.ROUND_UP);
        const valor: any = Number(valorUnitario);
        this.datosInventario.valorUnitario = valor;
      }

      if (valorInicial != this.datosInventario.valorUnitario && !this.onlineOffline) {
        this.popUpService.open({ codigoError: 'manejaIvaIncluido', severidad: SeveridadEnum.INFO });
        this.ingresoPrimeraVez = true;
      }

      if (this.clickCerrar) this.posService.guardandoProductoPopUp.next('cantidadCambioProductosFinalizados');
    }

    impuestoPorcentaje.forEach(element => {
      const valorImpuestoSinDescuento = (this.datosInventario.valorUnitario * Number(element.tarifa));
      let valorImpuesto = (this.datosInventario.valorUnitario * Number(element.tarifa));
      if (this.datosInventario.porcentajeDescuento && this.datosInventario.porcentajeDescuento > 0) valorImpuesto = valorImpuesto - ((this.datosInventario.senObsequio && !(element.nombre.toLowerCase().includes('impoconsumo') || element.tipo.toLowerCase().includes('impoconsumo'))) ? 0 : (valorImpuesto * Number(this.datosInventario.porcentajeDescuento)));
      const valor: any = Number(valorImpuesto);
      const impuesto: ImpuestoDocumentoMovimiento = {
        tipo: element.nombre ? element.nombre : element.tipo,
        valorUnitario: valor,
        valorTotal: valor * cantidad,
        porcentaje: Number(element.tarifa),
        valorTotalSinDescuento: valorImpuestoSinDescuento * cantidad
      }
      impuestos.push(impuesto);
    });

    impuestoValor.forEach(item => {
      const valor: any = Number(item.tarifa);
      const impuesto: ImpuestoDocumentoMovimiento = {
        tipo: item.nombre ? item.nombre : item.tipo,
        valorUnitario: valor,
        valorTotal: valor * cantidad,
        valorTotalSinDescuento: valor * cantidad
      }
      impuestos.push(impuesto);
    });

    this.datosInventario.impuestos = impuestos;
    this.datosInventario.impuestoRenglon = 0;
    this.datosInventario.impuestoTotalRenglon = 0;
    this.datosInventario.impuestoTotalSinDescuentos = 0;
    this.datosInventario.impuestos.forEach((element: any) => {
      this.datosInventario.impuestoRenglon += parseFloat(element.valorUnitario);
      this.datosInventario.impuestoTotalRenglon += parseFloat(element.valorTotal);
      this.datosInventario.impuestoTotalSinDescuentos += parseFloat(element.valorTotalSinDescuento);
    });

    if (this.datosInventario.porcentajeDescuento && this.datosInventario.porcentajeDescuento > 0) {
      this.datosInventario.precio = Number((Number(this.datosInventario.valorUnitario) - (Number(this.datosInventario.valorUnitario) * Number(this.datosInventario.porcentajeDescuento))) + Number(this.datosInventario.impuestoRenglon));
    } else {
      this.datosInventario.precio = Number(this.datosInventario.valorUnitario) + Number(this.datosInventario.impuestoRenglon);
    }

    this.datosInventario.precio = Number(this.datosInventario.precio.toFixed(2));
    this.datosInventario.precioRenglon = Number((this.datosInventario.precio * Number(this.datosInventario.cantidad)).toFixed(2));
    this.datosInventario.valorTotalRenglon = Number((this.datosInventario.valorUnitario * Number(this.datosInventario.cantidad)).toFixed(2));


    if (this.sincronizando) this.datosInventario.modificadoEnSync = true;
    this.productosDBService.setDocumentoMovimimentoInventarioUno(this.datosInventario);
    if (!this.datosInventario.id) this.datosInventario = await this.productosDBService.getDocumentoMovimientoInventarioLast();
    this.copiaDatosInventario = { ...this.datosInventario };
    this.edicionProductoEnCurso = false;
    if (this.clickCerrar) {
      this.posService.guardandoProductoPopUp.next();
    }
  }

  calcularPrecioConImpuestos() {
    let precio = 0;
    const inventario = this.inventario;
    const cantidad = Number(this.datosInventario.cantidad);
    const idEmpresa = parseInt(this.documentoEncabezado.empresa.id);
    const impuestoAplicablesEmpresa = inventario.impuestoInventario.filter(imp => imp.empresas && imp.empresas.includes(idEmpresa));
    const impuestoValor = impuestoAplicablesEmpresa.filter(e => e.tipoCobro && e.tipoCobro.toUpperCase() == 'VALOR');
    const impuestoPorcentaje = impuestoAplicablesEmpresa.filter(e => e.tipoCobro && e.tipoCobro.toUpperCase() == 'PORCENTAJE');
    const impuestos: ImpuestoDocumentoMovimiento[] = [];
    const impuestosListaPrecio = inventario.listaPrecios ? inventario.listaPrecios.impuestos : [];

    impuestoValor.forEach(element => {
      const existeEnListaPrecio = impuestosListaPrecio.some(imp => imp.id == element.id);
      if (existeEnListaPrecio) {
        this.datosInventario.valorUnitario = this.datosInventario.valorUnitario - Number(element.tarifa);
      }
    });

    impuestoPorcentaje.forEach(element => {
      const existeEnListaPrecio = impuestosListaPrecio.some(imp => imp.id == element.id);
      if (existeEnListaPrecio) {
        this.datosInventario.valorUnitario = this.datosInventario.valorUnitario / (1 + Number(element.tarifa));
      }
    });

    impuestoPorcentaje.forEach(element => {
      const valorImpuestoSinDescuento = (this.datosInventario.valorUnitario * Number(element.tarifa));
      let valorImpuesto = (this.datosInventario.valorUnitario * Number(element.tarifa));
      if (this.datosInventario.porcentajeDescuento && this.datosInventario.porcentajeDescuento > 0) valorImpuesto = valorImpuesto - (valorImpuesto * Number(this.datosInventario.porcentajeDescuento));
      const impuesto: ImpuestoDocumentoMovimiento = {
        tipo: element.tipo,
        valorUnitario: valorImpuesto,
        valorTotal: valorImpuesto * cantidad,
        porcentaje: Number(element.tarifa),
        valorTotalSinDescuento: valorImpuestoSinDescuento * cantidad
      }
      impuestos.push(impuesto);
    });

    impuestoValor.forEach(item => {
      const impuesto: ImpuestoDocumentoMovimiento = {
        tipo: item.tipo,
        valorUnitario: Number(item.tarifa),
        valorTotal: Number(item.tarifa) * cantidad,
        valorTotalSinDescuento: Number(item.tarifa) * cantidad
      }
      impuestos.push(impuesto);
    });

    let impuestoRenglon: any = 0;
    impuestos.forEach(element => impuestoRenglon += element.valorUnitario);
    if (this.datosInventario.porcentajeDescuento && this.datosInventario.porcentajeDescuento > 0) {
      precio = (Number(this.datosInventario.valorUnitario) - (Number(this.datosInventario.valorUnitario) * Number(this.datosInventario.porcentajeDescuento))) + Number(impuestoRenglon);
    } else {
      precio = Number(this.datosInventario.valorUnitario) + Number(impuestoRenglon);
    }
    return precio;
  }

  esperarRespuesta(tipoMensaje?) {
    const resp = this.popUpService.respuesta.subscribe(
      async (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup') {
          if (respuesta.event) {
            let respCerrar: any = false;
            if ((!this.datosInventario.precio || this.datosInventario.precio == 0) && (this.datosInventario.inventarioBodega && this.datosInventario.unidadMedida && (this.datosInventario.cantidad && this.datosInventario.cantidad > 0)) && tipoMensaje != 'favorable') {
              this.datosInventario.precioRenglon = this.valorTotalDescuento ? this.valorTotalDescuento : 0;
              if (this.sincronizando) this.datosInventario.modificadoEnSync = true;
              this.productosDBService.setDocumentoMovimimentoInventarioUno(this.datosInventario);
              if (!this.datosInventario.id) this.datosInventario = await this.productosDBService.getDocumentoMovimientoInventarioLast();
              this.copiaDatosInventario = { ...this.datosInventario };
              if (this.onlineOffline && this.documentoEncabezado.enLinea) this.eraBorrador(this.idPrFV);
              this.datosInventario['edicionProductoEnCurso'] = this.edicionProductoEnCurso;
              respCerrar = this.datosInventario;
              this.clickCerrar = true;
              this.cerrar.emit(respCerrar);
            } else {
              if (!this.datosInventario.id) this.datosInventario = await this.productosDBService.getDocumentoMovimientoInventarioLast();
              this.eliminarProducto();
            }
          } else {
            if (!this.datosInventario.precio || this.datosInventario.precio == 0) {
              setTimeout(() => {
                this.esperarRespuestaSeguirEditando();
                this.popUpService.open({ codigoError: 'continuarEditando', severidad: SeveridadEnum.QUESTION, conBoton: true, botonSiNo: true })
              }, 50)
            }
          }
        }
      }
    )
  }

  esperarRespuestaSeguirEditando() {
    const resp = this.popUpService.respuesta.subscribe(
      async (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup' && !respuesta.event) {
          if (!this.datosInventario.id) this.datosInventario = await this.productosDBService.getDocumentoMovimientoInventarioLast();
          this.eliminarProducto()
        }
      });
  }

  async eliminarProducto(data?) {
    if (this.onlineOffline) {
      let mensaje = {
        id: this.idPrFV,
        accion: 'eliminarDocumentoMovimientoInventario',
        clase: 'DocumentoMovimientoInventario',
        valor: 'ok',
        prioridad: true,
        permisoGrueso: this.tipoDocumento,
        atributo: ''
      };
      this.serviceColas.agregarACola(mensaje);

      if (this.datosInventario) {
        if (!this.datosInventario.id) this.datosInventario = await this.productosDBService.getDocumentoMovimientoInventarioLast();
        this.productosDBService.deleteMovimientoInventarioByID(this.datosInventario.id);
      }
    } else if (this.datosInventario) {
      this.productosDBService.deleteMovimientoInventarioByID(this.datosInventario.id);
    }
    this.cerrar.emit({ data: data});
  }

  async cerrarPopup() {
    if (this.documentoEncabezado.anulado) {
      this.cerrar.emit(this.datosInventario);
    } else {
      setTimeout(async () => {
        if (this.datosInventario && ((this.datosInventario.precio && this.datosInventario.precio != 0) || (this.datosInventario.porcentajeDescuento == 1)) && this.datosInventario.inventarioBodega && this.datosInventario.unidadMedida && (this.datosInventario.cantidad && this.datosInventario.cantidad > 0)) {
          if (this.favorable) {
            if (this.sincronizando) this.datosInventario.modificadoEnSync = true;
            this.productosDBService.setDocumentoMovimimentoInventarioUno(this.datosInventario);
            if (!this.datosInventario.id) this.datosInventario = await this.productosDBService.getDocumentoMovimientoInventarioLast();
            if (this.onlineOffline && this.documentoEncabezado.enLinea) this.eraBorrador(this.idPrFV);
            this.datosInventario['edicionProductoEnCurso'] = this.edicionProductoEnCurso;
            this.datosInventario['atributo'] = this.edicionProductoEnCurso ? this.atributo : '';
            this.clickCerrar = true;
            this.cerrar.emit(this.datosInventario);
          } else {
            this.esperarRespuesta('favorable');
            this.popUpService.open({ codigoError: '021PosCerrar', severidad: SeveridadEnum.QUESTION, conBoton: true, botonSiNo: true })
          }
        } else {
          this.esperarRespuesta();
          this.datosInventario && (!this.datosInventario.precio || this.datosInventario.precio == 0) && (this.datosInventario.inventarioBodega && this.datosInventario.unidadMedida && (this.datosInventario.cantidad && this.datosInventario.cantidad > 0)) ? this.popUpService.open({ codigoError: 'sinValorUnitario', severidad: SeveridadEnum.WARNING, conBoton: true, botonSiNo: true }) : this.popUpService.open({ codigoError: 'sinDatosCompletos', severidad: SeveridadEnum.ERROR, conBoton: true, eliminar: true });
        }
      }, 500);
    }
  }

  onRightClick(e, producto) {
    if (this.onlineOffline) {
      const botones: BotonMenuContextual[] = [
        new BotonMenuContextual(OpcionTabla.LISTADO_PRECIOS, () => this.arregloDocumento = {
          pagina: "",
          registrosPagina: 25,
          columna: "id",
          orden: 'ASC',
          contabilizado: this.documentoEncabezado.contabilizado,
          documento: producto.inventario.id,
          idRegistro: producto.renglonId,
          estaCruzado: false,
          presentadoElectronica: false,
          idLocal: producto.id
        })
      ];
      let x = e.clientX; let w = window.innerWidth;
      if (x + 200 > w) {
        x = e.clientX - 145;
      }
      this.menuContextualComponent.abrir(x, e.clientY, botones);
    }
  }

  ActualizarValorUnitario(e) {
    let envioDatosModificados;
    if (e != undefined) {
      envioDatosModificados = {
        columna: 'valorUnitario',
        edita: true,
        fila: this.filaListaPrecio,
        valorAnterior: this.datosInventario.valorUnitario,
        valorNuevo: e,
        valorSeleccionado: undefined
      }
      this.datosInventario.valorUnitario = e;
      this.arregloDocumento = null;
      this.cambioEnProducto('unitario', this.datosInventario.valorUnitario);
    }
  }


  obsequioProducto(data) {
    if (this.datosInventario.porcentajeDescuento == 1) {
      this.continuarGuardarObsequio(data);
    } else {
      this.datosInventario.porcentajeDescuento = 1;
      this.cambioEnProducto('descuento', this.datosInventario.porcentajeDescuento, data);
    }
  }

  continuarGuardarObsequio(data) {
    if (this.onlineOffline && this.documentoEncabezado.enLinea) {
      let mensaje = {
        id: this.datosInventario.renglonId,
        accion: 'editarDocumentoMovimientoInventario',
        clase: 'DocumentoMovimientoInventario',
        atributo: data.atributo,
        valor: data.valor,
        tipoDato: TipoDato[TipoDato.BOOLEAN],
        prioridad: true,
        permisoGrueso: this.tipoDocumento
      };
      let variable = this.serviceColas.agregarACola(mensaje);
      if (variable) {
        let sub = variable.subscribe(
          response => {
            if (response.length > 0) {
              if (response[0].codigoError) {
                this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });
                this.esObsequioValor = this.datosInventario.senObsequio;
              }
            } else {
              this.esObsequioValor = data.valor;
              this.datosInventario.senObsequio = data.valor;
              this.productosDBService.setDocumentoMovimimentoInventarioUno(this.datosInventario);
              this.calcularImpuestos('');
            }
          });
        this.subscriptions.add(sub);
      }
    }
  }

  abrirPersonalizados() {
    this.verPersonalizados.emit();
  }

  cerrarMenuContextual() {
    this.menuContextualComponent.cerrar();
  }

  cerrarPopUpError(){
    this.mostrarPopupSerial = false;
    this.mostrarPopupTallaColor = false;
    this.eliminarProducto();
  }

}
