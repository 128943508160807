import { ColaRESTService } from './../../../../../libs/servicios/src/lib/cola/cola-rest.service';
import { Component, OnInit, ViewChild, OnDestroy, Inject, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { RecuperarContrasenaComponent } from '@wo/frontend/login/recuperar-contrasena/recuperar-contrasena.component';
import { UrdLogin, Usuario, SeveridadEnum, PlataformaEnum } from '@wo/modelo';
import { ConfiguracionGeneralService, LoginService, LogoutService, MapaAcciones, PreferenciasUsuarioService, TabsService, UsuariosService } from '@wo/servicios';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { forkJoin, Subscription } from 'rxjs';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';
import { urlService } from '../environmentDynamicService';
import { CookieService } from 'ngx-cookie-service';
export interface url {
  url: string;
  urlReporte: string
  urlFront?: string;
  abreviaturaPais?: string;
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  @Input() popUp: boolean;
  @Output() inicioLogin = new EventEmitter<any>();
  @Output() respuestaLogin = new EventEmitter<any>();
  @ViewChild(RecuperarContrasenaComponent, { static: false })
  recuperarContrasena: RecuperarContrasenaComponent;
  loading: boolean;
  loadingPopUpMultiempresa: boolean = false;
  popupMultiEmpresa: boolean = false;
  listaLicencias = [];
  hasMultiLicencia: boolean = false;

  mostrarDatosSesion = false;
  mostrarUsuarioLog = false;
  mostrarChangePassword = false;
  mensajeTraducido = '';

  dominios: Array<any> = [];
  loginCredentials = { username: '', contrasena: '' };
  changePasswordInfo = { password: '', confPassword: '', senPoliticas: false, senTerminos: false };
  message = '';
  mostrarDatos: boolean = true;
  private usuarioSesion = new Usuario();
  private urds = [];
  public dominioSeleccionado: any;
  datos: any;
  private idsPermisos: Array<any> = [];
  private idsExcepciones: Array<any> = [];
  static arbolComando: any;
  listaEmails: Array<any> = [];
  codigo: any;
  token: any;
  urlDecode: string;
  urlFront: string;
  abreviaturaPais: string;
  noUserLogueado = false;
  licenciaSeleccionada: any;

  get arbolComando() {
    return LoginComponent.arbolComando;
  }

  noSesionesAbiertas: boolean;
  lastPing?: Date = null;

  private subscriptions = new Subscription();
  popupImagen: boolean;
  imagenPopup: string = '/assets/images/mantenimientoCloudHorizontal.jpeg';
  imagenPopupVertical: string = '/assets/images/mantenimientoCloudVertical.jpeg';

  POS: boolean;
  online = navigator.onLine;
  sonidoLogin: any;
  configCookies: any;
  okCookies = true;
  dominio:string = '.worldoffice.cloud';

  constructor(
    private router: Router,
    private servicioLogin: LoginService,
    private preferenciasUsuarioService: PreferenciasUsuarioService,
    private configuracionGeneralService: ConfiguracionGeneralService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private usuarioService: UsuariosService,
    private route: ActivatedRoute,
    private tabsService: TabsService,
    private popUpService: PopUpService,
    private integracionPOS: IntegracionPosService,
    private url: urlService,
    private cookieService: CookieService,
    private logoutService: LogoutService,
    private colaRest: ColaRESTService,
    @Inject('local') private local: boolean,
    @Inject('plataforma') plataforma: PlataformaEnum
  ) {
    this.codigo = this.route.snapshot.paramMap.get('codigo');
    if (this.codigo) {
      cookieService.deleteAll()
      localStorage.clear()
      sessionStorage.clear()
      this.urlDecode = atob(this.codigo)
    }

    this.token = this.route.snapshot.paramMap.get('token');

    this.POS = plataforma == PlataformaEnum.POS;
    if (this.POS) this.sonidoLogin = new Audio("assets/sonidosInterfaz/inicio.mp3");
  }

  ngOnInit() {
    $('body').removeClass('modoOscuro');
    $('.pane').removeClass('modoOscuro');
    $('#contenedorChat').removeClass('inicio');
    sessionStorage.removeItem('modoOscuro');
    sessionStorage.removeItem('urlEntorno');
    const session = JSON.parse(localStorage.getItem('_sessionStorage'));
    const cookies: any = this.cookieService.getAll();

    this.configCookies = cookies.config && atob(cookies.config) ? JSON.parse(atob(cookies.config)) : null;

    if (this.urlDecode) sessionStorage.setItem('cargarUrlServicio', this.urlDecode);
    if (this.codigo && this.urlDecode.includes('/') && session && session.Token) {
      let objetoSession = session;

      if (objetoSession !== null) {
        for (let index = 0; index < Object.keys(objetoSession).length; index++) {
          const element = objetoSession[Object.keys(objetoSession)[index]];
          sessionStorage.setItem(Object.keys(objetoSession)[index], element);
        }
      }
    } else if (this.POS && !this.online) {
      this.integracionPOS.consultarDataInicioLoginOffline.next();
      const consultaDataInicial = this.integracionPOS.dataInicioLoginOffline.subscribe((data) => {
        if ((data.token && data.token.valor)) {
          sessionStorage.setItem('RELOAD', 'false');
          sessionStorage.setItem('TRADUJO', 'es-co');
          sessionStorage.setItem('USER', data.token.clave);
          sessionStorage.setItem('Token', data.token.valor);
          if (data.terminal) sessionStorage.setItem('TERMINAL', data.terminal.valor);
          if (data.datosTerminalSel) sessionStorage.setItem('datosTerminalSel', data.datosTerminalSel.valor);
          if (data.monedaLocal) sessionStorage.setItem('MONEDALOCAL', data.monedaLocal.valor);
          this.router.navigate(['/panel']);
        }
        else {
          this.loginNecesario();
        }
      });
      this.subscriptions.add(consultaDataInicial);
    } else if (this.online && !this.popUp && cookies.user && cookies.token) {
      const ambiente = cookies.ambiente ? JSON.parse(cookies.ambiente) : null;
      if (ambiente) {
        this.url.setUrl(ambiente.url);
        this.url.setUrlReportes(ambiente.urlReporte);
      }
      if (cookies.ambiente) sessionStorage.setItem('urlEntorno', cookies.ambiente);

      this.loading = true;
      sessionStorage.setItem('RELOAD', 'false');
      sessionStorage.setItem('TRADUJO', 'es-co');
      sessionStorage.setItem('USER', cookies.user);
      sessionStorage.setItem('Token', cookies.token);
      this.loginCredentials = { username: cookies.user, contrasena: '' };
      this.usuarioRegistrado();
    } else {
      if (!this.popUp) {
        sessionStorage.removeItem('TERMINAL');
        sessionStorage.removeItem('USER');
        this.integracionPOS.eliminarTerminal();
      }
      this.loginNecesario();
    }
  }

  loginNecesario() {
    if (!this.popUp) {
      $('body').removeClass('modoOscuro');
      $('.pane').removeClass('modoOscuro');
      $('#contenedorChat').removeClass('inicio');
      sessionStorage.removeItem('modoOscuro');
      const session = JSON.parse(localStorage.getItem('_sessionStorage'));

      if (this.urlDecode) sessionStorage.setItem('cargarUrlServicio', this.urlDecode);
      if (this.codigo && this.urlDecode.includes('/') && session && session.Token) {

        let objetoSession = session;

        if (objetoSession !== null) {
          for (let index = 0; index < Object.keys(objetoSession).length; index++) {
            const element = objetoSession[Object.keys(objetoSession)[index]];
            sessionStorage.setItem(Object.keys(objetoSession)[index], element);
          }
        }

        this.router.navigate([this.urlDecode]);
      } else {
        this.noUserLogueado = true;
        const session = ['TABS_ACTIVAS', 'RELOAD', 'TRADUJO', 'USER', 'Token', 'PREFERENCIAS', 'MONEDALOCAL', 'PAISLOCAL', 'USUARIOSESION', 'ROLESSESION', 'PERMISOSUSUARIO', 'EXCEPCIONUSUARIO', 'CONFIGURADO', 'ACTIVELICENSE', 'ALMACENAMIENTO', 'SENCONFIGFE', 'LICENCE', 'LICENCENOMBRE', 'PANTALLASNOVISITADAS', 'PREFERENCIADECIMALES'];
        session.forEach(element => sessionStorage.removeItem(element));
        this.route.queryParams.subscribe(params => {
          let email = params['email'];
          if (email)
            this.loginCredentials.username = email;
        });
        this.tabsService.closeAllTabs(true, true);
      }
    } else {
      this.loginCredentials.username = sessionStorage.getItem('USER');
    }
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup') {
          this.respuestaPopUp()
        } else if (respuesta.tipo == 'focusOut') {
          this.respuestaPopUp()
        }
      }
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Detecta el click para mostrar u ocultar los datos del login
   */
  mostrarDatosLogin() {
    if (this.mostrarDatos) {
      this.mostrarDatos = false;
    } else {
      this.mostrarDatos = true;
    }
  }

  /**
   * Detecta el click para mostrar u ocultar los datos dela emoresa
   */
  cambiarEmpresaLogin() {
    if (this.mostrarDatos) {
      this.mostrarDatos = false;
    } else {
      this.mostrarDatos = true;
    }
    //this.autenticarTenant(this.dominioSeleccionado.id);
    this.autenticarTenant(this.dominioSeleccionado.dominio.id);
  }

  /**
   * Registra el ingreso al backend mediante servicio rest e inicializa variables locales
   */
  login() {
    this.loading = true;
    this.servicioLogin.listarTerceroUsuarios(this.loginCredentials.username, this.loginCredentials.contrasena).subscribe((res:any) => {

      if(!res.codigoError) {
        const {listaLicencias} = res;
        this.listaLicencias = listaLicencias;
        this.hasMultiLicencia = !!res.multilicencia;
        if (!this.hasMultiLicencia) {
          this.licenciaSeleccionada = listaLicencias[0].numeroLicencia;
        }
        if (this.local) {
          sessionStorage.removeItem('urlEntorno');
          if (this.hasMultiLicencia) {
            this.loading = false;
            this.popupMultiEmpresa = true;
          } else {
            this.consultaAmbienteLicencia(this.licenciaSeleccionada);
          }
        } else {
          if (this.hasMultiLicencia) {
            this.loading = false;
            this.popupMultiEmpresa = true;
          } else {
            this.consultaAmbienteLicencia(this.licenciaSeleccionada);
          }
        }
      } else {
        this.loading = false;
        this.popUpService.open({codigoError: res.codigoError, severidad: SeveridadEnum.ERROR});
      }
    }
    , () => {
      this.loading =false;
      this.popUpService.open({codigoError: "003", severidad: SeveridadEnum.ERROR});
    });

  }

  seleccionAmbienteLicencia(licencia: any) {
    this.loadingPopUpMultiempresa = true;
    const { numeroLicencia } = licencia;
    this.licenciaSeleccionada = numeroLicencia;
    this.consultaAmbienteLicencia(numeroLicencia);
  }

  consultaAmbienteLicencia(numeroLicencia: any) {
    this.servicioLogin.consultaAmbienteLicencia(this.loginCredentials.username, numeroLicencia).subscribe((res: url) => {
      this.urlFront = res.urlFront;
      this.abreviaturaPais = res.abreviaturaPais;
      let fecha = new Date();
      fecha.setDate(fecha.getDate() + 1);
      document.cookie = `prefijoPais=${this.abreviaturaPais};expires=${fecha.toUTCString()};domain=${this.dominio};path=/`;
      this.local ? this.iniciarLogueo() : this.validarUrl(res.url, res.urlReporte);

    }, () => {
      this.popUpService.open({codigoError: "003", severidad: SeveridadEnum.ERROR});
    });
  }

  validarUrl(url:string, urlReporte:string) {

    if (url && url == 'mantenimiento') {
      sessionStorage.removeItem('urlEntorno');
      this.popupImagen = true;
    } else if ((url && urlReporte)) {
      this.url.setUrl(url);
      this.url.setUrlReportes(urlReporte);
      sessionStorage.setItem('urlEntorno', JSON.stringify({url, urlReporte}));
      let fecha = new Date();
      fecha.setDate(fecha.getDate() + 1);
      document.cookie = `ambiente=${JSON.stringify({url, urlReporte})};expires=${fecha.toUTCString()};domain=${this.dominio};path=/`;
      this.iniciarLogueo();
    } else {
      sessionStorage.removeItem('urlEntorno');
      this.validarError();
    }

  }

  iniciarLogueo() {
    this.loading = !this.hasMultiLicencia;
    this.inicioLogin.emit();
    if (this.token) {
      this.continuarLogueo(this.token);
    } else {
      const nuevoDato = this.loginCredentials.username + '#licencia#schema#' + this.licenciaSeleccionada;
      this.servicioLogin.login(nuevoDato, this.loginCredentials.contrasena, this.POS)
        .subscribe(
          response => {
            if (response.status === 200) {
              this.continuarLogueo(response.headers.get('Authorization'));
            }
          },
          () => {
            this.validarError();
            this.loading = false;
          }
        );
    }
  }

  continuarLogueo(respuestaToken) {
  sessionStorage.setItem('RELOAD', 'false');
  sessionStorage.setItem('TRADUJO', 'es-co');
  sessionStorage.setItem('USER', this.loginCredentials.username);
  const token = respuestaToken;
  sessionStorage.setItem('Token', token);
  this.usuarioRegistrado();

  let fecha = new Date();
  fecha.setDate(fecha.getDate() + 1);
  document.cookie = `user=${this.loginCredentials.username};expires=${fecha.toUTCString()};domain=${this.dominio};path=/`;
  document.cookie = `token=${token};expires=${fecha.toUTCString()};domain=${this.dominio};path=/`;
  }

  validarError() {
    let username = this.loginCredentials.username.toLowerCase();
    this.servicioLogin.validarError(username).subscribe(
      response => {
        let codigo = response.codigoError;
        let lista = response.infoUsuario;
        this.loading = false;
        if (codigo == 'US-CONC') {
          this.mostrarDatosSesion = true;
          document.getElementById("dialogLoginLight").className = "dialogLogin sesiones";
          this.listaEmails = lista;
        } else if (codigo == 'US-LOG') {
          this.mostrarUsuarioLog = true;
          // const popup: Popup = new Popup(
          //   'ALERTAS.' + codigo,
          //   'dialogAlertAdvertencia',
          //   'fa-exclamation-triangle',
          //   'ALERTAS.advertencia',
          //   ''
          // );
          // this.popUpService.mostrarConBotones(popup);
        } else {
          // const popup: Popup = new Popup(
          //   'ALERTAS.' + codigo,
          //   'dialogAlertError',
          //   'fa-times-circle',
          //   'ALERTAS.error',
          //   ''
          // );
          this.esperarRespuesta();
          this.popUpService.open({ codigoError: codigo, severidad: SeveridadEnum.ERROR });
          setTimeout(() => this.respuestaLogin.emit(false), 500);
        }
      }, error => {
        this.loading = false;
        this.errorHandlerComponent.handler(error);
      }
    )
  }

  regresarLogin() {
    this.mostrarDatosSesion = false;
    document.getElementById("dialogLoginLight").className = "dialogLogin";
  }

  regresarLoginUserLog(e) {
    this.mostrarUsuarioLog = false;
    if (e) {
      this.cerrarSesionDoble();
    }
  }

  cerrarSesionDoble() {
    this.loading = true;
    let pojo = {
      email: this.loginCredentials.username,
      pass: this.loginCredentials.contrasena
    }
    this.servicioLogin.cerrarSesiones(pojo).subscribe(
      response => {
        this.loading = false;

        if (response.length > 0) {
          if (response[0].codigoError) {
            this.esperarRespuesta();
            this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });
          }

        } else {
          this.noSesionesAbiertas = true;
          this.esperarRespuesta();
          this.popUpService.open({ codigoError: 'sessionCerradaExito', severidad: SeveridadEnum.SUCCESS });
        }
      }
    )
  }

  respuestaPopUp() {
    if (this.noSesionesAbiertas) {
      this.login();
    }
  }


  /**
   * Solicita el urd del usuario registrado
   * @param username
   * @param password
   */
  padres: Array<UrdLogin> = [];
  usuarioRegistrado() {
    this.padres = [];
    this.servicioLogin.getURDSoTenant().subscribe(res => {
      if (res.superUsuario == true) {
        this.padres = res.tenantsOURDs;

        this.padres.forEach(element => {
          element.superAdmin = true;
        });
        this.mostrarDatos = false;
        this.loading = false;
      } else {
        this.urds = res.tenantsOURDs;
        this.dominiosTenant();
      }
    }, () => {
      this.popUp = false;
      this.cookieService.delete('user', '/', this.dominio);
      this.cookieService.delete('token', '/', this.dominio);
      this.cookieService.delete('config', '/', this.dominio);
      this.respuestaLogin.emit(false);
      this.limpiarCerrarSesion();
    });
  }

  limpiarCerrarSesion() {
    sessionStorage.removeItem('TABS_ACTIVAS');
    this.tabsService.closeAllTabs(true);
    this.colaRest.clear();
    this.cookieService.delete('user', '/', this.dominio);
    this.cookieService.delete('token', '/', this.dominio);
    this.cookieService.delete('config', '/', this.dominio);
    setTimeout(() => {
      this.router.navigate(['/login']).then(() => this.tabsService.clearWholeCache());
      localStorage.clear();
      sessionStorage.clear();
      location.reload();
    }, 200);
  }

  delete_cookie(name) {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=.worldoffice.cloud;path=/;`;
  }

  dominiosTenant() {
    let urdLogin: UrdLogin;
    this.urds.forEach(element => {
      let ind = this.buscarEmpresaPorId(this.padres, element.dominio.id); //this.padres.indexOf(element.dominio.dominioPadre)
      //si el padre ya existe
      if (ind != -1) {
        this.padres[ind].roles.push(element.rol);
        this.padres[ind].rolesNombres = this.padres[ind].rolesNombres + ', ' + element.rol.nombre;
        //el padre no existe
      } else {
        urdLogin = this.agregarEmpresa(element);
        this.padres.push(urdLogin);
      }
    });

    if (this.padres.length == 0) {
      this.loading = false;
      this.esperarRespuesta();
      this.popUpService.open({ codigoError: 'NOTIENEPERMISOS', severidad: SeveridadEnum.ERROR });

      // El login fue exitoso, es necesario cerrar la sesión previamente creada
      let pojo = {
        email: this.loginCredentials.username,
        pass: this.loginCredentials.contrasena
      }

      this.servicioLogin.cerrarSesiones(pojo).subscribe();
    } else if (this.padres.length == 1) {
      this.dominioSeleccionado = this.padres[0];
      if (this.configCookies) {
        this.autenticarTenantCookieExistente();
      } else {
        this.autenticarTenant(this.dominioSeleccionado.dominio.id);
      }
    } else {
      this.mostrarDatos = false;
    }
  }

  agregarEmpresaConPermiso(element) {
    let urdLogin = new UrdLogin();

    urdLogin.dominio = element.dominio;
    urdLogin.usuario = element.usuario;
    urdLogin.dominio = element.dominio.nombre;
    urdLogin.superAdmin = element.superAdministrador;
    if (urdLogin.superAdmin == true) {
      urdLogin.roles = [];
      urdLogin.rolesNombres = 'SuperAdministrador';
    } else {
      urdLogin.roles.push(element.rol);
      urdLogin.rolesNombres = element.rol.nombre;
    }
    return urdLogin;
  }

  agregarEmpresa(element) {
    let urdLogin = new UrdLogin();
    urdLogin.dominio = element.dominio;
    urdLogin.nombre = element.dominio.nombre;
    urdLogin.rolesNombres = '';
    urdLogin.usuario = element.usuario;
    if (urdLogin.superAdmin == true) {
      urdLogin.roles = [];
      urdLogin.rolesNombres = 'SuperAdministrador';
    } else {
      urdLogin.rolesNombres = element.rol.nombre;
      urdLogin.roles.push(element.rol);
    }
    return urdLogin;
  }

  agregarPadre(element) { }

  buscarEmpresaPorId(empresas, id) {
    let ind = 0;
    let ret = -1;
    empresas.forEach(element => {
      if (element.dominio.id == id) {
        ret = ind;
      }
      ind++;
    });
    return ret;
  }

  /**
   * Almacena la lista de permisos a los que tiene acceso el usuario loggeado
   */
  especificarPermisos(dominio) {
    const rolesSesion: any[] = [];
    dominio.roles.forEach(element => {
      rolesSesion.push(element.nombre);
    });
    this.usuarioSesion = dominio.usuario;

    sessionStorage.setItem('USUARIOSESION', JSON.stringify(this.usuarioSesion));
    sessionStorage.setItem('ROLESSESION', JSON.stringify(rolesSesion));

    let rol: boolean;
    if (dominio.superAdmin == true) {
      rol = true;
    }

    if (rol) {
      sessionStorage.setItem('PERMISOSUSUARIO', 'admin');
    } else {
      sessionStorage.setItem(
        'PERMISOSUSUARIO',
        JSON.stringify(this.idsPermisos)
      );
      if (this.idsExcepciones.length != 0) {
        sessionStorage.setItem(
          'EXCEPCIONUSUARIO',
          JSON.stringify(this.idsExcepciones)
        );
      }
    }
  }

  autenticarTenant(id) {
    this.servicioLogin.autenticartenant(id).subscribe(res => {
      if (res.status === 200) {
        const config = {
          configurado: !res.body.senConfigurado,
          activeLicense: res.body.senLicenciaVencida,
          almacenamiento: res.body.espacioAlmacenamientoLicencia,
          senconfigfe: res.body.senConfiguracionfe,
          license: res.body.licencia,
          licenseNombre: res.body.nombre,
        };

        sessionStorage.setItem('CONFIGURADO', JSON.stringify(config.configurado));
        sessionStorage.setItem('ACTIVELICENSE', JSON.stringify(config.activeLicense));
        sessionStorage.setItem('ALMACENAMIENTO', JSON.stringify(config.almacenamiento));
        sessionStorage.setItem('SENCONFIGFE', JSON.stringify(config.senconfigfe));
        sessionStorage.setItem('LICENCE', JSON.stringify(config.license));
        sessionStorage.setItem('LICENCENOMBRE', JSON.stringify(config.licenseNombre));

        const token = res.headers.get('Authorization');
        sessionStorage.setItem('Token', token);
        let fecha = new Date();
        fecha.setDate(fecha.getDate() + 1);
        document.cookie = `config=${btoa(JSON.stringify(config))};expires=${fecha.toUTCString()};domain=${this.dominio};path=/`;
        document.cookie = `token=${token};expires=${fecha.toUTCString()};domain=${this.dominio};path=/`;
        if (res.body.senChangePassword) {
          this.loading = false;
          this.mostrarChangePasswordForm();
        } else {

          if(this.abreviaturaPais !== 'co' ){
            this.loading =false;
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = `${this.urlFront}#/login`;
          }else {
            this.permisosUsuarios();
          }

        }
      }
    }, () => this.respuestaLogin.emit(false));
  }

  autenticarTenantCookieExistente() {
    sessionStorage.setItem('CONFIGURADO', JSON.stringify(this.configCookies.configurado));
    sessionStorage.setItem('ACTIVELICENSE', JSON.stringify(this.configCookies.activeLicense));
    sessionStorage.setItem('ALMACENAMIENTO', JSON.stringify(this.configCookies.almacenamiento));
    sessionStorage.setItem('SENCONFIGFE', JSON.stringify(this.configCookies.senconfigfe));
    sessionStorage.setItem('LICENCE', JSON.stringify(this.configCookies.license));
    sessionStorage.setItem('LICENCENOMBRE', JSON.stringify(this.configCookies.licenseNombre));
    this.permisosUsuarios();
  }

  permisosUsuarios() {
    this.servicioLogin.getPermisosMenu().subscribe(
      res => {
        console.log('res:', res)
        res.permisosGruesos.forEach(element => {
          let mapaPermisos: Map<string, any> = new Map();
          try {
            if (sessionStorage.getItem(element.url) === null) {
              element.permisosFinos.forEach(finos => {
                mapaPermisos.set(finos.codigoHtml, finos);
              });
              sessionStorage.setItem(element.url, JSON.stringify(mapaPermisos));
            } else {
              const url = JSON.parse(sessionStorage.getItem(element.url));
              let permisoPropioGrueso = new Map(url);
              element.permisosFinos.forEach(finos => {
                permisoPropioGrueso.set(finos.codigoHtml, finos);
              });
              sessionStorage.setItem(
                element.url,
                JSON.stringify(permisoPropioGrueso)
              );
            }
          } catch { }
        });
        res.menus.forEach(permite => {
          permite.forEach(menuPermiso => {
            this.idsPermisos.push(menuPermiso.nombre);
          });
        });
        this.especificarPermisos(this.dominioSeleccionado);
        const consultaDatosImportantes = forkJoin({
          preferencias: this.preferenciasUsuarioService.consultarPreferencias(),
          paisLocal: this.configuracionGeneralService.obtenerPaisLocal(),
          plantillasImportacion: this.configuracionGeneralService.getConfiguracionPlantillasImportacion(),
          decimales: this.preferenciasUsuarioService.consultarPreferenciaDecimales(),
          /* pantallasNoVisitadas: this.servicioLogin.getPantallasNoVisitadas(), */
          mapaComandos: this.servicioLogin.getMapaComandos(),
          mapaEntidades: this.servicioLogin.getMapaEntidades(),
          monedaLocal: this.configuracionGeneralService.obtenerMonedaLocal()
        });

        const subsDatosImportantes = consultaDatosImportantes.subscribe((resp: any) => {
          subsDatosImportantes.unsubscribe();
          if (resp.preferencias) sessionStorage.setItem('PREFERENCIAS', JSON.stringify(resp.preferencias));
          if (resp.paisLocal) sessionStorage.setItem('PAISLOCAL', JSON.stringify(resp.paisLocal));
          if (resp.plantillasImportacion) sessionStorage.setItem('PLANTILLASIMPORTACION', JSON.stringify(resp.plantillasImportacion));
          if (resp.decimales != undefined && resp.decimales != null) sessionStorage.setItem('PREFERENCIADECIMALES', JSON.stringify(resp.decimales));
          if (resp.pantallasNoVisitadas) sessionStorage.setItem('PANTALLASNOVISITADAS', JSON.stringify(resp.pantallasNoVisitadas));
          if (resp.monedaLocal) sessionStorage.setItem('MONEDALOCAL', JSON.stringify(resp.monedaLocal));
          if (resp.mapaEntidades) {
            sessionStorage.setItem('mapaEntidades', JSON.stringify(resp.mapaEntidades));
            resp.mapaEntidades.forEach(element => {
              MapaAcciones.entidadesBackFront.set(element.codigo, element.nombre);
              MapaAcciones.entidadesfrontBack.set(element.nombre, element.codigo);
            });
          }
          if (resp.mapaComandos) {
            sessionStorage.setItem('mapaComandos', JSON.stringify(resp.mapaComandos));
            resp.mapaComandos.forEach(element => {
              MapaAcciones.backFront.set(element.codigo, element.nombre);
              MapaAcciones.frontBack.set(element.nombre, element.codigo);
            });
          }
          this.loginFinalizado();
          this.validarPopUpPermisos(res)
        }, error => { });
      }
    );
  }


  loginFinalizado() {
    this.tabsService.finSesion = false;
    if (this.sonidoLogin) {
      this.sonidoLogin.pause();
      this.sonidoLogin.currentTime = 0;
      this.sonidoLogin.volume = 0.05;
      this.sonidoLogin.play();
    }
    if (this.popUp) {
      this.respuestaLogin.emit(true);
      setTimeout(() => this.loading = false, 3000);
    } else {
      if (this.POS) {
        const datosTerminalSel = { ultimaConexion: new Date() };
        sessionStorage.setItem('datosTerminalSel', JSON.stringify(datosTerminalSel));
      }

      let url = localStorage.getItem('navigateUrl');
      if (url) {
        localStorage.removeItem('navigateUrl')
        this.router.navigate([url]);
      } else {
        // FIXME: Se necesita la moneda para cargar los dashboard
        sessionStorage.removeItem('alertDesplegado');
        this.router.navigate(this.codigo && this.urlDecode ? [this.urlDecode] : ['/panel']);
      }
      this.loading = false;
    }
  }



  validarPopUpPermisos(res) {
    if (res.menus == undefined && res.permisosGruesos == undefined) {
      this.loading = false;
      this.esperarRespuesta();
      this.popUpService.open({ codigoError: 'asignacionRol', severidad: SeveridadEnum.ERROR });
    }
  }

  mostrarRecuperarContrasena() {
    this.recuperarContrasena.abrirPopup();
  }

  /**
   * TODO: metodo necesario?
   */
  cambio() { }

  mostrarChangePasswordForm() {
    this.mostrarChangePassword = true;
    this.mostrarDatosSesion = false;
    this.mostrarUsuarioLog = false;
  }

  changePassword() {
    if (!this.changePasswordInfo.password || !this.changePasswordInfo.confPassword) {
      this.esperarRespuesta();
      this.popUpService.open({
        codigoError: 'login.formCambioPassword.camposRequeridos', severidad: SeveridadEnum.ERROR, conBoton: false, detalle: null, detalles: null, mensajes: true
      });
      return;
    }

    if (!this.changePasswordInfo.senPoliticas || !this.changePasswordInfo.senTerminos) {
      this.esperarRespuesta();
      this.popUpService.open({
        codigoError: 'login.formCambioPassword.aceptarTerminosyPoliticas', severidad: SeveridadEnum.ERROR, conBoton: false, detalle: null, detalles: null, mensajes: true
      });
      return;
    }

    if (!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{5,}$/.test(this.changePasswordInfo.password)) {
      this.esperarRespuesta();
      this.popUpService.open({
        codigoError: 'login.formCambioPassword.passInvalida', severidad: SeveridadEnum.ERROR, conBoton: false, detalle: null, detalles: null, mensajes: true
      });
      return;
    }

    if (this.changePasswordInfo.password != this.changePasswordInfo.confPassword) {
      this.esperarRespuesta();
      this.popUpService.open({
        codigoError: 'login.formCambioPassword.passDistintas', severidad: SeveridadEnum.ERROR, conBoton: false, detalle: null, detalles: null, mensajes: true
      });
      return;
    }

    this.loading = true;
    this.usuarioService.changePassword(this.changePasswordInfo)
      .subscribe(
        response => {
          this.loading = false;
          if (response.codigoError == 'exito') {
            this.permisosUsuarios();
          } else {
            this.esperarRespuesta();
            this.popUpService.open({ codigoError: response.codigoError, severidad: SeveridadEnum.ERROR });
          }
        },
        error => {
          this.loading = false;
          this.errorHandlerComponent.handler(error);
        }
      );
  }
  respuestaPopUpMultiEmpresa() {
    this.popupMultiEmpresa = false;
  }
}
