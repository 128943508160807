import { Inject, Injectable } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { Subject } from 'rxjs';
import { Borrador, PlataformaEnum, Tab, TabData } from '@wo/modelo';
import { DocumentoEncabezadoService } from '@wo/servicio/lib/documento/documento-encabezado.service';
import { WoBoardRouteReuseStrategy } from '@wo/servicio/lib/wo-board-route-reuse-strategy';
import { ListenerBorradorService } from '@wo/servicio/lib/listenerBorrador/listener-borrador.service';

@Injectable({
  providedIn: 'root'
})
export class TabsService {
  mostrarPopupAuditoria: { codigoError: any; severidad: any; };
  userPermits: { nombre: string, link: string, senDisponible: boolean, sinPermiso: boolean }[];
  timeout;
  plataforma: PlataformaEnum;

  constructor(
    @Inject(RouteReuseStrategy) private strategy: WoBoardRouteReuseStrategy,
    @Inject('plataforma') plataforma: PlataformaEnum,
    private router: Router,
    private _LoaderService: LoaderService,
    private listenerBorradorService: ListenerBorradorService,
    private serviceDocumentoEncabezado: DocumentoEncabezadoService
  ) {
    this.plataforma = plataforma;
  }

  map = new Map<string, () => void>();

  registrarFuncion(funcionListener: () => void, pantalla): any {
    this.map.set(pantalla, funcionListener);
  }

  private cierraBorradorTabSource = new Subject<any>();
  cierraBorradorTab$ = this.cierraBorradorTabSource.asObservable();
  public cambio$ = new Subject<any>();
  finSesion = false;
  tipoMensaje: any;
  ruta: string = '/panel';

  private cambioTabSource = new Subject<any>();
  cambioTab$ = this.cambioTabSource.asObservable();

  private cambioTabGeneralSource = new Subject<any>();
  cambioTabGeneral$ = this.cambioTabGeneralSource.asObservable();


  tabCerrada = new Subject<any>();

  comandosMap: Map<string, any> = new Map(
    [
      ['SalidaAlmacenDetalle', true],
      ['ReciboCajaDetalle', true],
      ['SaldosInicialesDetalle', true],
      ['ComprobanteDeEgresoDetalle', true],
      ['NotaContabilidadDetalle', true],
      ['ConsignacionBancariaDetalle', true],
      ['NominaContableDetalle', true],
      ['DepreciacionesDetalle', true],
      ['DocumentoCancelacionDetalle', true],
      ['FacturaVentaDetalle', true],
      ['AjusteInventarioDetalle', true],
      ['CostoVentasDetalle', true],
      ['CotizacionDetalle', true],
      ['DevolucionRemisionDetalle', true],
      ['EntradaAlmacenDetalle', true],
      ['EntradaDeProductoTerminadoDetalle', true],
      ['FacturaCompraDetalle', true],
      ['NotaCreditoDetalle', true],
      ['NotaCreditoProveedoresDetalle', true],
      ['NotaDebitoDetalle', true],
      ['NotaDebitoProveedoresDetalle', true],
      ['OrdenCompraDetalle', true],
      ['PedidoDetalle', true],
      ['RemisionDetalle', true],
      ['RemisionCompraDetalle', true],
      ['DevolucionRemisionCompraDetalle', true],
      ['nominaEmpleadosDetalle', true],
      ['PuntodeVenta', true]
    ]
  );

  get currentTab() {
    return this.activeTab.tab;
  }

  public getOpenTabsName() {
    let tabs = [];
    this.openedTabs.forEach(tab => {
      tabs.push({ nombre: tab.nombre, parametros: tab.params });
    });
    return tabs;
  }

  public getOpenTabName() {
    return this.activeTab.tab.nombre;
  }

  public getOpenTabsUrl() {
    let tabs = [];
    this.openedTabs.forEach(tab => {
      tabs.push({ url: tab.url, parametros: tab.params });
    });
    return tabs;
  }

  public getCurrenTabName() {
    return this.activeTab.tab.nombre;
  }

  public getCurrenTabUrl() {
    return this.activeTab.tab.url;
  }

  get openedTabs() {
    return this.permanentTabs
      .concat(this.dynamicTabs)
      .map(tabData => tabData.tab);
  }

  public isConfirmingClosure = new Subject<any>();
  private tabCloseAction: () => void = null;

  public get activeTab() {
    return this.strategy.activeTab;
  }

  public set activeTab(tabData: TabData) {
    this.strategy.activeTab = tabData;
  }

  private get permanentTabs() {
    return this.strategy.permanentTabs;
  }

  private set permanentTabs(permanentTabs: TabData[]) {
    this.strategy.permanentTabs = permanentTabs;
  }

  private get dynamicTabs() {
    return this.strategy.dynamicTabs;
  }

  private get tabsOrden() {
    return this.strategy.tabsOrden;
  }

  private set dynamicTabs(dynamicTabs: TabData[]) {
    this.strategy.dynamicTabs = dynamicTabs;
  }

  private set tabsOrden(tabsOrden: any[]) {
    this.strategy.tabsOrden = tabsOrden;
  }

  validarAuditoria(tab) {
    // SE QUITA LA PALABRA COPIA PARA QUE BUSQUE EN EL MAPA CORRECTAMENTE
    const tablaSimplificada = tab.replace(/^copia([A-Z][a-zA-Z]*)/, "$1");
    return this.comandosMap.get(tablaSimplificada)
  }

  loadPersistedTabs() {
    const dynamicTabsStr = sessionStorage.getItem('DYNAMICTABS');
    const orderStr = sessionStorage.getItem('ORDERTABS');
    try {
      if (dynamicTabsStr) {
        const dynamicTabs = JSON.parse(dynamicTabsStr);
        this.dynamicTabs = dynamicTabs;
      }
      if (orderStr) {
        const tabsOrden = JSON.parse(orderStr);
        this.tabsOrden = tabsOrden;
      }
    } catch (e) {
      if (!(e instanceof SyntaxError)) {
        throw e;
      }
    }
    this.loadPersistedActiveTab();
  }

  openTab(tab: Tab, noSeleccionar?, noRedirigir?, noGuardar?, varias?) {
    if (!noGuardar) {
      const TABS: any = sessionStorage.getItem('TABS_ACTIVAS') ? JSON.parse(sessionStorage.getItem('TABS_ACTIVAS')) : [];
      let EXISTE = TABS.some(({ nombre: nombreTab }) => tab.nombre === nombreTab);
      if (!EXISTE) {
        EXISTE = TABS.some(({ url: urlTab }) => tab.url === urlTab);
      }
      if (!EXISTE || varias) {
        TABS.push({
          url: tab.url, nombre: tab.nombre, tab
        });
      } else {
        let TAB = TABS.find(({ nombre: nombreTab }) => tab.nombre === nombreTab);
        if (!TAB) {
          TAB = TABS.find(({ url: urlTab }) => tab.url === urlTab);
        }
        TAB.url = tab.url;
        TAB.nombre = tab.nombre;
        TAB.tab = tab;
      }
      sessionStorage.setItem('TABS_ACTIVAS', JSON.stringify(TABS));

    }
    if (tab.isCloseable) {
      const tabIndex = this.indexOfDynamicTabData(tab);
      const tabFound = tabIndex !== -1;
      if (tabFound && !varias) {
        const tabData = this.dynamicTabs[tabIndex];
        tabData.tab = tab;
        delete tabData.handle;
      } else {
        this.dynamicTabs.push(new TabData(tab));
      }
      this.persistTabs();
    }

    if (!noSeleccionar) {
      this.selectTab(tab, noRedirigir);
    }
  }

  selectTab(tab: Tab, noRedirigir?) {
    this.cambio$.next(tab.url);
    setTimeout(() => {
      const tabData = this.findTabData(tab);

      if (tabData) {
        if (!noRedirigir) {

          this.router.navigate(tab.url.split('/').concat(tab.params ? tab.params : ''), {
            queryParams: tab.queryParams
          })
        }
        this.activeTab = tabData;
        if (this.finSesion == false) {
          if (tab.url == '/panel') {
            this.cambioTabSource.next();
          } else {
            this.cambioTabGeneralSource.next(tab.url);
          }
        }
      }

      this.persistActiveTab();
      this.tabOrder();
      let getFuncionListener = this.map.get(tab.nombre);
      if (getFuncionListener) {
        getFuncionListener();
      }

      this._LoaderService.verificarPantalla(
        tab.nombre,
        'loadingIndex',
        'paneTabs'
      );
    }, 0);
  }

  confirmTabClosure() {
    this.tabCloseAction();
    this.isConfirmingClosure.next({
      action: false,
      mensaje: this.tipoMensaje
    });
  }

  cancelTabClosure() {
    this.isConfirmingClosure.next({
      action: false,
      mensaje: this.tipoMensaje
    });
  }

  closePermanentTab() {
    this.permanentTabs = [];
    this.afterTabClose();
  }

  closeTabWithConfirmation(tab: Tab) {
    this.tabCloseAction = () => this.closeTabEvent(tab);
    this.isConfirmingClosure.next({
      action: true,
      mensaje: this.tipoMensaje
    });
  }

  closeTabsToRightWithConfirmation(tab: Tab) {
    this.tabCloseAction = () => this.closeTabsToRightAction(tab);
    this.isConfirmingClosure.next({
      action: true,
      mensaje: this.tipoMensaje
    });
  }

  closeTabsToLeftWithConfirmation(tab: Tab) {
    this.tabCloseAction = () => this.closeTabsToLeftAction(tab);
    this.isConfirmingClosure.next({
      action: true,
      mensaje: this.tipoMensaje
    });
  }

  closeAllTabsWithConfirmation() {
    this.tabCloseAction = () => this.closeAllTabsAction(false);
    this.isConfirmingClosure.next({
      action: true,
      mensaje: this.tipoMensaje
    });
  }

  closeOtherTabsWithConfirmation(tab: Tab) {
    this.tabCloseAction = () => this.closeOtherTabsAction(tab);
    this.isConfirmingClosure.next({
      action: true,
      mensaje: this.tipoMensaje
    });
  }

  closeTab(tab: Tab, eliminar?: boolean) {
    this._LoaderService.cerrarTabAltos(tab.nombre, 'paneTabs');
    if (!JSON.parse(sessionStorage.getItem('CONFIGURADO')) && !JSON.parse(sessionStorage.getItem('ACTIVELICENSE'))) {
      this.cierraBorradorTabSource.next(tab.url);
      const borrador = this.listenerBorradorService.getBorrador(tab.nombre)
        ? this.listenerBorradorService.getBorrador(tab.nombre)
        : new Borrador(false, 'PIERDEN_CAMBIOS');
      this.tipoMensaje = borrador.mensaje;
      if (borrador.borrador && !eliminar) {
        this.closeTabWithConfirmation(tab);
      } else {
        this.closeTabEvent(tab);
      }
    }
  }

  closeTabEvent(tab: Tab) {

    const tabIndex = this.indexOfDynamicTabData(tab);
    const tabFound = tabIndex !== -1;
    if (tabFound) {
      this.dynamicTabs.splice(tabIndex, 1);
    }
    this.quitarOrdenTab(tab.url);
    const TABS: any = sessionStorage.getItem('TABS_ACTIVAS') ? JSON.parse(sessionStorage.getItem('TABS_ACTIVAS')) : [];
    let EXISTE = TABS.findIndex(({ nombre: nombreTab }) => tab.nombre === nombreTab);
    /*     if (!EXISTE) {
          EXISTE = TABS.findIndex(({ url: urlTab }) => tab.url === urlTab);
        } */

    if (EXISTE >= 0) {
      this.strategy.deactivateOutlet(TABS[EXISTE].url);
      TABS.splice(EXISTE, 1);
    }
    sessionStorage.setItem('TABS_ACTIVAS', JSON.stringify(TABS));

    this.afterTabClose(false, tab);
  }

  async closeTabsToRight(tab: Tab) {
    let valido: any = await this.validarPantallasAbiertas(TabsClosureEnum.ALL, true)
    if (valido) {
      this.closeTabsToRightAction(tab);
    } else {
      this.tipoMensaje = this.mostrarPopupAuditoria ? this.mostrarPopupAuditoria : 'PIERDEN_CAMBIOS';
      this.closeTabsToRightWithConfirmation(tab);
    }
  }

  closeTabsToRightAction(tab) {
    const tabIndex = this.indexOfDynamicTabData(tab);
    const tabFound = tabIndex !== -1;
    if (tabFound) {
      this.closeOrderToRight(tabIndex + 1);
      this.dynamicTabs.splice(tabIndex + 1);
    }
    const TABS: any = sessionStorage.getItem('TABS_ACTIVAS') ? JSON.parse(sessionStorage.getItem('TABS_ACTIVAS')) : [];
    let EXISTE = TABS.findIndex(({ nombre: nombreTab }) => tab.nombre === nombreTab);
    if (!EXISTE) {
      EXISTE = TABS.findIndex(({ url: urlTab }) => tab.url === urlTab);
    }

    if (EXISTE >= 0) {
      TABS.forEach((tab, index) => {
        if (index > EXISTE) this.strategy.deactivateOutlet(tab.url);
      });
      TABS.splice(EXISTE + 1);
    }

    sessionStorage.setItem('TABS_ACTIVAS', JSON.stringify(TABS));
    this.afterTabClose();
  }

  async closeTabsToLeft(tab: Tab) {
    let valido: any = await this.validarPantallasAbiertas(TabsClosureEnum.ALL, true)
    if (valido) {
      this.closeTabsToLeftAction(tab);
    } else {
      this.tipoMensaje = this.mostrarPopupAuditoria ? this.mostrarPopupAuditoria : 'PIERDEN_CAMBIOS';
      this.closeTabsToLeftWithConfirmation(tab);
    }
  }

  closeTabsToLeftAction(tab: Tab) {
    const tabIndex = this.indexOfDynamicTabData(tab);
    const tabFound = tabIndex !== -1;
    if (tabFound) {
      this.closeOrderToLeft(tabIndex);
      this.dynamicTabs.splice(0, tabIndex);
    }

    const TABS: any = sessionStorage.getItem('TABS_ACTIVAS') ? JSON.parse(sessionStorage.getItem('TABS_ACTIVAS')) : [];
    let EXISTE = TABS.findIndex(({ nombre: nombreTab }) => tab.nombre === nombreTab);
    if (!EXISTE) {
      EXISTE = TABS.findIndex(({ url: urlTab }) => tab.url === urlTab);
    }

    if (EXISTE >= 0) {
      TABS.forEach((tab, index) => {
        if (index < EXISTE) this.strategy.deactivateOutlet(tab.url);
      });
      TABS.splice(0, EXISTE);
    }

    sessionStorage.setItem('TABS_ACTIVAS', JSON.stringify(TABS));

    this.afterTabClose();
  }

  async closeAllTabs(finSesion, noRedirigir?) {
    if (noRedirigir) {
      this.closeAllTabsAction(finSesion, noRedirigir);
    } else {
      let valido: any = await this.validarPantallasAbiertas(TabsClosureEnum.ALL, true);
      if (valido) {
        this.closeAllTabsAction(finSesion, noRedirigir);
      } else {
        this.tipoMensaje = this.mostrarPopupAuditoria ? this.mostrarPopupAuditoria : 'PIERDEN_CAMBIOS';
        this.closeAllTabsWithConfirmation();
      }
    }
  }

  closeAllTabsAction(finSesion, noRedirigir?) {
    this.finSesion = finSesion;
    sessionStorage.removeItem('TABS_ACTIVAS');
    this.dynamicTabs = [];
    this.strategy.deactivateAllHandles();
    this.tabsOrden.length = 0;
    this.afterTabClose(noRedirigir);
  }

  async closeOtherTabs(tab: Tab) {
    let valido: any = await this.validarPantallasAbiertas(TabsClosureEnum.ALL, true)
    if (valido) {
      this.closeOtherTabsAction(tab);
    } else {
      this.tipoMensaje = this.mostrarPopupAuditoria ? this.mostrarPopupAuditoria : 'PIERDEN_CAMBIOS';
      this.closeOtherTabsWithConfirmation(tab);
    }
  }

  closeOtherTabsAction(tab) {
    const tabData = this.findDynamicTabData(tab);
    if (tabData) {
      this.closeOrderOthers(tab);
      this.dynamicTabs = [tabData];
    }

    let TABS: any = sessionStorage.getItem('TABS_ACTIVAS') ? JSON.parse(sessionStorage.getItem('TABS_ACTIVAS')) : [];

    const EXISTE = TABS.find(({ url: urlTab, nombre: nombreTab }) => tab.url === urlTab);
    const INDEX_EXISTE = TABS.findIndex(({ url: urlTab, nombre: nombreTab }) => tab.url === urlTab);
    if (EXISTE) {
      TABS.forEach((tab, index) => {
        if (index != INDEX_EXISTE) this.strategy.deactivateOutlet(tab.url);
      });
      TABS = [EXISTE];
    }

    sessionStorage.setItem('TABS_ACTIVAS', JSON.stringify(TABS));

    this.afterTabClose();
  }

  private afterTabClose(noRedirigir?, tab?) {
    this.tabCerrada.next(tab);
    if (this.tabsOrden.length > 0) {
      let indexTabOpen = this.openedTabs.findIndex(
        x => x.url == this.tabsOrden[this.tabsOrden.length - 1]
      );
      if (indexTabOpen != -1) {
        if (!this.has(this.activeTab.tab)) {
          this.selectTab(this.openedTabs[indexTabOpen]);
        }
        this.persistTabs();
      } else {
        this.selectTab(this.openedTabs[0]);
      }
    } else {
      if (noRedirigir) {
        this.persistTabs();
        this.clearWholeCache();
      } else {
        this.selectTab(this.openedTabs[0]);
        this.persistTabs();
      }
    }
  }

  private persistTabs() {
    sessionStorage.setItem(
      'DYNAMICTABS',
      JSON.stringify(
        this.dynamicTabs.map(tabData => {
          const { handle, ...tabDataSansHandle } = tabData;
          return tabDataSansHandle;
        })
      )
    );
    this.persistActiveTab();
  }

  private persistActiveTab() {
    this.activeTab && sessionStorage.setItem('ACTIVETAB', JSON.stringify(this.activeTab.tab));
  }

  private tabOrder() {
    let tab = this.activeTab.tab.url;
    let tabIndex = this.tabsOrden.findIndex(x => x === tab);
    if (tabIndex != -1) {
      this.tabsOrden.splice(tabIndex, 1);
      this.tabsOrden.push(tab);
    } else {
      this.tabsOrden.push(tab);
    }
    sessionStorage.setItem('ORDERTABS', JSON.stringify(this.tabsOrden));
  }

  private quitarOrdenTab(tab) {
    const tabIndex = this.tabsOrden.findIndex(x => x == tab);
    if (tabIndex != -1) {
      this.tabsOrden.splice(tabIndex, 1);
    }
  }

  closeOrderToRight(tabIndex) {
    for (let i = tabIndex; i < this.dynamicTabs.length; i++) {
      this.quitarOrdenTab(this.dynamicTabs[i].tab.url);
    }
  }

  closeOrderToLeft(tabIndex) {
    for (let i = 0; i < tabIndex; i++) {
      this.quitarOrdenTab(this.dynamicTabs[i].tab.url);
    }
  }

  closeOrderOthers(tab) {
    this.tabsOrden.length = 0;
    this.tabsOrden.push(this.ruta);
    this.tabsOrden.push(tab.url);
  }

  clearCache(tab: Tab) {
    const tabData = this.findTabData(tab);
    tabData.handle = null;
  }

  clearWholeCache() {
    this.permanentTabs.forEach(tabData => (tabData.handle = null));
    this.dynamicTabs.forEach(tabData => (tabData.handle = null));
  }

  private loadPersistedActiveTab() {
    const activeTabStr = sessionStorage.getItem('ACTIVETAB');
    try {
      if (activeTabStr) {
        const activeTab = JSON.parse(activeTabStr);
        const OPENED_TABS = this.openedTabs;
        if (OPENED_TABS.some(t => t.url == activeTab.url)) {
          if (this.activeTab && (this.activeTab && this.activeTab.tab.url !== activeTab.url)) {
            this.selectTab(activeTab, true);
          }
        }
      }
    } catch (e) {
      if (!(e instanceof SyntaxError)) {
        throw e;
      }
    }
    setTimeout(() => {
      if (!this.activeTab) {
        this.selectTab(this.openedTabs[0], false);
      }
    }, 500);
  }

  private has(tab: Tab) {
    return !!this.findTabData(tab);
  }

  private indexOfPermanentTabData(tab: Tab) {
    return this.permanentTabs.findIndex(tabData => tabData.tab.url === tab.url);
  }

  private indexOfDynamicTabData(tab: Tab) {
    const indexTab = this.dynamicTabs.findIndex(tabData => tabData.tab.nombre === tab.nombre);
    return indexTab > -1 ? indexTab : this.dynamicTabs.findIndex(tabData => !tab.nombre.includes('PuntoDeVenta') && tabData.tab.url === tab.url);
  }

  private findTabData(tab: Tab) {
    if (tab.isCloseable) {
      return this.findDynamicTabData(tab);
    } else {
      return this.findPermanentTabData(tab);
    }
  }

  private findPermanentTabData(tab: Tab) {
    return this.permanentTabs.find(tabData => tabData.tab.url === tab.url);
  }

  private findDynamicTabData(tab: Tab) {
    const tabFound = this.dynamicTabs.find(tabData => tabData.tab.nombre === tab.nombre);
    return tabFound ? tabFound : this.dynamicTabs.find(tabData => !tab.nombre.includes('PuntoDeVenta') && tabData.tab.url === tab.url);
  }


  cambiarTabTeclado(tabIndex) {
    if (this.openedTabs.length > tabIndex - 1) {
      this.selectTab(this.openedTabs[tabIndex - 1]);
    }
  }

  async validarPantallasAbiertas(action: any, validateCurrent: boolean, tab?) {
    this.mostrarPopupAuditoria = null;
    const PANTALLAS_ABIERTAS: any[] = this.getTabsByAction(action, tab);
    let valido: boolean = true;
    PANTALLAS_ABIERTAS.forEach(async element => {
      if (this.listenerBorradorService.getBorrador(element.nombre)) {
        if (
          this.listenerBorradorService.getBorrador(element.nombre).borrador === true
        ) {
          valido = validateCurrent
            ? false
            : element.nombre === tab.nombre
              ? valido
              : false;
        }
      }
    });
    for (let i = 0; i < PANTALLAS_ABIERTAS.length; i++) {
      if (this.validarAuditoria(PANTALLAS_ABIERTAS[i].nombre)) {
        let response: any = await this.serviceDocumentoEncabezado.getAuditoria(PANTALLAS_ABIERTAS[i].parametros[0]).toPromise();
        if (response.length > 0) {
          if (response[0].codigoError) {
            let mensaje = {
              codigoError: response[0].codigoError,
              severidad: response[0].severidad
            }
            valido = false;
            this.mostrarPopupAuditoria = mensaje;
            return valido;
          }
        }

      }
    }
    return valido;
  }

  getTabsByAction(action: any, tab?) {
    let tabsByAction: string[] = this.getOpenTabsName();
    const tabIndex =
      tab !== undefined ? tabsByAction.findIndex(x => x === tab.nombre) : null;

    switch (action) {
      case TabsClosureEnum.OTHERS:
        tabsByAction.splice(tabIndex, 1);
        break;
      case TabsClosureEnum.RIGHT:
        tabsByAction = tabsByAction.slice(tabIndex + 1, tabsByAction.length);
        break;
      case TabsClosureEnum.LEFT:
        tabsByAction = tabsByAction.slice(0, tabIndex);
        break;
      default:
        break;
    }
    return tabsByAction;
  }

  findTab(nombre, param) {
    return this.openedTabs.find(tab => (tab.nombre && nombre && tab.nombre.toUpperCase() === nombre.toUpperCase()) && tab.params[0] === param + '');
  }

  actualizarNombreTab(tab, nuevoNombre, textoAdicional?) {
    let tabActual = this.dynamicTabs.find(tabData => tabData.tab.nombre === tab.nombre);
    tabActual = tabActual ? tabActual : this.dynamicTabs.find(tabData => !tab.nombre.includes('PuntoDeVenta') && tabData.tab.url === tab.url);

    let tabActualOpen = this.openedTabs.find(tabData => tabData.nombre === tab.nombre);
    tabActualOpen = tabActualOpen ? tabActualOpen : this.openedTabs.find(tabData => !tab.nombre.includes('PuntoDeVenta') && tabData.url === tab.url);

    let TABS: any = sessionStorage.getItem('TABS_ACTIVAS') ? JSON.parse(sessionStorage.getItem('TABS_ACTIVAS')) : [];
    let tabActualActivas = TABS.find(tabData => tabData.nombre === tab.nombre);
    tabActualActivas = tabActualActivas ? tabActualActivas : TABS.find(tabData => !tab.nombre.includes('PuntoDeVenta') && tabData.url === tab.url);

    tabActual.tab.nombre = nuevoNombre;
    tabActualOpen.nombre = nuevoNombre;
    tabActualActivas.nombre = nuevoNombre;
    tabActualActivas.tab.nombre = nuevoNombre;

    if (nuevoNombre.includes('PuntoDeVenta')) {
      tabActual.tab.textoAdicional = textoAdicional;
      tabActualOpen.textoAdicional = textoAdicional;
      tabActualActivas.tab.textoAdicional = textoAdicional;
    }
    sessionStorage.setItem('TABS_ACTIVAS', JSON.stringify(TABS));
  }
}

export class TabsClosureEnum {
  static ALL = 'ALL';
  static OTHERS = 'OTHERS';
  static RIGHT = 'RIGHT';
  static LEFT = 'LEFT';
}
