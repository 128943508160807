import { AfterViewInit, Component, Input, ViewChild, OnDestroy } from '@angular/core';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { DocumentoExterno, DocumentoTipoEnum, FiltroWo, Operador, TipoDato, TipoFiltro, Moneda, Tab, BotonMenuContextual, OpcionTabla, SeveridadEnum } from '@wo/modelo';
import { ColaService, MasFvService, MapaComandosService, TabsService, MonedaTrmService, FormatoUtilService, ListenerBorradorService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { MenuContextualComponent } from '@wo/frontend/menu-contextual/menu-contextual.component';
import { take } from 'rxjs/operators';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';

@Component({
  selector: 'app-tercero-externo',
  templateUrl: './tercero-externo.component.html',
  styleUrls: ['./tercero-externo.component.css']
})

export class TerceroExternoComponent implements AfterViewInit, OnDestroy {

  @Input() idEncabezado;
  @Input('tipoDocumento') tipoDocumento;
  @Input('disabledNegocio') disabledNegocio = false;
  @Input('completoDocumentoExterno') completoDocumentoExterno;

  //INICIO popups que se abren desde el documento Encabezado
  @ViewChild(MenuContextualComponent, { static: false })
  private menuContextualComponent: MenuContextualComponent;
  moneda: Moneda;
  trm: any;
  filtroMonedaDisponible: Array<FiltroWo> = [];
  msjeCrearTRM = false;
  monedaAsociadoTRM: any;
  msjeDocAsociadoTRM: boolean;
  tieneError = false;
  mostrarTrm = false;
  primerClick = true;
  monedaValorAnterior: any;
  monedaLocal = JSON.parse(sessionStorage.getItem('MONEDALOCAL'));

  documentoExterno: DocumentoExterno = { id: null, prefijo: null, fecha: null, numero: null, versionFe: null, cufe: null, moneda: null, trm: null };
  esBorrador: Boolean = true;
  mostrarCude: boolean = false;
  listaVersion = [{"id":1, "valor": "Fase 1"},{"id":0, "valor": "Validacion Previa"}]

  private subscriptions = new Subscription();

  filtrosPrefijo: Array<FiltroWo> = [];

  private comandos = this.mapaComandosService.getComandosDocumentoExterno();

  trmSelected: any;

  constructor(private serviceColas: ColaService, private errorHandlerComponent: ErrorHandlerComponent,
    private listenerBorradorService: ListenerBorradorService, private masFvService: MasFvService,
    private _LoaderService: LoaderService, private mapaComandosService: MapaComandosService,
    public tabsService: TabsService,
    private monedaTRMService: MonedaTrmService,
    protected formatoUtilService: FormatoUtilService,
    private popUpService: PopUpService) { }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup') this.respuestaPopup(respuesta.event)
      }
    )
  }

  ngAfterViewInit(): void {
    this.obtenerDocumentoExterno();
    this.inicializarFiltros();
    if(this.tipoDocumento == DocumentoTipoEnum.NOTA_CREDITO_COMPRA){
      this.mostrarCude = true;
      this.listaVersion = [{"id":0, "valor": "Validacion Previa"}]
      this.filtrosPrefijo = [new FiltroWo('descripcion', '', null, TipoFiltro.IGUAL, TipoDato.NOT_IN, null, ['VACIO'], 'Prefijo', Operador.AND),
      new FiltroWo('codigoDocumento', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [DocumentoTipoEnum.FATURA_COMPRA], 'documentoTipoes', Operador.AND),
      new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'Prefijo', Operador.AND)];
      
    }else{
      this.filtrosPrefijo = [new FiltroWo('descripcion', '', null, TipoFiltro.IGUAL, TipoDato.NOT_IN, null, ['VACIO'], 'Prefijo', Operador.AND),
      new FiltroWo('codigoDocumento', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [DocumentoTipoEnum.FACTURA_VENTA], 'documentoTipoes', Operador.AND)];
      this.mostrarCude = false;
      this.listaVersion = [{"id":1, "valor": "Fase 1"},{"id":0, "valor": "Validacion Previa"}]
    }
  }

  getFiltroMonedaSenDisponible() {
    return new FiltroWo('senDisponible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'Moneda', Operador.AND);
  }

  /**
   * inicializarFiltros: carga los filtros de los combos necesarios por cada documento.
   */
  inicializarFiltros() {
    this.filtroMonedaDisponible.push(this.getFiltroMonedaSenDisponible())
  }

  convertirMonedaANum() {
    if (this.primerClick) {
      this.primerClick = false;
      let moneda = this.documentoExterno.trm;

      if (moneda != undefined) {
        moneda = moneda.toString();

        let re = new RegExp('\\' + this.documentoExterno.moneda.separadorMiles, 'g');
        let sinMiles = moneda.replace(re, '');
        this.trm = sinMiles;
        return sinMiles;
      }
    }
  }

  validarTRM(id) {
    this.monedaTRMService.getTRM(id, this.documentoExterno.fecha).subscribe(
      res => {
        if (res == null) {
          this.msjeCrearTRM = true;
          this.msjeDocAsociadoTRM = true;
          this.monedaAsociadoTRM = this.documentoExterno.moneda;
          //mostrar pop up
          this.esperarRespuesta();
          this.popUpService.open({ codigoError: '008', severidad: SeveridadEnum.QUESTION });
        } else {
          this.trmSelected = res;
          const campo = 'moneda';
          if (this.comandos.get(campo)) {
            this.mostrarTrm = true;
            let mensajeEditar = this.getMensaje(campo, id);
            let crea = this.documentoExterno.id ? false : true;
            crea ? this.enviar(this.getMensaje('crear', this.idEncabezado), mensajeEditar) : this.enviar(mensajeEditar, null);
          }
        }
      }
    )
  }

  onRightClickTrm(e) {
    const tabTrm = new Tab(
      '/panel/contabilidad/configuracion/documentos/monedas/trm',
      'AsociarMonedaTRM', [],
      {
        valoresIniciales: JSON.stringify({
          fecha: this.documentoExterno.fecha,
          monedaId: this.documentoExterno.moneda.id
        })
      }
    );
    const botones: BotonMenuContextual[] = [
      new BotonMenuContextual(OpcionTabla.VER, () =>
        this.tabsService.openTab(tabTrm)
      )
    ];
    this.menuContextualComponent.abrir(e.clientX, e.clientY, botones, 'asociar-moneda-trm');
  }

  onRightClickMoneda(e) {
    const tabListaMonedas = new Tab('/panel/contabilidad/configuracion/documentos/monedas', 'Monedas');
    const botones: BotonMenuContextual[] = [
      new BotonMenuContextual(OpcionTabla.ADMINISTRAR, () =>
        this.tabsService.openTab(tabListaMonedas)
      )
    ];
    this.menuContextualComponent.abrir(e.clientX, e.clientY, botones, 'moneda');
  }

  private obtenerDocumentoExterno(): void {
    this._LoaderService.colocarMascara("documento-externo", { tabla: false }, "loadingIndex", "contGridFluid");
    let sub = this.masFvService.getDocumentoExterno(this.idEncabezado).subscribe((response: DocumentoExterno) => {
      this._LoaderService.quitarMascara("documento-externo", "tabla", "loadingIndex", "contGridFluid");
      this.documentoExterno = response;
      this.esBorrador = this.documentoExterno.id ? false : true;
      this.listenerBorradorService.esBorrador.next(this.esBorrador);
    }, error => this.errorHandlerComponent.handler(error));
    this.subscriptions.add(sub);
  }

  cambio(campo: string, valorNuevo: any): void {
    if (this.comandos.get(campo)) {
      if (campo == 'moneda') {

        if (!this.documentoExterno.fecha) {
          this.verPopup('418', SeveridadEnum.INFO, '');

          setTimeout(() => {
            this.documentoExterno.moneda = null;
          }, 50);

        } else {
          this.validarTRM(valorNuevo);
        }
      } else if (campo == 'trm') {
        this.asignarTrm();
      } else {
        let mensajeEditar = this.getMensaje(campo, valorNuevo);
        let crea = this.documentoExterno.id ? false : true;
        crea ? this.enviar(this.getMensaje('crear', this.idEncabezado), mensajeEditar) : this.enviar(mensajeEditar, null);
      }
    }
  }

  private crearTRM() {
    let fecha = this.documentoExterno.fecha;

    let monedaError = this.monedaAsociadoTRM;
    this.tabsService.openTab(new Tab('/panel/contabilidad/configuracion/documentos/monedas/trm', 'AsociarMonedaTRM', [], {
      valoresIniciales: JSON.stringify({
        fecha: fecha,
        monedaId: monedaError.id
      })
    }));

    if (this.monedaValorAnterior) {
      setTimeout(() => {
        this.documentoExterno.moneda = this.monedaValorAnterior;
      }, 50);
    } else {
      setTimeout(() => {
        this.documentoExterno.moneda = null;
      }, 50);
    }
  }

  respuestaPopup(e) {
    if (e) {
      if (this.msjeCrearTRM) {
        this.crearTRM();
        this.msjeCrearTRM = false;
        if (this.msjeDocAsociadoTRM) {
          this.msjeDocAsociadoTRM = false;
        }
      }
    } else {
      this.documentoExterno.moneda = this.monedaValorAnterior;
    }
  }

  getMensaje(campo: string, valorNuevo: any): Object {
    let { atributo, tipoDato, accion } = this.comandos.get(campo);
    return {
      clase: 'FeDocumentoExterno',
      id: (campo != 'crear') ? this.documentoExterno.id : this.idEncabezado,
      accion: accion,
      atributo: atributo,
      valor: valorNuevo,
      prioridad: true,
      tipoDato: tipoDato,
      permisoGrueso: this.tipoDocumento
    };
  }

  asignarTrm() {
    if (this.trmSelected) {
      this.documentoExterno.trm = this.formatoUtilService.formatoMoneda(
        this.trmSelected.valor, this.documentoExterno.moneda
      );

      let mensajeEditar = this.getMensaje('trm', this.trmSelected.valor);
      let crea = this.documentoExterno.id ? false : true;
      crea ? this.enviar(this.getMensaje('crear', this.idEncabezado), mensajeEditar) : this.enviar(mensajeEditar, null);
    }
  }

  enviar(mensaje: any, mensaje2: any): void {
    let tieneError = false;
    this._LoaderService.colocarMascara("documento-externo", { tabla: false }, "loadingIndex", "contGridFluid");
    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1)).subscribe(
        response => {
          this._LoaderService.quitarMascara("documento-externo", "tabla", "loadingIndex", "contGridFluid");
          if (response.length > 0) {
            response.forEach(cambioBack => {
              if (cambioBack.codigoError) {
                this.verPopup(cambioBack.codigoError, mensaje.severidad, '');
                (cambioBack.codigoError == '403') && (this.documentoExterno.fecha = cambioBack.valor);
              } else {
                if (cambioBack.atributo == 'moneda') {
                  this.asignarTrm();
                  this.monedaValorAnterior = this.documentoExterno.moneda;
                }
                if (cambioBack.accion == 'crearDocumentoExterno') {
                  this.documentoExterno.id = cambioBack.id;
                  mensaje2.id = cambioBack.id;
                  this.enviar(mensaje2, null);
                }
                let borrador = this.verificarBorrador();
                this.listenerBorradorService.esBorrador.next(borrador);
                if (this.esBorrador == true && borrador == false) {
                  this.esBorrador = false
                  this.enviar(this.getMensaje('borrador', borrador), null);
                }
              }
            });
          }
        },
        error => this.errorHandlerComponent.handler(error));
      this.subscriptions.add(sub);
    }
  }

  actualizarMapaCufeRequerido({ id: IdVersion }): void {
    this.comandos.set('cufe', { ...this.comandos.get('cufe'), requerido: (IdVersion == 0) ? true : false })
  }

  verificarBorrador = () => Array.from(this.comandos).some(([clave, valor]) => (valor.requerido) && (!this.documentoExterno[clave]));
  verPopup = (codigoError, severidad, detalles) => {
    this.esperarRespuesta();
    this.popUpService.open({ codigoError, severidad, conBoton: undefined, detalle: detalles });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  };

}

