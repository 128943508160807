/* RUTAS PARA LAS VISTAS DESPUES DEL LOGUEO.

Los componentes de las rutas hijas serán cargados por Lazy Load
para evitar la carga innecesaria de archivos al inicio de la aplicación.

*/

import { Routes } from '@angular/router';
import { ActiveLicenseGuard } from '@wo/frontend/licencia/administrar-licencia/activeLicense.guard';
import { PuntosVentaComponent } from './configuracion/puntosDeVenta/puntosDeVenta.component';
import { TerminalesComponent } from './configuracion/terminales/terminales.component';
import { InicioComponent } from './inicio/inicio.component';
import { ListadoFacturasComponent } from './listado-facturas/listado-facturas.component';
import { PuntosDeVentaComponent } from './puntos-de-venta/puntos-de-venta.component';
import { ConfiguracionGeneralComponent } from '@wo/frontend/configuracion-general/configuracion-general.component';
import { InformeVentaComprobanteDiarioComponent } from './informes/informe-venta-comprobante-diario/informe-venta-comprobante-diario.component';
import { InformeVentaProductoFiltroComponent } from './informes/informe-venta-producto-filtro/informe-venta-producto-filtro.component';
import { InformeVentaTerminalComponent } from './informes/informe-venta-terminal/informe-venta-terminal.component';
import { ListaPreciosOtraMonedaComponent } from '../../../wo-frontend/src/app/lista-precios/lista-precios-otra-moneda/lista-precios-otra-moneda.component';
import { HistorialTurnosComponent } from './turnos/historial-turnos/historial-turnos.component';
import { InformeVentaTurnosComponent } from './informes/informe-venta-turnos/informe-venta-turnos.component';

export const PRIVATE_ROUTING_POS: Routes = [
    { path: '', component: InicioComponent, data: { nombre: 'DashBoard' }, canActivate: [ActiveLicenseGuard] },
    { path: 'listado-facturas', component: ListadoFacturasComponent, data: { nombre: 'ListadoFacturas', url: '/listado-facturas', permisoGruesoRequerido: 'ListadoFacturasPosVenta' }, canActivate: [ActiveLicenseGuard] },
    { path: 'punto-de-venta/:id', component: PuntosDeVentaComponent, data: { nombre: 'PuntoDeVenta', url: '/punto-de-venta/', permiteVarias: true }, canActivate: [ActiveLicenseGuard] },
    {
        path: 'configuracion',
        children: [
            { path: 'general', component: ConfiguracionGeneralComponent, data: { nombre: 'ConfiguracionDetallePos', url: '/configuracion/general', permisoGruesoRequerido: 'ConfiguracionPos' }, canActivate: [ActiveLicenseGuard] },
            {
                path: 'puntos-de-venta',
                children: [
                    { path: '', component: PuntosVentaComponent, data: { nombre: 'PuntosDeVenta', permisoGruesoRequerido: 'PuntoDeVenta', url: '/configuracion/puntos-de-venta' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'terminales-pos', component: TerminalesComponent, data: { nombre: 'Terminales', permisoGruesoRequerido: 'Terminal', url: '/configuracion/puntos-de-venta/terminales-pos' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'terminales-pos/:id', component: TerminalesComponent, data: { nombre: 'Terminales', permisoGruesoRequerido: 'Terminal', url: '/configuracion/puntos-de-venta/terminales-pos' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            { path: 'listas-precios', component: ListaPreciosOtraMonedaComponent, data: { nombre: 'ListaPrecioPos', url: '/configuracion/listas-precios', permisoGruesoRequerido: 'ListaDePrecio' }, canActivate: [ActiveLicenseGuard] },
        ]
    },
    {
        path: 'informes',
        children: [
            { path: 'venta-producto-pos', component: InformeVentaProductoFiltroComponent, data: { nombre: 'InfoVentasProductoDetallado', url: '/informes/venta-producto-pos', permisoGruesoRequerido: 'InfoVentasProductoDetalladoFiltro' }, canActivate: [ActiveLicenseGuard] },
            { path: 'comprobante-informe-diario-pos', component: InformeVentaComprobanteDiarioComponent, data: { nombre: 'InfoComprobanteDiario', url: '/informes/comprobante-informe-diario-pos', permisoGruesoRequerido: 'InfoComprobanteDiarioFiltro' }, canActivate: [ActiveLicenseGuard] },
            { path: 'venta-terminal-pos', component: InformeVentaTerminalComponent, data: { nombre: 'InfoVentasTerminal', url: '/informes/venta-terminal-pos', permisoGruesoRequerido: 'InfoVentasTerminalFiltro' }, canActivate: [ActiveLicenseGuard] },
            { path: 'venta-turno', component: InformeVentaTurnosComponent, data: { nombre: 'InfoVentasTurnos', url: '/informes/venta-turno', permisoGruesoRequerido: 'InfoVentasTurnosFiltro' }, canActivate: [ActiveLicenseGuard] },
        ]
    },
    {
        path: 'turnos',
        children: [
            { path: 'historial-turnos', component: HistorialTurnosComponent, data: { nombre: 'Turnos', url: '/turnos/historial-turnos', permisoGruesoRequerido: 'Turnos' }, canActivate: [ActiveLicenseGuard] }
        ]
    },
    { path: '**', redirectTo: '' }
];
