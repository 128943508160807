<app-menu-contextual></app-menu-contextual>

<span #selectPag *ngIf="mostrarSelect">
  <div id="select-paga" class="contextmenu"
    [ngStyle]="{'left.px':coordenadas.x-5,'top.px':coordenadas.y, position:'absolute', 'z-index':'+1','max-width.px':this.dimension.width - 4}">
    <wo-select-pag [columnas]="columnasComponente" [valorActual]="valorActual" [columnasRequeridas]="columnasRequeridas"
      [headerTranslate]="columnas[columna]?.headerTranslate" [disabled]="columnas[columna]?.soloLectura"
      [requerido]="requerido" [tipoFiltro]="tipoFiltro" [filtros]="filtros" [entidad]="entidad"
      [disabled]="disabledSelect" [pantalla]="pantalla"
      [registrosPagina]="columnas[columna]?.registrosPorPagina ? columnas[columna]?.registrosPorPagina:columnas[columna]?.registrosPagina"
      [lista]="lista" [dimension]="dimension" [intoWoTabla]="true" [customColumns]="customColumns"
      [editable]="columnas[columna]?.editable" [vaciarContenidoSelect]="vaciarContenidoSelect"
      [totalPages]="totalPaginas" [ancho]="woTablaDiv?.offsetWidth" [columnasMoneda]="columnasMoneda"
      (filtroEscritura)="filtroEscrituraImp($event,fila)" [columnasFecha]="columnasFecha"
      [columnasValorInput]="columnasSelectInput?columnasSelectInput:columnasComponente" [tieneIconImage]="false"
      [intoPopup]="intoPopup" [alturaWoTabla]="alturaWoTabla" [validarAlturaWoTabla]="validarAlturaWoTabla"
      (respuesta)="mostrarSelect=false; actualizarFocosTeclados($event); verificarLista($event,fila,columna,informacionColumna)"
      [atributo]="columnas[columna]?.atributo" (cambioPestana)="cambioPestanaSelect($event)"
      [anchoWoSelect]="columnas[columna]?.anchoWoSelect" [columnasOrdenar]="columnas[columna]?.columnasOrdenar"
      (contenidoVacio)="contenidoVacioEvent($event)" [informarContenidoVacio]="informarContenidoVacio"
      [idAtributoFiltroRenglon]="datos && datos[fila] && columnas[columna] && [columnas[columna]?.idAtributoFiltroRenglon] && datos[fila][columnas[columna]?.idAtributoFiltroRenglon] ? datos[fila][columnas[columna]?.idAtributoFiltroRenglon].id : null">
    </wo-select-pag>
  </div>
</span>

<span #acordeon *ngIf="mostrarAcordeon">
  <div id="acordeon" class="contextmenu listMenu"
    [ngStyle]="{'left.px':coordenadas.x,'top.px':coordenadas.y, position:'absolute' }">
    <acordeon-select [tablaEnum]="tablaEnum" [ngClass]="{'sinFlecha' : !(opciones?.editable) }" [anchoAcordeon]="ancho"
      [intoWoTabla]="true" [tablaEnum]="tablaEnum" [valorActual]="valorActual"
      [columnaActual]="columnasSinHidden[columnaActualFlechas]" [id]="acordeon+'-'+fila+'-'+columna"
      [columnas]="columnasComponente" [altoPopup]="popupSize" [posicionYPantalla]="posicionComponenteYPantalla"
      [posicionY]="posicionComponenteY" [arbol]="arbol" [tipo]="'text'"
      (rightClickAcordeon)="mostrarAcordeon=false;onRightClick($event,columna,fila,valorActual.id, datos,false)"
      (respuesta)="datos[fila][atributoComponente]=$event; actualizarFocosTeclados($event); cambioAcordeon(fila,columna,datos[fila][atributoComponente], atributoComponente)"
      [minChars]="1" [disabled]="columnas[columna]?.soloLectura" [dimensionComponente]="dimension"
      [editable]="columnas[columna]?.editable"></acordeon-select>
  </div>
</span>

<span #selectHeader *ngIf="mostrarSelectHeader">
  <div id="selectHeader" class="contextmenu listMenu"
    [ngStyle]="{'background':'#B3B7C6','text-align':'right','left.px':coordenadasHeader.x,'top.px':coordenadasHeader.y, position:'absolute' }">
    <wo-select-pag id="selectHeader" [columnas]="columnasSelectHeader" [valorActual]="valorActualSelectHeader"
      [disabled]="columnas[columna]?.soloLectura" [requerido]="requerido" [entidad]="entidadSelectHeader"
      [paginacionManual]="paginacionManualSelectHeader" [registrosPagina]="5" [textAlign]="'center'"
      [lista]="listaSelectHeader" [dimension]="dimension" [intoWoTabla]="true" [paginacion]="paginacionSelectHeader"
      [editable]="columnas[columna]?.editable" [headerTranslate]="columnas[columna]?.headerTranslate"
      [totalPages]="totalPaginas" [ancho]="woTablaDiv?.offsetWidth" (filtroEscritura)="filtroEscrituraImp($event)"
      [atributo]="'Header'" (respuesta)="cambiaSelectColumna($event);actualizarFocosTeclados($event,'header')">
    </wo-select-pag>
  </div>
</span>

<span #multiselectElement *ngIf="mostrarMultiselect">
  <div id="multiselect" class="multiContext"
    [ngStyle]="{'left.px':coordenadas.x,'top.px':coordenadas.y, position:'absolute' }">
    <multiselect class="tablaMultiselect" [abrir]="abrirMulti" [tablaElement]="elementRef.nativeElement" [fila]="fila"
      [columna]="columna" [tablaEnum]="tablaEnum" [totalELementos]="columnas[columna]?.totalElementos"
      [elementoNumero]="fila+1" [id]="multiSelectActual" [estaEnLaTabla]="true" [identificador]="'F'+fila+'C'+columna"
      [columnaActual]="multiSelectActual" [dimensionComponente]="dimension" [valores]="valoresMultiselect"
      [altoPopup]="popupSize" [paginacion]="paginacionMultiselec" [registrosPagina]="registroPaginaMultiselec"
      [anchoMultiSelect]="ancho" [entidad]="entidadMultiselec" [columnas]="columnasMultiselec"
      [posicionYPantalla]="posicionComponenteYPantalla" [tablaEnum]="tablaEnum" [posicionY]="posicionComponenteY"
      [seleccionados]="seleccionadosMultiselect" [requerido]="requeridoMultiselect" [filtros]="filtrosMultiselect"
      [atributo]="atributoMultiselect" (selecionar)="selecionarMultiselect($event)"
      (respuesta)="cambia(fila,columna,columnaValoresMultiselect,$event); actualizarFocosTeclados() "
      [cambia]="cambioMultiselect" [disabled]="columnas[columna]?.soloLectura" [editable]="columnas[columna]?.editable"
      [generarTodos]="todasMultiselect" [onlyBusqueda]="onlyBusquedaMultiSelect" [scrollMoving]="ocultarMultiSelect"
      [celdaActual]="celdaMultiselect" (controlUltimaFila)="actualizarUltimaFila($event)">
    </multiselect>
  </div>
</span>


<div #woTablaDiv id="woTableCont-{{tablaEnum}}" class="woTableCont"
  [ngClass]="{'loadingWoTabla':loaderWoTabla && !tablaHija}" [ngStyle]="woTableStyle" oncontextmenu="return false"
  style="max-height: none; z-index: 0;" (click)="clickWoTable()"
  [style.minHeight]="minAltoScroll ? minAltoScroll : tablaHija ? 'auto':''" [style.margin]="tablaHija ? '10px 20px':''">

  <table #cuerpo id="woTabla-{{tablaEnum}}" cellpadding="0" cellspacing="0" border="0" (copy)="copy($event)"
    (keydown.end)="onkeyEnd()" (keydown.home)="onkeyStart($event)" style="display: block;"
    [style.opacity]="tablaHija ? 1:0" (paste)="onPaste($event)">
    <tbody *ngIf="opciones?.subheader">
      <tr>
        <th *ngIf="!ocultarNumeracion"></th>
        <td *ngFor="let a of columnas; let j=index; trackBy: trackByAtributo"
          [ngStyle]="{ 'padding': '0.7px 0.7px 0.7px 8px' }" [ngClass]="a | comprobarEstilo"
          [ngStyle]="a | traerClaseMinima:columnas">
          <fieldset [disabled]="true" id="tbodyHead-{{a.atributo}}">
            <span [ngSwitch]="a.tipoInput">
              <input class="prueba3" tabindex="-1" [disabled]="true" type="text" autocomplete="off" name="name"
                [(ngModel)]="subheader[a.atributo]" [title]="subheader[a.atributo]">
              <!-- <input class="prueba3" tabindex="-1" [disabled]="true" type="text" autocomplete="off" name="name"
                [(ngModel)]="subheader[a.atributo]"> -->
            </span>
          </fieldset>
        </td>
      </tr>
    </tbody>
    <cdk-virtual-scroll-viewport id="cdkVirtual" CdkVirtualScrollViewportPatch [itemSize]="itemSize" [minBufferPx]="100"
      [maxBufferPx]="400"
      [ngStyle]="{ 'height': altoScroll && !tablaHija ? (altoScroll.toString().includes('px') ? altoScroll:altoScroll+'vh'):'auto', 'max-height': tablaHija ? '300px':'', 'min-height': minAltoScroll ? minAltoScroll : tablaHija ? '300px':'' }"
      class="smooth" (scroll)="onHorizontalScroll($event)" (scrolledIndexChange)="onScroll($event)"
      (mousewheel)="onMouseWheelChrome($event)">

      <thead id="thead-{{tablaEnum}}">
        <tr>
          <th *ngIf="!ocultarNumeracion" id="resetTh-{{tablaEnum}}" class="num"
            [style.top.px]="viewPort && viewPort['_renderedContentOffset'] ? '-' + viewPort['_renderedContentOffset'] : '0'">
          </th>
          <ng-container *ngFor="let c of columnas; let m=index; trackBy: trackByAtributo">
            <th id="{{'C'+m+'-'+tablaEnum}}"
              [style.top.px]="viewPort && viewPort['_renderedContentOffset'] ? '-' + viewPort['_renderedContentOffset'] : '0'"
              class="{{c.atributo}}+{{m}} ColumnasTH  ColumnaTH-{{c.atributo}} ColumnaTH-{{c.atributo}}-{{tablaEnum}} {{tablaEnum}}TH"
              tabindex="-1" *ngIf="!(c?.hidden)" [ngStyle]="c | traerClaseMinima:columnas"
              [ngClass]="c | comprobarClaseLicencia" attr.data-numcol="{{m}}" attr.data-atributo="{{c.atributo}}"
              (click)="comprobarClick(c)"
              attr.data-header="{{c.header == '' || c.header == null || c.header == undefined ? c.atributo : c.header }}">

              <span *ngIf="!bloquearAnchos" attr.data-tipoDato="{{c?.tipoDato}}" attr.data-tipoInput="{{c?.tipoInput}}"
                id="ajustarColumna-{{c.atributo}}-{{tablaEnum}}" class="ajustarColumna"
                attr.data-tablaEnum="{{tablaEnum}}" (mousedown)="onMouseDown($event,c.atributo,'ajusteColumnas',m)"
                (click)="esperarAjusteColumnas(false)" attr.data-columna="{{c.atributo}}">
              </span>

              <span *ngIf="c?.header!=undefined" class="th">
                <a style="user-select: none" class="{{'tha ' + 'tour'+c.atributo}} flex">
                  <a *ngIf="c.requerido" class="itemRequerido"><span>*</span></a>
                  <p [ngClass]="{'conIcoHeader': c.icoHeader}">{{c.header | translate}}</p>
                  <div *ngIf="c.icoHeader" class="contenedorIcoHeader icoNoInput flex"
                    [ngClass]="{'icoSinOrdenar': !(opciones.ordenar!=false && c.ordenar!=false)}"
                    (click)="clickIcoHeader(c)">
                    <i class="iconoHeader {{c.icoHeader}}"></i>
                  </div>
                  <span *ngIf="opciones.ordenar!=false && c.ordenar!=false" class="order"
                    [ngClass]="{'conIcoHeader': c.icoHeader}">
                    <span *ngIf="c.orden==undefined">
                      <img (click)="ordenar(c)" src="assets/images/iconsAdmonUsuario/icoOrdenaNo.png">
                    </span>
                    <span *ngIf="c.orden=='ASC'">
                      <img (click)="ordenar(c)" src="assets/images/iconsAdmonUsuario/icoOrdenaUp.png">
                    </span>
                    <span *ngIf="c.orden=='DESC'">
                      <img (click)="ordenar(c)" src="assets/images/iconsAdmonUsuario/icoOrdenaDown.png">
                    </span>
                  </span>
                  <span *ngIf="c.senMostrarTooltip"
                    [ngStyle]="{'top': c?.topTooltip ? c.topTooltip : '0px', 'right': '-15px'}"
                    class="mensajeTooltip order" [title]="c?.mensajeTooltip">
                    <i class="fas fa-info-circle"></i>
                  </span>
                </a>
              </span>
              <span id="{{'select-header'+m}}" *ngIf="c?.selectHeader!=undefined" class="wlabel">
                <wo-label-field id="'selectHeader'" [acordeon]="false" [entidad]="c.selectHeader.valor"
                  [columnas]="c.selectHeader.columnas" (click)="onClickSelectHeader(c , m)"
                  [ngClass]="{'conIcoHeader': c.icoHeader}"></wo-label-field>
                <div *ngIf="c.icoHeader" class="contenedorIcoHeader flex"
                  [ngClass]="{'icoSinOrdenar': !(opciones.ordenar!=false && c.ordenar!=false)}"
                  (click)="clickIcoHeader(c)">
                  <i class="iconoHeader {{c.icoHeader}}"></i>
                </div>
                <span *ngIf="opciones.ordenar!=false && c.ordenar!=false" class="orderHeader">
                  <span *ngIf="c.orden==undefined">
                    <img (click)="ordenar(c)" src="assets/images/iconsAdmonUsuario/icoOrdenaNo.png">
                  </span>
                  <span *ngIf="c.orden=='ASC'">
                    <img (click)="ordenar(c)" src="assets/images/iconsAdmonUsuario/icoOrdenaUp.png">
                  </span>
                  <span *ngIf="c.orden=='DESC'">
                    <img (click)="ordenar(c)" src="assets/images/iconsAdmonUsuario/icoOrdenaDown.png">
                  </span>
                </span>
              </span>
              <span *ngIf="c?.checkHeader!=undefined">
                <input tabindex="-1" type="checkbox" name="name" [(ngModel)]="c.checkHeader.valor"
                  (ngModelChange)="cambiaCheckHeader(c)" [disabled]="c.checkHeader?.disabled"
                  [ngStyle]="{'cursor': c.checkHeader?.disabled ? 'not-allowed':'auto'}">
                <blockquote class="control-blockquote"></blockquote>
              </span>
            </th>
          </ng-container>
          <th id="opcionesAyuda"
            [style.top.px]="viewPort && viewPort['_renderedContentOffset'] ? '-' + viewPort['_renderedContentOffset'] : '0'">
            <img id="menu-Tabla" src="assets/images/iconsAdmonUsuario/icoOpcionesTitle.png" title="Opciones"
              (click)="desplegarOpcionesTabla()">
          </th>
        </tr>
      </thead>

      <tbody id="tbody-{{tablaEnum}}">
        <ng-container *ngIf="noScrollInfinito; else cdkScrollInfinito">
          <tr class="fila-{{i}} fila-{{tablaEnum}}" *ngFor="let datoFila of datos; let i=index; trackBy: trackById">
            <ng-container [ngTemplateOutlet]="renderFilas" [ngTemplateOutletContext]="{ $implicit: datoFila, index: i}">
            </ng-container>
          </tr>
        </ng-container>

        <ng-template #cdkScrollInfinito>
          <tr #scrollInfinito class="fila-{{i}} fila-{{tablaEnum}}"
            *cdkVirtualFor="let datoFila of datos; let i=index; trackBy: trackById">
            <ng-container [ngTemplateOutlet]="renderFilas" [ngTemplateOutletContext]="{ $implicit: datoFila, index: i}">
            </ng-container>
          </tr>
        </ng-template>

        <ng-template #renderFilas let-d let-i=index>

          <th *ngIf="!ocultarNumeracion && !abrirRenglones" class="th-{{tablaEnum}} thStick" tabindex="-1"
            (click)="resaltarFila(i+1,-1,true,false)" [ngStyle]="{'width':sizeWidthBody[0]+'px'}">{{i+1}}</th>
          <th *ngIf="!ocultarNumeracion && abrirRenglones" class="th-{{tablaEnum}} thStick" tabindex="-1"
            (click)="abrirRenglon(i)" [ngStyle]="{'width':sizeWidthBody[0]+'px', 'cursor': 'pointer'}">
            <span *ngIf="d.id && d.datosTablaHija.length > 0" class="dropTable icon-trianguloDer1"
              [ngStyle]="{'transform': d.abrirRenglon ? 'rotate(90deg)':'rotate(0)'}"></span>
          </th>
          <!-- (mousedown)="mousedown(i+1,true)"  (mousemove)="mousemove($event,i+1,true)" (mouseup)="mouseup(i+1)" -->
          <!-- <th (mousedown)="mousedownCel($event,i+1,-1,null,d,null)"  class="th-{{tablaEnum}} th-numeros-{{tablaEnum}}" tabindex="-1" (click)="resaltarFila(i+1,'izquierdo',$event)">{{i+1}}</th> -->
          <ng-container *ngFor="let a of columnas; let j=index; trackBy: trackByAtributo">
            <!-- class="{{a.atributo}}-{{tablaEnum}}" -->
            <!-- (mousedown)="mousedownCel($event,i+1,j+1,d[a.atributo],d,a)" (mousemove)="mousemoveCel($event,i+1,j+1)" (mouseup)="mouseupCel($event,i+1,j+1) -->
            <td #tdElementTabla class="columna-{{j}} td-Table-{{tablaEnum}}" [ngStyle]="(a | traerClaseMinima:columnas)"
              [ngClass]="a | comprobarEstilo" attr.data-iddata="{{d.id}}" attr.data-atributo="{{a.atributo}}"
              attr.data-numcol="{{j}}" attr.data-numfil="{{i}}" tabindex="0"
              (keydown.enter)="onClickTableEvent(i,j,a,$event,true,d); onKeyEnter(i,j,a,$event,true)"
              (keydown.shift.tab)="onKeyTab($event,a,i,j)" (keyup.arrowleft)="onkeyArrow($event,a,i,j)"
              (keyup.arrowup)="onkeyArrow($event,a,i,j)" (keydown.control.arrowup)="copiarCeldaSuperior($event,a,i,j)"
              (keyup.arrowdown)="onkeyArrow($event,a,i,j)" (keyup.arrowright)="onkeyArrow($event,a,i,j)"
              (keyup.tab)="onKeyTab($event,a,i,j)" (keyup.backspace)="onKeyBackSpace($event,a,i,j)"
              id="{{'F'+i+'C'+j}}-{{tablaEnum}}" *ngIf="!(a?.hidden)" [ngClass]="{'tdAcordeon': a?.tipoInput === 9}"
              (mousedown)="mousedownCel($event,i+1,j+1,d[a.atributo],d,a)"
              (contextmenu)="onclickRigthTable(i,j,a,$event,true,d,true)"
              (click)="onClickTableEvent(i,j,a,$event,true,d)" (dblclick)="doubleClick(d,a,i)"
              [ngStyle]="{'width':sizeWidthBody[j+1]+'px' ,'min-width':sizeWidthBody[j+1]+'px', 'max-width':sizeWidthBody[j+1]+'px'}"
              [title]="d[a.atributo] | agregarTitulo:a.tipoInput:a.columnas:a.tipoNumeric:a?.tooltip:null:null:null:a?.senTooltipPersonalizado"
              attr.data-tipoInput="{{a.tipoInput}}" attr.data-dAtributo="{{d[a.atributo]}}"
              attr.data-aColumnas="{{a.columnas}}">
              <!-- [title]="agregarTitulo(d[a.atributo],a.tipoInput,a.columnas, a?.tooltip)" -->
              <fieldset id="{{a.tipoInput}}"
                [disabled]="i | fieldSetDisable:j:datos:opciones.editable:dataDisabled:a.atributo"
                [ngStyle]="i | fieldSetStyle:j:datos:opciones"
                [ngClass]="d | resaltarErrores:a:datos:errores:errores.length:mostrarBorrador:mostrarErrores:i"
                [title]="d | mostrarTooltip:a:errores">
                <!-- <p>jota{{j}} ii {{i}}</p> -->
                <span [ngSwitch]="a.tipoInput" *ngIf="activa" [ngStyle]="{'color': d.colorLetra ? d.colorLetra : null}">
                  <!-- Input texto -->
                  <span *ngSwitchCase="0">
                    <!-- agregar ngif -->
                    <div *ngIf="a.permiteClickDisabled; else inputText"
                      [class]="a.atributo !== 'jwt' ? 'flex' : 'flexStart'">
                      <span class="{{a.atributo}}-{{tablaEnum}} {{a.atributo}}-{{tablaEnum}}-{{d.id}}" tabindex="-1"
                        style="max-width: 200px; max-height: 30px; width: calc(100% - 2px);"
                        [title]="d[a.atributo] |  agregarTitulo:a.tipoInput:a.columnas:a.tipoNumeric:a?.tooltip"
                        [ngStyle]="{'cursor': a.atributo == 'jwt' ?'pointer' : 'inherit' ,'color': d.colorLetra ? d.colorLetra : null, 'text-align': a.alinear ? a.alinear:null, 'text-transform': a?.lowerCase ? 'lowercase': a?.upperCase ? 'uppercase': 'none'}"
                        ondrop="return false">
                        {{d[a.atributo]}}
                      </span>
                    </div>
                    <ng-template #inputText>
                      <a *ngIf="a?.ayuda" [title]="a.ayuda">
                        <input id="textInputAyuda-{{tablaEnum}}-F{{i}}C{{j}}"
                          (keydown.control.arrowup)="copiarCeldaSuperior($event,a,i,j)"
                          class="{{a.atributo}}-{{tablaEnum}} {{a.atributo}}-{{tablaEnum}}-{{d.id}}" tabindex="-1"
                          [(maxlength)]="a.maxLen"
                          [disabled]="!a.editable || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo])"
                          type="text" name="name" [(ngModel)]="d[a.atributo]" (blur)="cambia(i,j,a);" autocomplete="off"
                          [ngStyle]="{'pointer-events': 'none', 'text-transform': a?.lowerCase ? 'lowercase': a?.upperCase ? 'uppercase': 'none' }"
                          size="2" style="width: calc(100% - 2px);" ondrop="return false;"
                          [ngStyle]="{'color': d.colorLetra ? d.colorLetra : null, 'text-align': a.alinear ? a.alinear:null}">
                      </a>
                      <div class="divTextInput" *ngIf="a?.upperCase" style="width: 100%">
                        <input id="textInputUpperCase-{{tablaEnum}}-F{{i}}C{{j}}" j
                          (keydown.control.arrowup)="copiarCeldaSuperior($event,a,i,j)"
                          class="{{a.atributo}}-{{tablaEnum}} {{a.atributo}}-{{tablaEnum}}-{{d.id}}" tabindex="-1"
                          *ngIf="!a.ayuda" [(maxlength)]="a.maxLen"
                          [disabled]="!a.editable || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo])"
                          type="text" name="name" [(ngModel)]="d[a.atributo]" (blur)="cambia(i,j,a);" autocomplete="off"
                          style="text-transform:uppercase" size="2" style="width: calc(100% - 2px);"
                          [title]="d[a.atributo] |  agregarTitulo:a.tipoInput:a.columnas:a.tipoNumeric:a?.tooltip"
                          [ngStyle]="{'color': d.colorLetra ? d.colorLetra : null, 'text-align': a.alinear ? a.alinear:null}">
                      </div>
                      <div class="divTextInput" *ngIf="a?.lowerCase" style="width: 100%">
                        <input id="textInputLowerCase-{{tablaEnum}}-F{{i}}C{{j}}"
                          (keydown.control.arrowup)="copiarCeldaSuperior($event,a,i,j)"
                          class="{{a.atributo}}-{{tablaEnum}} {{a.atributo}}-{{tablaEnum}}-{{d.id}}" tabindex="-1"
                          *ngIf="!a.ayuda" [(maxlength)]="a.maxLen"
                          [disabled]="!a.editable || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo])"
                          type="text" name="name" [(ngModel)]="d[a.atributo]" (blur)="cambia(i,j,a);" autocomplete="off"
                          size="2" style="width: calc(100% - 2px); text-transform: lowercase;"
                          [title]="d[a.atributo] |  agregarTitulo:a.tipoInput:a.columnas:a.tipoNumeric:a?.tooltip"
                          [ngStyle]="{'color': d.colorLetra ? d.colorLetra : null, 'text-align': a.alinear ? a.alinear:null}">
                      </div>
                      <div class="divTextInput" *ngIf="!a?.upperCase && !a?.lowerCase" style="width: 100%">
                        <input id="textInputNupperCase-{{tablaEnum}}-F{{i}}C{{j}}"
                          (keydown.control.arrowup)="copiarCeldaSuperior($event,a,i,j)"
                          class="{{a.atributo}}-{{tablaEnum}} {{a.atributo}}-{{tablaEnum}}-{{d.id}}" tabindex="-1"
                          *ngIf="!a.ayuda" [(maxlength)]="a.maxLen"
                          [disabled]="!a.editable || (d?.mapaBloqueado | mustHideFormat:j:d.id) || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo])"
                          type="text" name="name" [(ngModel)]="d[a.atributo]" (blur)="cambia(i,j,a);" autocomplete="off"
                          size="2" style="width: calc(100% - 2px);"
                          [title]="d[a.atributo] |  agregarTitulo:a.tipoInput:a.columnas:a.tipoNumeric:a?.tooltip"
                          ondrop="return false"
                          [ngStyle]="{'color': d.colorLetra ? d.colorLetra : null, 'text-align': a.alinear ? a.alinear:null}">
                      </div>
                    </ng-template>
                  </span>
                  <!-- Input numero -->
                  <span *ngSwitchCase="1"
                    [ngClass]="{ 'sinTipoNumeric': a?.tipoNumeric!=='moneda' && a?.tipoNumeric!=='porcentaje', 'porcentajeTipoNumeric': a.tipoNumeric=='porcentaje' }"
                    class="span-TipoMoneda-{{a.atributo}}">



                    <span class="tipoMoneda span-TipoMoneda-{{a.atributo}} {{a.tipoNumeric}}-{{a.atributo}} "
                      [ngClass]="{'tipoMonedaMM': opciones?.multimoneda}" *ngIf="a.tipoNumeric=='moneda'">

                      <ng-container *ngIf="mostrarMultimoneda && (d.monedasMap && d.monedasMap.get(a?.atributo))">
                        <div class="simboloMonedaSinMulti" *ngIf="a.moneda">
                          <p>{{a?.moneda?.simbolo}}</p>
                        </div>

                        <div class="simboloMonedaSinSinMulti" *ngIf="!a.moneda">
                          <p>{{simboloMoneda}}</p>
                        </div>

                        <div class="conMoneda" *ngIf="(d.monedasMap && d.monedasMap.get(a?.atributo))">
                          <input id="textInputNumero-{{tablaEnum}}"
                            (keypress)="validacionNumeros($event); validacionCampoMoneda($event,d[a.atributo],i,a.atributo)"
                            (keydown.control.arrowup)="copiarCeldaSuperior($event,a,i,j)"
                            (focus)="d.quitarFormato = true; a.quitarFormato = true;" tabindex="-1" id="inputMoneda"
                            [disabled]="!a.editable || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo])"
                            autocomplete="off" type="text" name="name"
                            [ngModel]="d[a.atributo] | formatearNumero:a.atributo:a.moneda:a.tipoNumeric:d.monedasMap:opciones:mostrarMultimoneda:decimalesMoneda:(d.quitarFormato && a.quitarFormato)"
                            (ngModelChange)="d[a.atributo] = $event" [(maxlength)]="a.maxLen"
                            (blur)="d.quitarFormato = false; a.quitarFormato = false; cambiaMoneda(i,j,a)"
                            [title]="d[a.atributo] | formatearNumero:a.atributo:a.moneda:a.tipoNumeric:d.monedasMap:opciones:mostrarMultimoneda:decimalesMoneda"
                            size="2" ondrop="return false;"
                            [ngStyle]="{'color': d.colorLetra ? d.colorLetra : null, 'text-align': a.alinear ? a.alinear:null}">
                        </div>

                        <div class="simboloMMoneda">
                          <p>{{d?.monedasMap?.get(a?.atributo)?.simbolo}}</p>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="opciones.mostrarMonedaRenglon">
                        <div class="simboloMonedaSinSinMulti" *ngIf="!a.moneda">
                          <p>{{d?.monedasMap?.get(a?.atributo)?.simbolo}}</p>
                        </div>
                        <div class="sinMoneda">
                          <input id="textInputNumero-{{tablaEnum}}"
                            (keypress)="validacionNumeros($event); validacionCampoMoneda($event,d[a.atributo],i,a.atributo)"
                            (keydown.control.arrowup)="copiarCeldaSuperior($event,a,i,j)"
                            (focus)="d.quitarFormato = true; a.quitarFormato = true" tabindex="-1" id="inputMoneda"
                            [disabled]="!a.editable || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo])"
                            autocomplete="off" type="text" name="name"
                            [ngModel]="d[a.atributo] | formatearNumero:a.atributo:a.moneda:a.tipoNumeric:d.monedasMap:opciones:mostrarMultimoneda:decimalesMoneda:(d.quitarFormato && a.quitarFormato)"
                            (ngModelChange)="d[a.atributo] = $event" [(maxlength)]="a.maxLen"
                            (blur)="d.quitarFormato = false; a.quitarFormato = false; cambiaMoneda(i,j,a)"
                            [title]="d[a.atributo] | formatearNumero:a.atributo:a.moneda:a.tipoNumeric:d.monedasMap:opciones:mostrarMultimoneda:decimalesMoneda"
                            size="2" style="width: calc(100% - 9px)" ondrop="return false;"
                            [ngStyle]="{'color': d.colorLetra ? d.colorLetra : null, 'text-align': a.alinear ? a.alinear:null}">
                        </div>
                      </ng-container>

                      <ng-container
                        *ngIf="!opciones.mostrarMonedaRenglon && (!mostrarMultimoneda||!(d.monedasMap && d.monedasMap.get(a?.atributo)))">
                        <div class="simboloMonedaSinMulti" *ngIf="a.moneda && !a?.sinSimbolo">
                          <p>{{a?.moneda?.simbolo}}</p>
                        </div>

                        <div class="simboloMonedaSinSinMulti" *ngIf="!a.moneda">
                          <p>{{simboloMoneda}}</p>
                        </div>

                        <div class="sinMoneda">
                          <input id="textInputNumero-{{tablaEnum}}"
                            [ngStyle]="{ 'cursor': !a.editable || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo]) ? 'not-allowed' : 'auto' }"
                            (keypress)="validacionNumeros($event); validacionCampoMoneda($event,d[a.atributo],i,a.atributo)"
                            (keydown.control.arrowup)="copiarCeldaSuperior($event,a,i,j)"
                            (focus)="d.quitarFormato = true; a.quitarFormato = true" tabindex="-1" id="inputMoneda"
                            [disabled]="!a.editable || (dataDisabled[i] && dataDisabled[i][a.atributo])"
                            autocomplete="off" type="text" name="name"
                            [ngModel]="d[a.atributo] | formatearNumero:a.atributo:a.moneda:a.tipoNumeric:d.monedasMap:opciones:mostrarMultimoneda:decimalesMoneda:(d.quitarFormato && a.quitarFormato)"
                            (ngModelChange)="d[a.atributo] = $event" [(maxlength)]="a.maxLen"
                            (blur)="d.quitarFormato = false; a.quitarFormato = false; cambiaMoneda(i,j,a)"
                            [title]="d[a.atributo] | formatearNumero:a.atributo:a.moneda:a.tipoNumeric:d.monedasMap:opciones:mostrarMultimoneda:decimalesMoneda"
                            size="2" style="width: calc(100% - 9px)" ondrop="return false;"
                            [ngStyle]="{'color': d.colorLetra ? d.colorLetra : null, 'text-align': a.alinear ? a.alinear:null}">
                        </div>
                      </ng-container>

                    </span>
                    <span class="tipoNumero" *ngIf="a.tipoNumeric!='moneda' && a.tipoNumeric!='porcentaje'">
                      <ng-container *ngIf="i % 2 == 0; else filaImpar">
                        <wo-text-field [tablaEnumera]="tablaEnum+i" [tipoInput]="'number'" [maxlength]="a.maxLen"
                          id="numero_identificacion{{i}}" name="noID" [requerido]="a?.requerido"
                          [tablaEditable]="opciones?.editable && (!dataDisabled || !dataDisabled[i] || !dataDisabled[i][a.atributo])"
                          [valorActual]="d[a.atributo]" [intoWoTabla]='true' [tablaEnumera]="tablaEnum"
                          [atributo]="a.atributo" [cantidadDecimales]="a.cantidadDecimales"
                          [soloLectura]="a.soloLectura" [cantidadEnteros]="a.cantidadEnteros" [fila]="i"
                          (respuesta)="d[a.atributo]=$event;cambia(i,j,a); actualizarFocosTeclados() "
                          [disabled]="!a.editable || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo])"
                          [noFormatearBlur]="a.noFormatearBlur">
                        </wo-text-field>
                      </ng-container>
                      <ng-template #filaImpar>
                        <wo-text-field [tablaEnumera]="tablaEnum+i" [tipoInput]="'number'" [maxlength]="a.maxLen"
                          id="numero_identificacion{{i}}" name="noID" [requerido]="a?.requerido"
                          [tablaEditable]="opciones?.editable && (!dataDisabled || !dataDisabled[i] || !dataDisabled[i][a.atributo])"
                          [valorActual]="d[a.atributo]" [intoWoTabla]='true' [tablaEnumera]="tablaEnum"
                          [atributo]="a.atributo" [cantidadDecimales]="a.cantidadDecimales"
                          [cantidadEnteros]="a.cantidadEnteros" [fila]="i" [soloLectura]="a.soloLectura"
                          (respuesta)="d[a.atributo]=$event;cambia(i,j,a); actualizarFocosTeclados() "
                          [disabled]="!a.editable || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo])"
                          [noFormatearBlur]="a.noFormatearBlur">
                        </wo-text-field>
                      </ng-template>
                    </span>
                    <span class="tipoPorcentaje" *ngIf="a.tipoNumeric=='porcentaje'">
                      <ng-container *ngIf="i % 2 == 0; else filaImparPorcentaje">
                        <wo-text-field [tablaEnumera]="tablaEnum+i" [atributo]="a.atributo" [tipoInput]="'number'"
                          [esPorcentaje]="'true'" [maxlength]="a.maxLen" id="numero_identificacion{{i}}" name="noID"
                          [fila]="i" [requerido]="a?.requerido"
                          [tablaEditable]="opciones?.editable && (!dataDisabled || !dataDisabled[i] || !dataDisabled[i][a.atributo])"
                          [valorActual]="d[a.atributo]" [intoWoTabla]='true'
                          (respuesta)="d[a.atributo]=$event;cambia(i,j,a); actualizarFocosTeclados()"
                          [disabled]="!a.editable || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo])"
                          [decimalesPorcentaje]=a.decimales [tipoDato]=a.tipoDato
                          [defaultPorcentaje]="valorDefaultPorcentaje">
                        </wo-text-field>
                      </ng-container>
                      <ng-template #filaImparPorcentaje>
                        <wo-text-field [tablaEnumera]="tablaEnum+i" [atributo]="a.atributo" [tipoInput]="'number'"
                          [esPorcentaje]="'true'" [maxlength]="a.maxLen" id="numero_identificacion{{i}}" name="noID"
                          [fila]="i" [requerido]="a?.requerido"
                          [tablaEditable]="opciones?.editable && (!dataDisabled || !dataDisabled[i] || !dataDisabled[i][a.atributo])"
                          [valorActual]="d[a.atributo]" [intoWoTabla]='true'
                          (respuesta)="d[a.atributo]=$event;cambia(i,j,a); actualizarFocosTeclados()"
                          [disabled]="!a.editable || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo])"
                          [decimalesPorcentaje]=a.decimales [tipoDato]=a.tipoDato
                          [defaultPorcentaje]="valorDefaultPorcentaje">
                        </wo-text-field>
                      </ng-template>
                      <p *ngIf="a.tarifaPorMil">‰</p>
                      <p *ngIf="!a.tarifaPorMil">%</p>
                      <!-- <div *ngIf="d[a.atributo]">
                        <p *ngIf="a.tarifaPorMil">‰</p>
                        <p *ngIf="!a.tarifaPorMil">%</p>
                      </div> -->
                    </span>
                  </span>
                  <!-- InputFecha -->
                  <span *ngSwitchCase="2">
                    <wo-text-field class="datepicker2" [tipoInput]="a.formatoFecha ? a.formatoFecha:'date'"
                      [startAt]="startAt" [tablaEnumera]="'inicial'" [tablaEnumera]="tablaEnum"
                      [valorActual]="d[a.atributo]" [intoWoTabla]='true' name="noID"
                      [tablaEditable]="opciones?.editable && (!dataDisabled || !dataDisabled[i] || !dataDisabled[i][a.atributo])"
                      [hideFormat]="mustHideFormat(d?.mapaBloqueado, j, d.id)" [fila]="i"
                      (respuesta)="d[a.atributo]=$event;cambia(i,j,a); actualizarFocosTeclados() "
                      [atributo]="a.atributo" [requerido]="a?.requerido"
                      [disabled]="!a.editable || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo])"
                      [minDate]="a?.minDate" [maxDate]="a?.maxDate" [noPermiteEscritura]=a?.noPermiteEscritura>
                    </wo-text-field>
                  </span>
                  <!-- Input select -->
                  <span *ngSwitchCase="3" id="{{'span-select-pag'+i+j}}">
                    <wo-label-field
                      [titulo]="d[a?.atributo] | agregarTitulo:a?.tipoInput:a?.columnas:a?.tipoNumeric:a?.tooltip:d?.customColumns:a?.atributo:a?.columnasValorInput"
                      [atributo]="a?.atributo"
                      [disabled]="a?.soloLectura || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo])"
                      id="wo-label-field"
                      [editable]="!(i | fieldSetDisable:j:datos:opciones.editable) && (!dataDisabled || !dataDisabled[i] || !dataDisabled[i][a.atributo])"
                      [customColumns]="d?.customColumns" [acordeon]="false"
                      [entidad]="datos.length > 0 ? datos[i][a?.atributo] : null" [columnas]="a?.columnas"
                      [width]="mapaAnchos.get(a?.atributo)" [columnasValorInput]="a?.columnasValorInput" [select]="true"
                      [tipo]="a?.atributo+'-select-'+i">
                    </wo-label-field>
                  </span>
                  <!-- Input multiselect -->
                  <span *ngSwitchCase="4" class="Multiselect-{{a.atributo}}-{{tablaEnum}}" id="{{'multiselect'+i+j}}">
                    <wo-label-field id="multiselectLabel" class="{{ 'F' + i + 'C' + j + '-' + tablaEnum}}"
                      (eliminarElemento)="abrirMultiselect(a.atributo,d[a.atributo],a.valores,a.filtros,a.requerido,a.header,a.paginacion,a.registrosPagina,a.entidad,a.columnas,a?.todas,i,j,a, a?.onlyBusqueda);eliminarElementoMultiselect($event,d[a.atributo],a.requerido)"
                      (click)="abrirMultiselect(a.atributo,d[a.atributo],a.valores,a.filtros,a.requerido,a.header,a.paginacion,a.registrosPagina,a.entidad,a.columnas,a?.todas,i,j,a, a?.onlyBusqueda)"
                      [esMultiSelect]="true" [limiteRegistrosMultiselect]="a.limiteRegistrosMultiselect" [seleccionados]="d[a.atributo]" [atributo]="a.propiedad"
                      [titulo]="d[a.atributo] |  agregarTitulo:a.tipoInput:a.columnas:a.tipoNumeric:a?.tooltip"
                      [disabled]="a.soloLectura || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo])"
                      [editable]="!(i | fieldSetDisable:j:datos:opciones.editable) && (!dataDisabled || !dataDisabled[i] || !dataDisabled[i][a.atributo])"
                      [acordeon]="false" [entidad]="a.entidad" [columnas]="a.columnas" [tipo]="a.atributo+'-select-'+i"
                      [tema]="woTableStyle" (cambiaMultiselect)="cambiaValoresMultiselect($event)" [fila]="i"
                      [columna]="j">
                    </wo-label-field>

                  </span>
                  <!-- Input check -->
                  <span *ngSwitchCase="5">
                    <input *ngIf="a.header == 'Valida'" tabindex="-1" type="checkbox" name="name" class="1"
                      style="cursor: pointer;"
                      [disabled]="(!a?.editable || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo]))"
                      [ngStyle]="{'color': d.colorLetra ? d.colorLetra : null, 'text-align': a.alinear ? a.alinear:null}">
                    <input *ngIf="a.header != 'Valida'" tabindex="-1" type="checkbox" name="name"
                      [(ngModel)]="d[a.atributo]" (ngModelChange)="cambia(i,j,a)" size="2"
                      [ngClass]="d | verificarMapaBloqueado:a:columnas" class="2"
                      [ngStyle]="{'color': d.colorLetra ? d.colorLetra : null, 'text-align': a.alinear ? a.alinear:null}"
                      [disabled]="((a.bloqueadoSistema | verificarBloqueadoCheck:a.editable:a.disableCheck)  || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo]))">

                    <span
                      *ngIf="!opciones?.editable || !a.editable || a.disableCheck || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo]) || a.importacion">
                      <span class="contentItem">
                        <span class="itemActivo" *ngIf="d[a.atributo]"></span>
                        <span class="itemInactivo" *ngIf="!d[a.atributo]"></span>
                      </span>
                    </span>
                    <span *ngIf="(opciones?.editable) && a.editable && !a.disableCheck && !a.importacion"
                      style="min-width:30px;">
                      <input tabindex="-1" type="checkbox" name="name" [(ngModel)]="d[a.atributo]"
                        (ngModelChange)="cambia(i,j,a)" size="2" class="3"
                        [ngStyle]="{'color': d.colorLetra ? d.colorLetra : null, 'text-align': a.alinear ? a.alinear:null}">
                      <blockquote class="control-blockquote"></blockquote>
                    </span>
                  </span>
                  <!-- Input Button -->
                  <span *ngSwitchCase="6" style="min-width:50px;">
                    <ng-container *ngIf="a.subirBajar">
                      <span class="btnOrder">
                        <button class="btnBajar" (click)="clickBotonBajarSubir(i,j,a,+1)"
                          *ngIf="(datos.length - 2 != i) &&  (datos.length - 1 != i)"></button>
                        <button class="btnSubir" (click)="clickBotonBajarSubir(i,j,a,-1)"
                          *ngIf="i != 0 && (datos.length - 1 != i) "></button>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="a.atributo == 'nombreCompleto'">
                      <a>d[a.atributo]</a>
                      <button tabindex="-1" (click)="clickBoton(i,j,a)" [ngStyle]="a | traerClaseMinima:columnas"
                        [ngClass]="a | comprobarEstilo"
                        [disabled]="!a.editable || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo])"
                        [style.cursor]="!a.editable || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo]) ? 'not-allowed':'pointer'"
                        [style.filter]="(!a.editable || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo]) ? 'grayscale(1)':'none') | safe"></button>
                    </ng-container>
                    <ng-container *ngIf="!a.subirBajar">
                      <button tabindex="-1" (click)="clickBoton(i,j,a)" [ngStyle]="a | traerClaseMinima:columnas"
                        [ngClass]="a | comprobarEstilo"
                        [disabled]="!a.editable || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo])"
                        [style.cursor]="!a.editable || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo]) ? 'not-allowed':'pointer'"
                        [style.filter]="(!a.editable || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo]) ? 'grayscale(1)':'none') | safe"
                        *ngIf="datos[i].id"></button>
                    </ng-container>
                  </span>
                  <!-- Input Area -->
                  <span *ngSwitchCase="7">
                    <textarea tabindex="-1"
                      [disabled]="!a?.editable || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo])"
                      spellcheck="false" [ngStyle]="{'cursor': a?.editable? 'auto':'not-allowed', 'overflow':'hidden'}"
                      class="TextArea " [maxlength]="a.maxLen" type="text" [(ngModel)]="d[a.atributo]"
                      (blur)="clickConcepto=false;cambia(i,j,a)" (mouseenter)="clickConcepto=true"
                      (mouseleave)="clickConcepto=false" wrap="off"
                      [ngStyle]="{'color': d.colorLetra ? d.colorLetra : null, 'text-align': a.alinear ? a.alinear:null}"
                      [ngClass]="{'abrirConcepto': i==a?.colZoom}"></textarea>
                  </span>
                  <!-- Input Area -->
                  <span *ngSwitchCase="8">
                    <input class="{{a.atributo}}-{{tablaEnum}}" tabindex="-1" type="color" [(ngModel)]="d[a.atributo]"
                      (ngModelChange)="clickColor(i,j,a)" id="colorPicker" name="favcolor" value="#30A5F4" size="2"
                      style="width: calc(100% - 2px);" ondrop="return false;"
                      [ngStyle]="{'color': d.colorLetra ? d.colorLetra : null, 'text-align': a.alinear ? a.alinear:null}">
                  </span>
                  <!-- Acordeon -->
                  <span *ngSwitchCase="9" id="{{'span-acordeon'+i+j}}">
                    <wo-label-field
                      [disabled]="a.soloLectura || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo])"
                      id="wo-label-field"
                      [editable]="opciones?.editable && (!dataDisabled || !dataDisabled[i] || !dataDisabled[i][a.atributo])"
                      [entidad]="datos.length > 0 ? datos[i][a.atributo] : null" [acordeon]="true"
                      [columnas]="a.columnas">
                    </wo-label-field>
                  </span>
                  <!-- Input Email -->
                  <span *ngSwitchCase="10">
                    <input id="{{a.header}}InputEmail" tabindex="-1" type="email" [(ngModel)]="d[a.atributo]"
                      [(maxlength)]="a.maxLen" class="{{a.atributo}}-{{tablaEnum}} a.clase"
                      (blur)="cambia(i,j,a,$event);" autocomplete="off"
                      [ngStyle]="{'color': d.colorLetra ? d.colorLetra : null, 'text-align': a.alinear ? a.alinear:null}"
                      [disabled]="!a.editable || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo])"
                      size="2" style="width: calc(100% - 2px); text-transform: lowercase;">
                  </span>

                  <!-- Input CUSTOM -->
                  <span *ngSwitchCase="11">
                    <div *ngIf="isFunction(a.formatter)" [innerHTML]="a.formatter(d)">
                    </div>
                    <div *ngIf="!isFunction(a.formatter)">
                      <input class="custom {{a.atributo}}-{{tablaEnum}}" [(maxlength)]="a.maxLen"
                        [disabled]="!a.editable || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo])"
                        type="text" name="name" [ngModel]="d | getPropertyValue:a.atributo" (blur)="cambia(i,j,a);"
                        autocomplete="off" tabindex="0" size="2" style="width: calc(100% - 2px);"
                        [ngStyle]="{'color': d.colorLetra ? d.colorLetra : null, 'text-align': a.alinear ? a.alinear:null}">
                    </div>
                  </span>
                  <!-- Input FILE -->
                  <span *ngSwitchCase="20">
                    <span *ngIf="d.id" (click)="cambia(i,j,a)"
                      [ngClass]="{'fas fa-paperclip': !d[a.atributo] && !a.iconoEspecifico && !a.iconoEspecificoFalse, 'fas fa-eye': d[a.atributo] && !a.iconoEspecifico && !a.listaIconos}"
                      class="{{d[a.atributo] && a.listaIconos && a.listaIconos[d[a.atributo]] ? a.listaIconos[d[a.atributo]].icono: d[a.atributo] && a.iconoEspecifico ? 'fas ' + a.iconoEspecifico: !d[a.atributo] && a.iconoEspecificoFalse ? 'fas '+a.iconoEspecificoFalse:''}}"
                      style="display: flex; justify-content: center; align-content: center; align-items: center;cursor: pointer; font-size: 20px; font-family: 'Font Awesome 5 Free';font-weight: 900;"
                      [title]="d[a.atributo] && a.listaIconos && a.listaIconos[d[a.atributo]] && a.listaIconos[d[a.atributo]].tooltip ? (d[a.atributo] | tooltipTitulo:a.listaIconos[d[a.atributo]]:d) : (d[a.atributo] | agregarTitulo:a.tipoInput:a.columnas:a.tipoNumeric:a?.tooltip)"
                      [ngStyle]="{'color': d.colorLetra ? d.colorLetra : null, 'text-align': a.alinear ? a.alinear:null}"></span>
                  </span>
                  <!-- Input EDITAR -->
                  <span *ngSwitchCase="21" class="inputConBoton"
                    [ngClass]="{'inputConBotonDisabled': !(d[a.atributo] && (d[a.atributo]?.contador || d[a.atributo]?.contador==0)) && (i | fieldSetDisable:j:datos:opciones.editable:dataDisabled:a.atributo),'inputConBotonCantidad': d[a.atributo] && (d[a.atributo]?.contador || d[a.atributo]?.contador==0) && !(i | fieldSetDisable:j:datos:opciones.editable:dataDisabled:a.atributo), 'inputConBotonDisabledCantidad': d[a.atributo] && (d[a.atributo]?.contador || d[a.atributo]?.contador==0) && (i | fieldSetDisable:j:datos:opciones.editable:dataDisabled:a.atributo)}">
                    <span *ngIf="a.tipoDato == 2 && d[a.atributo]"
                      class="tipoMoneda span-TipoMoneda-{{a.atributo}} {{a.tipoNumeric}}-{{a.atributo}} "
                      [ngClass]="{'tipoMonedaMM': opciones?.multimoneda}">
                      <div class="simboloMonedaSinMulti" *ngIf="a.moneda">
                        <p>{{a?.moneda?.simbolo}}</p>
                      </div>

                      <div class="simboloMonedaSinSinMulti" *ngIf="!a.moneda">
                        <p>{{simboloMoneda}}</p>
                      </div>
                      <div class="conMoneda">
                        <input id="textInputNumero-{{tablaEnum}}" style="width: calc(100% - 60px); height: 100%"
                          tabindex="-1" id="inputMoneda" autocomplete="off" type="text" name="name"
                          [ngModel]="formatValue(d[a.atributo].valor)" (ngModelChange)="d[a.atributo].valor = $event"
                          [(maxlength)]="a.maxLen" [title]="formatValue(d[a.atributo].valor)" size="2"
                          ondrop="return false;" disabled readonly
                          [ngStyle]="{'color': d[a.atributo].colorLetra ? d[a.atributo].colorLetra : null, 'text-align': a.alinear ? a.alinear:null}">
                      </div>

                      <div *ngIf="d?.monedasMap?.get(a?.atributo)" class="simboloMMoneda">
                        <p>{{d?.monedasMap?.get(a?.atributo)?.simbolo}}</p>
                      </div>
                    </span>
                    <span *ngIf="a.tipoDato != 2 || (a.tipoDato == 2 && !d[a.atributo])"
                      [title]="d[a.atributo]?.valor ? (d[a.atributo].valor | formatearNumero:a.atributo:a.moneda:a.tipoNumeric:d.monedasMap:opciones:mostrarMultimoneda:decimalesMoneda) : !d[a.atributo] ? 'Vacio' : d[a.atributo] ">
                      <span *ngIf="d[a.atributo]?.valor ? d[a.atributo].valor : d[a.atributo]">
                        <span
                          style="display: flex; align-content: center; align-items: center; width: calc(100% - 16px); line-height: 1; font-family: 'Roboto','sans-serif'"
                          *ngIf="a.maxLen; else noMaxLen">
                          {{(d[a.atributo]?.valor
                          ? (d[a.atributo].valor |
                          formatearNumero:a.atributo:a.moneda:a.tipoNumeric:d.monedasMap:opciones:mostrarMultimoneda:decimalesMoneda):d[a.atributo]).substring(0,
                          a.maxLen)}}
                        </span>
                        <ng-template #noMaxLen>
                          <span
                            style="display: flex; align-content: center; align-items: center; width: calc(100% - 16px); line-height: 1; font-family: 'Roboto','sans-serif'">
                            {{d[a.atributo]?.valor
                            ? (d[a.atributo].valor |
                            formatearNumero:a.atributo:a.moneda:a.tipoNumeric:d.monedasMap:opciones:mostrarMultimoneda:decimalesMoneda):d[a.atributo]}}
                          </span>
                        </ng-template>
                      </span>
                      <ng-template #noData>

                      </ng-template>
                    </span>

                    <span *ngIf="!(i | fieldSetDisable:j:datos:opciones.editable:dataDisabled:a.atributo)"
                      (click)="cambia(i,j,a)" class="{{a.iconoEspecifico ? 'fas '+a.iconoEspecifico:'fas fa-edit'}}"
                      style="justify-content: right; align-content: right; align-items: right;cursor: pointer; font-size: 15px; width: 15px; height: 15px;; font-family: 'Font Awesome 5 Free';font-weight: 900;"></span>
                    <span *ngIf="d[a.atributo] && (d[a.atributo]?.contador || d[a.atributo]?.contador==0)"
                      class="masCifra">{{(d[a.atributo]?.contador
                      || d[a.atributo]?.contador==0) ?
                      '('+d[a.atributo]?.contador+')':''}}</span>
                  </span>
                  <!-- Input COMPARAR VALORES -->
                  <span *ngSwitchCase="22" class="inputCompararValores">
                    <ng-container *ngIf="d[a.atributo] && d[a.atributo].valor && d[a.atributo].valor2">

                      <span class="tipoMoneda span-TipoMoneda-{{a.atributo}} {{a.tipoNumeric}}-{{a.atributo}} "
                        [ngClass]="{'tipoMonedaMM': opciones?.multimoneda}">

                        <div class="conMoneda valorComparado flex">
                          <span class="simboloMonedaSinMulti flex" *ngIf="a.moneda"
                            [ngStyle]="{'color': d[a.atributo].colorLetra ? d[a.atributo].colorLetra : null}">{{a?.moneda?.simbolo}}</span>
                          <span class="simboloMonedaSinSinMulti flex" *ngIf="!a.moneda"
                            [ngStyle]="{'color': d[a.atributo].colorLetra ? d[a.atributo].colorLetra : null}">{{simboloMoneda}}</span>
                          <input id="textInputNumero-{{tablaEnum}}" tabindex="-1" id="inputMoneda" autocomplete="off"
                            type="text" name="name"
                            [ngModel]="d[a.atributo].valor | formatearNumero:a.atributo:a.moneda:a.tipoNumeric:d.monedasMap:opciones:mostrarMultimoneda:decimalesMoneda"
                            (ngModelChange)="d[a.atributo].valor = $event" [(maxlength)]="a.maxLen" size="2"
                            ondrop="return false;" disabled readonly
                            [ngStyle]="{'color': d[a.atributo]?.colorLetra ? d[a.atributo]?.colorLetra : null, 'text-align': a.alinear ? a.alinear:'center'}">
                          <span *ngIf="d?.monedasMap?.get(a?.atributo)"
                            class="simboloMMoneda">{{d?.monedasMap?.get(a?.atributo)?.simbolo}}</span>
                        </div>
                      </span>

                      <span *ngIf="d.id" (click)="cambia(i,j,a)"
                        [ngClass]="{'fas fa-creative-commons-nc': !d[a.atributo] && !a.iconoEspecificoFalse, 'fas fa-eye': d[a.atributo] && !a.iconoEspecifico && !a.listaIconos}"
                        class="{{d[a.atributo] && a.listaIconos && a.listaIconos[d[a.atributo]] ? a.listaIconos[d[a.atributo]].icono: d[a.atributo] && a.iconoEspecifico ? 'fas ' + a.iconoEspecifico: !d[a.atributo] && a.iconoEspecificoFalse ? 'fas '+a.iconoEspecificoFalse:''}}"
                        style="display: flex; justify-content: center; align-content: center; align-items: center;cursor: pointer; font-size: 18px; font-family: 'Font Awesome 5 Free';font-weight: 900;"
                        [ngStyle]="{'color': d[a.atributo]?.colorIcono ? d[a.atributo]?.colorIcono : null, 'text-align': a.alinear ? a.alinear:null}"></span>

                      <span class="tipoMoneda span-TipoMoneda-{{a.atributo}} {{a.tipoNumeric}}-{{a.atributo}} "
                        [ngClass]="{'tipoMonedaMM': opciones?.multimoneda}">

                        <div class="conMoneda valorComparado valorComparado2 flex">
                          <span class="simboloMonedaSinMulti flex" *ngIf="a.moneda"
                            [ngStyle]="{'color': d[a.atributo]?.colorLetra2 ? d[a.atributo]?.colorLetra2 : null}">{{a?.moneda?.simbolo}}</span>
                          <span class="simboloMonedaSinSinMulti flex" *ngIf="!a.moneda"
                            [ngStyle]="{'color': d[a.atributo]?.colorLetra2 ? d[a.atributo]?.colorLetra2 : null}">{{simboloMoneda}}</span>
                          <input id="textInputNumero-{{tablaEnum}}" tabindex="-1" id="inputMoneda" autocomplete="off"
                            type="text" name="name"
                            [ngModel]="d[a.atributo].valor2 | formatearNumero:a.atributo:a.moneda:a.tipoNumeric:d.monedasMap:opciones:mostrarMultimoneda:decimalesMoneda"
                            (ngModelChange)="d[a.atributo].valor2 = $event" [(maxlength)]="a.maxLen" size="2"
                            ondrop="return false;" disabled readonly
                            [ngStyle]="{'color': d[a.atributo]?.colorLetra2 ? d[a.atributo]?.colorLetra2 : null, 'text-align': a.alinear ? a.alinear:'center'}">
                          <span *ngIf="d?.monedasMap?.get(a?.atributo)"
                            class="simboloMMoneda">{{d?.monedasMap?.get(a?.atributo)?.simbolo}}</span>
                        </div>
                      </span>
                    </ng-container>
                  </span>
                  <!-- Input CHECK BUTTON -->
                  <span *ngSwitchCase="23">
                    <div class="form-group form-group__input" style="margin: 0!important;">
                      <wo-text-field-seg id="senActivo" name="activo" [valorActual]="d[a.atributo]"
                        [tipoInput]="'checkbox'" [clase]="'switch '" [requerido]="a?.requerido"
                        [disabled]="!a.editable || (dataDisabled && dataDisabled[i] && dataDisabled[i][a.atributo])"
                        (respuesta)="d[a.atributo]=$event;cambia(i,j,a)">
                      </wo-text-field-seg>
                    </div>
                  </span>
                </span>
              </fieldset>
            </td>
          </ng-container>
          <span id="menu" class="td-Table-{{tablaEnum}}" (click)="!d.ocultarOpciones ? clickOpciones(i):{}">
            <td tabindex="0" (keyup.esc)="onKeyMenu($event,i,columna)"
              (keyup.arrowup)="onKeyArrowMenu($event,i,columna)" (keyup.arrowdown)="onKeyArrowMenu($event,i,columna)"
              (keyup.arrowleft)="onKeyArrowMenu($event,i,columna)" (keydown.shift.tab)="onKeyMenu($event,i,columna)"
              (keyup.tab)="onKeyMenu($event,i,columna)" (keyup.enter)="onKeyMenu($event,i,columna)"
              id="{{'F'+i+'Final'}}-{{tablaEnum}}" class="menuAResaltar">
              <span
                *ngIf="(opciones?.menu?.length > 0) && (i != undefined) && !(opciones?.desabilitado) && !d?.ocultarOpciones">
                <p *ngIf="d.id" id="indice${{i}}-{{tablaEnum}}" class="icoOpciones" (click)="abrirOpciones(i)">
                  <span></span>
                  <span></span>
                  <span></span>
                </p>
              </span>
            </td>
          </span>
        </ng-template>
      </tbody>

      <tfoot *ngIf="opciones?.foot">
        <tr>
          <th></th>
          <td id="tfoot-{{a.atributo}}-{{j}}" *ngFor="let a of columnas; let j=index; trackBy: trackByAtributo"
            [ngStyle]="a | traerClaseMinima:columnas" [ngClass]="a | comprobarEstilo">
            <fieldset [disabled]="!(opciones?.editable)">
              <span [ngSwitch]="a.tipoInput">
                <span *ngSwitchCase="0">
                  <input class="prueba1 {{a.atributo}}-{{tablaEnum}}" tabindex="-1" [disabled]="a.soloLectura"
                    type="text" autocomplete="off" name="name" [(ngModel)]="footer[a.atributo]">
                </span>
                <!-- Input numero -->
                <span *ngSwitchCase="1">
                  <span class="tipoMoneda" *ngIf="a.tipoNumeric=='moneda'">
                    <!-- <p>{{monedas[opciones?.moneda?.tag].simbolo}}</p> -->
                    <!-- <div *ngIf="a.moneda">
                      <p>{{a?.moneda?.simbolo}}</p>
                    </div>
                    <div *ngIf="!a.moneda">
                      <p>{{simboloMoneda}}</p>
                    </div> -->

                    <input class="prueba2 " tabindex="-1" [disabled]="'true'" type="text" name="name" autocomplete="off"
                      [ngModel]="footer[a.atributo] | formatearNumero:a.atributo:a.moneda:a.tipoNumeric:null:opciones:mostrarMultimoneda:decimalesMoneda"
                      (ngModelChange)="footer[a.atributo] = $event" (click)="clickMoneda(fila,j,a)">
                  </span>
                  <span class="tipoNumero" *ngIf="a.tipoNumeric!='moneda' && a.tipoNumeric!='porcentaje'">
                    <input class="{{a.atributo}}-{{tablaEnum}}" tabindex="-1" [disabled]="a.soloLectura" type="number"
                      autocomplete="off" name="name" [(ngModel)]="footer[a.atributo]">
                  </span>
                  <span class="tipoPorcentaje" *ngIf="a.tipoNumeric=='porcentaje';">
                    <input class="{{a.atributo}}-{{tablaEnum}}" tabindex="-1" [disabled]="a.soloLectura" type="number"
                      autocomplete="off" name="name" [ngModel]="footer[a.atributo] | porcentajePipe:a.decimales:0"
                      (ngModelChange)="footer[a.atributo]=$event/100">
                    <p>%</p>
                  </span>
                </span>
              </span>
            </fieldset>
          </td>
        </tr>
      </tfoot>
    </cdk-virtual-scroll-viewport>
  </table>
</div>

<!-- No eliminar este div divUbicaRenglonCerrado-->
<div id="divUbicaRenglonCerrado"></div>

<tr id="contenedorRenglonAbierto" style="height: 300px; display:none" class="renglonAbierto">
  <th class="thStick thHide"><span></span></th>
  <td colspan="8">
    <ng-container *ngIf="idRenglonPadre">
      <wo-tabla id="tablaHija" style="display: none;" [tablaEnum]="'WT_SUB_TABLA_HIJA'"
        (onClickTable)="accionTablaHija('onClickTable',$event)" [datos]="datosTablaHija" [columnas]="columnasTablaHija"
        [permisos]="permisosTablaHija" [opciones]="opcionesTablaHijas" [paginacion]="paginacionTablaHija"
        [assureDraft]="true" [tablaHija]="true" (updateDraft)="accionTablaHija('updateDraft',$event)"
        (onClickOpciones)="clickOpcionesTablaHija($event,idRenglonPadre)"
        (afterChange)="accionTablaHija('afterChange',$event)" [noScrollInfinito]="true">
      </wo-tabla>
    </ng-container>
  </td>
  <th class="thStick thHide"><span></span></th>
</tr>

<div *ngIf="mostrarPaginador" class="contPaginador" [ngStyle]="{'margin-bottom': tablaHija ? '60px':'20px'}">
  <paginacion [registroActual]="posicionActualScroll" [mostrarPaginador]="mostrarPaginador"
    [totalRegistros]="paginacion && paginacion.totalElementos ? paginacion.totalElementos:0"
    (inicializarDatosTabla)="inicializarDatosTabla()" (registroFinal)="onkeyEnd()" (registroInicial)="onkeyStart(false)"
    (siguienteRango)="siguienteRango()" (rangoAnterior)="rangoAnterior()" (irARegistro)=irARegistro($event)>
  </paginacion>
</div>

<div *ngIf="mostrarOpciones && opciones?.menu != 'sinOpciones'"
  [ngStyle]="{position:'absolute','left.px': coordenadasOpciones.x+70, 'top.px': coordenadasOpciones.y}">
  <app-opciones-wo-tabla [tablaEnums]="tablaEnum" [opcionesTabla]="opciones" [menus]="opciones?.menu"
    (menuSeleccionado)=opcionSeleccionada($event) (mostrarMensaje)=mostrarPopOpcionesTabla($event)>
  </app-opciones-wo-tabla>
</div>

<div *ngIf="mostrarOpcionesHeaderTabla"
  [ngStyle]="{position:'absolute','left.px': coordenadasOpcionesHeader.x+70, 'top.px': coordenadasOpcionesHeader.y}">
  <app-opciones-wo-tabla [tablaEnums]="tablaEnum" [menus]="opcionHeaderTabla"
    (menuSeleccionado)=opcionSeleccionadaHeader($event)></app-opciones-wo-tabla>
</div>
