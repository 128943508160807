<app-menu-contextual></app-menu-contextual>
<div [class]="color" (click)="cerrarMenuContextual()">
  <div tabindex="1" id="dialog" class="dialogOverlay">
    <div class="dialog">
      <div class="dialogTitle" (mouseover)="draggable = true">
        <span>{{(editar ? 'detallePedido.editarProducto':'detallePedido.agregarProducto') | translate}}
          <b class="nombreProducto">{{datosInventario.codigo + '-' + datosInventario.descripcion}}</b></span>
        <a (click)="cerrarPopup()" class="close" title="{{'popUpGenera.cerrar' | translate}}">
          <i class="fas fa-times"></i>
        </a>
      </div>
      <div class="dialogContent popUpDetalleProducto" (mouseover)="draggable = false"
        [ngClass]="{'permiteEdicion': !documentoEncabezado.contabilizado && !documentoEncabezado.anulado && !documentoEncabezado.bloqueado}">
        <div *ngIf="!loading" id="contenedorForm" class="form-inputs">
          <p *ngIf="documentoEncabezado.contabilizado || documentoEncabezado.anulado || documentoEncabezado.bloqueado"
            class="noEdicion">
            <i class="fas fa-exclamation-circle"></i>
            {{'ALERTAS.026' | translate}}
          </p>
          <fieldset
            [disabled]="documentoEncabezado.contabilizado || documentoEncabezado.anulado || documentoEncabezado.bloqueado">
            <div class="contGridFluid">
              <div class="colGrid2">
                <fieldset>
                  <div class="form-group fas fa-warehouse">
                    <select-pag-seg id="inventarioBodega" [valorActual]="datosInventario.inventarioBodega"
                      [columnas]="['nombre']" [completo]="completo" [lista]="dataBodega" (click)="obtenerBodegas()"
                      [requerido]="true" [label]="'detallePedido.bodega'" [pantalla]="'POS'" [gang]="'POS-movimientos'"
                      (respuesta)="datosInventario.inventarioBodega=$event; cambioEnProducto('bodega',datosInventario.inventarioBodega)">
                    </select-pag-seg>
                  </div>
                </fieldset>
              </div>

              <div class="colGrid2">
                <fieldset>
                  <div id="unidadMedida" class="form-group fas fa-cube">
                    <select-pag-seg [valorActual]="datosInventario.unidadMedida" [columnas]="['codigo','nombre']"
                      [completo]="completo" [columnasValorInput]="['codigo','nombre']" (click)="obtenerUnidades()"
                      [lista]="dataUnidad" [requerido]="true" [label]="'detallePedido.unidad'" [pantalla]="'POS'"
                      [gang]="'POS-movimientos'"
                      (respuesta)="datosInventario.unidadMedida=$event; cambioEnProducto('unidad',datosInventario.unidadMedida)">
                    </select-pag-seg>
                  </div>
                </fieldset>
              </div>

              <div id="cantidad" class="colGrid2">
                <fieldset>
                  <div tabindex="-1" class="form-group fas fa-hashtag">
                    <wo-text-field-seg id="cantidad" [label]="'detallePedido.cantidad'"
                      [valorActual]="datosInventario.cantidad" [completo]="completo" [tipoInput]="'number'"
                      [requerido]="true" [cantidadEnteros]="9" [cantidadDecimales]="8" [pantalla]="'POS'"
                      [gang]="'POS-movimientos'"
                      (respuesta)="datosInventario.cantidad=$event; cambioEnProducto('cantidad',datosInventario.cantidad)">
                    </wo-text-field-seg>
                  </div>
                </fieldset>
              </div>

              <div id="valorUnitario" class="colGrid2" (contextmenu)="onRightClick($event, datosInventario)">
                <fieldset>
                  <div tabindex="-1" class="form-group fas fa-dollar-sign">
                    <wo-text-field-seg id="valorUnitario"
                      [label]="('detallePedido.valorUnitario' | translate) + (datosInventario.unidadMedida.nombre ? datosInventario.unidadMedida.nombre : '')"
                      [valorActual]="datosInventario.valorUnitario" [completo]="completo" [requerido]="true"
                      [tipoInput]="'number'" [moneda]="moneda" [formatearInstante]="true" [cantidadEnteros]="11"
                      [cantidadDecimales]="2" [pantalla]="'POS'" [gang]="'POS-movimientos'"
                      (respuesta)="datosInventario.valorUnitario=$event; cambioEnProducto('unitario',datosInventario.valorUnitario)">
                    </wo-text-field-seg>
                  </div>
                </fieldset>
              </div>

              <div id="descuento" class="colGrid2">
                <fieldset>
                  <div tabindex="-1" class="form-group fas fa-donate">
                    <wo-text-field-seg id="porcentajeDescuento" [label]="'detallePedido.descuento'"
                      [valorActual]="datosInventario.porcentajeDescuento" [requerido]="true" [esPorcentaje]="true"
                      [tipoInput]="'number'" [cantidadEnteros]="2" [cantidadDecimales]="3" [pantalla]="'POS'"
                      [gang]="'POS-movimientos'"
                      (respuesta)="datosInventario.porcentajeDescuento=$event; cambioEnProducto('descuento',datosInventario.porcentajeDescuento)">
                    </wo-text-field-seg>
                  </div>
                </fieldset>
              </div>

              <div *ngIf="onlineOffline && documentoEncabezado.enLinea" id="obsequio" class="colGrid2">
                <fieldset>
                  <div tabindex="-1" class="form-group">
                    <wo-text-field-seg id="porcentajeDescuento" name="activo"
                      [valorActual]="datosInventario.senObsequio" [tipoInput]="'checkbox'" [pantalla]="'POS'"
                      [gang]="'POS-movimientos'" [clase]="'form-group switch fas fa-gift'"
                      [label]="'detallePedido.obsequio'"
                      (respuesta)="datosInventario.senObsequio=$event; obsequioProducto({'atributo':'senObsequio','valor':datosInventario.senObsequio})">
                    </wo-text-field-seg>
                  </div>
                </fieldset>
              </div>

              <div class="colGrid2">
                <fieldset>
                  <div class="form-group fas fa-kaaba">
                    <acordeon-select-seg id="centroCosto" [id]="'1'" [columnas]="['codigo','nombre']"
                      [arbol]="CentroDeCostos" [label]="'detallePedido.centroDeCostos'" [filtroLabel]="false"
                      [atributo]="'nombreCentro'" [valorActual]="datosInventario.centroCosto"
                      [atributoMostrar]="'nombre'" [minChars]="'1'" (click)="obtenerCentroDeCostos($event)"
                      [tipo]="'text'" [pantalla]="'POS'" [gang]="'POS-movimientos'"
                      (respuesta)="datosInventario.centroCosto=$event; cambioEnProducto('centro', datosInventario.centroCosto)">
                    </acordeon-select-seg>
                  </div>
                </fieldset>
              </div>

              <div class="{{onlineOffline && documentoEncabezado.enLinea ? 'colGrid2':'colGrid1'}}">
                <fieldset>
                  <div tabindex="-1" class="form-group fas fa-sticky-note">
                    <text-area-seg id="concepto" [label]="'detallePedido.nota'" [valorActual]="datosInventario.concepto"
                      [completo]="completo" maxlength="500" [pantalla]="'POS'" [gang]="'POS-movimientos'"
                      (respuesta)="datosInventario.concepto=$event; cambioEnProducto('nota',datosInventario.concepto)">
                    </text-area-seg>
                  </div>
                </fieldset>
              </div>
              <div *ngIf="documentoEncabezado.atributosRenglones" class="colGrid1 position">
                <div class="form-group">
                  <button appControlSeguridad id="opciones" class="buttonBlueS" type="button"
                    [disabled]="documentoEncabezado.contabilizando || !onlineOffline" (click)="abrirPersonalizados()"
                    [ngClass]="{'buttonDisabled' : documentoEncabezado.contabilizando || !onlineOffline}">
                    <i class="fa fa-braille" aria-hidden="true"></i>
                    <span>{{'tabla.camposPersonalizados' | translate}}</span>
                  </button>
                </div>
              </div>
            </div>
          </fieldset>

          <div class="colGrid-4 totalResumen resumenProducto">
            <table width="100%" border="0" cellspacing="0" cellpadding="0" class="contTheme contTotales">
              <thead>
                <tr class="parteSuperiorTabla">
                  <td></td>
                  <td></td>
                </tr>
              </thead>
              <tbody *ngIf="openTotal">
                <tr id="campo-subtotal">
                  <td>{{'puntosVenta.resumen.subtotal' | translate}}</td>
                  <td class="c" attr.data-valorCampo="{{datosInventario.valorTotalRenglon}}">
                    {{datosInventario.valorTotalRenglon | formatearNumero:'':null:'moneda'}}
                  </td>
                </tr>
                <tr id="campo-descuentos">
                  <td>{{'puntosVenta.resumen.descuentos' | translate}}</td>
                  <td class="informacion"
                    attr.data-valorCampo="{{datosInventario.valorUnitario * datosInventario.porcentajeDescuento}}">
                    {{datosInventario.cantidad * datosInventario.valorUnitario * datosInventario.porcentajeDescuento
                    |
                    formatearNumero:'':null:'moneda'}}
                  </td>
                </tr>
                <tr id="campo-impuestos" *ngIf="datosInventario.impuestoTotalRenglon">
                  <td>{{'puntosVenta.resumen.impuestos' | translate}}</td>
                  <td class="c" attr.data-valorCampo="{{datosInventario.impuestoTotalRenglon}}">
                    {{datosInventario.impuestoTotalRenglon | formatearNumero:'':null:'moneda'}}
                  </td>
                </tr>
                <input *ngIf="datosInventario.impuestoTotalRenglon" class="openImpuestos" type="checkbox">
                <blockquote *ngIf="datosInventario.impuestoTotalRenglon"></blockquote>
                <tr class="impuestosBody" *ngFor="let i of datosInventario.impuestos">
                  <td class="subtitTotal flex" title="{{i.tipo}}">
                    <p class="nombreImpuesto" [ngClass]="{'nombreImpuestoConPorcentaje':i.porcentaje}">{{i.tipo}}</p>
                    <span *ngIf="i.porcentaje">({{(i.porcentaje * 100)}}%)</span>
                  </td>
                  <td>{{i.valorTotal | formatearNumero:'':null:'moneda'}}</td>
                </tr>
              </tbody>
              <tfoot>
                <input class="openTotal" type="checkbox" [ngModel]="openTotal" [checked]="openTotal"
                  (click)="openTotal = !openTotal" (keydown.enter)="$event.preventDefault()"
                  title="{{'puntosVenta.resumen.detalleTotal' | translate}}">
                <i class="verMasTotales fas {{openTotal ? 'fa-angle-up':'fa-angle-down'}}"></i>
                <tr id="campo-netoPagar">
                  <td>{{'puntosVenta.resumen.total' | translate}}</td>
                  <td class="informacion" attr.data-valorCampo="{{datosInventario.precioRenglon}}">
                    {{datosInventario.precioRenglon | formatearNumero:'':null:'moneda'}}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

        </div>
        <div id="loading" *ngIf="loading" class="flex">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
      </div>
    </div>
  </div>
</div>
<app-pop-up-listado [color]="'themePOS'" [arregloDocumento]="arregloDocumento" [POS]="true"
  (valorUnitario)="ActualizarValorUnitario($event)"></app-pop-up-listado>


  <ng-container *ngIf="mostrarPopupSerial">
    <app-pop-up-seriales [tipoDocumento]="tipoDocumento" [estaContabilizado]="documentoEncabezado.contabilizado" (cerrarSeriales)="cerrarPopUpError()" [data]="dataPopError"></app-pop-up-seriales>
  </ng-container>

  <ng-container *ngIf="mostrarPopupTallaColor">
    <app-pop-up-tallacolor [tipoDocumento]="tipoDocumento" [estaContabilizado]="documentoEncabezado.contabilizado" (cerrarTallaColor)="cerrarPopUpError()" [data]="dataPopError"></app-pop-up-tallacolor>
  </ng-container>