import { Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CambiarContrasenaComponent } from '@wo/frontend/usuario/cambiar-contrasena/cambiar-contrasena.component';
import { PlataformaEnum, SeveridadEnum, Tab } from '@wo/modelo';
import { LogoutService, TabsService } from '@wo/servicios';
import { fromEvent, interval, merge, Observable, Subscription } from 'rxjs';
import { MenuComponent } from '@wo/frontend/menu/menu.component';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { SecurityService } from '@wo/frontend/login/security.service';
import { PRIVATE_ROUTING } from '@wo/frontend/private-routing.routing';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';
import { Router } from '@angular/router';
import { NotificacionesService } from '@wo/servicio/lib/notificaciones.service';
import { format, register } from 'timeago.js';
import * as FileSaver from 'file-saver';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';

declare const NotificationFx: any;

// @ts-ignore
register('es_ES', (number, index, total_sec) => [
  ['Justo ahora', 'Ahora mismo'],
  ['Hace %s segundos', 'En %s segundos'],
  ['Hace 1 minuto', 'En 1 minuto'],
  ['Hace %s minutos', 'En %s minutos'],
  ['Hace 1 hora', 'En 1 hora'],
  ['Hace %s horas', 'En %s horas'],
  ['Hace 1 dia', 'En 1 dia'],
  ['Hace %s dias', 'En %s dias'],
  ['Hace 1 semana', 'En 1 semana'],
  ['Hace %s semanas', 'En %s semanas'],
  ['1 mes', 'En 1 mes'],
  ['Hace %s meses', 'En %s meses'],
  ['Hace 1 año', 'En 1 año'],
  ['Hace %s años', 'En %s años']
][index]);

@Component({
  selector: 'app-wo-board',
  templateUrl: './wo-board.component.html',
  styleUrls: ['./wo-board.component.css']
})

export class WoBoardComponent implements OnInit, OnDestroy {
  currentUser = '';
  mostrar = true;
  wrapper = 'wrapper';
  langActual: any = sessionStorage.getItem('TRADUJO');
  senConfigurado = false;
  senActiveLicense = false;
  licenciaActual = '';
  nombreLicencia: string;
  modoOscuro: boolean;
  clickSincronizar: boolean;
  currentYear: number;
  preventBeforeUnload = false;

  @ViewChild('menu', { static: false })
  private menu: MenuComponent;

  @ViewChild(CambiarContrasenaComponent, { static: false })
  private cambiarContrasenaComponent: CambiarContrasenaComponent;

  aparecen = [
    {
      nombre: 'idiomas',
      muestra: false,
      esMenu: false
    },
    {
      nombre: 'info',
      muestra: false,
      esMenu: false
    },
    {
      nombre: 'compras',
      muestra: false,
      esMenu: true
    },
    {
      nombre: 'notificaciones',
      muestra: false,
      esMenu: true
    }
  ];

  private subscriptions = new Subscription();
  terminal: any;
  private get isLoggedIn() {
    return this.currentUser !== '';
  }
  id = sessionStorage.getItem("USUARIOSESION");
  mensajeCabecera = false;
  loadingNotificaciones: boolean = false;
  notificaciones = [];

  datoABuscar = new FormControl();
  listaRutasValidas: any = [];
  listaRutasMostrar: Observable<string[]>;
  opcionesMenu: any = [];

  rutasUrlValidas = [];
  POS: boolean;
  menuPOS = [
    { nombre: 'PuntoDeVenta', link: '/panel/punto-de-venta', senDisponible: true },
    { nombre: 'ListadoFacturas', link: '/panel/listado-facturas', senDisponible: true },
    { nombre: 'PuntosDeVenta', link: '/panel/configuracion/puntos-de-venta', senDisponible: true },
    { nombre: 'TerminalesPOS', link: '/panel/configuracion/puntos-de-venta/terminales-pos', senDisponible: true },
    { nombre: 'ConfiguracionGeneral', link: '/panel/configuracion/configuracion-general', senDisponible: true },
    { nombre: 'ListasPrecios', link: '/panel/configuracion/listas-precios', senDisponible: true },
    { nombre: 'ComprobanteInformeDiarioPOS', link: '/panel/informes/comprobante-informe-diario-pos', senDisponible: true },
    { nombre: 'InformeVentaTurnosPOS', link: '/panel/informes/venta-turno', senDisponible: true },
    { nombre: 'InformeVentaTerminalPOS', link: '/panel/informes/venta-terminal-pos', senDisponible: true },
    { nombre: 'InformeVentaProductoPOS', link: '/panel/informes/venta-producto-pos', senDisponible: true },
    { nombre: 'Turnos', link: '/panel/turnos/historial-turnos', senDisponible: true }
  ];

  terminalSel: any = {
    id: 0,
    nombre: 'Sin Seleccionar'
  };

  turnoActivo: any = {
    codigo: 0,
    nombre: 'Sin Turno'
  };

  public onlineOffline: boolean = navigator.onLine;
  pendienteSincronizacion: any;
  sincronizando: boolean;

  public onlineEvent: Observable<Event>;
  public offlineEvent: Observable<Event>;

  listadoAbierto: boolean = true;
  puntoVentaAbierto: boolean = true;
  subSincronizando: any;

  PRIVATE_ROUTING_POS = [
    { path: '', data: { nombre: 'DashBoard' } },
    { path: 'listado-facturas', data: { nombre: 'ListadoFacturas', url: '/listado-facturas' } },
    { path: 'punto-de-venta/:id', data: { nombre: 'PuntoDeVenta', url: '/punto-de-venta/' } },
    {
      path: 'configuracion',
      children: [
        { path: 'general', data: { nombre: 'ConfiguracionDetallePos', url: '/configuracion/general' } },
        {
          path: 'puntos-de-venta',
          children: [
            { path: '', data: { nombre: 'PuntosDeVenta', permisoGruesoRequerido: 'puntoDeVenta', url: '/configuracion/puntos-de-venta' } },
            { path: 'terminales-pos', data: { nombre: 'Terminales', permisoGruesoRequerido: 'terminalesPOS', url: '/configuracion/puntos-de-venta/terminales-pos' } },
            { path: 'terminales-pos/:id', data: { nombre: 'Terminales', permisoGruesoRequerido: 'terminalesPOS', url: '/configuracion/puntos-de-venta/terminales-pos' } }
          ]
        },
        { path: 'listas-precios', data: { nombre: 'ListaPrecioPos', url: '/configuracion/listas-precios' } },
      ]
    },
    {
      path: 'turnos',
      children: [
        { path: 'historial-turnos', data: { nombre: 'Turnos', url: '/turnos/historial-turnos' } }
      ]
    },
    {
      path: 'informes',
      children: [
        { path: 'venta-producto-pos', data: { nombre: 'InfoVentasProductoDetallado', url: '/informes/venta-producto-pos' } },
        { path: 'comprobante-informe-diario-pos', data: { nombre: 'InfoComprobanteDiario', url: '/informes/comprobante-informe-diario-pos' } },
        { path: 'venta-turno', data: { nombre: 'InfoVentasTurnos', url: '/informes/venta-turno' } },
        { path: 'venta-terminal-pos', data: { nombre: 'InfoVentasTerminal', url: '/informes/venta-terminal-pos' } }
      ]
    },
    { path: '**', redirectTo: '' }
  ];

  sonidoClick: any;

  terminalManejaTurno: boolean;

  popupImagen: boolean;
  imagenPopup = '';
  base64: boolean;
  timeago = timestamp => format(timestamp, 'es_ES');
  intervalNotificaciones: any;
  sonidoNotificacion = new Audio("assets/sonidosInterfaz/notification.mp3");
  listadoUrlImportacionesTipoDoc = {
    FC: ['/panel/compras/facturas-compra', 'FacturasDeCompra'],
    FV: ['/panel/ventas/facturas-venta', 'FacturasDeVenta'],
    CZ: ['/panel/ventas/cotizaciones', 'Cotizaciones'],
    REM: ['/panel/ventas/remisiones', 'Remisiones'],
    PD: ['/panel/ventas/pedidos', 'Pedidos'],
    EPT: ['/panel/productos-y-servicios/entrada-prod-terminado', 'EntradaDeProductoTerminado'],
    SA: ['/panel/productos-y-servicios/salida-almacen', 'SalidaDeAlmacen'],
    EA: ['/panel/productos-y-servicios/entrada-almacen', 'EntradaDeAlmacen'],
    SI: ['/panel/contabilidad/saldos-iniciales', 'SaldosIniciales'],
    TERCEROS: ['/panel/empresas-y-personas', 'Terceros'],
    INVENTARIOS: ['/panel/productos-y-servicios', 'Inventarios'],
    FICHAPERSONAL: ['/panel/empresas-y-personas', 'Terceros'],
    RC: ['/panel/contabilidad/recibos-caja', 'RecibosDeCaja'],
    NC: ['/panel/contabilidad/nota-contabilidad', 'NotasContabilidad'],
    CE: ['/panel/contabilidad/comprobante-egreso', 'ComprobantesDeEgreso'],
    LOTES: ['/panel/productos-y-servicios/configuracion/lotes', 'Lotes'],
    TALLASCOLORES: ['/panel/productos-y-servicios/configuracion/tallas-colores', 'TallaColor'],
  };

  detalleUrlImportacionesTipoDoc = {
    FC: ['/panel/compras/facturas-compra/detalle', 'FacturaCompraDetalle'],
    NDC: ['/panel/compras/notas-debito-proveedores/detalle', 'NotaDebitoProveedoresDetalle'],
    NCC: ['/panel/compras/notas-credito-proveedores/detalle', 'NotaCreditoProveedoresDetalle'],
    FV: ['/panel/ventas/facturas-venta/detalle', 'FacturaVentaDetalle'],
    NDV: ['/panel/ventas/notas-debito/detalle', 'NotaDebitoDetalle'],
    NCV: ['/panel/ventas/notas-credito/detalle', 'NotaCreditoDetalle'],
    SI: ['/panel/contabilidad/saldos-iniciales/detalle', 'SaldosInicialesDetalle'],
    RC: ['/panel/contabilidad/recibos-caja/detalle', 'ReciboCajaDetalle'],
    CE: ['/panel/contabilidad/comprobante-egreso/detalle', 'ComprobanteDeEgresoDetalles'],
    NC: ['/panel/contabilidad/nota-contabilidad/detalle', 'NotaContabilidadDetalle'],
    NOMC: ['/panel/contabilidad/nomina-contable/detalle', 'NominaContableDetalle'],
    CV: ['/panel/contabilidad/costo-ventas/detalle', 'CostoVentasDetalle'],
    CB: ['/panel/contabilidad/consignacion-bancaria/detalle', 'ConsignacionBancariaDetalle'],
    DP: ['/panel/contabilidad/depreciacion/detalle', 'DepreciacionesDetalle'],
    CCR: ['/panel/contabilidad/cancelacion-cuentas/detalle', 'DocumentoCancelacionDetalle']
  };

  notificacionMostrada: any;
  notificacionMantenimiento: any;

  constructor(
    private router: Router,
    private menucomponent: MenuComponent,
    private cerrarSesion: LogoutService,
    public translate: TranslateService,
    private integracionPOS: IntegracionPosService,
    private tabsService: TabsService,
    private securityService: SecurityService,
    @Inject('plataforma') plataforma: PlataformaEnum,
    private popUpService: PopUpService,
    private notificacionesServices: NotificacionesService,
    protected translateUtilService: TranslateUtilService
  ) {
    this.tabsService.loadPersistedTabs();
    this.translate.addLangs(['en', 'es-co']);
    this.translate.setDefaultLang('es-co');
    const browserLang = this.translate.getBrowserLang();
    const idioma = sessionStorage.getItem('TRADUJO');
    this.translate.use(browserLang.match(/en|es-co/) ? browserLang : idioma);
    this.POS = plataforma == PlataformaEnum.POS;
    if (this.POS) {
      this.sonidoClick = new Audio("assets/sonidosInterfaz/click.mp3");
      this.onlineEvent = fromEvent(window, 'online');
      this.offlineEvent = fromEvent(window, 'offline');

      const cambioTerminal = this.integracionPOS.cambioTerminal.subscribe((response) => {

        this.terminalSel = response;
        this.terminalManejaTurno = this.terminalSel.senManejaTurno;
      });
      this.subscriptions.add(cambioTerminal);

      const cambioTurno = this.integracionPOS.cambioTurno.subscribe((response) => {
        if (response && response.codigo) {
          this.turnoActivo = response;
          this.turnoActivo.nombre = `Turno ${this.turnoActivo.codigo}`;
        } else {
          this.turnoActivo = {
            codigo: 0,
            nombre: 'Sin Turno'
          };
        }
      });
      this.subscriptions.add(cambioTurno);

      const result = this.integracionPOS.resultadosPendientesSync.subscribe((data: any) => {
        console.log('data:', data)
        this.pendienteSincronizacion = data.encabezados || data.movimientos || data.pagos || data.terceros;
      });
      this.subscriptions.add(result);

      this.subSincronizando = this.integracionPOS.sincronizando.subscribe(() => {
        this.sincronizando = true;
      });
      this.subscriptions.add(this.subSincronizando);

      const subSincronizado = this.integracionPOS.sincronizado.subscribe((result) => {
        console.log('result:', result)
        this.sincronizando = false;
        if (result) {
          this.integracionPOS.pendientesSync.next('modificadoEnSync');
        } else if (this.clickSincronizar) {
          this.popUpService.open({ codigoError: 'errorSincronizacion', severidad: SeveridadEnum.ERROR });
        } else {
          this.pendienteSincronizacion = false;
        }
        this.clickSincronizar = false;
      });
      this.subscriptions.add(subSincronizado);

      merge(this.onlineEvent, this.offlineEvent).subscribe((res: any) => {
        const online = res && res.type === 'online';
        this.pendienteSincronizacion = true;
        this.sincronizando = false;
        this.onlineOffline = online;
        if (this.onlineOffline) {
          this.integracionPOS.pendientesSync.next();
        }
      });

      let cambioTab = this.tabsService.cambio$.subscribe(ruta => {
        this.validarHabilitarTerminal();
      });
      this.subscriptions.add(cambioTab);

      let cierreTab = this.tabsService.cierraBorradorTab$.subscribe(ruta => {
        setTimeout(() => {
          this.validarHabilitarTerminal();
        }, 500);
      });
      this.subscriptions.add(cierreTab);
    } else {
      this.notificacionesServices.suscriptorPubSub();
    }

    this.modoOscuro = !!sessionStorage.getItem('modoOscuro');
    this.cambiarModoOscuro();

    this.subscriptions.add(this.notificacionesServices.notificacion.subscribe(notificacion => {
      if (!this.notificaciones.some(e => e.alert == notificacion.alert && e.titulo == notificacion.titulo && e.texto == notificacion.texto && e.texto2 == notificacion.texto2 && e.fecha == notificacion.fecha)) {
        if (this.sonidoNotificacion) {
          this.sonidoNotificacion.pause();
          this.sonidoNotificacion.currentTime = 0;
          this.sonidoNotificacion.volume = 0.5;
          this.sonidoNotificacion.play();
        }
        const fechaActual = new Date();
        notificacion.mantenimiento = notificacion.titulo.toLowerCase().includes('mantenimiento') && new Date(notificacion.fecha) > fechaActual;
        notificacion.fechaAgo = this.timeago(notificacion.fecha);
        notificacion.irYoutube = (notificacion.texto2 && notificacion.texto2.toLowerCase().includes('youtube')) || (notificacion.accion && notificacion.accion.toLowerCase().includes('youtube'));
        notificacion.irLinkExterno = (notificacion.texto2 && notificacion.texto2.toLowerCase().includes('linkexterno'));
        notificacion.descargar = (notificacion.accion && notificacion.accion.toLowerCase().includes('descarga'));
        this.notificaciones.push(notificacion);
        sessionStorage.setItem('notificaciones', JSON.stringify(this.notificaciones));
        this.ordenarNotificaciones();

        if (!this.intervalNotificaciones && this.aparecen[3].muestra) {
          this.inicializarInterval();
        }

        const mostrarNotificacion = this.aparecen.find(menu => menu.nombre == 'notificaciones');
        if (!mostrarNotificacion.muestra) {
          if (notificacion.titulo.toLowerCase().includes('mantenimiento')) {
            if (this.notificacionMantenimiento) this.notificacionMantenimiento.dismiss();
            this.notificacionMantenimiento = new NotificationFx({
              message: `<span class="icon fas fa-bullhorn"></span>
                        <p>${notificacion.texto}</p>`,
              layout: 'bar',
              effect: 'slidetop',
              type: 'notice',
              ttl: null,
              onClose: function () { }
            });
            this.notificacionMantenimiento.show();
          } else {
            const textoTitulo = notificacion.titulo ? this.translateUtilService.getTranslateText(notificacion.titulo) : '';
            const textoTituloBoton = notificacion.tituloAccion ? this.translateUtilService.getTranslateText(notificacion.tituloAccion) : '';
            if (this.notificacionMostrada) this.notificacionMostrada.dismiss();
            const messageText = `<div class="flex">
            <span class="fechaNotificacion">${notificacion.fechaAgo}</span>
            <i class="icoAlerta fas ${notificacion.alert ? 'fas fa-exclamation-triangle' : 'fa-info'}"></i>
            </div>
            <p>
            <span>${notificacion.texto}</span>
            ${textoTituloBoton ? `<br>
            <button class="accionNotificacion ${notificacion.irYoutube ? 'youtube' : notificacion.irLinkExterno ? 'linkExterno' : ''}">
            <i class="${notificacion.irYoutube ? 'fab fa-youtube' : notificacion.irLinkExterno ? 'fas fa-link' : notificacion.descargar ? 'fas fa-save' : 'fas fa-angle-double-right'}"></i>
            ${textoTituloBoton}
            </button>` : ''}
            </p>`;

            const messageImg = `
            <span class="tituloImgVistaPreviaNoti">
              <i class="icoAlerta fas ${notificacion.alert ? 'fas fa-exclamation-triangle' : 'fa-info'}"></i>
              <span>${notificacion.titulo}</span>
            </span>
            <span class="fechaNotificacion">${notificacion.fechaAgo}</span>
            <div class="imgVistaPreviaNoti">
              <img class="imagenNotiVistaPrevia" src="${notificacion.base64 ? notificacion.imagen.includes('data:image/png;base64,') ? notificacion.imagen : 'data:image/png;base64,' + notificacion.imagen : notificacion.imagen}" alt="worldOffice"
              onerror="this.src='assets/images/noImagen.png';">
            </div>`
            this.notificacionMostrada = new NotificationFx({
              message: notificacion.imagen ? messageImg : messageText,
              layout: 'attached',
              effect: 'bouncyflip',
              type: 'notice',
              onClose: function () {
              }
            });

            this.notificacionMostrada.show();

            if (notificacion.tituloAccion) {
              const selector = notificacion.imagen ? '.imagenNotiVistaPrevia' : '.accionNotificacion';
              setTimeout(() => {
                const button = document.querySelector(selector);
                button.addEventListener("click", () => notificacion.imagen ? this.extenderNotificacionPopup(notificacion) : this.clickAccionNotificacion(notificacion));
                setTimeout(() => {
                  button.removeEventListener("click", () => notificacion.imagen ? this.extenderNotificacionPopup(notificacion) : this.clickAccionNotificacion(notificacion));
                }, 13000);
              }, 1000);
            }
          }
        }
      }
    }));
  }

  validarHabilitarTerminal() {
    const tabsAbiertas = this.tabsService.openedTabs;
    this.listadoAbierto = tabsAbiertas.some(tab => tab.url == '/panel/listado-facturas');
    this.puntoVentaAbierto = tabsAbiertas.some(tab => tab.url == '/panel/punto-de-venta');
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler($event) {
    this.preventBeforeUnload = this.cerrarSesion.preventBeforeUnload;
    if (this.isLoggedIn && !this.preventBeforeUnload) {
      $event.returnValue = 'Are you sure?';
    }
  }

  cerrarMenus() {
    this.aparecen.forEach(element => {
      element.muestra = false;
    });
  }

  entornoClick() {
    this.aparecen.forEach(element => {
      if (element.esMenu === false) {
        element.muestra = false;
      }
    });
  }

  entornoClickCerrado() {
    this.menucomponent.cerrarMenus();
    this.aparecen.forEach(element => {
      element.muestra = false;
    });

    if (this.intervalNotificaciones) {
      this.intervalNotificaciones.unsubscribe();
      this.intervalNotificaciones = null;
    }
  }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    this.validarHabilitarTerminal();
    this.terminal = this.integracionPOS.getTerminal();
    this.turnoActivo = this.integracionPOS.getTurno();
    this.preventBeforeUnload = this.cerrarSesion.preventBeforeUnload;
    if (this.terminal) {
      this.terminalSel = {
        id: this.terminal.id,
        nombre: this.terminal.nombre
      };
      this.terminalManejaTurno = this.terminal.senManejaTurno;
    }
    this.senConfigurado = JSON.parse(sessionStorage.getItem('CONFIGURADO'));
    this.senActiveLicense = JSON.parse(sessionStorage.getItem('ACTIVELICENSE'));
    this.currentUser = sessionStorage.getItem('USER');
    this.licenciaActual = sessionStorage.getItem('LICENCE');
    this.licenciaActual = this.licenciaActual.replace(/[""]+/g, '')
    this.mostrar = sessionStorage.getItem('mostrarMenu') === 'true';
    if (this.mostrar) {
      this.wrapper = 'wrapper';
    } else {
      this.wrapper = 'wrapperClose';
    }
    if (!this.isLoggedIn) {
      this.logout();
    }
    this.saveLogSessionStorage();

    const RUTAS = this.POS ? this.PRIVATE_ROUTING_POS : PRIVATE_ROUTING;
    this.validarRutasValidas(RUTAS);

    setTimeout(() => {
      this.opcionesMenu = JSON.parse(sessionStorage.getItem('ALL_MENUS_URL_ONLY'));
      this.opcionesMenu = this.opcionesMenu.filter(opcion => {
        if (opcion.senDisponible) opcion.nombreMostrar = this.translate.instant(`PANEL.${opcion.nombre}`);
        const rutaValida = this.rutasUrlValidas.find(opt => `/panel${opt.data.url}` == opcion.link);
        let routeUrl = opcion.link;
        if (!this.POS && rutaValida && rutaValida.canActivate) {
          const routeName = rutaValida.data.nombre;
          const routePermisoGrueso = rutaValida.data.permisoGruesoRequerido;
          const { hasLicence, hasPermit } = this.securityService.hasPermission(routeName, routeUrl, routePermisoGrueso);
          return hasLicence && hasPermit;
        }
        return rutaValida;
      });
      this.opcionesMenu = this.opcionesMenu.sort((a, b) => {
        return a.nombreMostrar > b.nombreMostrar ? 1 : a.nombreMostrar < b.nombreMostrar ? -1 : 0;
      })
      this.inicializarAutocomplete();
    }, 3000);

    this.senConfigurado = JSON.parse(sessionStorage.getItem('CONFIGURADO'));
    this.senActiveLicense = JSON.parse(sessionStorage.getItem('ACTIVELICENSE'));

    let notificaciones: any = sessionStorage.getItem('notificaciones');
    this.notificaciones = notificaciones ? JSON.parse(notificaciones) : [];

    if (this.notificaciones && this.notificaciones.length > 0) {
      this.notificaciones = this.notificaciones.map(noti => {
        noti.fechaAgo = this.timeago(noti.fecha);
        return noti;
      });
      this.ordenarNotificaciones();
    }

    this.datoABuscar.valueChanges.subscribe(ruta => {

      if (ruta) {
        if (!this.POS || this.onlineOffline) {
          if (ruta && ruta.link && ruta.nombre) {
            if (this.senConfigurado || this.senActiveLicense) {
              if (this.senConfigurado) {
                const asistenteInicialOpen = this.tabsService.openedTabs.find(tab => tab.url == '/panel/administracion-licencia/asistente-inicial');
                if (!asistenteInicialOpen) {
                  this.tabsService.openTab(
                    new Tab(
                      '/panel/administracion-licencia/asistente-inicial',
                      'ConfiguracionInicial',
                      []
                    )
                  );
                }
                this.popUpService.open({ codigoError: 'debeCompletarConfInicial', severidad: SeveridadEnum.ERROR });
              }
              if (this.senActiveLicense) {
                this.tabsService.openTab(
                  new Tab(
                    '/panel/administracion-licencia/detalle-licencia',
                    'AdministradorLicencia',
                    []
                  )
                );
                this.popUpService.open({ codigoError: 'debeCompletarRenovarSuPago', severidad: SeveridadEnum.ERROR });
              }
            } else {
              this.tabsService.openTab(new Tab(ruta.link, ruta.nombre));
            }

            setTimeout(() => {
              this.datoABuscar.setValue('');
              document.getElementById('inputBuscar').blur();
              this.inicializarAutocomplete();
            }, 500);
          }
        } else {
          this.popUpService.open({ codigoError: 'necesitaConexionInternet', severidad: SeveridadEnum.ERROR });
          setTimeout(() => {
            this.datoABuscar.setValue('');
            document.getElementById('inputBuscar').blur();
            this.inicializarAutocomplete();
          }, 500);
        }

      }
    })
    if (this.POS) setTimeout(() => this.integracionPOS.pendientesSync.next(), 3000);
  }

  validarRutasValidas(rutas) {
    rutas.forEach((ruta) => {
      if (!ruta.data && ruta.children) {
        this.validarRutasValidas(ruta.children);
      } else if (ruta.data && ruta.data.url && !ruta.path.includes(':')) {
        this.rutasUrlValidas.push(ruta);
      }
    });
  }

  inicializarAutocomplete() {
    const menusTenant = JSON.parse(sessionStorage.getItem('PERMISOSUSUARIO'));

    const excepciones = sessionStorage.getItem('EXCEPCIONUSUARIO');
    this.listaRutasValidas = this.opcionesMenu.filter(opcion => {
      return opcion.link != '/panel/' && opcion.nombreMostrar && !opcion.nombreMostrar.includes('PANEL') && opcion.senDisponible && menusTenant.some(x => x == opcion.nombre);
    });
    this.listaRutasMostrar = this.datoABuscar.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  displayFn(option): string | undefined {
    return option ? option.nombreMostrar : undefined;
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.listaRutasValidas.filter(ruta => this._normalizeValue(ruta.nombreMostrar).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  ngOnDestroy(): void {
    if (this.intervalNotificaciones) {
      this.intervalNotificaciones.unsubscribe();
      this.intervalNotificaciones = null;
    }
    if (this.notificacionesServices) this.notificacionesServices.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  // @param
  logout() {
    document.getElementById('logout').classList.add("loadingIndex");
    this.currentUser = '';
    if (this.POS && !this.onlineOffline) {
      this.integracionPOS.cerrarSesionOffline.next();
      this.router.navigate(['/login']);
    } else {
      this.cerrarSesion.cerrarSesion();
    }
  }

  mostrarAcordeon() {
    this.aparecen = this.aparecen.map(element => {
      element.muestra = false;
      return element;
    });
    if (this.mostrar) {
      sessionStorage.setItem('mostrarMenu', 'false');
      this.wrapper = 'wrapperClose';
    } else {
      sessionStorage.setItem('mostrarMenu', 'true');
      this.wrapper = 'wrapper';
    }
    this.mostrar = sessionStorage.getItem('mostrarMenu') === 'true';
  }

  mostrarIdiomasUsuario() {
    this.menu.mostrarChecked = false;
    this.aparecen.forEach(element => {
      if (element.nombre === 'idiomas') {
        if (element.muestra) {
          element.muestra = false;
        } else {
          element.muestra = true;
        }
      } else {
        element.muestra = false;
      }
    });
  }

  mostrarInformacionUsuario() {
    this.menu.mostrarChecked = false;
    this.aparecen.forEach(element => {
      if (element.nombre === 'info') {
        if (element.muestra) {
          element.muestra = false;
        } else {
          element.muestra = true;
        }
      } else {
        element.muestra = false;
      }
    });
    this.nombreLicencia = sessionStorage.getItem('LICENCENOMBRE');
    this.nombreLicencia = this.nombreLicencia.replace(/[""]+/g, '')
  }

  mostrarMenuCompra() {
    this.menu.mostrarChecked = false;
    this.aparecen.forEach(element => {
      if (element.nombre === 'compras') {
        if (element.muestra) {
          element.muestra = false;
        } else {
          element.muestra = true;
        }
      } else {
        element.muestra = false;
      }
    });
  }

  cerrarAlSeleccionar(lang: any) {
    this.aparecen[0].muestra = false;
    sessionStorage.setItem('TRADUJO', this.langActual);
  }

  volverAlHome() {
    this.senConfigurado = JSON.parse(sessionStorage.getItem('CONFIGURADO'));
    this.senActiveLicense = JSON.parse(sessionStorage.getItem('ACTIVELICENSE'));
    if (this.senConfigurado || this.senActiveLicense) {

    } else {
      this.tabsService.selectTab(this.tabsService.openedTabs[0]);
    }

  }

  cambiarPass() {
    this.cambiarContrasenaComponent.abrirPopup();
  }

  abrirConfiguracionLicencia() {
    this.tabsService.openTab(
      new Tab(
        '/panel/administracion-licencia/detalle-licencia',
        'AdministradorLicencia',
        []
      )
    );
  }

  saveLogSessionStorage() {
    localStorage.setItem('_sessionStorage', JSON.stringify(sessionStorage));
  }

  mostrarcentroservicios() { }

  cambiarTerminal() {
    if (this.POS) {
      if (this.sonidoClick) {
        this.sonidoClick.pause();
        this.sonidoClick.currentTime = 0;
        this.sonidoClick.volume = 0.05;
        this.sonidoClick.play();
      }
      this.integracionPOS.terminalSel.next(true);
    }
  }

  verTurnoActual() {
    if (this.POS) {
      if (this.sonidoClick) {
        this.sonidoClick.pause();
        this.sonidoClick.currentTime = 0;
        this.sonidoClick.volume = 0.05;
        this.sonidoClick.play();
      }
      this.integracionPOS.turnoActual.next();
    }
  }

  cambiarModoOscuro() {
    this.modoOscuro ? $('body').addClass('modoOscuro') : $('body').removeClass('modoOscuro');
    this.modoOscuro ? $('.pane').addClass('modoOscuro') : $('.pane').removeClass('modoOscuro');
    this.modoOscuro ? sessionStorage.setItem('modoOscuro', 'true') : sessionStorage.removeItem('modoOscuro');
  }

  sincronizarPOS() {
    console.log('this.sincronizando:', this.sincronizando)
    console.log('this.pendienteSincronizacion:', this.pendienteSincronizacion)
    if (!this.sincronizando) {
      if (this.pendienteSincronizacion) {
        this.sincronizando = this.onlineOffline;
        if (this.onlineOffline) this.integracionPOS.sincronizar.next();
      } else {
        setTimeout(() => {
          this.sincronizando = false;
        }, 3000);
      }
    }
  }

  inicializarInterval() {
    this.notificaciones = this.notificaciones.map(noti => {
      noti.fechaAgo = this.timeago(noti.fecha);
      return noti;
    });
    const intervalNoti = interval(30000);
    this.intervalNotificaciones = intervalNoti.subscribe(() => {
      if (this.notificaciones && this.notificaciones.length > 0) {
        const fechaActual = new Date();
        this.notificaciones = this.notificaciones.map(noti => {
          noti.mantenimiento = noti.mantenimiento && new Date(noti.fecha) > fechaActual;
          noti.fechaAgo = this.timeago(noti.fecha);
          return noti;
        });
        this.ordenarNotificaciones();
      } else {
        this.intervalNotificaciones.unsubscribe();
        this.intervalNotificaciones = null;
      }
    });
  }

  mostrarNotificaciones() {
    if (this.notificacionMostrada) this.notificacionMostrada.dismiss();
    this.menu.mostrarChecked = false;
    this.aparecen.forEach(element => {
      element.muestra = element.nombre === 'notificaciones' ? !element.muestra : false;
    });

    if (this.aparecen[3].muestra) {
      if (!this.intervalNotificaciones) {
        this.inicializarInterval();
      }
    } else {
      if (this.intervalNotificaciones) {
        this.intervalNotificaciones.unsubscribe();
        this.intervalNotificaciones = null;
      }
    }
  }

  ordenarNotificaciones() {
    this.notificaciones = this.notificaciones.sort((a, b) => {
      return new Date(a.fecha) > new Date(b.fecha) ? -1 : new Date(a.fecha) < new Date(b.fecha) ? 1 : 0;
    });
  }

  extenderNotificacionPopup(card, i?) {
    this.aparecen.forEach(element => {
      element.muestra = false;
    });
    if (card.imagen) {
      this.popupImagen = true;
      this.imagenPopup = card.imagen;
      this.base64 = card.base64;
    } else {
      if (card.accion) {
        const resp = this.popUpService.respuesta.subscribe(respuesta => {
          resp.unsubscribe();
          if (respuesta.event) {
            this.clickAccionNotificacion(card);
          }
        });
      }

      this.popUpService.open({ codigoError: '', severidad: card.alert ? SeveridadEnum.WARNING : SeveridadEnum.INFO, mensajes: true, messageInHTML: true, detalle: card.texto, conBoton: !!card.tituloAccion, accion: card.tituloAccion });
    }
  }

  clickAccionNotificacion(card) {
    if (card.tituloAccion) {
      if (card.irYoutube || card.irLinkExterno) {
        window.open(card.accion, '_blank');
      } else if (['notificaciones.ver', 'notificaciones.verDocumento'].includes(card.tituloAccion.toLowerCase()) || ['ALERTAS.titulo_extracto_pdf'].includes(card.titulo)) {
        let tabNueva: Tab;
        switch (card.accion) {
          case "irBloqueoDesbloqueo":
            tabNueva = new Tab('/panel/contabilidad/configuracion/documentos/bloqueo-documentos', `BloqueoGeneralDocumentos`);
            break;
          case "irContabilizaciones":
            tabNueva = new Tab('/panel/contabilidad/configuracion/cuentas-contables/contabilizaciones', `Contabilizaciones`);
            break;
          case "irInventarios":
            tabNueva = new Tab('/panel/productos-y-servicios', `Inventarios`);
            break;
          default:
            switch (card.titulo) {
              case "ALERTAS.titulo_importacion":
                tabNueva = new Tab(this.listadoUrlImportacionesTipoDoc[card.accion][0], this.listadoUrlImportacionesTipoDoc[card.accion][1]);
                break;
              case "ALERTAS.titulo_contabilizacionDocumento":
                const dataAccion = card.accion ? card.accion.split(',') : null;
                if (dataAccion && dataAccion.length > 1) {
                  tabNueva = new Tab(this.detalleUrlImportacionesTipoDoc[dataAccion[0]][0], this.detalleUrlImportacionesTipoDoc[dataAccion[0]][1], [dataAccion[1]], { crea: false });
                }
                break;
              case "ALERTAS.titulo_contabilizacionDocumento":
                tabNueva = new Tab('/panel/nomina/nomina-empleados/detalle', 'NominaEmpleadosDetalle', [card.accion], { crea: false })
                break;
              case "ALERTAS.titulo_generacionNomina":
                tabNueva = new Tab('/panel/nomina/nomina-empleados/detalle', 'NominaEmpleadosDetalle', [card.accion], { crea: false })
                break;
              case "ALERTAS.titulo_extracto_pdf":
                tabNueva = new Tab('/panel/Banco/conciliacion-bancaria', 'NuevaConciliacion', [card.accion], { crea: false })
                break;
              default:
                break;
            }
            break;
        }
        this.tabsService.openTab(tabNueva);
        this.entornoClickCerrado();
      } else if (['notificaciones.descargar', 'notificaciones.descargarErrores'].includes(card.tituloAccion)) {
        let contentType;
        switch (card.accion) {
          case "xlsx":
            contentType = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`;
            break;
          case "pdf":
            contentType = `application/pdf`;
            break;
          case "zip":
            contentType = `application/zip`;
            break;
          default:
            break;
        }
        const fileBlob = this.convertirB64toBlob(card.file, contentType);
        const nombreArchivo = card.tituloAccion == 'notificaciones.descargarErrores' ? this.translateUtilService.getTranslateText('notificaciones.archivoErrores') + ' ' + card.texto2 : card.texto2;
        FileSaver.saveAs(fileBlob, `${nombreArchivo}.${card.accion}`);
      }
    }
  }

  convertirB64toBlob(dataBase64, contentType) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataBase64);

    const byteNumbers = new Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteNumbers[i] = byteString.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });
    return blob;
  }

  cerrarNotificacion(eliminarTodas, card?, i?) {
    if (eliminarTodas) {
      // Quedan sólo las notificaciones de mantenimiento futuras
      const fechaActual = new Date();
      this.notificaciones = this.notificaciones.filter(e => e.mantenimiento && new Date(e.fecha) > fechaActual);
      this.aparecen.forEach(element => {
        element.muestra = false;
      });
      sessionStorage.setItem('notificaciones', JSON.stringify(this.notificaciones));
    } else {
      this.notificaciones.splice(i, 1);
      sessionStorage.setItem('notificaciones', JSON.stringify(this.notificaciones));
    }
  }
}
