import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColaService, ConfiguracionGeneralService, FormatoUtilService, PosService } from "@wo/servicios";
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { Subscription } from 'rxjs';
import { PaginacionWo, SeveridadEnum, TipoReporte } from '@wo/modelo';
import { WhatsAppService } from '@wo/servicio/lib/whatsapp.service';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormatearNumeroPipe } from '../pipes/formatear-numero.pipe';

@Component({
  selector: 'app-opciones-finales',
  templateUrl: './opciones-finales.component.html',
  styleUrls: ['./opciones-finales.component.css']
})
export class OpcionesFinalesComponent implements OnInit {
  @Input('color') color;
  @Input('documentoEncabezado') documentoEncabezado;
  @Input('docContabilizado') docContabilizado: boolean;
  @Input('mostrarTextoFinal') mostrarTextoFinal: any = {};
  @Input('pagoRapidoExitoso') pagoRapidoExitoso: boolean;
  @Input('loadingContabilizar') loadingContabilizar: boolean;
  @Input('onlineOffline') onlineOffline: boolean;
  @Input('sincronizado') sincronizado: boolean;
  @Input('bloquearOpciones') bloquearOpciones: boolean;
  @Input('netoPagarMostrar') netoPagarMostrar;
  @Input('permisosPOS') permisosPOS: any = {};
  @Output() cerrar = new EventEmitter<any>();
  @Output() abrirSelPlantillas = new EventEmitter<any>();

  public subscriptions = new Subscription();
  draggable = true;
  newInnerWidth = 0;
  isDragging = false;
  positionA = { x: 0, y: 0 };
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };
  sonidoClick: any = new Audio("assets/sonidosInterfaz/click.mp3");

  envioWhatsappActivo: boolean;
  mostrarOpcionesEnvio: boolean;
  opcionEnvioSel = '1';
  valorEnviar;
  valorEnviarNuevo;
  completo: boolean;
  whatsapp: boolean;
  claseValorNuevo = 'form-group fas fa-at';
  labelValorNuevo = 'puntosVenta.envio.nuevoCorreo';
  pdfCargado: any;
  enviandoPDF: boolean;

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Colombia, CountryISO.UnitedStates, CountryISO.UnitedKingdom, CountryISO.Ecuador, CountryISO.Venezuela, CountryISO.Mexico];
  valorNumeroNuevo: any;
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required])
  });
  listaDirecciones: any = [];
  listaValoresRegistrados: any = [];
  colombia: boolean;
  subSelPlantilla: any;

  constructor(
    protected serviceColas: ColaService,
    public formatoUtilService: FormatoUtilService,
    public popUpService: PopUpService,
    private whatsappService: WhatsAppService,
    private posService: PosService,
    private configuracionGeneralService: ConfiguracionGeneralService
  ) {
  }

  ngOnInit() {
    if (!this.permisosPOS) this.permisosPOS = {};
    this.draggable = sessionStorage.getItem('userAgent') != 'mobile';
    this.phoneForm.get("phone").valueChanges.subscribe(x => {
      if (x && x.dialCode) {
        this.colombia = x.dialCode === '+57';
        if (this.colombia && x.number.length > 11) this.phoneForm.controls.phone.setValue('');
      }
    });
  }

  abrirOpciones(option, disabled?) {
    if (!disabled) {
      this.valorEnviarNuevo = null;
      this.phoneForm.controls.phone.setValue('');
      if (option == 'whatsapp') {
        if (this.envioWhatsappActivo) {
          this.continuarEnvio(option);
        } else {
          this.whatsappService.estadoSesion().subscribe(resp => {
            this.envioWhatsappActivo = resp && resp.state == 'CONNECTED';
            if (this.envioWhatsappActivo) {
              this.continuarEnvio(option);
            } else {
              this.popUpService.open({ codigoError: 'noSesionWhatsApp', severidad: SeveridadEnum.ERROR });
            }
          }, () => {
            this.popUpService.open({ codigoError: 'servicioWhatsappNoDisponible', severidad: SeveridadEnum.ERROR });
          });
        }
      } else {
        this.continuarEnvio(option);
      }
    }
  }

  validarEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      this.valorEnviarNuevo = mail;
    } else {
      this.popUpService.open({ codigoError: 'emailNoValido', severidad: SeveridadEnum.ERROR });
      setTimeout(() => {
        this.valorEnviarNuevo = null;
      }, 0);
    }
  }

  continuarEnvio(option) {
    
    this.valorEnviar = option == 'whatsapp' ? this.documentoEncabezado.direccionTerceroExterno.telefonoPrincipal : this.documentoEncabezado.direccionTerceroExterno.emailPrincipal;
    this.whatsapp = option == 'whatsapp';
    this.claseValorNuevo = option == 'whatsapp' ? 'form-group  fab fa-whatsapp' : 'form-group  fas fa-at';
    this.labelValorNuevo = option == 'whatsapp' ? 'puntosVenta.envio.nuevoNumero' : 'puntosVenta.envio.nuevoCorreo';
    this.mostrarOpcionesEnvio = true;

    this.listaValoresRegistrados = [this.valorEnviar];
    const paginacionDirecciones = new PaginacionWo('id', 0, 0, 'ASC');
    this.posService[this.whatsapp ? 'getTelefonosTercero' : 'getCorreosTercero'](paginacionDirecciones, this.documentoEncabezado.terceroExterno.id).subscribe(dataEm => {
      this.listaDirecciones = dataEm.content;
      this.listaDirecciones.forEach(e => {
        if (this.whatsapp) {
          if (e.terceroTipoTelefono && e.terceroTipoTelefono.nombre == 'Móvil' && (this.listaValoresRegistrados.length == 0 || !this.listaValoresRegistrados.some(d => d == e.numero))) this.listaValoresRegistrados.push(e.numero);
        } else {
          if (this.listaValoresRegistrados.length == 0 || !this.listaValoresRegistrados.some(d => d == e.email)) this.listaValoresRegistrados.push(e.email);
        }
      });
    });
  }

  iniciarEnvio() {
    this.subSelPlantilla = this.whatsappService.plantillaSeleccionada.subscribe(e => {
      this.subSelPlantilla.unsubscribe();
      this.realizarEnvio(e);
    });

    let sub = this.configuracionGeneralService.getConfiguracionImprimir('POS', this.documentoEncabezado.id).subscribe(response => {
      if (response.senMostrar && response.descripcion !== '450') {
        let clasificacion = TipoReporte[TipoReporte['POS']];
        const dataInicializarImprimir = { clasificacion, accion: 'Imprimir', selectedTipo: null };
        this.abrirSelPlantillas.emit(dataInicializarImprimir);
      } else if (response.descripcion !== '450') {
        this.realizarEnvio(response.valorCombo ? response.valorCombo : 'FV_EST_POS');
      }
    }, () => {
      this.realizarEnvio('FV_EST_POS');
    });
    this.subscriptions.add(sub);
  }

  realizarEnvio(plantillaSel) {
    this.enviandoPDF = true;
    if (this.whatsapp) {
      if (!this.pdfCargado) {
        this.posService.getDocumentoImpreso(this.documentoEncabezado.encabezadoId, plantillaSel ? plantillaSel : 'FV_EST_POS').subscribe(
          res => {
            this.pdfCargado = res.pdf;
            let valorNumero = `57${this.valorEnviar}`;
            if (this.opcionEnvioSel == '2') {
              valorNumero = this.phoneForm.controls.phone.value.e164Number;
              valorNumero = valorNumero.replace('+', '');
            }
            this.envioWhatsApp(valorNumero, res.pdf);
          }, () => {
            this.enviandoPDF = false;
            this.popUpService.open({ codigoError: 'envioWhatsappError', severidad: SeveridadEnum.ERROR });
          });
      } else {
        let valorNumero = `57${this.valorEnviar}`;
        if (this.opcionEnvioSel == '2') {
          valorNumero = this.phoneForm.controls.phone.value.e164Number;
          valorNumero = valorNumero.replace('+', '');
        }
        this.envioWhatsApp(valorNumero, this.pdfCargado);
      }
    } else {
      this.envioCorreo(this.opcionEnvioSel != '2' ? this.valorEnviar : this.valorEnviarNuevo, plantillaSel);
    }
  }

  opcion(option, disabled?) {
    if (option && !disabled) {
      if (this.sonidoClick) {
        this.sonidoClick.pause();
        this.sonidoClick.currentTime = 0;
        this.sonidoClick.volume = 0.05;
        this.sonidoClick.play();
      }
      this.cerrar.emit(option);
    }
  }

  cerrarPopup() {
    if (this.subSelPlantilla) this.subSelPlantilla.unsubscribe();
    this.cerrar.emit(false);
  }

  envioWhatsApp(numeroEnviar, pdfBase64) {
    const prefijoNumero = this.documentoEncabezado.prefijo ? (this.documentoEncabezado.prefijo.nombre + this.documentoEncabezado.numero) : this.documentoEncabezado.numero;
    const datosEnvio = {
      namePdf: `Documento Equivalente POS ${prefijoNumero}`,
      phoneNumber: numeroEnviar,
      pdfBase64
    };
    this.whatsappService.enviarFactura(datosEnvio).subscribe(resp => {
      this.enviandoPDF = false;
      this.opcionEnvioSel = '1';
      if (resp && resp.message == 'Message sent') {
        this.mostrarOpcionesEnvio = false;
        this.popUpService.open({ codigoError: 'envioWhatsappOk', severidad: SeveridadEnum.SUCCESS });
      } else {
        this.popUpService.open({ codigoError: 'envioWhatsappError', severidad: SeveridadEnum.ERROR });
      }
    }, () => {
      this.enviandoPDF = false;
      this.opcionEnvioSel = '1';
      this.popUpService.open({ codigoError: 'envioWhatsappError', severidad: SeveridadEnum.ERROR });
    });
  }

  envioCorreo(correoEnviar, plantillaSel) {
    let monedaLocal = JSON.parse(sessionStorage.getItem('MONEDALOCAL'));
    let mensaje = {
      id: this.documentoEncabezado.encabezadoId,
      accion: 'enviarDocumentoEmail',
      filtro: {
        opcionesAdicionales: [
          {
            valor: correoEnviar,
            valor2: this.formatoUtilService.formatoMoneda(this.netoPagarMostrar, monedaLocal)
          }
        ]
      },
      codigo: plantillaSel ? plantillaSel : 'FV_EST_POS'
    };
    this.posService.getReporte(mensaje).subscribe(
      response => {
        this.enviandoPDF = false;
        this.opcionEnvioSel = '1';
        if (response[0] && response[0].codigoError) {
          this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });
        } else {
          this.mostrarOpcionesEnvio = false;
          this.popUpService.open({ codigoError: 'envioCorreoOk', severidad: SeveridadEnum.SUCCESS });
        }
      }, () => {
        this.enviandoPDF = false;
        this.opcionEnvioSel = '1';
        this.popUpService.open({ codigoError: 'envioCorreoError', severidad: SeveridadEnum.ERROR });
      }
    );
  }

}
