/* RUTAS PARA LAS VISTAS DESPUES DEL LOGUEO.

Los componentes de las rutas hijas serán cargados por Lazy Load
para evitar la carga innecesaria de archivos al inicio de la aplicación.

*/

import { Routes } from '@angular/router';
import { ConfiguradoGuard } from './configuracion-inicial/configurado.guard';
import { ActiveLicenseGuard } from './licencia/administrar-licencia/activeLicense.guard';


export const PRIVATE_ROUTING: Routes = [
    { path: '', loadChildren: 'apps/wo-frontend/src/app/panel/panel.module#PanelModule', data: { nombre: 'DashBoard' }, canActivate: [ActiveLicenseGuard] },
    {
        path: 'compras',
        children: [
            {
                path: 'facturas-compra',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-factura-compra/listado-factura-compra.module#ListadoFacturaCompraModule', data: { nombre: 'FacturasDeCompra', url: '/compras/facturas-compra' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/factura-compra/factura-compra.module#FacturaCompraModule', data: { nombre: 'FacturaCompraDetalle', permisoGruesoRequerido: 'FacturasDeCompra', url: '/compras/facturas-compra/detalle' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'importar-documento/:tipodoc', loadChildren: '@wo/frontend/documentos/detalle/documento-encabezado/importar-documento/importar-documentos.module#ImportarDocumentosModule', data: { nombre: 'ImportarDocumentosFacturaCompra', url: '/compras/facturas-compra/importar-documento' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'orden-compra',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-orden-compra/listado-orden-compra.module#ListadoOrdenCompraModule', data: { nombre: 'OrdenCompra', url: '/compras/orden-compra' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/orden-compra/orden-compra.module#OrdenCompraModule', data: { nombre: 'OrdenCompraDetalle', permisoGruesoRequerido: 'OrdenCompra', url: '/compras/orden-compra/detalle' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'remision-compra',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-remision-compra/listado-remision-compra.module#ListadoRemisionCompraModule', data: { nombre: 'RemisionCompra', url: '/compras/remision-compra' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/remision-compra/remision-compra.module#RemisionCompraModule', data: { nombre: 'RemisionCompraDetalle', permisoGruesoRequerido: 'RemisionCompra', url: '/compras/remision-compra/detalle' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'devolucion-remision-compra',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-devolucion-remision-compra/listado-devolucion-remision-compra.module#ListadoDevolucionRemisionCompraModule', data: { nombre: 'DevolucionRemisionCompra', url: '/compras/devolucion-remision-compra' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/devolucion-remision-compra/devolucion-remision-compra.module#DevolucionRemisionCompraModule', data: { nombre: 'DevolucionRemisionCompraDetalle', permisoGruesoRequerido: 'DevolucionRemisionCompra', url: '/compras/devolucion-remision-compra/detalle' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'notas-debito-proveedores',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-nota-debito-proveedores/listado-nota-debito-proveedores.module#ListadoNotaDebitoProveedoresModule', data: { nombre: 'NotasDebitoProveedores', url: '/compras/notas-debito-proveedores' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/nota-debito-proveedores/nota-debito-proveedores.module#NotaDebitoProveedoresModule', data: { nombre: 'NotaDebitoProveedoresDetalle', permisoGruesoRequerido: 'NotasDebitoProveedores', url: '/compras/notas-debito-proveedores/detalle' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'notas-credito-proveedores',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-nota-credito-proveedores/listado-nota-credito-proveedores.module#ListadoNotaCreditoProveedoresModule', data: { nombre: 'NotasCreditoProveedores', url: '/compras/notas-credito-proveedores' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/nota-credito-proveedores/nota-credito-proveedores.module#NotaCreditoProveedoresModule', data: { nombre: 'NotaCreditoProveedoresDetalle', permisoGruesoRequerido: 'NotasCreditoProveedores', url: '/compras/notas-credito-proveedores/detalle' }, canActivate: [ActiveLicenseGuard] }
                ]
            }
        ]
    },
    {
        path: 'ventas',
        children: [
            {
                path: 'facturas-venta',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-factura-venta/listado-factura-venta.module#ListadoFacturaVentaModule', data: { nombre: 'FacturasDeVenta', url: '/ventas/facturas-venta' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/factura-venta/factura-venta.module#FacturaVentaModule', data: { nombre: 'FacturaVentaDetalle', permisoGruesoRequerido: 'FacturasDeVenta', url: '/ventas/facturas-venta/detalle' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'importar-documento/:tipodoc', loadChildren: '@wo/frontend/documentos/detalle/documento-encabezado/importar-documento/importar-documentos.module#ImportarDocumentosModule', data: { nombre: 'ImportarDocumentosFV', url: '/ventas/facturas-venta/importar-documento', reuseRoute: true }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'notas-debito',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-nota-debito/listado-nota-debito.module#ListadoNotaDebitoModule', data: { nombre: 'NotasDebitoClientes', url: '/ventas/notas-debito' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/nota-debito/nota-debito.module#NotaDebitoModule', data: { nombre: 'NotaDebitoDetalle', permisoGruesoRequerido: 'NotasDebitoClientes', url: '/ventas/notas-debito/detalle' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'notas-credito',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-nota-credito/listado-nota-credito.module#ListadoNotaCreditoModule', data: { nombre: 'NotasCreditoClientes', url: '/ventas/notas-credito' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/nota-credito/nota-credito.module#NotaCreditoModule', data: { nombre: 'NotaCreditoDetalle', permisoGruesoRequerido: 'NotasCreditoClientes', url: '/ventas/notas-credito/detalle' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'devolucion-remisiones',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-devolucion-remision/listado-devolucion-remision.module#ListadoDevolucionRemisionModule', data: { nombre: 'DevolucionRemisiones', url: '/ventas/devolucion-remisiones' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/devolucion-remision-ventas/devolucion-remision.module#DevolucionRemisionModule', data: { nombre: 'DevolucionRemisionDetalle', permisoGruesoRequerido: 'DevolucionRemisiones', url: '/ventas/devolucion-remisiones/detalle' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'remisiones',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-remision/listado-remision.module#ListadoRemisionModule', data: { nombre: 'Remisiones', url: '/ventas/remisiones' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/remision-ventas/remision.module#RemisionModule', data: { nombre: 'RemisionDetalle', permisoGruesoRequerido: 'Remisiones', url: '/ventas/remisiones/detalle' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'importar-documento/:tipodoc', loadChildren: '@wo/frontend/documentos/detalle/documento-encabezado/importar-documento/importar-documentos.module#ImportarDocumentosModule', data: { nombre: 'ImportarDocumentosRemisionesVentas', url: '/ventas/remisiones/importar-documento', reuseRoute: true }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'cotizaciones',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-cotizacion/listado-cotizacion.module#ListadoCotizacionModule', data: { nombre: 'Cotizaciones', url: '/ventas/cotizaciones' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/cotizacion/cotizacion.module#CotizacionModule', data: { nombre: 'CotizacionDetalle', permisoGruesoRequerido: 'Cotizaciones', url: '/ventas/cotizaciones/detalle' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'importar-documento/:tipodoc', loadChildren: '@wo/frontend/documentos/detalle/documento-encabezado/importar-documento/importar-documentos.module#ImportarDocumentosModule', data: { nombre: 'ImportarDocumentosCotizaciones', url: '/ventas/cotizaciones/importar-documento', reuseRoute: true }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'pedidos',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-pedido/listado-pedido.module#ListadoPedidoModule', data: { nombre: 'Pedidos', url: '/ventas/pedidos' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/pedido/pedido.module#PedidoModule', data: { nombre: 'PedidoDetalle', permisoGruesoRequerido: 'Pedidos', url: '/ventas/pedidos/detalle' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'importar-documento/:tipodoc', loadChildren: '@wo/frontend/documentos/detalle/documento-encabezado/importar-documento/importar-documentos.module#ImportarDocumentosModule', data: { nombre: 'ImportarDocumentosPedidos', url: '/ventas/pedidos/importar-documento', reuseRoute: true }, canActivate: [ActiveLicenseGuard] }
                ]
            }
        ]
    },
    {
        path: 'contabilidad',
        children: [
            {
                path: 'depreciacion',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-depreciacion/listado-depreciacion.module#ListadoDepreciacionModule', data: { nombre: 'Depreciacion', url: '/contabilidad/depreciacion' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'generador-depreciacion', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-depreciacion/generador-depreciaciones/generador-depreciaciones.module#GeneradorDepreciacionesModule', data: { nombre: 'GeneradorDepreciaciones', permisoGruesoRequerido: 'Depreciacion', url: '/contabilidad/depreciacion/generador-depreciacion' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/depreciacion/depreciacion.module#DepreciacionModule', data: { nombre: 'DepreciacionesDetalle', permisoGruesoRequerido: 'Depreciacion', url: '/contabilidad/depreciacion/detalle' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'costo-ventas',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-costo-ventas/listado-costo-ventas.module#ListadoCostoVentasModule', data: { nombre: 'CostoVentas', url: '/contabilidad/costo-ventas' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/costo-ventas/costo-ventas.module#CostoVentasModule', data: { nombre: 'CostoVentasDetalle', permisoGruesoRequerido: 'CostoVentas', url: '/contabilidad/costo-ventas/detalle' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'saldos-iniciales',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-saldos-iniciales/listado-saldos-iniciales.module#ListadoSaldosInicialesModule', data: { nombre: 'SaldosIniciales', url: '/contabilidad/saldos-iniciales' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/saldos-iniciales/saldos-iniciales.module#SaldosInicialesModule', data: { nombre: 'SaldosInicialesDetalle', permisoGruesoRequerido: 'SaldosIniciales', url: '/contabilidad/saldos-iniciales/detalle' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'consignacion-bancaria',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-consignacion-bancaria/listado-consignacion-bancaria.module#ListadoConsignacionBancariaModule', data: { nombre: 'ConsignacionBancaria', url: '/contabilidad/consignacion-bancaria' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/consignacion-bancaria/consignacion-bancaria.module#ConsignacionBancariaModule', data: { nombre: 'ConsignacionBancariaDetalle', permisoGruesoRequerido: 'ConsignacionBancaria', url: '/contabilidad/consignacion-bancaria/detalle' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'conciliacion-bancaria',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/conciliacion-bancaria/conciliacion-bancaria-criterios.module#ConciliacionBancariaCriteriosModule', data: { nombre: 'ConciliacionBancaria', url: '/contabilidad/conciliacion-bancaria' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'recibos-caja',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-recibo-caja/listado-recibo-caja.module#ListadoReciboCajaModule', data: { nombre: 'RecibosDeCaja', url: '/contabilidad/recibos-caja' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/recibo-caja/recibo-caja.module#ReciboCajaModule', data: { nombre: 'ReciboCajaDetalle', permisoGruesoRequerido: 'RecibosDeCaja', url: '/contabilidad/recibos-caja/detalle' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'importar-documento/:tipodoc', loadChildren: '@wo/frontend/documentos/detalle/documento-encabezado/importar-documento-contable/importar-documento-contable.module#ImportarDocumentoContableModule',  data: { nombre: 'ImportarDocumentosRecibosCaja', permisoGruesoRequerido: 'RecibosDeCaja', url: '/contabilidad/recibos-caja/importar-documento' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'comprobante-egreso',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-comprobante-egreso/listado-comprobante-egreso.module#ListadoComprobanteEgresoModule', data: { nombre: 'ComprobantesDeEgreso', url: '/contabilidad/comprobante-egreso' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/comprobante-egreso/comprobante-egreso.module#ComprobanteEgresoModule', data: { nombre: 'ComprobanteDeEgresoDetalles', permisoGruesoRequerido: 'ComprobantesDeEgreso', url: '/contabilidad/comprobante-egreso/detalle' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'importar-documento/:tipodoc', loadChildren: '@wo/frontend/documentos/detalle/documento-encabezado/importar-documento-contable/importar-documento-contable.module#ImportarDocumentoContableModule',  data: { nombre: 'ImportarDocumentosComprobastesEgreso', permisoGruesoRequerido: 'ComprobantesDeEgreso', url: '/contabilidad/comprobante-egreso/importar-documento' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'nota-contabilidad',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-nota-contabilidad/listado-nota-contabilidad.module#ListadoNotaContabilidadModule', data: { nombre: 'NotasContabilidad', url: '/contabilidad/nota-contabilidad' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/nota-contabilidad/nota-contabilidad.module#NotaContabilidadModule', data: { nombre: 'NotaContabilidadDetalle', permisoGruesoRequerido: 'NotasContabilidad', url: '/contabilidad/nota-contabilidad/detalle' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'importar-documento/:tipodoc', loadChildren: '@wo/frontend/documentos/detalle/documento-encabezado/importar-documento-contable/importar-documento-contable.module#ImportarDocumentoContableModule',  data: { nombre: 'ImportarDocumentosNotasContabilidad', permisoGruesoRequerido: 'NotasContabilidad', url: '/contabilidad/nota-contabilidad/importar-documento' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'cancelacion-cuentas',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-documento-cancelacion/listado-documento-cancelacion.module#ListadoDocumentoCancelacionModule', data: { nombre: 'CancelaciondeCuentas', url: '/contabilidad/cancelacion-cuentas' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/documento-cancelacion/documento-cancelacion.module#DocumentoCancelacionModule', data: { nombre: 'DocumentoCancelacionDetalle', permisoGruesoRequerido: 'Cancelación de Cuentas', url: '/contabilidad/cancelacion-cuentas/detalle' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'configuracion-impuestos',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/impuestos/configuracion-impuestos/configuracion-impuestos.module#ConfiguracionImpuestosModule', data: { nombre: 'ConfiguracionImpuestosContabilidad', url: '/contabilidad/configuracion-impuestos' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'configuracion-numeracion',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/contabilidad-configuracion-numeracion/contabilidad-configuracion-numeracion.module#ContabilidadConfiguracionNumeracionModule', data: { nombre: 'ContabilidadConfiguracionNumeracion', url: '/contabilidad/configuracion-numeracion' }, canActivate: [ActiveLicenseGuard], }
                ]
            },
            {
                path: 'nomina-contable',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-nomina-contable/listado-nomina-contable.module#ListadoNominaContableModule', data: { nombre: 'NominaContable', url: '/contabilidad/nomina-contable' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/nomina-contable/nomina-contable.module#NominaContableModule', data: { nombre: 'NominaContableDetalle', permisoGruesoRequerido: 'NominaContable', url: '/contabilidad/nomina-contable/detalle' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'configuracion',
                children: [
                    {
                        path: 'cuentas-contables',
                        children: [
                            { path: '', loadChildren: 'apps/wo-frontend/src/app/cuenta-contable/listado-cuenta-contable/listado-cuenta-contable.module#ListadoCuentaContableModule', data: { nombre: 'ListadoCuentaContable', url: '/contabilidad/configuracion/cuentas-contables' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/cuenta-contable/cuenta-contable-detalle/cuenta-contable-detalle.module#CuentaContableDetalleModule', data: { nombre: 'CuentaContableDetalle', permisoGruesoRequerido: 'CuentasContables', url: '/contabilidad/configuracion/cuentas-contables/detalle' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'tipos', loadChildren: 'apps/wo-frontend/src/app/cuenta-contable/tipo-cuenta-contable/tipo-cuenta-contable.module#TipoCuentaContableModule', data: { nombre: 'TiposCuentaContable', url: '/contabilidad/configuracion/cuentas-contables/tipos' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'grupos', loadChildren: 'apps/wo-frontend/src/app/cuenta-contable/cuenta-contable-grupo/cuenta-contable-grupo.module#CuentaContableGrupoModule', data: { nombre: 'GruposCuentaContable', url: '/contabilidad/configuracion/cuentas-contables/grupos' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'conceptos', loadChildren: 'apps/wo-frontend/src/app/cuenta-contable/concepto-base-retencion/concepto-base-retencion.module#ConceptoBaseRetencionModule', data: { nombre: 'ConceptoCuentaContable', url: '/contabilidad/configuracion/cuentas-contables/conceptos' }, canActivate: [ActiveLicenseGuard] },
                            {
                                path: 'contabilizaciones',
                                children: [
                                    { path: '', loadChildren: 'apps/wo-frontend/src/app/contabilizaciones/listado-contabilizacion/listado-contabilizacion.module#ListadoContabilizacionModule', data: { nombre: 'Contabilizaciones', url: '/contabilidad/configuracion/cuentas-contables/contabilizaciones' }, canActivate: [ActiveLicenseGuard] },
                                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/contabilizaciones/administrador-contabilizaciones/administrador-contabilizaciones.module#AdministradorContabilizacionesModule', data: { nombre: 'ContabilizacionDetalle', permisoGruesoRequerido: 'Contabilizaciones', url: '/contabilidad/configuracion/cuentas-contables/contabilizaciones/detalle' }, canActivate: [ActiveLicenseGuard] }
                                ]
                            },
                        ]
                    },
                    {
                        path: 'documentos',
                        children: [
                            { path: 'prefijos', loadChildren: 'apps/wo-frontend/src/app/documento/prefijo/prefijo.module#PrefijoModule', data: { nombre: 'Prefijos', url: '/contabilidad/configuracion/documentos/prefijos' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'formas-pago', loadChildren: 'apps/wo-frontend/src/app/formaPago/forma-pago/forma-pago.module#FormaPagoModule', data: { nombre: 'FormasPago', url: '/contabilidad/configuracion/documentos/formas-pago' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'franquicia-formas-pago', loadChildren: 'apps/wo-frontend/src/app/formaPago/forma-pago-franquicia/forma-pago-franquicia.module#FormaPagoFranquiciaModule', data: { nombre: 'FranquiciaFormasPago', url: '/contabilidad/configuracion/documentos/franquicia-formas-pago' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'tipos-formas-pago', loadChildren: 'apps/wo-frontend/src/app/formaPago/forma-pago-tipo/forma-pago-tipo.module#FormaPagoTipoModule', data: { nombre: 'FormaPagoTipo', url: '/contabilidad/configuracion/documentos/tipos-formas-pago' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'clasificacion', loadChildren: 'apps/wo-frontend/src/app/documento/clasificaciones-documento/clasificaciones-documento.module#ClasificacionesDocumentoModule', data: { nombre: 'ClasificacionDocumentos', url: '/contabilidad/configuracion/documentos/clasificacion' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'centro-costos', loadChildren: 'apps/wo-frontend/src/app/centro-costos/centro-costos.module#CentroCostosModule', data: { nombre: 'CentrosDeCostos', url: '/contabilidad/configuracion/documentos/centro-costos' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'documento-resoluciones', loadChildren: 'apps/wo-frontend/src/app/documento-resoluciones/documento-resoluciones.module#DocumentoResolucionesModule', data: { nombre: 'DocumentoResoluciones', url: '/contabilidad/configuracion/documentos/documento-resoluciones' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'bloqueo-documentos', loadChildren: 'apps/wo-frontend/src/app/bloqueo-documentos/bloqueo-general-documentos/bloqueo-general-documentos.module#BloqueoGeneralDocumentosModule', data: { nombre: 'BloqueoGeneralDocumentos', permisoGruesoRequerido: 'bloqueoDocumentos', url: '/contabilidad/configuracion/documentos/bloqueo-documentos' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'bloqueo-documentos-detalle', loadChildren: 'apps/wo-frontend/src/app/bloqueo-documentos/bloqueo-detallado-documentos/bloqueo-detallado-documentos.module#BloqueoDetalladoDocumentosModule', data: { nombre: 'BloqueoDetalladoDocumentos', permisoGruesoRequerido: 'BloqueoDocumentos', url: '/contabilidad/configuracion/documentos/bloqueo-documentos-detalle' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'campos-personalizado', loadChildren: 'apps/wo-frontend/src/app/campos-personalizados/campos-personalizados.module#CamposPersonalizadosModule', data: { nombre: 'CamposPersonalizadoContabilidad', url: '/contabilidad/configuracion/documentos/campos-personalizado' }, canActivate: [ActiveLicenseGuard] },
                            {
                                path: 'monedas',
                                children: [
                                    { path: '', loadChildren: 'apps/wo-frontend/src/app/general/moneda/moneda/moneda.module#MonedaModule', data: { nombre: 'Monedas', url: '/contabilidad/configuracion/documentos/monedas' }, canActivate: [ActiveLicenseGuard] },
                                    { path: 'trm', loadChildren: 'apps/wo-frontend/src/app/general/moneda/asociar-moneda-trm/asociar-moneda-trm.module#AsociarMonedaTrmModule', data: { nombre: 'AsociarMonedaTRM', url: '/contabilidad/configuracion/documentos/monedas/trm' }, canActivate: [ActiveLicenseGuard] }
                                ]
                            }
                        ]
                    }
                ]
            },
            { path: 'configuracion-contabilizacion', loadChildren: 'apps/wo-frontend/src/app/configuracion-contabilizacion/configuracion-contabilizacion.module#ConfiguracionContabilizacionModule', data: { nombre: 'ConfiguracionContabilizacion', url: '/contabilidad/configuracion-contabilizacion' }, canActivate: [ActiveLicenseGuard] }
        ]
    },
    {
        path: 'Banco',
        children: [
            { path: 'gestion-bancos', loadChildren: 'apps/wo-frontend/src/app/bancos/gestion-banco/gestion-banco.module#GestionBancoModule', data: { nombre: 'GestionBancos', permisoGruesoRequerido: 'GestionBancos', url: '/gestion-bancos' }, canActivate: [ActiveLicenseGuard] },
            { path: 'gestion-bancos/cuenta/:id', loadChildren: 'apps/wo-frontend/src/app/bancos/gestion-banco/gestion-banco.module#GestionBancoModule', data: { nombre: 'GestionBancos', permisoGruesoRequerido: 'GestionBancos', url: '/gestion-bancos/cuenta' }, canActivate: [ActiveLicenseGuard] },
            { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/bancos/detalle-banco/detalle-banco.module#DetalleBancoModule', data: { nombre: 'DetalleBancoS', url: 'Banco/detalle', permisoGruesoRequerido: 'GestionBancos' }, canActivate: [ActiveLicenseGuard] },
            { path: 'conciliacion-bancaria/:id', loadChildren: 'apps/wo-frontend/src/app/bancos/conciliacion/conciliacion.module#ConciliacionModule', data: { nombre: 'NuevaConciliacionS', url: 'Banco/conciliacion-bancaria', permisoGruesoRequerido: 'GestionBancos' }, canActivate: [ActiveLicenseGuard] },
            { path: 'historial-conciliacion/:id', loadChildren: 'apps/wo-frontend/src/app/bancos/historial-conciliacion/historial-conciliacion.module#HistorialConciliacionModule', data: { nombre: 'HistorialConciliacionS', permisoGruesoRequerido: 'GestionBancos', url: 'Banco/historial-conciliacion' }, canActivate: [ActiveLicenseGuard] },
            { path: 'configuracion-ajustes-automaticos', loadChildren: 'apps/wo-frontend/src/app/bancos/ajustes-automaticos/ajustes-automaticos.module#AjustesAutomaticosModule', data: { nombre: 'ConfiguracionAjustesAutomaticos', permisoGruesoRequerido: 'ConfiguracionAjustesAutomaticos', url: '/configuracion-ajustes-automaticos' }, canActivate: [ActiveLicenseGuard] },
        ]
    },
    {
        path: 'empresas-y-personas',
        children: [
            { path: '', loadChildren: 'apps/wo-frontend/src/app/tercero/listado-tercero/listado-tercero.module#ListadoTerceroModule', data: { nombre: 'Terceros', url: '/empresas-y-personas' }, canActivate: [ActiveLicenseGuard] },
            { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/tercero/tercero-detalle/tercero-detalle.module#TerceroDetalleModule', data: { nombre: 'TerceroDetalle', permisoGruesoRequerido: 'Terceros', url: '/empresas-y-personas/detalle' }, canActivate: [ActiveLicenseGuard] },
            { path: 'ficha-personal/:id', loadChildren: 'apps/wo-frontend/src/app/ficha-personal/ficha-personal.module#FichaPersonalModule', data: { nombre: 'ficha-personal', permisoGruesoRequerido: 'Terceros', url: '/empresas-y-personas/ficha-personal' }, canActivate: [ActiveLicenseGuard] },
            { path: 'importar', loadChildren: 'apps/wo-frontend/src/app/tercero/importar-terceros/importar-terceros.module#ImportarTercerosModule', data: { nombre: 'ImportarTerceros', permisoGruesoRequerido: 'Terceros', url: '/empresas-y-personas/importar' }, canActivate: [ActiveLicenseGuard] },
            {
                path: 'informes',
                children: [
                    { path: 'informes-tercero-zona', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-tercero-direccion/informe-tercero-direccion.module#InformeTerceroDireccionModule', data: { nombre: 'InformesTerceroZona', reuseRoute: true, url: '/empresas-y-personas/informes/informes-tercero-zona' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'configuracion',
                children: [
                    { path: 'tipos-tercero', loadChildren: 'apps/wo-frontend/src/app/tercero/tipo-tercero/tipo-tercero.module#TipoTerceroModule', data: { nombre: 'TiposDeTercero', url: '/empresas-y-personas/configuracion/tipos-tercero' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'tipos-identificacion', loadChildren: 'apps/wo-frontend/src/app/tercero/tercero-tipo-identificacion/tercero-tipo-identificacion.module#TerceroTipoIdentificacionModule', data: { nombre: 'TiposDeIdentificacionTercero', url: '/empresas-y-personas/configuracion/tipos-identificacion' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'observaciones', loadChildren: 'apps/wo-frontend/src/app/tercero-admon-observacion/tercero-admon-observacion.module#TerceroAdmonObservacionModule', data: { nombre: 'TerceroClasificacionObservaciones', url: '/empresas-y-personas/configuracion/observaciones' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'campos-personalizado', loadChildren: 'apps/wo-frontend/src/app/campos-personalizados/campos-personalizados.module#CamposPersonalizadosModule', data: { nombre: 'TercerosCamposPersonalizados', url: '/empresas-y-personas/configuracion/campos-personalizado' }, canActivate: [ActiveLicenseGuard] },
                    {
                        path: 'ubicacion',
                        children: [
                            { path: 'paises', loadChildren: 'apps/wo-frontend/src/app/general/pais/pais.module#PaisModule', data: { nombre: 'Paises', url: '/empresas-y-personas/configuracion/ubicacion/paises' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'departamentos', loadChildren: 'apps/wo-frontend/src/app/general/departamento/departamento.module#DepartamentoModule', data: { nombre: 'Departamentos', url: '/empresas-y-personas/configuracion/ubicacion/departamentos' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'ciudades', loadChildren: 'apps/wo-frontend/src/app/general/ciudad/ciudad.module#CiudadModule', data: { nombre: 'Ciudades', url: '/empresas-y-personas/configuracion/ubicacion/ciudades' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'zonas', loadChildren: 'apps/wo-frontend/src/app/administracion-zonas/administracion-zonas.module#AdministracionZonasModule', data: { nombre: 'Zonas', url: '/empresas-y-personas/configuracion/ubicacion/zonas' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'barrios', loadChildren: 'apps/wo-frontend/src/app/general/barrio/barrio.module#BarrioModule', data: { nombre: 'Barrios', url: '/empresas-y-personas/configuracion/ubicacion/barrios' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'tipos-direccion', loadChildren: 'apps/wo-frontend/src/app/general/tipo-direccion/tipo-direccion.module#TipoDireccionModule', data: { nombre: 'TiposDireccion', url: '/empresas-y-personas/configuracion/ubicacion/tipos-direccion' }, canActivate: [ActiveLicenseGuard] }
                        ]
                    }
                ]
            }
        ]
    },
    {
        // FIXME: Hacer que el visor de informes cargue desde su ruta y no como componente insertado en cada filtro.
        path: 'informes',
        children: [
            { path: 'detallado-documento-ventas', loadChildren: '@wo/frontend/reporte/pantallasFiltros/informe-detallado-documento-ventas/informe-detallado-documento-ventas.module#InformeDetalladoDocumentoVentasModule', data: { nombre: 'InformeDetalladoDocumentoVentas', reuseRoute: true, url: '/informes/detallado-documento-ventas' } },
            { path: 'resumen-costo-venta', loadChildren: '@wo/frontend/reporte/pantallasFiltros/informe-resumen-costo-venta/informe-resumen-costo-venta.module#InformeResumenCostoVentaModule', data: { nombre: 'InformeResumenCostoVenta', reuseRoute: true, url: '/informes/resumen-costo-venta' } },
            { path: 'gestion-recaudo-cartera', loadChildren: '@wo/frontend/reporte/pantallasFiltros/informe-estado-cuenta-periodo-determinado/informe-estado-cuenta-periodo-determinado.module#InformeEstadoCuentaPeriodoDeterminadoModule', data: { nombre: 'InformeGestionRecaudoCartera', reuseRoute: true, url: '/informes/gestion-recaudo-cartera' }, canActivate: [ActiveLicenseGuard] },
            { path: 'estado-cuenta-periodo', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-estado-cuenta-periodo/informe-estado-cuenta-periodo.module#InformeEstadoCuentaPeriodoModule', data: { nombre: 'InformeDeudasGeneradasPeriodo', reuseRoute: true, url: '/informes/estado-cuenta-periodo' }, canActivate: [ActiveLicenseGuard] },
            { path: 'estado-cuenta-movimientos', loadChildren: '@wo/frontend/reporte/pantallasFiltros/informe-estados-de-cuenta-movimiento/informe-estados-de-cuenta-movimiento.module#InformeEstadosdeCuentaMovimientoModule', data: { nombre: 'InformeEstadoCuentaM', reuseRoute: true, url: '/informes/estado-cuenta-movimientos' }, canActivate: [ActiveLicenseGuard] },
            { path: 'estado-cuenta-saldos', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-estadosde-cuenta/informe-estadosde-cuenta.module#InformeEstadosdeCuentaModule', data: { nombre: 'InformeEstadoCuentaS', reuseRoute: true, url: '/informes/estado-cuenta-saldos' }, canActivate: [ActiveLicenseGuard] },
            { path: 'estado-cuenta-saldos-30-60-90', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-estados-cuenta-saldos-30-60-90/informe-estados-cuenta-saldos-30-60-90.module#InformeEstadosCuentaSaldos306090Module', data: { nombre: 'InformeEstadoCuenta306090', reuseRoute: true, url: '/informes/estado-cuenta-saldos-30-60-90' }, canActivate: [ActiveLicenseGuard] },
            { path: 'cuentas-contables-sin-configuracion-iva', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-declaracion-iva-filtro/popup-informes-auditoria-iva/informe-cuentas-contables-sin-configuracion-iva/informe-cuentas-contables-sin-configuracion-iva.module#InformeCuentasContablesSinConfiguracionIvaModule', data: { nombre: 'InformeCuentasContablesIngresosSinConfiguracion', permisoGruesoRequerido: 'InformesContabilidad', reuseRoute: true, url: '/informes/cuentas-contables-sin-configuracion-iva' }, canActivate: [ActiveLicenseGuard] },
            { path: 'declaracion-iva', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-declaracion-iva-filtro/informe-declaracion-iva-filtro.module#InformeDeclaracionIvaFiltroModule', data: { nombre: 'InformeDeclaracionIva', reuseRoute: true, url: '/informes/declaracion-iva' }, canActivate: [ActiveLicenseGuard] },
            { path: 'declaracion-ica', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-declaracion-ica-filtro/informe-declaracion-ica-filtro.module#InformeDeclaracionIcaFiltroModule', data: { nombre: 'InformeDeclaracionIca', reuseRoute: true, url: '/informes/declaracion-ica' }, canActivate: [ActiveLicenseGuard] },
            { path: 'venta-producto', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-venta-producto-filtro/informe-venta-producto-filtro.module#InformeVentaProductoFiltroModule', data: { nombre: 'InformeVentaProducto', reuseRoute: true, url: '/informes/venta-producto' }, canActivate: [ActiveLicenseGuard] },
            { path: 'venta-vendedor', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-venta-vendedor-filtro/informe-venta-vendedor-filtro.module#InformeVentaVendedorFiltroModule', data: { nombre: 'InformeVentaVendedor', reuseRoute: true, url: '/informes/venta-vendedor' }, canActivate: [ActiveLicenseGuard] },
            { path: 'libro-auxiliar', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-libro-auxiliar-filtro/informe-libro-auxiliar-filtro.module#InformeLibroAuxiliarFiltroModule', data: { nombre: 'InformeLibroAuxiliar', reuseRoute: true, url: '/informes/libro-auxiliar' }, canActivate: [ActiveLicenseGuard] },
            { path: 'libro-oficial-diario', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/libro-oficial-diario-filtro/libro-oficial-diario-filtro.module#LibroOficialDiarioFiltroModule', data: { nombre: 'LibroOficialDiario', reuseRoute: true, url: '/informes/libro-oficial-diario' }, canActivate: [ActiveLicenseGuard] },
            { path: 'libro-mayor-y-balances', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-libro-mayor-balances/informe-libro-mayor-balances.module#InformeLibroMayorBalancesModule', data: { nombre: 'LibroMayorYBalances', reuseRoute: true, url: '/informes/libro-mayor-y-balances' }, canActivate: [ActiveLicenseGuard] },
            { path: 'comprobante-diario', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-comprobante-diario/informe-comprobante-diario.module#InformeComprobanteDiarioModule', data: { nombre: 'ComprobanteDiario', reuseRoute: true, url: '/informes/comprobante-diario' }, canActivate: [ActiveLicenseGuard] },
            { path: 'balance-de-prueba', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-balance-de-pruebas/informe-balance-de-pruebas.module#InformeBalanceDePruebasModule', data: { nombre: 'BalanceDePruebas', reuseRoute: true, url: '/informes/balance-de-prueba' }, canActivate: [ActiveLicenseGuard] },
            { path: 'comprobante-informe-diario', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-venta-comprobante-diario/informe-venta-comprobante-diario.module#InformeVentaComprobanteDiarioModule', data: { nombre: 'ComprobanteInformeDiario', reuseRoute: true, url: '/informes/comprobante-informe-diario' }, canActivate: [ActiveLicenseGuard] },
            { path: 'libro-inventario-balances', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-libro-inventario-balances-filtro/informe-libro-inventario-balances-filtro.module#InformeLibroInventarioBalancesFiltroModule', data: { nombre: 'LibroInventarioBalances', reuseRoute: true, url: '/informes/libro-inventario-balances' }, canActivate: [ActiveLicenseGuard] },
            { path: 'estado-resultados', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-estado-resultados/informe-estado-resultados.module#InformeEstadoResultadosModule', data: { nombre: 'InformeEstadoResultados', reuseRoute: true, url: '/informes/estado-resultados' }, canActivate: [ActiveLicenseGuard] },
            { path: 'venta-documento', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-venta-documento-filtro/informe-venta-documento-filtro.module#InformeVentaDocumentoFiltroModule', data: { nombre: 'InformeVentaDocumento', reuseRoute: true, url: '/informes/venta-documento' }, canActivate: [ActiveLicenseGuard] },
            { path: 'venta-forma-pago', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-venta-forma-pago-filtro/informe-venta-forma-pago-filtro.module#InformeVentaFormaPagoFiltroModule', data: { nombre: 'InformeVentaFormaPago', reuseRoute: true, url: '/informes/venta-forma-pago' }, canActivate: [ActiveLicenseGuard] },
            { path: 'venta-centro-costo', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-venta-centro-costo-filtro/informe-venta-centro-costo-filtro.module#InformeVentaCentroCostoFiltroModule', data: { nombre: 'InformeVentaCentroCosto', reuseRoute: true, url: '/informes/venta-centro-costo' }, canActivate: [ActiveLicenseGuard] },
            { path: 'consecutivo-de-documentos', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-documentos-anuados/informe-documentos-anuados.module#InformeDocumentosAnuadosModule', data: { nombre: 'InformeConsecutivoDeDocumentos', reuseRoute: true, url: '/informes/consecutivo-de-documentos' }, canActivate: [ActiveLicenseGuard] },
            { path: 'venta-zonas', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-venta-zonas-filtro/informe-venta-zonas-filtro.module#InformeVentaZonasFiltroModule', data: { nombre: 'InformeVentaZonas', reuseRoute: true, url: '/informes/venta-zonas' }, canActivate: [ActiveLicenseGuard] },
            { path: 'estados-cuenta-saldos', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-estados-cuenta-saldos-filtro/informe-estados-cuenta-saldos-filtro.module#InformeEstadosCuentaSaldosFiltroModule', data: { nombre: 'InformeEstadoCuentaSaldos', reuseRoute: true, url: '/informes/estados-cuenta-saldos' }, canActivate: [ActiveLicenseGuard] },
            { path: 'estados-cuenta-movimientos', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-estados-cuenta-movimientos-filtro/informe-estados-cuenta-movimientos-filtro.module#InformeEstadosCuentaMovimientosFiltroModule', data: { nombre: 'InformeEstadoCuentaMovimientos', reuseRoute: true, url: '/informes/estados-cuenta-movimientos' }, canActivate: [ActiveLicenseGuard] },
            { path: 'venta-comparativo', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-venta-comparativo-filtro/informe-venta-comparativo-filtro.module#InformeVentaComparativoFiltroModule', data: { nombre: 'InformeVentaComparativoVendedor', reuseRoute: true, url: '/informes/venta-comparativo' }, canActivate: [ActiveLicenseGuard] },
            { path: 'compra-comparativo', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-compra-comparativo-filtro/informe-compra-comparativo-filtro.module#InformeCompraComparativoFiltroModule', data: { nombre: 'InformeCompraComparativoVendedor', reuseRoute: true, url: '/informes/compra-comparativo' }, canActivate: [ActiveLicenseGuard] },
            { path: 'compra-centro-costo', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-compra-centro-costo-filtro/informe-compra-centro-costo-filtro.module#InformeCompraCentroCostoFiltroModule', data: { nombre: 'InformeCompraCentroCosto', reuseRoute: true, url: '/informes/compra-centro-costo' }, canActivate: [ActiveLicenseGuard] },
            { path: 'compra-forma-pago', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-compra-forma-pago-filtro/informe-compra-forma-pago-filtro.module#InformeCompraFormaPagoFiltroModule', data: { nombre: 'InformeCompraFormaPago', reuseRoute: true, url: '/informes/compra-forma-pago' }, canActivate: [ActiveLicenseGuard] },
            { path: 'compra-zonas', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-compra-zonas-filtro/informe-compra-zonas-filtro.module#InformeCompraZonasFiltroModule', data: { nombre: 'InformeCompraZonas', reuseRoute: true, url: '/informes/compra-zonas' }, canActivate: [ActiveLicenseGuard] },
            { path: 'compra-documento', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-compra-documento-filtro/informe-compra-documento-filtro.module#InformeCompraDocumentoFiltroModule', data: { nombre: 'InformeCompraDocumento', reuseRoute: true, url: '/informes/compra-documento' }, canActivate: [ActiveLicenseGuard] },
            //loadChildren:'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-estado-vencimiento/informe-estado-vencimiento.module#InformeEstadoVencimientoModule',
            { path: 'compra-vendedor', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-compra-vendedor-filtro/informe-compra-vendedor-filtro.module#InformeCompraVendedorFiltroModule', data: { nombre: 'InformeCompraVendedor', reuseRoute: true, url: '/informes/compra-vendedor' }, canActivate: [ActiveLicenseGuard] },
            { path: 'compra-producto', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-compra-producto-filtro/informe-compra-producto-filtro.module#InformeCompraProductoFiltroModule', data: { nombre: 'InformeCompraProducto', reuseRoute: true, url: '/informes/compra-producto' }, canActivate: [ActiveLicenseGuard] },
            { path: 'informe-balance-general', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-balance-general-filtro/informe-balance-general-filtro.module#InformeBalanceGeneralFiltroModule', data: { nombre: 'InformeBalanceGeneral', reuseRoute: true, url: '/informes/informe-balance-general' }, canActivate: [ActiveLicenseGuard] },
            { path: 'certificacion-impuestos', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-certificacion-impuestos-filtro/informe-certificacion-impuestos-filtro.module#InformeCertificacionImpuestosFiltroModule', data: { nombre: 'CertificadoImpuestos', reuseRoute: true, url: '/informes/certificacion-impuestos' }, canActivate: [ActiveLicenseGuard] },
            { path: 'documentos-vencidos', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-estado-vencimiento/informe-estado-vencimiento.module#InformeEstadoVencimientoModule', data: { nombre: 'InformeDocVencidos', reuseRoute: true, url: '/informes/documentos-vencidos' }, canActivate: [ActiveLicenseGuard] },
            { path: 'documentos-descuadrados', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-documentos-descuadrados-sin-contabilizar/informe-documentos-descuadrados-sin-contabilizar.module#InformeDocumentosDescuadradosSinContabilizarModule', data: { nombre: 'InformeDocDescuadrados', reuseRoute: true, url: '/informes/documentos-descuadrados' }, canActivate: [ActiveLicenseGuard] },
            { path: 'parametrizacion-informacion-exogena', loadChildren: 'apps/wo-frontend/src/app/informacion-exogena/configuracion/configuracion.module#ConfiguracionExogenaModule', data: { nombre: 'ParametrizacionInfoExogena', permisoGruesoRequerido: 'tipoAnexoInfExogena', url: '/informes/parametrizacion-informacion-exogena' }, canActivate: [ActiveLicenseGuard] },
            { path: 'informe-informacion-exogena', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informacion-exogena/informacion-exogena-filtro.module#InformacionExogenaFiltroModule', data: { nombre: 'InformeInformacionExogena', reuseRoute: true, url: '/informes/informe-informacion-exogena' }, canActivate: [ActiveLicenseGuard] },
            { path: 'costo-ventas-detallado', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-costo-venta-detallado/informe-costo-venta-detallado.module#InformeCostoVentaDetalladoModule', data: { nombre: 'InformeCostoVentasDetallado', reuseRoute: true, url: '/informes/costo-ventas-detallado' }, canActivate: [ActiveLicenseGuard] },
        ]
    },
    {
        path: 'productos-y-servicios',
        children: [
            { path: '', loadChildren: 'apps/wo-frontend/src/app/lista-inventarios/lista-inventarios.module#ListaInventariosModule', data: { nombre: 'Inventarios', url: '/productos-y-servicios' }, canActivate: [ActiveLicenseGuard] },
            { path: 'actualizar-productos-y-servicios', loadChildren: 'apps/wo-frontend/src/app/actualizacion-inventarios/actualizacion-inventarios.module#ActualizarInventariosModule', data: { nombre: 'ActualizarInventarios', permisoGruesoRequerido: 'Inventarios', url: '/productos-y-servicios/actualizar-productos-y-servicios' } },
            { path: 'ajuste-inventario', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-ajuste-inventario/listado-ajuste-inventario.module#ListadoAjusteInventarioModule', data: { nombre: 'AjusteInventarios', url: '/productos-y-servicios/ajuste-inventario' }, canActivate: [ActiveLicenseGuard] },
            { path: 'ajuste-inventario/detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/ajuste-inventario/ajuste-inventario.module#AjusteInventarioModule', data: { nombre: 'AjusteInventarioDetalle', permisoGruesoRequerido: 'AjusteInventarios', url: '/productos-y-servicios/ajuste-inventario/detalle' }, canActivate: [ActiveLicenseGuard] },
            { path: 'generador-ajuste-inventario', loadChildren: 'apps/wo-frontend/src/app/inventarios/generador-ajuste-inventario/generador-ajuste-inventario.module#GeneradorAjusteInventarioModule', data: { nombre: 'GeneradorAjusteInventario', permisoGruesoRequerido: 'AjusteInventarios', url: '/productos-y-servicios/generador-ajuste-inventario' }, canActivate: [ActiveLicenseGuard] },
            { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/inventarios/detalle-inventarios/detalle-inventarios.module#DetalleInventariosModule', data: { nombre: 'InventarioDetalle', permisoGruesoRequerido: 'Inventarios', url: '/productos-y-servicios/detalle' }, canActivate: [ActiveLicenseGuard] },
            { path: 'importar', loadChildren: 'apps/wo-frontend/src/app/inventarios/importar-inventario/importar-inventario.module#ImportarInventarioModule', data: { nombre: 'ImportarInventarios', permisoGruesoRequerido: 'Inventarios', url: '/productos-y-servicios/importar' }, canActivate: [ActiveLicenseGuard] },
            {
                path: 'configuracion',
                children: [
                    { path: 'clasificacion', loadChildren: 'apps/wo-frontend/src/app/inventarios/inventario-clasificacion/inventario-clasificacion.module#InventarioClasificacionModule', data: { nombre: 'ClasificacionesInventario', url: '/productos-y-servicios/configuracion/clasificacion' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'grupos', loadChildren: 'apps/wo-frontend/src/app/inventarios/administracion-grupos/administracion-grupos.module#AdministracionGruposModule', data: { nombre: 'InventarioGrupos', url: '/productos-y-servicios/configuracion/grupos' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'bodegas', loadChildren: 'apps/wo-frontend/src/app/inventario-bodega/inventario-bodega.module#InventarioBodegaModule', data: { nombre: 'Bodegas', url: '/productos-y-servicios/configuracion/bodegas' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'unidades-medida', loadChildren: 'apps/wo-frontend/src/app/unidadMedida/unidad-medida/unidad-medida.module#UnidadMedidaModule', data: { nombre: 'UnidadesMedida', url: '/productos-y-servicios/configuracion/unidades-medida' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'tipos-unidades-medida', loadChildren: 'apps/wo-frontend/src/app/unidadMedida/unidad-medida-tipo/unidad-medida-tipo.module#UnidadMedidaTipoModule', data: { nombre: 'UnidadMedidaTipo', url: '/productos-y-servicios/configuracion/tipos-unidades-medida' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'observaciones', loadChildren: 'apps/wo-frontend/src/app/inventarios/inventario-admon-observacion/inventario-admon-observacion.module#InventarioAdmonObservacionModule', data: { nombre: 'ListaInventariosObservaciones', url: '/productos-y-servicios/configuracion/observaciones' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'campos-personalizado', loadChildren: 'apps/wo-frontend/src/app/campos-personalizados/campos-personalizados.module#CamposPersonalizadosModule', data: { nombre: 'DocumentoCamposPersonalizados', url: '/productos-y-servicios/configuracion/campos-personalizado' }, canActivate: [ActiveLicenseGuard] },
                    {
                      path: 'lotes',
                      children: [
                        { path: '', loadChildren: 'apps/wo-frontend/src/app/inventario-lote/inventario-lote.module#InventarioLoteModule', data: { nombre: 'Lotes', url: '/productos-y-servicios/configuracion/lotes' }, canActivate: [ActiveLicenseGuard] },
                        { path: 'importar', loadChildren: 'apps/wo-frontend/src/app/inventario-lote/importar-lote/importar-lote.module#ImportarLoteModule', data: { nombre: 'ImportarLotes', permisoGruesoRequerido: 'InventarioLote', url: '/productos-y-servicios/configuracion/lotes/importar' }, canActivate: [ActiveLicenseGuard] }
                      ]
                    },
                    { path: 'listas-precios', loadChildren: 'apps/wo-frontend/src/app/lista-precios/lista-precios-otra-moneda/lista-precios-otra-moneda.module#ListaPreciosOtraMonedaModule', data: { nombre: 'ListasPrecios', url: '/productos-y-servicios/configuracion/listas-precios' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'actualizar-listas-precios', loadChildren: 'apps/wo-frontend/src/app/lista-precios/actualizar-listas-precios/actualizar-listas-precios.module#ActualizarListasPreciosModule', data: { nombre: 'ActualizarListasPrecios', url: '/productos-y-servicios/configuracion/actualizar-listas-precios' } },
                    {
                      path: 'tallas-colores',
                      children: [
                        { path: '', loadChildren: 'apps/wo-frontend/src/app/inventarios/talla-color/talla-color.module#TallaColorModule', data: { nombre: 'TallaColor', url: '/productos-y-servicios/configuracion/tallas-colores' }, canActivate: [ActiveLicenseGuard] },
                        { path: 'importar', loadChildren: 'apps/wo-frontend/src/app/inventarios/importar-talla-color/importar-talla-color.module#ImportarTallaColorModule', data: { nombre: 'ImportarTallaColor', permisoGruesoRequerido: 'TallaColor', url: '/productos-y-servicios/configuracion/tallas-colores/importar' }, canActivate: [ActiveLicenseGuard] }
                      ]
                     }
                ]
            },
            {
                path: 'salida-almacen',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-salida-almacen/listado-salida-almacen.module#ListadoSalidaAlmacenModule', data: { nombre: 'SalidaDeAlmacen', url: '/productos-y-servicios/salida-almacen' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/salida-almacen/salida-almacen.module#SalidaAlmacenModule', data: { nombre: 'SalidaAlmacenDetalles', permisoGruesoRequerido: 'SalidaDeAlmacen', url: '/productos-y-servicios/salida-almacen/detalle' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'importar-documento/:tipodoc', loadChildren: '@wo/frontend/documentos/detalle/documento-encabezado/importar-documento/importar-documentos.module#ImportarDocumentosModule', data: { nombre: 'ImportarDocumentosSalidaAlmacen', url: '/productos-y-servicios/salida-almacen/importar-documento', canActivate: [ActiveLicenseGuard] } }
                ]
            },
            {
                path: 'entrada-almacen',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-entrada-almacen/listado-entrada-almacen.module#ListadoEntradaAlmacenModule', data: { nombre: 'EntradaDeAlmacen', url: '/productos-y-servicios/entrada-almacen' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/entrada-almacen/entrada-almacen.module#EntradaAlmacenModule', data: { nombre: 'EntradaAlmacenDetalle', permisoGruesoRequerido: 'EntradaDeAlmacen', url: '/productos-y-servicios/entrada-almacen/detalle' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'importar-documento/:tipodoc', loadChildren: '@wo/frontend/documentos/detalle/documento-encabezado/importar-documento/importar-documentos.module#ImportarDocumentosModule', data: { nombre: 'ImportarDocumentosEntradaAlmacen', url: '/productos-y-servicios/entrada-almacen/importar-documento', canActivate: [ActiveLicenseGuard] } }
                ]
            },
            {
                path: 'entrada-prod-terminado',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-entrada-producto-terminado/listado-entrada-producto-terminado.module#ListadoEntradaProductoTerminadoModule', data: { nombre: 'EntradaDeProductoTerminado', url: '/productos-y-servicios/entrada-prod-terminado', canActivate: [ActiveLicenseGuard] } },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/entrada-producto-terminado/entrada-producto-terminado.module#EntradaProductoTerminadoModule', data: { nombre: 'EntradaDeProductoTerminadoDetalle', permisoGruesoRequerido: 'EntradaProdTerminado', url: '/productos-y-servicios/entrada-producto-terminado/detalle' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'importar-documento/:tipodoc', loadChildren: '@wo/frontend/documentos/detalle/documento-encabezado/importar-documento/importar-documentos.module#ImportarDocumentosModule', data: { nombre: 'ImportarDocumentosEntradaProdTerminado', url: '/productos-y-servicios/entrada-prod-terminado/importar-documento', canActivate: [ActiveLicenseGuard] } }
                ]
            },
            {
                path: 'informes',
                children: [
                    { path: 'informe-inventarios-cruce-documentos', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/control-cruces-documentos/control-cruces-documentos/control-cruces-documentos.module#ControlCrucesDocumentosModule', data: { nombre: 'InformeControlCruceDocumentos', reuseRoute: true, url: '/productos-y-servicios/informes/informe-inventarios-cruce-documentos' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'informe-inventarios-general', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-inventario-general/informe-inventario-general.module#InformeInventarioGeneralModule', data: { nombre: 'InformesInventarioGeneral', reuseRoute: true, url: '/productos-y-servicios/informes/informe-inventarios-general' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'informe-inventarios-catalogo', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-inventario-catalogo/informe-inventario-catalogo.module#InformeInventarioCatalogoModule', data: { nombre: 'InformeInventariosCatalogo', reuseRoute: true, url: '/productos-y-servicios/informes/informe-inventarios-catalogo' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'informe-inventarios-consolidado-de-existencias', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-inventarios-consolidado-de-existencias/informe-inventarios-consolidado-de-existencias.module#InformeInventariosConsolidadoDeExistenciasModule', data: { nombre: 'InformeInventariosConsolidadoDeExistencias', reuseRoute: true, url: '/productos-y-servicios/informes/informe-inventarios-consolidado-de-existencias' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'informe-inventarios-consolidado-de-movimientos', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-inventarios-consolidado-de-movimientos/informe-inventarios-consolidado-de-movimientos.module#InformeInventariosConsolidadoDeMovimientosModule', data: { nombre: 'InformeInventariosConsolidadoDeMovimientos', reuseRoute: true, url: '/productos-y-servicios/informes/informe-inventarios-consolidado-de-movimientos' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'informe-inventarios-minimos-maximos-reorden', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-inventarios-minimos-maximos-reorden/informe-inventarios-minimos-maximos-reorden.module#InformeInventariosMinimosMaximosReordenModule', data: { nombre: 'InformeInventariosMinMax', reuseRoute: true, url: '/productos-y-servicios/informes/informe-inventarios-minimos-maximos-reorden' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'informe-inventarios-kardex', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-inventarios-kardex/informe-inventarios-kardex.module#InformeInventariosKardexModule', data: { nombre: 'InformeInventariosKardex', reuseRoute: true, url: '/productos-y-servicios/informes/informe-inventarios-kardex' }, canActivate: [ActiveLicenseGuard] },
                    // loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-depreciacion-activos-filtro/informe-depreciacion-activos.module#InformeDepreciacionFiltrosModule',
                    { path: 'informe-inventarios-activo-centro-costo', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-activos-fijos-centro-costos-filtro/informe-activos-fijos-centro-costos-filtro.module#InformeActivosFijosCentroCostosFiltroModule', data: { nombre: 'InformeActivosFijosCentroCosto', reuseRoute: true, url: '/productos-y-servicios/informes/informe-inventarios-activo-centro-costo' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'informe-inventarios-activo-depreciacion', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-depreciacion-activos-filtro/informe-depreciacion-activos.module#InformeDepreciacionFiltrosModule', data: { nombre: 'InformeActivosDepreciacion', reuseRoute: true, url: '/productos-y-servicios/informes/informe-inventarios-activo-depreciacion' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'informe-inventarios-activo-mantenimiento', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-activos-fijos-mantenimiento-filtro/informe-activos-fijos-mantenimiento-filtro.module#InformeActivosFijosMantenimientoFiltroModule', data: { nombre: 'InformeActivosFijosMantenimiento', reuseRoute: true, url: '/productos-y-servicios/informes/informe-inventarios-activo-mantenimiento' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'informe-inventarios-activo-ubicacion', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-activos-fijos-ubicacion-responsable/informe-activos-fijos-ubicacion-responsable.module#InformeActivosFijosUbicacionResponsableModule', data: { nombre: 'InformeActivosFijosUbicacion', reuseRoute: true, url: '/productos-y-servicios/informes/informe-inventarios-activo-ubicacion' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'informe-inventarios-activo-garantia', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-activos-fijos-vencimiento-garantias/informe-activos-fijos-vencimiento-garantias.module#InformeActivosFijosVencimientoGarantiasModule', data: { nombre: 'InformeActivosFijosGarantia', reuseRoute: true, url: '/productos-y-servicios/informes/informe-inventarios-activo-garantia' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'informe-inventarios-activo-poliza', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-activos-fijos-vencimiento-poliza/informe-activos-fijos-vencimiento-poliza.module#InformeActivosFijosVencimientoPolizaModule', data: { nombre: 'InformeActivosFijosPoliza', reuseRoute: true, url: '/productos-y-servicios/informes/informe-inventarios-activo-poliza' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
        ]
    },
    {
        path: 'seguridad',
        children: [
            { path: 'usuarios', loadChildren: 'apps/wo-frontend/src/app/administracionUsuarios/tabla-admon-usuarios/tabla-admon-usuarios.module#TablaAdmonUsuariosModule', data: { nombre: 'Usuarios', url: '/seguridad/usuarios' }, canActivate: [ActiveLicenseGuard] },
            { path: 'usuarioDetalle/:id', loadChildren: 'apps/wo-frontend/src/app/usuario/usuario.module#UsuarioModule', data: { nombre: 'DetalleUsuario', permisoGruesoRequerido: 'Usuarios', url: '/seguridad/usuarioDetalle' }, canActivate: [ActiveLicenseGuard] },
            { path: 'usuarioDetalleApi/:id', loadChildren: 'apps/wo-frontend/src/app/usuario/usuario.module#UsuarioModule', data: { nombre: 'DetalleUsuariosApi', permisoGruesoRequerido: 'Usuarios', url: '/seguridad/usuarioDetalleApi', reuseRoute: true }, canActivate: [ActiveLicenseGuard] },
            { path: 'roles', loadChildren: 'apps/wo-frontend/src/app/rol/roles/roles.module#RolesModule', data: { nombre: 'Roles', url: '/seguridad/roles' }, canActivate: [ActiveLicenseGuard] },
            { path: 'rolDetalle/:id', loadChildren: 'apps/wo-frontend/src/app/rol/permisos-roles/permisos-roles.module#PermisosRolesModule', data: { nombre: 'DetalleRoles', permisoGruesoRequerido: 'Roles', url: '/seguridad/rolDetalle' }, canActivate: [ActiveLicenseGuard] }
        ]
    },
    {
        path: 'impuestos',
        children: [
            {
                path: 'configuracion',
                children: [
                    { path: 'excepciones', loadChildren: 'apps/wo-frontend/src/app/excepcion-detalle/excepcion-detalle.module#ExcepcionDetalleModule', data: { nombre: 'ExcepcionDetalle', permisoGruesoRequerido: 'ConfiguracionImpuestosContabilidad', url: '/impuestos/configuracion/excepciones' }, canActivate: [ActiveLicenseGuard] }
                ]
            }
        ]
    },
    {
        path: 'configuracion',
        children: [
            { path: 'general', loadChildren: 'apps/wo-frontend/src/app/configuracion-general/configuracion-general.module#ConfiguracionGeneralModule', data: { nombre: 'ConfiguracionGeneral', url: '/configuracion/general' }, canActivate: [ActiveLicenseGuard] },
            { path: 'empresas-y-sucursales', loadChildren: 'apps/wo-frontend/src/app/empresa/empresa.module#EmpresaModule', data: { nombre: 'EmpresaYOSucursales', url: '/configuracion/empresas-y-sucursales' }, canActivate: [ActiveLicenseGuard] },
            { path: 'diseno-informe', loadChildren: 'apps/wo-frontend/src/app/diseno-informe/lista-informe/lista-informe.module#ListaInformeModule', data: { nombre: 'DisenoInforme', url: '/configuracion/diseno-informe' }, canActivate: [ActiveLicenseGuard] }
        ]
    },
    {
        path: 'administracion-licencia',
        children: [
            { path: 'asistente-inicial', loadChildren: 'apps/wo-frontend/src/app/configuracion-inicial/asistente-configuracion-inicial/asistente-configuracion-inicial.module#AsistenteConfiguracionInicialModule', data: { nombre: 'ConfiguracionInicial', url: '/administracion-licencia/asistente-inicial' }, canActivate: [ConfiguradoGuard, ActiveLicenseGuard] },
            { path: 'detalle-licencia', loadChildren: 'apps/wo-frontend/src/app/licencia/administrar-licencia/administrar-licencia.module#AdministrarLicenciaModule', data: { nombre: 'AdministradorLicencia', url: '/administracion-licencia/detalle-licencia' } },
            { path: 'detalle-historial-pago/:idPago', loadChildren: 'apps/wo-frontend/src/app/licencia/administrar-licencia/historial-pagos/detalle-historial-pago/detalle-historial-pago.module#DetalleHistorialPagoModule', data: { nombre: 'DetalleHistorialPago', url: '/administracion-licencia/detalle-historial-pago' } }

        ]
    },
    {
        path: 'facturacion-electronica',
        children: [
            { path: 'inicio', loadChildren: 'apps/wo-frontend/src/app/facturacionElectronica/inicio/inicio.module#InicioModule', data: { nombre: 'InicioFE', url: '/facturacion-electronica/inicio' }, canActivate: [ActiveLicenseGuard] },
            { path: 'mis-documentos', loadChildren: 'apps/wo-frontend/src/app/facturacionElectronica/mis-documentos/mis-documentos.module#MisDocumentosModule', data: { nombre: 'MisDocumentosFE', url: '/facturacion-electronica/mis-documentos' }, canActivate: [ActiveLicenseGuard] },
            { path: 'detalle-documento-electronico/:idDocumento', loadChildren: 'apps/wo-frontend/src/app/facturacionElectronica/detalle-documento-electronico/detalle-documento-electronico.module#DetalleDocumentoElectronicoModule', data: { nombre: 'DetalleDocumentoElectronico', permisoGruesoRequerido: 'MisDocumentosFE', url: '/facturacion-electronica/detalle-documento-electronico' }, canActivate: [ActiveLicenseGuard] },
            { path: 'facturas-sin-enviar', loadChildren: 'apps/wo-frontend/src/app/facturacionElectronica/factura-electronica-sin-enviar/factura-electronica-sin-enviar.module#FacturaElectronicaSinEnviarModule', data: { nombre: 'FacturasSinEnviar', url: '/facturacion-electronica/facturas-sin-enviar' }, canActivate: [ActiveLicenseGuard] },
            { path: 'asistente', loadChildren: 'apps/wo-frontend/src/app/facturacionElectronica/asistente/asistente.module#AsistenteModule', data: { nombre: 'AsistenteFE', url: '/facturacion-electronica/asistente' }, canActivate: [ActiveLicenseGuard] },
            { path: 'fe-envio-masivo-emails', loadChildren: 'apps/wo-frontend/src/app/facturacionElectronica/fe-envio-masivo-emails/fe-envio-masivo-emails.module#FeEnvioMasivoEmailsModule', data: { nombre: 'EnvioMasivoEMails', url: '/facturacion-electronica/fe-envio-masivo-emails' }, canActivate: [ActiveLicenseGuard] },
            { path: 'terceroactualizacion', loadChildren: 'apps/wo-frontend/src/app/facturacionElectronica/tercero-actualizacion/tercero-actualizacion.module#TerceroActualizacionModule', data: { nombre: 'terceroactualizacion', url: '/facturacion-electronica/terceroactualizacion' }, canActivate: [ActiveLicenseGuard] }
        ]
    },
    {
        path: 'nomina',
        children: [
            {
                path: 'configuracion',
                children: [
                  { path: 'configuracion-pila', loadChildren: 'apps/wo-frontend/src/app/nomina/configuracion-nomina-pila/configuracion-nomina-pila.module#ConfiguracionNominaPilaModule', data: { nombre: 'ConfiguracionPila', permisoGruesoRequerido: 'ConfiguracionPila', url: '/nomina/configuracion/configuracion-pila' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'configuracion-prestaciones-sociales', loadChildren: 'apps/wo-frontend/src/app/nomina/configuracion-nomina-prestaciones/configuracion-nomina-prestaciones/configuracion-nomina-prestaciones.module#ConfiguracionNominaPrestacionesModule', data: { nombre: 'ConfigNominaYPrestaciones', permisoGruesoRequerido: 'ConfigNominaYPrestaciones', url: '/nomina/configuracion/configuracion-prestaciones-sociales' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'tarifas-fondo-solidaridad', loadChildren: 'apps/wo-frontend/src/app/nomina/tarifas-fondo-solidaridad/tarifas-fondo-solidaridad.module#TarifasFondoSolidaridadModule', data: { nombre: 'TarifasFondoSolidaridad', permisoGruesoRequerido: 'TarifasFondoSolidaridad', url: '/nomina/configuracion/tarifas-fondo-solidaridad' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'retencion-fuente-salarios', loadChildren: 'apps/wo-frontend/src/app/nomina/retencion-fuente-salarios/retencion-fuente-salarios.module#RetencionFuenteSalariosModule', data: { nombre: 'RetencionFuenteSalarios', permisoGruesoRequerido: 'RetencionFuenteSalarios', url: '/nomina/configuracion/retencion-fuente-salarios' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'cargo', loadChildren: 'apps/wo-frontend/src/app/nomina/cargos/cargos.module#CargosModule', data: { nombre: 'NominaCargo', permisoGruesoRequerido: 'nominaCargo', url: '/nomina/configuracion/cargo' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'centro-de-trabajo', loadChildren: 'apps/wo-frontend/src/app/nomina/centros-trabajo/centros-trabajo.module#CentrosTrabajoModule', data: { nombre: 'NominaCentroTrabajo', permisoGruesoRequerido: 'nominaCentroTrabajo', url: '/nomina/configuracion/centro-de-trabajo' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'concepto', loadChildren: 'apps/wo-frontend/src/app/nomina/conceptos-nomina/conceptos-nomina-module/conceptos-nomina.module#ConceptosNominaModule', data: { nombre: 'ConceptosNomina', url: '/nomina/configuracion/concepto', permisoGruesoRequerido: 'nominaConceptos' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'contrato-area', loadChildren: 'apps/wo-frontend/src/app/nomina/areas/areas.module#AreasModule', data: { nombre: 'NominaContratoArea', permisoGruesoRequerido: 'nominaContratoArea', url: '/nomina/configuracion/contrato-area' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'deducido-Recurrente-tipo', loadChildren: 'apps/wo-frontend/src/app/nomina/tipos/tipos.module#TiposModule', data: { nombre: 'tipo', permisoGruesoRequerido: 'NominaDeducidoRecurrenteTipo', url: '/nomina/configuracion/deducido-Recurrente-tipo' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
              path: 'importar-ficha-personal',
              children: [
                { path: '', loadChildren: 'apps/wo-frontend/src/app/ficha-personal/importar-ficha-personal/importar-ficha-personal.module#ImportarFichaPersonalModule', data: { nombre: 'ImportarFichaPersonal', permisoGruesoRequerido: 'importarFichaPersonal', url: '/nomina/importar-ficha-personal' }, canActivate: [ActiveLicenseGuard] }
              ]
            },
            {
                path: 'nomina-empleados',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-nomina-empleados/listado-nomina-empleados.module#ListadoNominaEmpleadosModule', data: { nombre: 'NominaEmpleados', url: '/nomina/nomina-empleados' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/nomina-empleados/nomina-empleados.module#NominaEmpleadosModule', data: { nombre: 'NominaEmpleadosDetalle', url: '/nomina/nomina-empleados/detalle', permisoGruesoRequerido: 'NominaEmpleados' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'liquidacion-planilla-pila',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-nomina-pila/listado-nomina-pila.module#ListadoNominaPilaModule', data: { nombre: 'LiquidacionPila',  permisoGruesoRequerido: 'LiquidacionPila', url: '/nomina/liquidacion-planilla-pila' }, canActivate: [ActiveLicenseGuard] },
                    {
                      path: 'vista-previa-pila',
                      children: [
                          { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-nomina-pila/vista-previa-pila/vista-previa-pila.module#VistaPreviaPilaModule', data: { nombre: 'vistaPreviaPila' } },
                          { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-nomina-pila/vista-previa-pila/vista-previa-pila.module#VistaPreviaPilaModule', data: { nombre: 'vistaPreviaPilaDetalle' } }
                      ]
                  }
                ]
            },
            {
                path: 'nomina-electronica',
                children: [
                    { path: 'estados', loadChildren: 'apps/wo-frontend/src/app/nom-electronica/consultar_estados_envio/consultar_estados_envio.module#ConsultarEstadosEnviosNEModule', data: { nombre: 'ConsultarEstadosEnviosNE', url: '/nomina/nomina-electronica/estados' } },
                    { path: 'envio', loadChildren: 'apps/wo-frontend/src/app/nom-electronica/enviar_nomina_electronica/enviar_nomina_electronica.module#EnviarNominaElectronicaModule', data: { nombre: 'NominaElectronicaEnvio', url: '/nomina/nomina-electronica/envio' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'configuracion', loadChildren: 'apps/wo-frontend/src/app/nom-electronica/habilitacion-nomina-dian/habilitacion-nomina-dian.module#HabilitacionNominaDianModule', data: { nombre: 'NominaElectronicaConfiguracion', permisoGruesoRequerido: 'nominaElectronicaConfiguracion', url: '/nomina/nomina-electronica/configuracion' }, canActivate: [ActiveLicenseGuard] },
                    {
                        path: 'vista-previa-envio',
                        children: [
                            { path: '', loadChildren: 'apps/wo-frontend/src/app/nom-electronica/vista-previa-envio/vista-previa-envio.module#VistaPreviaEnvioNEModule', data: { nombre: 'vistaPreviaNE' } },
                            { path: 'detalle/:id', loadChildren: 'apps/wo-frontend/src/app/nom-electronica/vista-previa-envio/vista-previa-envio.module#VistaPreviaEnvioNEModule', data: { nombre: 'vistaPreviaDetalleNE' } }
                        ]
                    }
                ]
            }, {
                path: 'novedades-diarias',
                children: [
                    { path: '', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-novedades-diarias/listado-novedades-diarias.module#ListadoNovedadesDiariasModule', data: { nombre: 'NovedadesDiariasN', url: '/nomina/novedades-diarias', permisoGruesoRequerido: 'NovedadesDiarias' }, canActivate: [ActiveLicenseGuard] }
                ]
            },
            {
                path: 'prestaciones-sociales',
                children: [
                    { path: 'liquidacion-prima-servicio', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-liquidacion-prima-servicio/listado-liquidacion-prima-servicio.module#ListadoLiquidacionPrimaServicioModule', data: { nombre: 'LiquidacionPrimaDeServicio', permisoGruesoRequerido: 'ConfigNominaYPrestaciones', url: '/nomina/prestaciones-sociales/liquidacion-prima-servicio' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'liquidacion-prima-servicio/detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/liquidacion-prima-servicio/liquidacion-prima-servicio.module#LiquidacionPrimaServicioModule', data: { nombre: 'LiquidacionPrimaDeServicioDetalles', url: '/nomina/prestaciones-sociales/liquidacion-prima-servicio/detalle', permisoGruesoRequerido: 'ConfigNominaYPrestaciones' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'liquidacion-cesantias-intereses', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-liquidacion-censatias-sobre-cesantias/listado-liquidacion-censatias-sobre-cesantias/liquidacion-censatias-sobre-cesantias.module#ListadoLiquidacionCensatiasSobreCesantiasModule', data: { nombre: 'LiquidacionCesantiasIntereses', permisoGruesoRequerido: 'ConfigNominaYPrestaciones', url: '/nomina/prestaciones-sociales/liquidacion-cesantias-intereses' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'liquidacion-cesantias-intereses/detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/censantias-sobre-cesantias/censantias-sobre-cesantias.module#CensatiasSobreCentiasModule', data: { nombre: 'LiquidacionCesantiasInteresesDetalles', permisoGruesoRequerido: 'ConfigNominaYPrestaciones', url: '/nomina/prestaciones-sociales/liquidacion-cesantias-intereses/detalle' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'liquidacion-vacaciones', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-liquidacion-vacaciones/listado-liquidacion-vacaciones.module#ListadoLiquidacionVacacionesModule', data: { nombre: 'LiquidacionVacaciones', permisoGruesoRequerido: 'ConfigNominaYPrestaciones', url: '/nomina/prestaciones-sociales/liquidacion-vacaciones' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'liquidacion-vacaciones/detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/liquidacion-de-vacaciones/liquidacion-de-vacaciones.module#LiquidacionVacacionesModule', data: { nombre: 'LiquidacionVacacionesDetalles', permisoGruesoRequerido: 'ConfigNominaYPrestaciones', url: '/nomina/prestaciones-sociales/liquidacion-vacaciones/detalle' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'liquidacion-contrato', loadChildren: 'apps/wo-frontend/src/app/documentos/listado/listado-liquidacion-contrato/listado-liquidacion-contrato.module#ListadoLiquidacionContratoModule', data: { nombre: 'LiquidacionContrato', permisoGruesoRequerido: 'ConfigNominaYPrestaciones', url: '/nomina/prestaciones-sociales/liquidacion-contrato' }, canActivate: [ActiveLicenseGuard] },
                    { path: 'liquidacion-contrato/detalle/:id', loadChildren: 'apps/wo-frontend/src/app/documentos/detalle/liquidacion-de-contratos/liquidacion-de-contratos.module#LiquidacionContratosModule', data: { nombre: 'LiquidacionContratoDetalles', permisoGruesoRequerido: 'ConfigNominaYPrestaciones', url: '/nomina/prestaciones-sociales/liquidacion-contrato/detalle' }, canActivate: [ActiveLicenseGuard] },
                ]
            },
            {
                path: 'informes',
                children: [
                    {
                        path: 'prestamos-anticipios-descuentos-recurrentes',
                        children: [
                            { path: 'saldos_a_la_fecha', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-control-prestamo-anticipo-descuento/informe-control-prestamo-anticipo-descuento.module#InformeControlPrestamoAnticipoDescuentoModule', data: { nombre: 'SaldosALaFecha', reuseRoute: true, url: '/nomina/informes/prestamos-anticipios-descuentos-recurrentes/saldos_a_la_fecha' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'movimientos', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-control-prestamo-anticipo-descuento/informe-control-prestamo-anticipo-descuento.module#InformeControlPrestamoAnticipoDescuentoModule', data: { nombre: 'Movimientos', reuseRoute: true, url: '/nomina/informes/prestamos-anticipios-descuentos-recurrentes/movimientos' }, canActivate: [ActiveLicenseGuard] }
                        ]
                    },
                    {
                        path: 'empleado-contrato',
                        children: [
                            { path: 'centro-trabajo', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-empleados-contratos-nomina/informe-empleados-contratos-nomina.module#InformeEmpleadosContratosNominaModule', data: { nombre: 'EmpleadoPorCentroTrabajo', reuseRoute: true, url: '/nomina/informes/empleado-contrato/centro-trabajo' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'tipo-contrato', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-empleados-contratos-nomina/informe-empleados-contratos-nomina.module#InformeEmpleadosContratosNominaModule', data: { nombre: 'EmpleadoPorTipoContrato', reuseRoute: true, url: '/nomina/informes/empleado-contrato/tipo-contrato' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'centro-costo', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-empleados-contratos-nomina/informe-empleados-contratos-nomina.module#InformeEmpleadosContratosNominaModule', data: { nombre: 'EmpleadoPorCC', reuseRoute: true, url: '/nomina/informes/empleado-contrato/centro-costo' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'area-cargo', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-empleados-contratos-nomina/informe-empleados-contratos-nomina.module#InformeEmpleadosContratosNominaModule', data: { nombre: 'EmpleadoPorAreaYCargo', reuseRoute: true, url: '/nomina/informes/empleado-contrato/area-cargo' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'ingreso-periodo', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-empleados-contratos-nomina/informe-empleados-contratos-nomina.module#InformeEmpleadosContratosNominaModule', data: { nombre: 'EmpleadoPorIngresoPeriodo', reuseRoute: true, url: '/nomina/informes/empleado-contrato/ingreso-periodo' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'periodo-retiro', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-empleados-contratos-nomina/informe-empleados-contratos-nomina.module#InformeEmpleadosContratosNominaModule', data: { nombre: 'EmpleadoPorRetiroPeriodo', reuseRoute: true, url: '/nomina/informes/empleado-contrato/periodo-retiro' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'vencimiento-periodo-prueba', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-empleados-contratos-nomina/informe-empleados-contratos-nomina.module#InformeEmpleadosContratosNominaModule', data: { nombre: 'EmpleadoPorVencimientoPeriodoPrueba', reuseRoute: true, url: '/nomina/informes/empleado-contrato/vencimiento-periodo-prueba' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'vencimiento-termino-fijo', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-empleados-contratos-nomina/informe-empleados-contratos-nomina.module#InformeEmpleadosContratosNominaModule', data: { nombre: 'EmpleadoPorVencimientoTerminoFijo', reuseRoute: true, url: '/nomina/informes/empleado-contrato/vencimiento-termino-fijo' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'seguridad-social', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-empleados-contratos-nomina/informe-empleados-contratos-nomina.module#InformeEmpleadosContratosNominaModule', data: { nombre: 'EmpleadoPorSeguridadSocial', reuseRoute: true, url: '/nomina/informes/empleado-contrato/seguridad-social' }, canActivate: [ActiveLicenseGuard] }
                        ]
                    },
                    {
                        path: 'certificaciones-nomina',
                        children: [
                            { path: 'ingreso-retenciones', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-certificado-ingresos-retenciones/informe-certificado-ingresos-retenciones.module#InformeCertificadoIngresosRetencionesModule', data: { nombre: 'CertificadoIngresoRetenciones', reuseRoute: true, url: '/nomina/informes/certificaciones-nomina/ingreso-retenciones' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'laboral', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-certificaciones-nomina/informe-certificaciones-nomina.module#InformeCertificacionesNominaModule', data: { nombre: 'CertificadoLaboral', reuseRoute: true, url: '/nomina/informes/certificaciones-nomina/laboral' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'promedio-salarial', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-promedio-salarial/informe-promedio-salarial.module#InformePromedioSalarialModule', data: { nombre: 'PromedioSalarial', reuseRoute: true, url: '/nomina/informes/certificaciones-nomina/promedio-salarial' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'concepto-nomina', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-certificaciones-nomina/informe-certificaciones-nomina.module#InformeCertificacionesNominaModule', data: { nombre: 'ConceptoNomina', reuseRoute: true, url: '/nomina/informes/certificaciones-nomina/concepto-nomina' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'ausencias-nomina', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-certificaciones-nomina/informe-certificaciones-nomina.module#InformeCertificacionesNominaModule', data: { nombre: 'AusenciasNomina', reuseRoute: true, url: '/nomina/informes/certificaciones-nomina/ausencias-nomina' }, canActivate: [ActiveLicenseGuard] },
                            { path: 'libro-vacaciones', loadChildren: 'apps/wo-frontend/src/app/reporte/pantallasFiltros/informe-libro-vacaciones/informe-libro-vacaciones.module#InformeLibroVacacionesModule', data: { nombre: 'LibroVacaciones', reuseRoute: true, url: '/nomina/informes/certificaciones-nomina/libro-vacaciones' }, canActivate: [ActiveLicenseGuard] }
                        ]
                    }
                ]
            },
        ]
    },
    {
        path: 'radian',
        children: [
            { path: 'eventos-radian', loadChildren: 'apps/wo-frontend/src/app/radian/radian.module#RadianModule', data: { nombre: 'EventosRadian', url: '/radian/eventos-radian' }, canActivate: [ActiveLicenseGuard] },
            { path: 'tercero-aprobador', loadChildren: 'apps/wo-frontend/src/app/radian/aprobacion-radian/aprobacion-radian.module#AprobacionRadianModule', data: { nombre: 'TerceroAprobadorRADIAN', url: '/radian/tercero-aprobador' }, canActivate: [ActiveLicenseGuard] }
        ]
    },

    {
        path: 'documento-soporte',
        children: [
            { path: 'mis-documento-soporte', loadChildren: 'apps/wo-frontend/src/app/documentoSoporte/mis-documento-soporte/mis-documento-soporte.module#MisDocumentoSoporteModule', data: { nombre: 'MisDocumentoSoporte', url: '/documento-soporte/mis-documento-soporte' }, canActivate: [ActiveLicenseGuard] },
            { path: 'documentos-sin-enviar', loadChildren: 'apps/wo-frontend/src/app/facturacionElectronica/factura-electronica-sin-enviar/factura-electronica-sin-enviar.module#FacturaElectronicaSinEnviarModule', data: { nombre: 'DocumentosSinEnviar', url: '/documento-soporte/documentos-sin-enviar' }, canActivate: [ActiveLicenseGuard] },
        ]
    },
    /*  {
            path: 'pos',
            children: [
                {
                    path: 'configuracion',
                    children: [
                        {
                            path: 'puntos-de-venta',
                            children: [
                                { path: '', loadChildren: 'apps/wo-frontend/src/app/pos/puntosDeVenta/puntosDeVenta.module#PuntosVentaModule', data: { nombre: 'PuntosDeVenta', permisoGruesoRequerido: 'puntoDeVenta', url: '/pos/configuracion/puntos-de-venta' }, canActivate: [ActiveLicenseGuard] },
                                { path: 'terminales-pos', loadChildren: 'apps/wo-frontend/src/app/pos/terminales/terminales.module#TerminalesModule', data: { nombre: 'TerminalesPOS', permisoGruesoRequerido: 'terminalesPOS', url: '/pos/configuracion/terminales-pos' }, canActivate: [ActiveLicenseGuard] },
                                { path: 'terminales-pos/:id', loadChildren: 'apps/wo-frontend/src/app/pos/terminales/terminales.module#TerminalesModule', data: { nombre: 'TerminalesPOSID', permisoGruesoRequerido: 'terminalesPOS', url: '/pos/configuracion/terminales-pos' }, canActivate: [ActiveLicenseGuard] }
                            ]
                        }
                    ]
                }
            ]
        },*/
    {
        path: 'soporte',
        data: { nombre: 'Soporte' },
        children: [
            {
                path: 'form-registro',
                loadChildren:
                    '@wo/frontend/soporte/form-registro/form-registro.module#FormRegistroModule',
                data: { nombre: 'FormRegistroSoporte' },
                canActivate: [ActiveLicenseGuard]
            },
            {
                path: 'mis-soportes',
                loadChildren:
                    '@wo/frontend/soporte/mis-soportes/mis-soportes.module#MisSoportesModule',
                data: { nombre: 'MisSoportes' },
                canActivate: [ActiveLicenseGuard]
            },
            {
                path: 'detalle-soporte/:soporteId',
                loadChildren:
                    '@wo/frontend/soporte/detalle-soporte/detalle-soporte.module#DetalleSoporteModule',
                data: { nombre: 'DetalleSoporte', permisoGruesoRequerido: 'Soporte' },
                canActivate: [ActiveLicenseGuard]
            }
        ]
    },

    { path: 'visor', loadChildren: '@wo/frontend/reporte/viewer/viewer.module#ViewerModule', data: { nombre: 'imprimir' } },
    { path: 'visor/:id/:codigo', loadChildren: '@wo/frontend/reporte/viewer/viewer.module#ViewerModule', data: { nombre: 'imprimirIdCodigo' } },
    { path: 'visor/:codigo', loadChildren: '@wo/frontend/reporte/viewer/viewer.module#ViewerModule', data: { nombre: 'imprimirCodigo' } },
    { path: 'visor/id/:codigo/:accion', loadChildren: '@wo/frontend/reporte/viewer/viewer.module#ViewerModule', data: { nombre: 'imprimirIdCodigoAccion' } },
    { path: 'visor/:id/:codigo/:accion/:comando', loadChildren: '@wo/frontend/reporte/viewer/viewer.module#ViewerModule', data: { nombre: 'imprimirIdCodigoAccionComando' } },

    { path: '404', loadChildren: 'apps/wo-frontend/src/app/not-found/not-found.module#NotFoundModule', data: { nombre: 'notFound.titulo' }, canActivate: [ActiveLicenseGuard] },
    { path: '**', redirectTo: '404' }
];
