import { ValidacionPestanaContacto } from "./validacionPopupMas/validacion-pestana-contactos";
import { ValidacionPestanaClasificacion } from "./validacionPopupMas/validacion-pestana-clasificacion";
import { ValidacionPestanaOtros } from "./validacionPopupMas/validacion-pestana-otros-checks";
import { ValidacionPestanaTerceros } from "./validacionPopupMas/validacion-pestana-terceros";
import { ValidacionElaboradoPor } from './validacionPopupMas/validacion-pestana-elaboradoPor';

export class ValidacionBotonMas {

    validacionPestanaContacto: ValidacionPestanaContacto;
    validacionPestanaClasificacion: ValidacionPestanaClasificacion;
    validacionPestanaOtros: ValidacionPestanaOtros;
    validacionPestanaTerceros: ValidacionPestanaTerceros;
    validacionElaboradoPor: ValidacionElaboradoPor;
    validacionFechasSinRef: boolean = false;
    constructor() { }

}
