<div class="colGrid1" style="text-align: justify; margin-bottom: 2%;">
    <span>
        {{'documentoEncabezado.tituloFechasSinRefrencias' | translate}}
    </span>
</div>
<div class="form-inputs mt-2">
    <div class="contGridFluid">
        <div class="colGrid2">
            <div class="form-group date fas fa-calendar-alt">
                <fieldset [disabled]="estaContabilizado">
                    <wo-text-field-seg class="datepicker2" id="fechaIn" [identificacion]="'fechaIni'" name="inputdata2" [disabledLocalImportante]="estaContabilizado"
                        [tipoInput]="'date'" [valorActual]="fechaIni" [pantalla]="'documentoEncabezado'" [disabled]="estaContabilizado"
                        [label]="'documentoEncabezado.fechaInicio'" (activarLabelEmit)="fechaIni=$event"
                        (respuesta)="fechaIni=$event;cambio('FECHA_INICIO_SIN_REFERENCIA_FACTURA',$event)">
                    </wo-text-field-seg>
                </fieldset>
            </div>
        </div>
        <div class="colGrid2">
            <div class="form-group date fas fa-calendar-alt">
                <fieldset [disabled]="estaContabilizado">
                <wo-text-field-seg class="datepicker2" id="fechaFin" [identificacion]="'fechaFin'" name="inputdata2" [disabledLocalImportante]="estaContabilizado"
                    [tipoInput]="'date'" [valorActual]="fechaFin" [pantalla]="'documentoEncabezado'" [disabled]="estaContabilizado"
                    [label]="'documentoEncabezado.fechaFin'" (activarLabelEmit)="fechaFin=$event"
                    (respuesta)="fechaFin=$event;cambio('FECHA_FIN_SIN_REFERENCIA_FACTURA',$event)">
                </wo-text-field-seg>
                </fieldset>
            </div>
        </div>

    </div>
</div>