<div id="contenedorGeneralConf" class="themeCont" [ngClass]="{'themePOS': POS}">
  <div class="titlePrincipal">
    <h1><span class="contTit">{{(this.POS ? 'PANEL.ConfiguracionGeneralPOS' : 'PANEL.ConfiguracionGeneral') |
        translate}}</span></h1>
  </div>
  <div class="tabsVertical">
    <div class="tabContent" *ngFor="let pestana of pestanas">
      <input class="{{pestana.active}} checkTab" type="checkbox">
      <label class="labelMenu"><span><a id="{{pestana.codigo}}Pestana" (click)="cambiarPestana(pestana)"
            [ngClass]="{'noDisponible': !pestana.senDisponible}">{{pestana.nombre}}</a></span></label>
      <div class="tabV">
        <div class="content" *ngIf="mostrarContenidoPestana">
          <div class="tablaInsertHere" id="tablaInsertHere" *ngIf="mostrarTabla"></div>
          <!--insert tabla-->
          <ng-container *ngIf="!mostrarTabla">
            <div *ngFor="let content of contenido">
              <ul class="infoTab"
                *ngIf="content.codigo != 3300 && content.codigo != 3400 && content.codigo != 3600 && content.codigo != 8800">
                <li>
                  <div class="titGrupoTab" id="{{content.codigo}}Contenido"><span>{{content.codigo !== 13001 ? content.nombre : 'Cuentas API'}}</span></div>
                  <div class="form-inputs">
                    <fieldset>
                      <div class="contGridFluid" *ngIf="content.codigo !== 13001">
                        <ul class="colGrid1" *ngFor="let hijos of content.configuracionGenerales">
                          <li *ngIf="hijos.codigo !== 7004">
                            <div class="form-group icon-trianguloDer1" *ngIf="hijos.tipo=='CHECK'">
                              <label for="{{hijos.codigo}}Especifico"
                                class="control-label">{{hijos.descripcion}}</label>
                              <input type="checkbox" id="{{hijos.codigo}}Especifico" name="{{hijos.codigo}}Especifico"
                                [(ngModel)]="hijos.valor"
                                (ngModelChange)="cambio(hijos.id,hijos.valor , 'CHECK',hijos.codigo)"
                                (change)="actualizarChecks(hijos,content.configuracionGenerales)"
                                [disabled]="comprobarDisabled(hijos)">
                              <i class="bar"></i>
                            </div>
                            <div class="form-group icon-trianguloDer1" *ngIf="hijos.tipo=='TEXTDISABLED'">
                              <label for="{{hijos.codigo}}Especifico"
                                class="control-label">{{hijos.descripcion}}</label>
                              <p id="especifico{{hijos.codigo}}" (click)="copiarTexto(hijos)">{{hijos.valor}}</p>
                            </div>
                            <div class="form-group icon-trianguloDer1" *ngIf="hijos.tipo=='SELECT'">
                              <label for="{{hijos.codigo}}Especifico" class="control-label">
                                {{hijos.descripcion}}
                                <span *ngIf="[7003].includes(hijos.codigo)" class="tooltipInfo icoAyuda">
                                  <span class="tooltipIcon" (click)="mostrarAyuda(hijos)"></span>
                                </span>
                              </label>
                              <select ngModel name="{{hijos.codigo}}Especifico" id="{{hijos.codigo}}Especifico"
                                [(ngModel)]="hijos.valor"
                                (ngModelChange)="cambio(hijos.id,hijos.valor.codigo , 'SELECT', null, hijos)">
                                <option class="option" *ngFor="let seleccionado of hijos.valoresDisponibles"
                                  [title]="seleccionado.tooltip" [ngValue]="seleccionado"> {{seleccionado.valor}}
                                </option>
                              </select>
                              <i class="bar"></i>
                            </div>
                            <div class="form-group icon-trianguloDer1" *ngIf="hijos.tipo=='BUTTON'">
                              <label for="{{hijos.codigoid}}Especifico"
                                class="control-label">{{hijos.descripcion}}</label>
                              <button class="buttonYellowS" id="{{hijos.codigo}}Especifico">...</button>
                            </div>
                            <div class="form-group icon-trianguloDer1 REO" *ngIf="hijos.tipo=='CHECKSELECT'">
                              <label for="{{hijos.codigo}}Especifico"
                                class="control-label">{{hijos.descripcion}}</label>
                              <input type="checkbox" id="{{hijos.codigo}}Especifico" name="{{hijos.codigo}}Especifico"
                                [(ngModel)]="hijos.valor" (ngModelChange)="cambio(hijos.id,hijos.valor , 'CHECK')"
                                (change)="actualizarChecks(hijos,content.configuracionGenerales)">
                              <i class="bar"></i>
                              <ng-container *ngIf="hijos.valor">
                                <select class="fechaActual" name="{{hijos.codigo}}Especifico"
                                  id="{{hijos.codigo}}Especifico" [(ngModel)]="hijos.valorCombo"
                                  (ngModelChange)="cambio(hijos.id,hijos.valorCombo.codigo , 'SELECTCHECK')">
                                  <option class="option" *ngFor="let seleccionado of hijos.valoresDisponibles"
                                    [ngValue]="seleccionado"> {{seleccionado.valor}}
                                  </option>
                                </select>
                                <i class="bar iFecha"></i>
                              </ng-container>
                            </div>
                            <div class="form-group icon-trianguloDer1" *ngIf="hijos.codigo==5201">
                              <label for="{{hijos.codigo}}Especifico"
                                class="control-label">{{hijos.descripcion}}</label>
                              <input type="checkbox" id="{{hijos.codigo}}Especifico" name="{{hijos.codigo}}Especifico"
                                [(ngModel)]="hijos.valor" (ngModelChange)="cambio(hijos.id,hijos.valor , 'CHECK')"
                                (change)="actualizarChecks(hijos,content.configuracionGenerales)">
                              <i class="bar"></i>
                              <div class="contButton" *ngIf="hijos.valor">
                                <button id="{{hijos.codigo}}EspecificoButton" class="buttonYellowS"
                                  (click)="abrirPopUpMaximoVendedor()">{{'configuracionGeneral.descuento' |
                                  translate}}</button>
                              </div>
                            </div>
                          </li>
                          <li class="opcionesNuevas" *ngIf="hijos.codigo == 7004">
                            <div style="margin-bottom: 0;" class="form-group icon-trianguloDer1">
                              <label for="{{hijos.codigo}}Especifico" class="control-label"
                                style="margin-right: 0;">{{hijos.descripcion}}</label>
                              <div class="form-inputs colGrid2" style="width: calc(50% + 10px) !important;">
                                <div class="form-group nuevosSelects">
                                  <select-pag-seg id="{{hijos.codigo}}Especifico" [identificacion]="'genero-select'"
                                    [valorActual]="hijos.valor" [columnasRequeridas]="['codigo','nombreCompleto']"
                                    [columnas]="['nombreCompleto']" [entidad]="'Tercero'" [pag]="true"
                                    [registrosPagina]="'10'" [label]="''" [filtro]="filtroARL"
                                    (respuesta)="hijos.valor = $event; cambioTercero(hijos.id,hijos.valor, 'asociarConfiguracionGeneralNominaTercero')">
                                  </select-pag-seg>
                                </div>
                              </div>
                            </div>
                          </li>

                        </ul>

                      </div>
                    </fieldset>

                    <div class="contGridFluid" *ngIf="content.codigo == 13001">
                      <div class="colGrid5">
                        <div class="form-group selectToken">
                          <select-pag-seg id="selectToken" name="selectToken"
                                          [columnas]="['email']" [registrosPagina]="10" [valorActual]="cuentaServicioSelected" [entidad]="'Usuario'"
                                          [filtro]="filtrosCuentaAPI" [atributo]="'email'" [ordenPaginacion]="'DESC'"
                                          [label]="'Cuenta de servicio'" [completo]="mostrarErrores" [pag]="true" [requerido]="false"
                                          (respuesta)="cuentaServicioSelected = $event;cambioCuentaServicio(cuentaServicioSelected)">
                          </select-pag-seg>
                        </div>
                      </div>
                      <div class="colGrid2" >
                        <div class="botonera" style="margin-left: 50px;" >
                          <button id="desc" class="buttonBlueS fas fa-solid fa-plus" [disabled]="activarBtnCrearToken" (click)="generarToken()">
                            <span>Generar Token</span>
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>

                </li>
              </ul>
              <!-- START CONFIGURACIÓN CRUCE DE ANTICIPOS -->
              <div *ngIf="content.codigo == 3600">
                <app-configuracion-cruce-anticipos [data]="content"></app-configuracion-cruce-anticipos>
              </div>
              <!-- END CONFIGURACIÓN CRUCE DE ANTICIPOS -->
            </div>
          </ng-container>

          <div *ngIf="pestana.codigo == 13000 && pestana.senDisponible && pestana.active">
            <div id="listadoTokens" class="tablaUbicar" style="width: 75%;" >
              <div class="contListado">
                <wo-tabla id="listaTokens" altoScroll="40" [tablaEnum]="'WT_CONFIGURACION_LISTADO_TOKEN'"
                          [datos]="contentTokens" [columnas]="columnasToken" [opciones]="opcionesTablaToken"
                          (onClickTable)="onClickTable($event)"
                          [esListado]="true"
                          [paginacion]="paginacionListadoToken"
                          [tituloTabla]="'Listado Tokens' | translate"
                          (afterChange)="afterChange($event)" (clickFinal)="setFinalClick($event)" ></wo-tabla>

                </div>
              <span style="padding-top: 200px;">Conozca los <a href="https://developer.worldoffice.cloud/terminos.html" target="_blank"> términos y condiciones de uso </a></span>
            </div>
          </div>

          <div *ngIf="pestana.codigo==3000 && pestana.senDisponible && pestana.active">
            <div *ngFor="let content of contenido">
              <div *ngIf="content.codigo == 3300 && content.senDisponible">
                <configuracion-general-cuentas></configuracion-general-cuentas>
              </div>
            </div>
          </div>

          <div *ngIf="pestana.codigo==9000 && pestana.senDisponible && pestana.active">
            <configuracion-general-documentos (docAutoCompleto)="docAutoCompleto($event)"
              [mostrarErrores]="mostrarErroresDocAuto">
            </configuracion-general-documentos>
          </div>

          <div *ngIf="pestana.codigo==14000 && pestana.senDisponible && pestana.active">
            <app-configuracion-general-envios></app-configuracion-general-envios>
          </div>

          <div *ngIf="pestana.codigo==2 && pestana.senDisponible && pestana.active">
            <app-configuracion-general-auditoria></app-configuracion-general-auditoria>
          </div>

          <div *ngIf="pestana.codigo==3000 && pestana.senDisponible && pestana.active">
            <div class="contGeneral">
              <div class="contGridFluid">
                <div class="infoTab">
                  <div *ngFor="let content of contenido">
                    <div *ngIf="content.codigo == 3400 && content.senDisponible">
                      <div class="titGrupoTab">
                        <span>{{'CONFIGURACION_GENERAL.nivelDeCuentas' | translate}}</span>
                      </div>
                      <div class="form-inputs colGrid2">
                        <div class="titGrupoTab2">
                          <span>{{'CONFIGURACION_GENERAL.balance' | translate}}</span>
                        </div>

                        <div class="colGrid1">
                          <div class="form-group icon-trianguloDer1">
                            <wo-text-field id="nivel1Balance" [tipoInput]="'number'" name="nivel1Balance"
                              [valorActual]="nivelBalance[0].numeroDigitos" [label]="'CONFIGURACION_GENERAL.nivel1'"
                              (activarLabelEmit)="nivelBalance[0].numeroDigitos=$event"
                              (respuesta)="nivelBalance[0].numeroDigitos=$event;cambioNivel(nivelBalance[0],'balance',$event)"
                              [disabled]="false" [requerido]="true" [maxlength]="2">
                            </wo-text-field>
                          </div>
                        </div>

                        <div class="colGrid1">
                          <div class="form-group icon-trianguloDer1">
                            <wo-text-field id="nivel2Balance" [tipoInput]="'number'" name="nivel2Balance"
                              [valorActual]="nivelBalance[1].numeroDigitos" [label]="'CONFIGURACION_GENERAL.nivel2'"
                              (activarLabelEmit)="nivelBalance[1].numeroDigitos=$event"
                              (respuesta)="nivelBalance[1].numeroDigitos=$event;cambioNivel(nivelBalance[1],'balance',$event)"
                              [disabled]="false" [requerido]="true" [maxlength]="2">
                            </wo-text-field>
                          </div>
                        </div>

                        <div class="colGrid1">
                          <div class="form-group icon-trianguloDer1">
                            <wo-text-field id="nivel3Balance" [tipoInput]="'number'" name="nivel3Balance"
                              [valorActual]="nivelBalance[2].numeroDigitos" [label]="'CONFIGURACION_GENERAL.nivel3'"
                              (activarLabelEmit)="nivelBalance[2].numeroDigitos=$event"
                              (respuesta)="nivelBalance[2].numeroDigitos=$event;cambioNivel(nivelBalance[2],'balance',$event)"
                              [disabled]="false" [requerido]="true" [maxlength]="2">
                            </wo-text-field>
                          </div>
                        </div>

                        <div class="colGrid1">
                          <div class="form-group icon-trianguloDer1">
                            <wo-text-field id="nivel4Balance" [tipoInput]="'number'" name="nivel4Balance"
                              [valorActual]="nivelBalance[3].numeroDigitos" [label]="'CONFIGURACION_GENERAL.nivel4'"
                              (activarLabelEmit)="nivelBalance[3].numeroDigitos=$event"
                              (respuesta)="nivelBalance[3].numeroDigitos=$event;cambioNivel(nivelBalance[3],'balance',$event)"
                              [disabled]="false" [requerido]="true" [maxlength]="2">
                            </wo-text-field>
                          </div>
                        </div>

                        <div class="colGrid1">
                          <div class="form-group icon-trianguloDer1">
                            <wo-text-field id="nivel5Balance" [tipoInput]="'number'" name="nivel5Balance"
                              [valorActual]="nivelBalance[4].numeroDigitos" [label]="'CONFIGURACION_GENERAL.nivel5'"
                              (activarLabelEmit)="nivelBalance[4].numeroDigitos=$event"
                              (respuesta)="nivelBalance[4].numeroDigitos=$event;cambioNivel(nivelBalance[4],'balance',$event)"
                              [disabled]="false" [requerido]="true" [maxlength]="2">
                            </wo-text-field>
                          </div>
                        </div>

                        <div class="colGrid1">
                          <div class="form-group icon-trianguloDer1">
                            <wo-text-field id="nivel6Balance" [tipoInput]="'number'" name="nivel6Balance"
                              [valorActual]="nivelBalance[5].numeroDigitos" [label]="'CONFIGURACION_GENERAL.nivel6'"
                              (activarLabelEmit)="nivelBalance[5].numeroDigitos=$event"
                              (respuesta)="nivelBalance[5].numeroDigitos=$event;cambioNivel(nivelBalance[5],'balance',$event)"
                              [disabled]="false" [requerido]="true" [maxlength]="2">
                            </wo-text-field>
                          </div>
                        </div>

                        <div class="colGrid1">
                          <div class="form-group icon-trianguloDer1">
                            <wo-text-field id="nivel7Balance" [tipoInput]="'number'" name="nivel7Balance"
                              [valorActual]="nivelBalance[6].numeroDigitos" [label]="'CONFIGURACION_GENERAL.nivel7'"
                              (activarLabelEmit)="nivelBalance[6].numeroDigitos=$event"
                              (respuesta)="nivelBalance[6].numeroDigitos=$event;cambioNivel(nivelBalance[6],'balance',$event)"
                              [disabled]="false" [requerido]="true" [maxlength]="2">
                            </wo-text-field>
                          </div>
                        </div>
                      </div>


                      <div class="form-inputs colGrid2">
                        <!-- <div class="titGrupoTab">
                          <span>{{'CONFIGURACION_GENERAL.nivelDeCuentas' | translate}}</span>
                        </div> -->

                        <div class="titGrupoTab2 titMargin">
                          <span>{{'CONFIGURACION_GENERAL.perdidaGanancia' | translate}}</span>
                        </div>

                        <div class="colGrid1">
                          <div class="form-group icon-trianguloDer1">
                            <wo-text-field id="nivel1PyG" [tipoInput]="'number'" name="nivel1PyG"
                              [valorActual]="nivelPyG[0].numeroDigitos" [label]="'CONFIGURACION_GENERAL.nivel1'"
                              [tipoInput]="'number'" (activarLabelEmit)="nivelPyG[0].numeroDigitos=$event"
                              (respuesta)="nivelPyG[0].numeroDigitos=$event;cambioNivel(nivelPyG[0],'PyG',$event)"
                              [disabled]="false" [requerido]="true" [maxlength]="2">
                            </wo-text-field>
                          </div>
                        </div>

                        <div class="colGrid1">
                          <div class="form-group icon-trianguloDer1">
                            <wo-text-field id="nivel2PyG" [tipoInput]="'number'" name="nivel2PyG"
                              [valorActual]="nivelPyG[1].numeroDigitos" [label]="'CONFIGURACION_GENERAL.nivel2'"
                              (activarLabelEmit)="nivelPyG[1].numeroDigitos=$event"
                              (respuesta)="nivelPyG[1].numeroDigitos=$event;cambioNivel(nivelPyG[1],'PyG',$event)"
                              [disabled]="false" [requerido]="true" [maxlength]="2">
                            </wo-text-field>
                          </div>
                        </div>

                        <div class="colGrid1">
                          <div class="form-group icon-trianguloDer1">
                            <wo-text-field id="nivel3PyG" [tipoInput]="'number'" name="nivel3PyG"
                              [valorActual]="nivelPyG[2].numeroDigitos" [label]="'CONFIGURACION_GENERAL.nivel3'"
                              (activarLabelEmit)="nivelPyG[2].numeroDigitos=$event"
                              (respuesta)="nivelPyG[2].numeroDigitos=$event;cambioNivel(nivelPyG[2],'PyG',$event)"
                              [disabled]="false" [requerido]="true" [maxlength]="2">
                            </wo-text-field>
                          </div>
                        </div>

                        <div class="colGrid1">
                          <div class="form-group icon-trianguloDer1">
                            <wo-text-field id="nivel4PyG" [tipoInput]="'number'" name="nivel4PyG"
                              [valorActual]="nivelPyG[3].numeroDigitos" [label]="'CONFIGURACION_GENERAL.nivel4'"
                              (activarLabelEmit)="nivelPyG[3].numeroDigitos=$event"
                              (respuesta)="nivelPyG[3].numeroDigitos=$event;cambioNivel(nivelPyG[3],'PyG',$event)"
                              [disabled]="false" [requerido]="true" [maxlength]="2">
                            </wo-text-field>
                          </div>
                        </div>

                        <div class="colGrid1">
                          <div class="form-group icon-trianguloDer1">
                            <wo-text-field id="nivel5PyG" [tipoInput]="'number'" name="nivel5PyG"
                              [valorActual]="nivelPyG[4].numeroDigitos" [label]="'CONFIGURACION_GENERAL.nivel5'"
                              (activarLabelEmit)="nivelPyG[4].numeroDigitos=$event"
                              (respuesta)="nivelPyG[4].numeroDigitos=$event;cambioNivel(nivelPyG[4],'PyG',$event)"
                              [disabled]="false" [requerido]="true" [maxlength]="2">
                            </wo-text-field>
                          </div>
                        </div>

                        <div class="colGrid1">
                          <div class="form-group icon-trianguloDer1">
                            <wo-text-field id="nivel6PyG" [tipoInput]="'number'" name="nivel6PyG"
                              [valorActual]="nivelPyG[5].numeroDigitos" [label]="'CONFIGURACION_GENERAL.nivel6'"
                              (activarLabelEmit)="nivelPyG[5].numeroDigitos=$event"
                              (respuesta)="nivelPyG[5].numeroDigitos=$event;cambioNivel(nivelPyG[5],'PyG',$event)"
                              [disabled]="false" [requerido]="true" [maxlength]="2">
                            </wo-text-field>
                          </div>
                        </div>

                        <div class="colGrid1">
                          <div class="form-group icon-trianguloDer1">
                            <wo-text-field id="nivel7PyG" [tipoInput]="'number'" name="nivel7PyG"
                              [valorActual]="nivelPyG[6].numeroDigitos" [label]="'CONFIGURACION_GENERAL.nivel7'"
                              (activarLabelEmit)="nivelPyG[6].numeroDigitos=$event"
                              (respuesta)="nivelPyG[6].numeroDigitos=$event;cambioNivel(nivelPyG[6],'PyG',$event)"
                              [disabled]="false" [requerido]="true" [maxlength]="2">
                            </wo-text-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="tablaUbicarID" class="tablaUbicar" *ngIf="mostrarTabla">
  <div class="contListado">
    <div class="s8000Contenido">
      <span>{{'configuracionGeneral.impresiones' | translate}}</span>
    </div>
    <wo-tabla id="configuracionImpresiones" altoScroll="55" [tablaEnum]="'WT_CONFIGURACION_IMPRESIONES'"
      [datos]="contenido" [idElemento]="pestanaImpresiones.id" [columnas]="columnas" [opciones]="opcionesTabla"
      [mostrarPaginador]="false" (onClickTable)="cambioSelect($event)"
      [tituloTabla]="'configuracionGeneral.impresiones' | translate" [paginacion]="paginacion"
      (afterChange)="afterChange($event)" (clickFinal)="setFinalClick($event)" ></wo-tabla>
  </div>
</div>

<app-descuentomaximo-vendedor></app-descuentomaximo-vendedor>
