import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PopoverService } from '@wo/frontend/servicios/popover/popover.service';
import { FiltroWo, Operador, PaginacionWo, SeveridadEnum, TipoDato, TipoFiltro, TipoInput } from '@wo/modelo';
import { FiltroService, FormatoUtilService, PosicionComboService } from '@wo/servicios';
import { Observable, Subject, Subscription } from 'rxjs';
import { ErrorHandlerComponent } from '../error-handler/error-handler.component';
import { RecargarDataService } from '../servicios/recargarData/recargar-data.service';
import { PopUpService } from '../servicios/popUp/pop-up.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: [
    './multiselect.component.css',
    '../select-pag/select-pag.component.css'
  ]
})
export class MultiselectComponent implements OnInit, OnDestroy, OnChanges {
  arrowkeyLocation: number = -1;
  activoActual: boolean = false;
  prueba: boolean = false;
  copiaValores: any;
  tienePaginacion: boolean = false;
  headerTranslate: string = "";
  copiaTotalPages: number;
  posYAI: number = 0;
  posXAI: number = 0;
  todos = { id: -1, nombre: 'Todos', nombreDocumento: 'Todos', cuenta: 'Todas', nombreCompleto: 'Todos', descripcion: 'Todos', valorMulti: false, primerNombre: "Todos", 'inventario.descripcion': 'Todos', 'tercero.nombreCompleto': 'Todos', descripcionNumero:'Todos', }
  filaActualUltima: boolean = false;
  opcion: any;
  seleccionadosTotales;

  subscriptionsRecargarDataService = new Subscription();
  filtrosEmitir: FiltroWo[];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private servicioFiltro: FiltroService,
    private elementRef: ElementRef,
    private errorHandlerComponent: ErrorHandlerComponent,
    private popoverService: PopoverService,
    private posicionComboServise: PosicionComboService,
    private FormatoUtilService: FormatoUtilService,
    public _RecargarDataService: RecargarDataService,
    private popUpService: PopUpService
  ) {
    /*
     let sub4 = this._RecargarDataService.subscribe(this.recargarValores.bind(this));
     this.subscriptionsRecargarDataService.add(sub4); */
  }
  @Input('estaEnLaTabla') estaEnLaTabla = false;
  @Input('dimensionComponente') dimensionComponente;
  @Input('seleccionados') seleccionados = [];
  @Input('opciones') opciones = [];
  @Input('identificador') identificador: string;
  @Input('valores') valores;
  @Input('atributo') atributo;
  @Input('label') label;
  @Input('filtros') filtros;
  @Input('requerido') requerido;
  @Input('columnaActual') columnaActual;
  @Input('columnas') columnas;
  @Input('columnasRequeridas') columnasRequeridas;
  @Input('entidad') entidad;
  @Input('registrosPagina') registrosPagina;
  @Input('paginacion') paginacion: boolean;
  @Input('prefijoVacio') prefijoVacio;
  @Input('prefijoVacioConFiltros') prefijoVacioConFiltros;
  @Input('sinCentroCostos') sinCentroCostos;
  @Input('esReporte') esReporte: boolean;
  @Input('altoPopup') altoPopup: any;
  @Input('posicionY') posicionY: any;
  @Input('posicionYPantalla') posicionYPantalla: any;
  @Input('busquedaChar') busquedaChar = 2;
  @Input('abrirIzquierda') abrirIzquierda = false;
  @Input('dataId') dataId;
  @Input('tablaElement') tablaElement;
  @Input('celdaActual') celdaActual;
  @Input('clase') clase;
  @Input('soloOpcion') soloOpcion: boolean;
  @Input('msjMaxElementos') msjMaxElementos: string;


  @Input('disabled') disabled: boolean;
  @Input('bloquadoLicencia') bloquadoLicencia = false;
  @Input('editable') editable: boolean;
  @Input('scrollMoving') scrollMoving: boolean;

  @Input('anchoMultiSelect') anchoMultiSelect: any = false;
  @Input('newIndex') newIndex: string = "-1";

  @Input('completo') completo: boolean;
  @Input('sinFlecha') sinFlecha: boolean;

  @Input('totalELementos') totalELementos: any;
  @Input('elementoNumero') elementoNumero: any;

  @Input('abrir') abrir: any = false;
  @Input('fila') fila;
  @Input('columna') columna;
  @Input('tablaEnum') tablaEnum;
  @Input('servicioExterno') servicioExterno
  @Input('paginacionServicio') paginacionServicio: { totalElements: number; totalPages: number; };
  @Input('cambia') cambia;

  @Input('onlyBusqueda') onlyBusqueda = false;
  @Input('generarTodos') generarTodos = true;
  @Input('esLista') esLista = true;
  @Input('iniciarTodos') iniciarTodos = false;
  @Input('mostrarValores') mostrarValores: boolean = false;
  @Input('valorResaltado') valorResaltado: any;
  @Input('textoTooltip') textoTooltip: string = '';
  @Input('leftTooltip') leftTooltip: string;
  @Input('paginacionLocal') paginacionLocal: boolean;
  @Input('cargarEntidadDatos') cargarEntidadDatos: boolean;

  todosLosValores: any = [];

  randomOff;
  abrirEnTabla = false;
  todosActivado = false;
  probador = false;
  loading: boolean;
  totalPages: number;
  lenght: number;
  private subscriptions = new Subscription();
  x: number;
  y: number;
  claseDocTipoItem = 'docTipoItem';
  claseDocTipoList = 'docTipoList'
  busquedaValor: any;
  valorTodos = false;
  //estados De seleccion
  primerEstado = false;// caso base, se seleccionan algunas opciones
  segundoEstado = false;// Todos seleccionados
  tercerEstado = false; // Se activa al ser verdadero el estado2 y quitar any seleccion
  CuartoEstado = true; // La lista de seleccionados esta vacía
  //opciones = [];
  auxiliar;
  numero = 0;
  estadoPaginas = new Map();
  cargadoPaginas = new Map();
  busqueda = true;
  paginacionGeneral: PaginacionWo;
  esCargado: boolean = false;
  tipoFiltro = [TipoFiltro.EMPIEZA_CON];

  @Output() respuesta: EventEmitter<any> = new EventEmitter<any>();
  @Output() respuesta2: EventEmitter<any> = new EventEmitter<any>();
  @Output() controlUltimaFila: EventEmitter<any> = new EventEmitter<any>();
  @Output() selecionar: EventEmitter<any> = new EventEmitter<any>();
  @Output() respuestaOpc: EventEmitter<any> = new EventEmitter<any>();
  @Output() cerrarMultiselect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickMultiSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  actualizarFocoTablaEmitterPag: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("multiselectList", { static: true }) multiselectList: ElementRef;
  @ViewChild('inputValor', {static : false}) inputValor: ElementRef;
  subscription: Subscription
  @Output() valorResaltar: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitirClickAbrir: EventEmitter<any> = new EventEmitter<any>();

  cargarDatosContenidoObs = new Subject<any>();

  ngOnInit() {
    const subscribeDatosMultiselect = this._RecargarDataService.subscribeDatosMultiselect.subscribe(datos => {
      this.seleccionados = datos;
    });
    this.subscriptions.add(subscribeDatosMultiselect);
    this.estadoPaginas.set(1, false);
    this.cargadoPaginas.set(1, false);
    this.randomOff = this.FormatoUtilService.stringGen(10);
    /*if (this.esReporte && !this.esLista) {
      this.obtenerTodosLosvalores("r");
    }*/

    if (this.esLista && this.iniciarTodos) {
      // this.seleccionados = this.valores.map(x => Object.assign({}, x));
      this.valores.forEach(element => {
        this.seleccionados.push(element);
        this.seleccionados = [...this.seleccionados];
        this.copiaValores = [];
        this.copiaValores = this.valores;
      });
      // this.respuesta.emit(this.seleccionados);
      // no actualizaba a true
      setTimeout(() => {
        this.valorTodos = true;
      }, 0);
    }

    const observableFiltroEscritura = this.cargarDatosContenidoObs.pipe(debounceTime(500)).subscribe(filtrosAplicados => {
      if (filtrosAplicados.entidad) {
        this.cargarEntidad(filtrosAplicados)
      } else {
        let sub = this.servicioFiltro.getFiltradoPojo(this.paginacionGeneral, this.entidad, filtrosAplicados.datosRequeridos).subscribe(response => {
          this.elementRef.nativeElement.classList.remove('loading');
          this.loading = false;
          this.valores = response.content;
          this.totalPages = response.totalPages;
          if (this.valores.length == 0) {
            this.paginaActual = 0;
          } else {
            this.paginaActual = 1;
          }
        });
        this.subscriptions.add(sub);
      }
    });
    this.subscriptions.add(observableFiltroEscritura);
  }


  estaSeleccionado(v) {
    if (v.id == -1) {
      return true;
    }

    if (this.seleccionados && this.seleccionados.find(x => x.id == v.id)) {
      return true;
    }

    if (this.opciones[0] == -1) {
      let a = this.opciones.findIndex(x => x.id == v.id);
      if (a == -1) {
        return true;
      }
    }
    else if (this.opciones[0] != -1) {
      let a = this.opciones.findIndex(x => x.id == v.id);
      if (a != -1) {
        return true;
      }
    }
    return false;
  }

  estaTodos() {
    if (this.seleccionados) {
      if (this.seleccionados.find(x => x.id == -1) != undefined) {
        this.valorTodos = true;
        return true;
      }
    }
    this.valorTodos = false;
    return false;

  }


  esObligatorio(v) {
    let index = this.seleccionados.findIndex(x => x.id == undefined);
    if (index !== -1) {
      this.seleccionados.splice(index, 1);
      this.seleccionados = [...this.seleccionados];
    }

    if (this.requerido != undefined) {
      if (this.requerido) {
        if (this.seleccionados.length == 1) {
          return true;
        }
      }
    }
    return false;
  }

  gestionarEstadoPaginas(valor) {
    for (var i = 1; i <= this.totalPages; i++) {
      this.estadoPaginas.set(i, valor);
    }
  }

  /*
    - Para seleccionar elementos sin la opcion "EsReporte" es necesario agregar el elemento de respuesta del multiselect al Array de Seleccionados y hacerle Object.assign.
    - El elemento retorna el atributo "seleccionado" y debe entenderse como el estado previo al click, entiendase mejor como un "¿Estaba seleccionado?; así si retorna un seleccionado = true, esto indica que el elemento se encontraba seleccionado previamente y se acabó de quitar".
  */
  cambiaSelect(v, todos?) {
    if (!this.estaSeleccionado(v) && this.seleccionados && this.seleccionados.length == this.totalELementos) {
      const mensaje = this.msjMaxElementos ? this.msjMaxElementos : `El valor máximo de elementos seleccionados permitidos es: ${this.totalELementos}`;
      this.popUpService.open({ codigoError: mensaje, severidad: SeveridadEnum.ERROR, mensajes: this.msjMaxElementos ? false : true });
      return;
    }
    if (this.generarTodos) {
      if (this.editable == true || this.editable == undefined) {
        //opcion selecionada TODOS
        if (todos !== undefined && todos !== null) {
          if (!todos === false) {
            //Quitar Todos
            this.valorTodos = false;
            this.selecionar.emit({ opcion: "quitarTodos", valor: this.todos, lista: this.seleccionados });
            this.opcion = "quitarTodos";
          } else {
            //Poner Todos
            this.valorTodos = true;
            this.selecionar.emit({ opcion: "ponerTodos", valor: this.todos, lista: this.seleccionados });
            this.opcion = "ponerTodos";
          }
        } else {
          //opcion elemento normal
          if (this.estaSeleccionado(v) || this.valorTodos) {
            if (this.valorTodos) {
              //Todos esta activado quita el todos y deja toda la lista sin el elemento
              this.selecionar.emit({ opcion: "quitarEyT", valor: v, lista: this.seleccionados })
              this.opcion = "quitarEyT";
              let index = this.seleccionados.findIndex(x => x.id == v.id);
              if (index != -1) {
                if (!this.esReporte) {
                  this.seleccionados.splice(index, 1);
                  this.seleccionados = [...this.seleccionados];
                };
              }
              setTimeout(() => {
                this.valorTodos = false;
              }, 0);
            } else {
              let valido = true;
              if (this.esObligatorio(v) && !this.esReporte) {
                valido = false;
              }
              //Quita el elemento si es valido
              if (valido) {
                this.selecionar.emit({ opcion: "quitarElemento", valor: v, lista: this.seleccionados })
                this.opcion = "quitarElemento";
              }

            }
          } else {

            //Agrega el elemento
            this.selecionar.emit({ opcion: "ponerElemento", valor: v, lista: this.seleccionados })
            this.opcion = "ponerElemento";
          }
        }
      }


      if (this.esReporte) {
        this.soloOpcion;
        let index;
        switch (this.opcion) {
          case "quitarTodos":

            this.opciones = [];
            //this.opciones.push(-5);
            this.numero = 0;
            this.CuartoEstado = true;
            this.primerEstado = false;
            this.segundoEstado = false;
            this.tercerEstado = false;
            this.gestionarEstadoPaginas(false);
            if (this.esLista == false) {
              this.seleccionados = [];
            } else {
              this.seleccionados.splice(0, this.seleccionados.length);
              this.seleccionados = [...this.seleccionados];
            }
            this.respuesta.emit(this.seleccionados);
            this.respuesta2.emit(this.opciones);

            break;
          case "ponerTodos":
            this.primerEstado = false;
            this.segundoEstado = true;
            this.tercerEstado = false;
            this.CuartoEstado = false;
            this.gestionarEstadoPaginas(true);
            this.opciones = [];
            this.seleccionados = [];
            this.numero = this.lenght;
            index = this.seleccionados.findIndex(x => x.id == -1);
            if (this.esLista == false) {
              this.obtenerTodosLosvalores(v.id);

            } else {
              this.valores.forEach(element => {
                this.seleccionados.push(element);
              });
              this.opciones.push(-1);
              this.respuesta.emit(this.seleccionados);
              this.respuesta2.emit(this.opciones);
              // no actualizaba a true
              setTimeout(() => {
                this.valorTodos = true;
              }, 0);
              this.respuesta.emit(this.seleccionados);
              this.respuesta2.emit(this.opciones);
            }

            break;
          case "quitarEyT":
            if (this.entidad != undefined) this.obtenerTodosLosvalores("r");
            if (this.segundoEstado) {
              this.segundoEstado = false;
              this.tercerEstado = true;
              this.primerEstado = false;
              this.CuartoEstado = false;
              this.opciones.push(v);
              this.numero = this.numero - 1;
            }
            index = this.seleccionados.findIndex(x => x.id == v.id);
            if (index != -1) {

              this.seleccionados.splice(index, 1);
            }
            this.valorTodos = false;
            this.estadoPaginas.set(this.paginaActual, false);
            this.respuesta.emit(this.seleccionados);
            this.respuesta2.emit(this.opciones);
            break;
          case "quitarElemento":
            if (this.primerEstado) {
              index = this.opciones.findIndex(x => x.id == v.id);
              this.opciones.splice(index, 1);
              this.numero = this.numero - 1;
              if (this.numero == 0) {
                this.CuartoEstado = true;
                this.primerEstado = false;
                this.segundoEstado = false;
                this.tercerEstado = false;
                this.opciones = [];
                //this.opciones.push(-5);
              }
            }
            else if (this.tercerEstado) {
              this.opciones.push(v);
              this.numero = this.numero - 1;
              if (this.numero == 0) {
                this.opciones = [];
                this.CuartoEstado = true;
                this.primerEstado = false;
                this.segundoEstado = false;
                this.tercerEstado = false;
              }
            }
            index = this.seleccionados.findIndex(x => x.id == v.id);
            if (index != -1) {

              this.seleccionados.splice(index, 1)
            }
            this.estadoPaginas.set(this.paginaActual, false);
            this.respuesta.emit(this.seleccionados);
            this.respuesta2.emit(this.opciones);
            break;
          case "ponerElemento":
            if (this.CuartoEstado) {
              this.primerEstado = true;
              this.segundoEstado = false;
              this.tercerEstado = false;
              this.CuartoEstado = false;
              this.opciones.push(v);
              this.numero = this.numero + 1;

              if (this.numero == this.lenght) {
                this.segundoEstado = true;
                this.primerEstado = false;
                this.tercerEstado = false;
                this.CuartoEstado = false;
                this.opciones = [];
                this.opciones.push(-1);
                this.valorTodos = true;
              }

              if (this.soloOpcion && this.opciones.length > 1) {
              }

            } else if (this.primerEstado) {
              this.opciones.push(v);
              this.numero = this.numero + 1;
              if (this.numero == this.lenght) {
                this.segundoEstado = true;
                this.primerEstado = false;
                this.tercerEstado = false;
                this.CuartoEstado = false;
                this.opciones = [];
                this.opciones.push(-1);
                this.valorTodos = true;
              }
            } else if (this.tercerEstado) {
              index = this.opciones.findIndex(x => x.id == v.id);
              this.opciones.splice(index, 1);
              this.numero = this.numero + 1;
              if (this.numero == this.lenght) {
                this.segundoEstado = true;
                this.primerEstado = false;
                this.tercerEstado = false;
                this.CuartoEstado = false;
                this.opciones = [];
                this.opciones.push(-1);
                this.valorTodos = true;
              }
            }
            let a = this.seleccionados.push(v);
            this.respuesta.emit(this.seleccionados);
            this.respuesta2.emit(this.opciones);
            break;
          default:
            break;
        }
        if (!this.esReporte) {
          this.estaTodos();
        }
      }
    } else {
      if (this.editable == true || this.editable == undefined) {
        let valido = true;
        if (this.seleccionados) {
          if (this.estaSeleccionado(v)) {
            if (this.esObligatorio(v) && !this.esReporte) {
              valido = false;
              this.abrir = false;
            } else {
              v.seleccionado = true;
            }
          } else {
            v.seleccionado = false;
          }
        }
        if (valido) {
          if (v.nombreDocumento || v.nombre) {
            if (v.id === -1) {
              const paginacion = new PaginacionWo(this.columnas[0]);
              paginacion.orden = 'ASC';
              paginacion.pagina = this.paginaActual - 1;
              paginacion.registrosPorPagina = 0;
              let col = this.columnasRequeridas
                ? this.columnasRequeridas
                : this.columnas;
              if (this.filtros != undefined) {
                paginacion.filtros = this.filtros;
              }
              let sub = this.servicioFiltro.getFiltradoPojo(paginacion, this.entidad, col).subscribe(
                response => {
                  let valoresAenviar: any = response.content
                  if (v.nombre) {
                    valoresAenviar.push({ id: -1, nombre: 'Todos' });
                  } else {
                    valoresAenviar.push({ id: -1, nombreDocumento: 'Todos' });
                  }
                  //  if(this.sinCentroCostos==true) {
                  //   this.valores.unshift("Registros sin Centros de Costos")
                  // }
                  if (this.prefijoVacio === true) {
                    if (this.prefijoVacioConFiltros === true) {
                      this.valores.unshift({ id: 1, nombre: "Sin Prefijo" })
                    } else {
                      this.valores[0].nombre = "Sin Prefijo"
                    }
                  }
                  this.respuesta.emit(valoresAenviar)
                });
              this.subscriptions.add(sub);
            } else {
              this.respuesta.emit(v);
            }
          } else {
            this.respuesta.emit(v);
          }
        }
      }

    }
  }

  obtenerTodosLosvalores2(v): any {
    this.disabled = true;
    this.loading = true;
    const paginacion = new PaginacionWo(this.columnas[0]);
    paginacion.orden = 'ASC';
    paginacion.pagina = 0;
    paginacion.registrosPorPagina = 0;
    let col = this.columnasRequeridas
      ? this.columnasRequeridas
      : this.columnas;
    if (this.filtros != undefined) {
      paginacion.filtros = this.filtros;
    }
    let sub = this.servicioFiltro.getFiltradoPojo(paginacion, this.entidad, col).subscribe(
      response => {
        let index;
        this.disabled = false;
        this.seleccionados = response.content;
        this.seleccionados.push(this.todos);
        this.seleccionados = [...this.seleccionados];
        this.valorTodos = true;
      },
      error => {
        this.disabled = false;
        this.errorHandlerComponent.handler(error);
      }
    );
  }



  obtenerTodosLosvalores(v): any {
    if (this.seleccionadosTotales) {
      this.seleccionados.push(this.todos);
      this.seleccionados = this.seleccionadosTotales;
      this.valorTodos = true;
      this.seleccionados = [...this.seleccionados]; let todos = new Array();
      todos.push(this.todos);
      //this.respuesta.emit(todos);//linea_390
    } else {
      this.disabled = true;
      this.loading = true;
      const paginacion = new PaginacionWo(this.columnas[0]);
      paginacion.orden = 'ASC';
      paginacion.pagina = 0;
      paginacion.registrosPorPagina = 0;
      let col = this.columnasRequeridas
        ? this.columnasRequeridas
        : this.columnas;
      if (this.filtros != undefined) {
        paginacion.filtros = this.filtros;
      }
      let sub = this.servicioFiltro.getFiltradoPojo(paginacion, this.entidad, col).subscribe(
        response => {
          let index;
          this.disabled = false;
          this.seleccionadosTotales = response.content;
        },
        error => {
          this.disabled = false;
          this.errorHandlerComponent.handler(error);
        }
      );
    }
  }

  estaEnLista(elemento) {
    let index;
    index = this.seleccionados.findIndex(x => x.id == elemento.id);
    if (index != -1) {
      return true;
    }
    return false;
  }

  comprobacion(valor) {
    if (this.opciones[0] == -1) {
      let a = this.opciones.findIndex(x => x.id == valor.id);
      if (a == -1) {
        return true;
      }
    }
    else if (this.opciones[0] != -1) {
      let a = this.opciones.findIndex(x => x.id == valor.id);
      if (a != -1) {
        return true;
      }
    }
    return false;
  }

  cargarEntidad(abrirSi?) {
    this.esCargado = true;
    if (this.disabled != true) {
      this.loading = true;
      const paginacion = new PaginacionWo(this.columnas[0]);
      paginacion.orden = 'ASC';
      paginacion.pagina = this.paginaActual - 1;
      paginacion.registrosPorPagina =
        this.paginacion == true ? this.registrosPagina : 0;
      let col = this.columnasRequeridas
        ? this.columnasRequeridas
        : this.columnas;
      if (this.filtros != undefined) {
        if (this.paginacionGeneral != undefined)
          paginacion.filtros = this.paginacionGeneral.filtros;
        else
          paginacion.filtros = this.filtros;
      }
      if (this.entidad != undefined) {
        let sub = this.servicioFiltro.getFiltradoPojo(paginacion, this.entidad, col).subscribe(
          response => {

            this.loading = false;
            this.valores = response.content;
            if (this.entidad.toString().toLowerCase().includes('prefijo')) {
              const opcionSinPrefijo = this.valores.find(dato => dato.id == 1 && dato.nombre == '');
              if (opcionSinPrefijo) {
                opcionSinPrefijo.nombre = 'Sin prefijo';
              }
            }

            if (this.valorTodos || this.estadoPaginas.get(this.paginaActual)) {
              this.valores.forEach(element => {
                if (!this.estaEnLista(element)) {
                  if (this.comprobacion(element))
                    this.seleccionados.push(element);
                  this.seleccionados = [...this.seleccionados];
                }
              });
            }

            this.copiaValores = Object.assign([], this.valores);
            this.totalPages = response.totalPages;
            this.copiaTotalPages = response.totalPages;
            //this.iniciarEstadoPaginas(this.totalPages);
            this.lenght = response.totalElements;
            if (this.totalPages > 1) {
              this.tienePaginacion = true;
            }
            // if(this.sinCentroCostos===true) {
            //   this.valores.unshift({nombre:"Registros sin Centros de Costos"})
            // }
            if (this.prefijoVacio === true) {
              if (this.prefijoVacioConFiltros === true) {
                this.valores.unshift({ id: 1, nombre: "Sin Prefijo" })
              } else {
                this.valores[0].nombre = "Sin Prefijo"
              }
            }
            if (this.y == undefined) {
              this.y = this.calcularPosicionY();
            }
            if (this.label == 'tercero.principal.responsabilidadFiscal') {
              this.y = -150;
            }
            if (abrirSi !== undefined) {
              this.abrir = abrirSi;
            } else {
              this.abrir = true;
              setTimeout(() => {
                this.x = this.calcularPosicionX();
              }, 200);
            }
          },
          error => {
            this.errorHandlerComponent.handler(error);
          }
        );
      }

    }

  }
  // cargarEntidadEnTabla(){
  //   const paginacion = new PaginacionWo(this.columnas[0]);
  //       paginacion.orden = 'ASC';
  //       paginacion.pagina = 1;
  //       paginacion.registrosPorPagina =
  //         this.paginacion == true ? this.registrosPagina : 0;
  //       let col = this.columnasRequeridas
  //         ? this.columnasRequeridas
  //         : this.columnas;
  //       if (this.filtros != undefined) {
  //         if(this.paginacionGeneral!=undefined)
  //           paginacion.filtros = this.paginacionGeneral.filtros;
  //         else
  //           paginacion.filtros = this.filtros;
  //       }
  //   let sub = this.servicioFiltro.getFiltradoPojo(paginacion, this.entidad, col).subscribe(
  //     response => {

  //       this.loading = false;



  //     }
  //   );
  // }

  calcularPosicionX(): any {
    let componenteFieldset: any = document.querySelector("#multiselectList");
    let dimensionFieldset = this.posicionComboServise.obtenerDimensionObjeto(componenteFieldset);
    let coordenadasFielset = this.posicionComboServise.obtenerCordenadasDesdeObjeto(componenteFieldset, null);
    let componenteSobreInsertar = this.elementRef.nativeElement;
    let dimensionComponenteSobreInsertar = this.posicionComboServise.obtenerDimensionObjeto(componenteSobreInsertar);
    let coordenadasComponenteSobreInsertar = this.posicionComboServise.obtenerCordenadasDesdeObjeto(componenteSobreInsertar, null);
    let tamaPopup = this.posicionComboServise.obtenerUltimoElementoContenedor(this.elementRef.nativeElement);
    let dimensionPopup = this.posicionComboServise.obtenerDimensionObjeto(tamaPopup);
    const cercaniaPantalla = (coordenadasComponenteSobreInsertar.x + dimensionFieldset.width) / dimensionPopup.width;
    // Se cambia de 0.98 a 0.96 ya que en algunas pantallas no funciona
    if (cercaniaPantalla > 0.96) {
      const posicion = dimensionComponenteSobreInsertar.width - dimensionFieldset.width;
      if (posicion > -10) {
        return 0;
      } else {
        const x = coordenadasComponenteSobreInsertar.x;
        const width = dimensionFieldset.width + 20;
        const tamPopupWidth = dimensionPopup.width;
        const cercaniaNueva = (x + width) / tamPopupWidth;
        if (cercaniaNueva > 0.96) {
          return posicion;
        }
        return tamPopupWidth - (x + width);
      }
    } else {
      return 0;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.abrir) {
      if (changes.abrir.currentValue) {
        this.abrir = false;
        this.cargarEntidad();
      }
    }

    if (changes.seleccionados) {
      if (changes.seleccionados.currentValue) {
        if (changes.seleccionados.currentValue.length == 0) {
          this.valorTodos = false;
          this.seleccionados = [];
          this.opciones = [];
          this.numero = 0;
          this.CuartoEstado = true;
        } else
          if (changes.seleccionados.currentValue[0].id == -1 && changes.seleccionados.currentValue.length == 1 && !this.esReporte) {
            this.valorTodos = true;
            this.seleccionados.push(this.todos);
            this.seleccionados = this.seleccionadosTotales;
            this.seleccionados = [...this.seleccionados];
          }
        if (changes.seleccionados.currentValue.length > 1 && this.esReporte) {
          this.numero = changes.seleccionados.currentValue.length;
        }
      }
    }

    if (changes.editable) {
      this.editable = changes.editable.currentValue;
      if (this.editable == false) {
        this.claseDocTipoItem = 'docTipoItem blockMulti';
        this.claseDocTipoList = 'docTipoList blockMulti';
      }
    }

    if (changes.disabled) {
      this.disabled = changes.disabled.currentValue;
    }

    if (changes.scrollMoving) {
      this.validateCloseByScroll();
    }

    if (changes.filtros && !changes.filtros.firstChange) {
      this.cargarEntidad(false);
    }

    if (changes.celdaActual) {

      if (changes.celdaActual.currentValue.incompleta) {
        this.seleccionados = [];
      }

      if (changes.celdaActual.currentValue.ultimaFila) {
        this.filaActualUltima = true;
      } else {
        this.filaActualUltima = false;
      }

      if (!this.esReporte) {
        this.estaTodos();
      }

    }

    if (changes.cambia) {
      if (!changes.cambia.firstChange) {
        this.valorTodos = true;
      }
    }

    if (this.numero == 0 && this.seleccionados != undefined && this.seleccionados != null) {
      if (this.seleccionados.length > 0) {
        this.mostrarValores = true;
      }
    }

    if (changes.valores) {
      if (!this.entidad && this.paginacionLocal) {
        this.totalPages = 1;
        this.paginacionServicio = { totalElements: 0, totalPages: 1 };
        this.paginaActual = 1;
        if (changes.valores.currentValue && changes.valores.currentValue.length > 0) {
          this.todosLosValores = [...changes.valores.currentValue];
          this.totalPages = Math.ceil(changes.valores.currentValue.length / (this.registrosPagina ? this.registrosPagina : 10));
          this.paginacionServicio = { totalElements: changes.valores.currentValue.length, totalPages: this.totalPages };
          this.valores = this.todosLosValores.filter((e, i) => i < this.registrosPagina);
        }
      }
    }
  }

  /**@param event
   * el metodo se encarga de obtener el path dado un evento, esto dado que no todos los navegadores soportan
   * una misma forma de obtener el path  lo cual puede generar errores en algun navegador
   */
  getEventPath(event) {
    if (event.path) {
      // soportado por google chrome

      return event.path;
    } else if (event.composedPath && event.composedPath()) {
      // soportado por firefox

      return event.composedPath();
    } else {
      // para internet explorer se arma el path manualmente

      let path = [];
      let target = event.target;
      while (target.parentNode) {
        path.push(target);
        target = target.parentNode;
      }
      path.push(document, window);
      return path;
    }
  }

  public onClick(event) {
    if (event) {
      const PATH = this.getEventPath(event);
      const path = Array.prototype.slice.call(PATH)
      let validoId = false;
      let validoElement = false;
      let cerrarBoton = false;

      if (path.find(x => x.id == "botonCerrarMulti")) {
        cerrarBoton = true;
      }

      if (path.indexOf(this.elementRef.nativeElement) !== -1) {
        validoElement = true;
      }

      if (path.find(x => x.id == "multiselectLabel")) {
        validoId = true;
      }
      if (this.estaEnLaTabla) {
        if (!cerrarBoton) {
          if (path.contains(this.elementRef.nativeElement)) {
            validoElement = true;
          }
          if (path.find(x => x.id == "multiselectLabel")) {
            validoId = true;
          }
          if (this.abrir) {

            if (!validoId && !validoElement) this.abrir = false;

            if (validoId) this.limpiarBusqueda();

            validoElement ? this.abrirIzquierdaEncabezados() : this.abrir = false;

          } else {

            if (!validoElement) this.abrir = false

            if (validoId) {
              this.limpiarBusqueda();
              this.cambiaPagina(1, true)
            }
          }
        }
      } else {
        if (this.abrir) {
          if (!validoId && !validoElement) {
            this.busquedaValor = "";
            this.busquedaFiltro("", false);
            this.abrir = false;
            this.cerrarMultiselect.emit(true);
          }
        }
      }
    }
  }

  keyControl(event: KeyboardEvent) {

    //down arrow
    if (event.keyCode == 40) {
      if (this.arrowkeyLocation < this.valores.length - 1) {
        this.arrowkeyLocation++;
      }
    }
    //up arrow
    if (event.keyCode == 38) {
      if (this.arrowkeyLocation > 0) {
        this.arrowkeyLocation--;
      }
    }
    //tab y esc
    if (event.keyCode === 9 || event.keyCode === 27) {
      this.abrir = false;
      this.arrowkeyLocation = -1;
    }

    //arrow right
    if (event.keyCode === 39) {
      this.arrowkeyLocation = 0;
      this.cambiaPagina(1);
    }
    //arrow left
    if (event.keyCode === 37) {
      this.arrowkeyLocation = 0;
      this.cambiaPagina(-1);
    }

  }

  enter(event: KeyboardEvent) {
    if (this.abrir) {
      let trs = Array.prototype.slice.call(document.getElementsByClassName("trMultiselect-" + this.atributo))
      trs[this.arrowkeyLocation].getElementsByTagName("a")[0].click();
    } else {
      this.abrir = true;
      let sub = this.popoverService.subscribe(this.onClick.bind(this));
      this.subscriptions = new Subscription();
      this.subscriptions.add(sub);
    }
  }

  esc() {
    this.abrir = false;
    this.subscriptions.unsubscribe();
  }

  validateCloseByScroll() {
    if (this.scrollMoving && this.abrir === true) {
      this.abrir = false;
    }
  }

  calcularPosicionY() {
    if ((this.posicionY || this.posicionYPantalla) && this.valores) {
      let tamaBody = document.body.clientHeight - 40;
      let altoRenglonBox = 24;
      let tamaListSelect = this.valores.length * altoRenglonBox + 10;
      var intoPopup = true;
      if (this.altoPopup) {
        tamaBody = this.altoPopup - 40;
      }

      let tamaBoxAbierto = tamaListSelect + this.posicionY;

      if (this.posicionYPantalla) {
        intoPopup = false;
        tamaBoxAbierto = tamaListSelect + this.posicionYPantalla;
      }

      if (intoPopup) {
        if (tamaBoxAbierto > tamaBody) {
          return this.posicionY - tamaListSelect - 31;
        } else {
          return this.posicionY;
        }
      }

      // if(this.atributo == 'terceroTipos')return this.posicionYPantalla - tamaListSelect - 30;
      if (tamaBoxAbierto > tamaBody) {
        return this.posicionYPantalla - tamaListSelect - 30;

      } else {
        return this.posicionYPantalla - 5;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscriptionsRecargarDataService.unsubscribe();
  }

  paginaActual: number = 1;
  @Output() cambioPestana: EventEmitter<any> = new EventEmitter<any>();
  @Output() cambioBuscador: EventEmitter<any> = new EventEmitter<any>();
  //Cuando se cambia de pagina, se verifica que no se salga del rango
  cambiaPagina(valor, filtro?) {
    this.arrowkeyLocation = 0;
    if (!this.entidad && this.paginacionLocal) {
      this.paginaActual += valor;
      if (this.paginaActual <= this.totalPages && this.paginaActual > 0) {
        this.valores = this.todosLosValores.filter((e, i) => i < this.registrosPagina * this.paginaActual && i >= this.registrosPagina * (this.paginaActual - 1));
      } else {
        this.paginaActual -= valor;
      }
    } else {
      if (filtro) {
        this.paginaActual = 1;
        this.cargarEntidad();
      } else {
        this.paginaActual += valor;

        if (this.paginaActual <= this.totalPages && this.paginaActual > 0) {
          if (this.entidad) {

            this.cargarEntidad();
          } else {
            this.cambioPestana.emit(this.paginaActual);
          }
        } else {
          this.paginaActual -= valor;
        }
      }
    }
  }

  abrirMultiselectClick(event) {
    this.onClickMultiSelect.emit(true)
    if (this.servicioExterno) {
      if (!this.abrir) this.emitirClickAbrir.emit();
      if (this.paginacionServicio) this.totalPages = this.paginacionServicio.totalPages
    }
    if (this.bloquadoLicencia === true || this.disabled === true) {
      if (this.bloquadoLicencia === true) this.popUpService.mensaje.next('noDisponible');
      return;
    };
    if(this.cargarEntidadDatos){
      this.cargarEntidad();
    }
    this.randomOff = this.FormatoUtilService.stringGen(10);
    if (!this.estaEnLaTabla) {

      if (!event.target.classList.contains("buttonX")) {
        if (!this.abrir) {
          let sub = this.popoverService.subscribe(this.onClick.bind(this));
          this.subscriptions = new Subscription();
          this.subscriptions.add(sub);
          if (this.entidad && this.valores == undefined) {
            this.paginaActual = 1;
            this.cargarEntidad();
          } else {
            this.abrir = true;
            this.lenght = this.valores.length
          }
        } else {
          this.abrir = false;
        }
      }
    }
  }


  busquedaFiltro(valor, abrir) {
    if (!valor) {
      this.elementRef.nativeElement.classList.remove('loading');
    }

    if (valor.length == 0 && this.esCargado) {
    } else if (valor.length <= 1) {
      this.paginaActual = 1;
      this.paginacionGeneral = undefined;
      this.cargarDatosContenidoObs.next({ abrir, entidad: true });
      if (this.servicioExterno) {
        this.cambioBuscador.emit("");
      }
    } else if (1 <= valor.length) {
      let filtros: Array<FiltroWo> = [];
      this.paginacionGeneral = new PaginacionWo('');
      this.paginacionGeneral.registrosPorPagina = this.registrosPagina;
      this.paginacionGeneral.pagina = this.paginaActual - 1;
      this.paginacionGeneral.orden = 'ASC';
      const columnas = this.columnasRequeridas ? this.columnasRequeridas : this.columnas;
      let datosRequeridos = columnas;
      columnas.forEach((element) => {
        const array = element && element.includes('.') ? element.split('.') : [];
        const filtroId = array && array.length > 0 ? array.some(e => e == 'id') : false;
        if (!filtroId) {
          if (element === "codigo") {
            let atributo = element;
            let tipoDato = TipoDato.STRING;
            let tipoFiltro = TipoFiltro.EMPIEZA_CON;
            let clase = null;
            let valores = null;
            let filtro: FiltroWo = new FiltroWo(atributo, valor, null, tipoFiltro, tipoDato, null, valores, clase, Operador.OR);
            filtros.push(filtro);
          } else {
            let atributo = element;
            let tipoDato = TipoDato.STRING;
            let tipoFiltro = TipoFiltro.CONTIENE;
            let clase = null;
            let valores = null;
            let filtro: FiltroWo = new FiltroWo(atributo, valor, null, tipoFiltro, tipoDato, null, valores, clase, Operador.OR);
            filtros.push(filtro);
          }
        }
      }
      );

      if (this.filtros != undefined) {
        if (this.filtros.forEach) {
          this.filtros.forEach(element => {
            filtros.push(element);
          });
        }
      }

      if (this.servicioExterno) {
        this.cambioBuscador.emit(filtros)
      } else {
        if (this.esLista != false) {
          this.paginacionGeneral.filtros = filtros;
          this.paginacionGeneral.columnaOrdenar = "id";
          this.elementRef.nativeElement.classList.add('loading');
          this.cargarDatosContenidoObs.next({ datosRequeridos });
        } else {
          let auxDatos = [];
          this.valores.forEach(dato => {
            this.columnas.forEach(column => {
              if (
                dato[column]
                  .toString()
                  .toLowerCase()
                  .includes(valor.toString().toLowerCase())
              ) {
                auxDatos.push(dato);
              }
            });
          });
          this.valores = auxDatos.slice();
        }
      }
      this.esCargado = false;
    } else {
      this.valores = this.copiaValores;
      this.totalPages = this.copiaTotalPages;
    }
  }


  limpiarBusqueda() {
    this.busquedaValor = "";
  }

  abrirIzquierdaEncabezados() {
    if (this.abrirIzquierda) {
      let elementoMultiselect = this.elementRef.nativeElement
      let elemento = document.getElementsByClassName("divMulti-" + this.dataId)[0];
      let coords = elemento.getBoundingClientRect();
      if (this.posYAI == 0 && this.posXAI == 0) {
        let alto = coords.top;
        let multiselectwidth = document.getElementsByClassName(this.clase)[0].clientWidth;
        let correccion = (multiselectwidth + coords.left) - (elemento.clientWidth);
        this.posYAI = alto;
        this.posXAI = correccion;
      }
      let x = (coords as any).x + coords.width;
      let anchoPantalla = window.innerWidth;
      if (anchoPantalla < x) {
        elemento.classList.add("abrirIzquierdaEl");
        elemento.setAttribute("style", "top:" + 0 + "px !important; left:" + 0 + "px !important")
        elemento.setAttribute("style", "top:" + this.posYAI + "px !important; left:" + this.posXAI + "px !important")
      }
    }
  }

  recargarValores(e) {
    if (e.tipoCampo === TipoInput.MULTISELECT) {
      this.seleccionados = e.informacion;
    }
  }

  valorSelResaltar(e) {
    if (!e.seleccionado && (!this.valorResaltado || (this.valorResaltado != e && !this.valorResaltado.id || (this.valorResaltado.id != e.id)))) {
      this.valorResaltar.emit(e);
    }
  }

}
