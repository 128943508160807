import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DateAdapter, MatDatepicker, MatDatepickerInputEvent, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DatePickerComponent } from '../date-picker.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MMMM/YYYY',
  },
  display: {
    dateInput: 'MMMM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-month-picker',
  templateUrl: './../date-picker.component.html',
  styleUrls: ['./../date-picker.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
  ],
})
export class MonthPickerComponent extends DatePickerComponent implements OnInit {

  component = 'MonthPickerComponent';

  @Input('faltante') faltante;
  @Input('valorActual') valorActual;
  @Input('minDate') minDate;
  @Input('maxDate') maxDate;
  @Input('noPermiteEscritura') noPermiteEscritura = false;

  constructor() {
    super();
  }

  eventoCambioFecha(event: MatDatepickerInputEvent<any>) {
    if (this.requerido) {
      this.edicion.emit(null);
    } else {
      if (event.value == null || event.value == undefined) {
        event.value = {};
        event.value._d = new Date();
        this.valorFechaMaterial = event.value._d;
      }
      this.llenarFecha(event.value);
    }

  }

  llenarFecha(event: any) {
    let año = event._d.getFullYear();
    let mes = event._d.getMonth();
    this.valorFechaActualInput = año + "-" + mes + "-" + "01";
    this.valorAnterior = año + "-" + mes + "-" + "01";
    mes = mes + 1;
    if (mes.toString().length == 1) {
      mes = "0" + mes;
    }
    this.edicion.emit((año + '-' + mes + '-' + "01"));
    
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.valorActual) {
      if (changes.valorActual.currentValue === undefined || changes.valorActual.currentValue === null) {
      } else {
        // if (this.cont == 0) {
        /* let year = changes.valorActual.currentValue.split("-")[0]; 
        let month = +changes.valorActual.currentValue.split("-")[1] - 1; 
        let day = changes.valorActual.currentValue.split("-")[2]; */

        this.valorFechaMaterial = new Date(changes.valorActual.currentValue.replace(/-/g, "/"));
        this.cont++;
        // }
      }
    }
  }

  ngOnInit() {
    this.dateType = "month";
  }

  chosenMonthHandler(normalizedMonth: any, datepicker: MatDatepicker<any>) {
    this.valorFechaMaterial = new Date(normalizedMonth._d);
    this.llenarFecha(normalizedMonth);
    datepicker.close();
  }

}