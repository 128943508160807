import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InventarioLote, PaginacionWo } from '@wo/modelo';
import { Observable } from 'rxjs';
import { Services } from '../services.service';
import { urlService } from '@wo/frontend/environmentDynamicService';

@Injectable({ providedIn: 'root' })
export class InventarioLoteService extends Services {

  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
  }

  getgetInventarioloteOrdenado(paginacion: PaginacionWo): Observable<InventarioLote> {
    return this.http.post<InventarioLote>(
      this.url.getUrl() + 'inventarioLote/listarInventarioLotes',
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getLoteCompras(idMovimientoInventario: number): Observable<any> {
    return this.http.get<any>(
      `${this.url.getUrl()}inventarioLote/loteByMovimientoInventario/${idMovimientoInventario}`,
      { withCredentials: true, headers: this.headers }
    );
  }

  getLoteVentas(idMovimientoInventario: number, paginacion: PaginacionWo) {
    return this.http.post<InventarioLote>(
      `${this.url.getUrl()}inventarioLote/loteCantidadByInventario/${idMovimientoInventario}`,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }
  getProveedor(paginacion): Observable<any> {
    return this.http.post<any>(
      `${this.url.getUrl()}inventarioLote/listarProveedores`,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getlistarInventarioLote(paginacion: PaginacionWo, id): Observable<InventarioLote> {
    return this.http.post<InventarioLote>(
      this.url.getUrl() + 'inventarioLote/listarInventarioLotesPorInventario/'+ id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getlistarInventarioLoteVentas(paginacion: PaginacionWo, id): Observable<InventarioLote> {
    return this.http.post<InventarioLote>(
      this.url.getUrl() + 'inventarioLote/listarInventarioLotesPorInventarioExistencias/'+ id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }
  getlistarInventarioLoteMov(paginacion: PaginacionWo, id): Observable<InventarioLote> {
    return this.http.post<InventarioLote>(
      this.url.getUrl() + 'inventarioLote/listarInventarioLotesPorMovimiento/'+ id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }

  getlistarInventarioLoteMovVentas(paginacion: PaginacionWo, id): Observable<InventarioLote> {
    return this.http.post<InventarioLote>(
      this.url.getUrl() + 'inventarioLote/listarInventarioLotesPorMovimientoExistencias/'+ id,
      paginacion,
      { withCredentials: true, headers: this.headers }
    );
  }
  getListarLotesSelect(paginacion): Observable<any> {
    return this.http.post<any>(
      `${this.url.getUrl()}inventarioLote/listarInventarioLoteSelect`,
      paginacion,
       { withCredentials: true, headers:this.headers }
      );
  }

}
