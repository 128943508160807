import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PubSubtoken } from '@wo/pos/modelo/pubSubtoken';
import { PubSubGetMensaje } from '@wo/pos/modelo/pubSubGetMensaje';
import { PubSubMensaje } from '@wo/pos/modelo/pubSubMensaje';
import { urlService } from '@wo/frontend/environmentDynamicService';


@Injectable({
  providedIn: 'root'
})
export class PubSubService {
  urlBase: string

  constructor(
    private http: HttpClient,
    private url: urlService,
    @Inject('backendNotificaciones') backendUrl: string
  ) {
    this.urlBase = backendUrl;
  }

  getTipoLicencia(licencia: String): Observable<any> {
    return this.http.get<any>(this.url.getUrl() + `getTipoLicencia/${licencia}`, { withCredentials: true });
  }

  getTokenSubPub(group: String): Observable<HttpResponse<PubSubtoken>> {
    const url = this.urlBase + 'negociate?id=' + group;
    return this.http.post<PubSubtoken>(url, null, { observe: 'response' });
  }

  getNotification(getNotification: PubSubGetMensaje): Observable<HttpResponse<PubSubMensaje[]>> {
    const url = this.urlBase + 'getNotification';
    return this.http.post<PubSubMensaje[]>(url, getNotification, { observe: 'response' });
  }

}
