import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { Inventario, InventarioEmpresaBodega, PaginacionWo, SeveridadEnum, TipoDato, TipoInput } from '@wo/modelo';
import { ColaService, DashboardService, FormatoUtilService, GeneralUtil, InventarioService, ScrollInfinitoService } from '@wo/servicio/index';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

@Component({
  selector: 'app-mas-inventario-principal',
  templateUrl: './mas-inventario-principal.component.html',
  styleUrls: ['./mas-inventario-principal.component.css',
    '../../../../../usuario/usuario.component.css',
    '../../../../../wo-tabla/wo-tabla.component.css']
})
export class MasInventarioPrincipalComponent implements OnChanges, OnDestroy {

  @Input() inventario: Inventario;
  @Output() respuestaModal = new EventEmitter<any>();

  subscriptions = new Subscription();
  id: number;
  sinDatos: boolean;
  loading: boolean = true;
  valorProceso;
  valor;
  monedaLocal = JSON.parse(sessionStorage.getItem('MONEDALOCAL'));
  allSucursales: Array<InventarioEmpresaBodega> = [];
  allSucursalesAux: Array<InventarioEmpresaBodega> = [];
  response: any;

  private chartExistencias: am4charts.PieChart
  nombreInventario; mostrarPopup = false;

  existenciasPorBodega: Array<any> = [];
  existenciasPorEmpresa: Array<any> = [];

  public paginacion: any;
  public paginacionE: any;
  respuestaCostoPromedio: boolean;

  public columnasPorBodega: any[] = [
    {
      header: this.translateUtilService.getTranslateText(
        'documentoEncabezado.masInventario.columnasBodega.bodega'
      ),
      atributo: 'nombre',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      estilo: "colWidth50"
    },
    {
      header: this.translateUtilService.getTranslateText(
        'documentoEncabezado.masInventario.columnasBodega.cantidad'
      ),
      atributo: 'cantidad',
      tipoDato: TipoDato.NUMERIC,
      tipoInput: TipoInput.NUMBER,
      estilo: "colWidth50"
    }
  ];

  public columnasPorEmpresa: any[] = [
    {
      header: this.translateUtilService.getTranslateText(
        'documentoEncabezado.masInventario.columnasEmpresa.prefijo'
      ),
      atributo: 'codigo',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      estilo: "colWidth50"
    },
    {
      header: this.translateUtilService.getTranslateText(
        'documentoEncabezado.masInventario.columnasEmpresa.empresa'
      ),
      atributo: 'nombre',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      estilo: "colWidth50"
    },
    {
      header: this.translateUtilService.getTranslateText(
        'documentoEncabezado.masInventario.columnasEmpresa.cantidad'
      ),
      atributo: 'cantidad',
      tipoDato: TipoDato.NUMERIC,
      tipoInput: TipoInput.NUMBER,
      estilo: "colWidth50"
    }
  ];

  public opcionesTabla = { editable: false, paginado: false, ordenar: true, crear: false };


  constructor(
    private dashboardService: DashboardService,
    private scrollInfinitoService: ScrollInfinitoService,
    private inventarioService: InventarioService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private translateUtilService: TranslateUtilService,
    private formatoUtilService: FormatoUtilService,
    public _LoaderService: LoaderService,
    private serviceColas: ColaService,
    private popUpService: PopUpService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inventario) {
      if (changes.inventario.currentValue) {
        if (!GeneralUtil.isNullOrUndefined(this.inventario) && !GeneralUtil.isEmptyObject(this.inventario)) {
          this.id = this.inventario.id;
          this.nombreInventario = this.inventario.nombre;
          this.mostrarPopup = true;
          this.cargarChart();
          this.validarCostoPromedio(this.id)
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  cargarChart() {
    let sub = this.inventarioService.getExistenciasEmpresaBodega(this.id).subscribe((response: InventarioEmpresaBodega[]) => {
      this.loading = false;
      setTimeout(() => {
        (response.length > 0) ? this.inicializarChart(response, true) : this.sinDatos = true;
      }, 500);
    }, (error) => this.errorHandlerComponent.handler(error));
    this.subscriptions.add(sub);
  }

  validarCostoPromedio(id) {
    console.log("Validar Costo Promedio", this.monedaLocal);
    let sub = this.inventarioService.getCostoPromedioInventario(id).subscribe(response => {
      this.loading = false;
      this.valorProceso = this.formatoUtilService.formatoMoneda(response, this.monedaLocal)
    }, (error) => this.errorHandlerComponent.handler(error));

    this.subscriptions.add(sub);
  }

  cambiaSucursal(event: any) {
    let data = [];
    (event.id === -1) ? data = data.concat(this.allSucursalesAux) : data = data.concat(event);
    this.existenciasPorBodega = [];
    data.forEach(sucursal => {
      sucursal.bodegas.forEach(bodega => {
        if (this.existenciasPorBodega.findIndex(item => item.id === bodega.id) === -1) {
          this.existenciasPorBodega = this.existenciasPorBodega.concat(bodega);
        }
      });
    });
    this.inicializarChart(data, false);
  }

  inicializarChart(datos: InventarioEmpresaBodega[], inicial: boolean) {
    if (inicial) {
      this.allSucursales = [];
      this.allSucursalesAux = datos;
      this.allSucursales.push({ id: -1, nombre: 'Todas las Sucursales', cantidad: 0, bodegas: [] }, ...datos);
    }

    let selected;
    let chart = am4core.create("chartdiv-Existencias", am4charts.PieChart3D);
    // Add data
    chart.data = datos;
    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries3D());
    pieSeries.dataFields.value = "cantidad";
    pieSeries.dataFields.category = "nombre";

    pieSeries.ticks.template.disabled = true;

    pieSeries.alignLabels = false;

    pieSeries.labels.template.text = "{nombre}\n{value.percent.formatNumber('#.0')}%";
    pieSeries.labels.template.radius = am4core.percent(-50);
    pieSeries.labels.template.fill = am4core.color("white");
    pieSeries.labels.template.align = 'center';
    pieSeries.labels.template.fontSize = '14';
    pieSeries.labels.template.wrap = true;
    pieSeries.labels.template.maxWidth = 100;

    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.slices.template.propertyFields.isActive = "pulled";
    pieSeries.slices.template.strokeWidth = 0;

    pieSeries.slices.template.events.on("sizechanged", function (event: any) {
      let axis = event.target;
      let cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      if (cellWidth < axis.renderer.labels.template.maxWidth) {
        axis.renderer.labels.template.rotation = -45;
        axis.renderer.labels.template.horizontalCenter = "right";
        axis.renderer.labels.template.verticalCenter = "middle";
      }
      else {
        axis.renderer.labels.template.rotation = 0;
        axis.renderer.labels.template.horizontalCenter = "middle";
        axis.renderer.labels.template.verticalCenter = "top";
      }
    });

    pieSeries.slices.template.events.on("hit", function (event: any) {
      if (event.target.dataItem.dataContext.id != undefined) {
        selected = event.target.dataItem.dataContext.id;
      } else {
        selected = undefined;
      }
      chart.data = generateChartData();
    })

    function generateChartData() {
      let chartData = [];
      for (let i = 0; i < datos.length; i++) {
        if (datos[i].id == selected) {
          for (let x = 0; x < datos[i].bodegas.length; x++) {
            chartData.push({
              nombre: datos[i].bodegas[x].nombre,
              cantidad: datos[i].bodegas[x].cantidad,
              id: datos[i].bodegas[x].id,
              pulled: true,
              color: chart.colors.getIndex(i)
            });
          }
        } else {
          chartData.push({
            bodegas: datos[i].bodegas,
            cantidad: datos[i].cantidad,
            id: datos[i].id,
            nombre: datos[i].nombre
          });
        }
      }
      return chartData;
    }
  }

  inicializarBodega(id: any, pagina, registrosPorPagina, columna, orden, registroInicial?) {
    const paginacion = new PaginacionWo(columna);
    paginacion.pagina = pagina;
    paginacion.registrosPorPagina = registrosPorPagina;
    paginacion.orden = orden;
    if (registroInicial !== undefined) paginacion.registroInicial = registroInicial;
    this.inventarioService
      .getExistenciasInventarioPorBodega(id, paginacion)
      .subscribe(
        response => {
          this.response = response;
          this.paginacion = {
            totalPaginas: response.totalPages,
            totalElementos: response.totalElements,
            pagina: paginacion.pagina,
            registroInicial: registroInicial,
            registrosPorPagina: paginacion.registrosPorPagina,
            numeroElementos: response.numberOfElements,
            orden: orden,
            columna: columna,
            columnaOrdenar: columna,
            filtros: paginacion.filtros
          };
          this.existenciasPorBodega = this.scrollInfinitoService.crearArregloScrollInfinito(this.paginacion, this.existenciasPorBodega, response.content);
          this.existenciasPorBodega = this.existenciasPorBodega.map(x => Object.assign({}, x));
        }, error => this.errorHandlerComponent.handler(error));
  }

  inicializarEmpresa(id: any, pagina, registrosPorPagina, columna, orden, registroInicial?) {
    const paginacion = new PaginacionWo(columna);
    paginacion.pagina = pagina;
    paginacion.registrosPorPagina = registrosPorPagina;
    paginacion.orden = orden;
    if (registroInicial !== undefined) paginacion.registroInicial = registroInicial;
    this.inventarioService
      .getExistenciasInventarioPorEmpresa(id, paginacion)
      .subscribe(response => {
        response.content = response.content.filter(e => e.cantidad != 0);
        let cantidad0 = response.content.filter(e => e.cantidad == 0);
        this.paginacionE = {
          totalPaginas: response.totalPages,
          totalElementos: response.totalElements,
          pagina: paginacion.pagina,
          registrosPorPagina: paginacion.registrosPorPagina,
          numeroElementos: response.numberOfElements,
          orden: orden,
          registroInicial: registroInicial,
          columna: columna,
          columnaOrdenar: columna,
          filtros: paginacion.filtros
        };
        this.paginacionE.numeroElementos += -cantidad0;
        this.existenciasPorEmpresa = this.scrollInfinitoService.crearArregloScrollInfinito(this.paginacionE, this.existenciasPorEmpresa, response.content);
        this.existenciasPorEmpresa = this.existenciasPorEmpresa.map(x => Object.assign({}, x));
        this.existenciasPorEmpresa = this.existenciasPorEmpresa.filter(item => item.id !== undefined);
      }, error => this.errorHandlerComponent.handler(error));
  }

  // cerrarPopUp(noCerrar?) {
  //   if (!noCerrar) {
  //     this.mostrarPopup = false;
  //   }
  //   this.existenciasPorBodega.length = 0;
  //   this.existenciasPorEmpresa.length = 0;
  //   this.sinDatos = false;
  //   this.loading = true;
  //   this.respuestaModal.emit(this.recalculoExitoso);
  //   this.dashboardService.destruirGrafica(this.chartExistencias);
  // }

  afterChangeBodega(e) {
    if (e.ordenar) {
      this.inicializarBodega(
        this.id,
        e.pagActual,
        e.registrosPorPagina,
        'inventarioBodega.nombre',
        e.orden
      );
    }
    if (e.pag) {
      this.inicializarBodega(
        this.id,
        e.pagActual,
        e.registrosPorPagina,
        'inventarioBodega.nombre',
        e.orden,
        e.registroInicial
      );
    }
  }

  afterChangeEmpresa(e) {
    if (e.ordenar) {
      this.inicializarEmpresa(
        this.id,
        e.pagActual,
        e.registrosPorPagina,
        'documentoEncabezado.empresa.nombre',
        e.orden
      );
    }
    if (e.pag) {
      this.inicializarEmpresa(
        this.id,
        e.pagActual,
        e.registrosPorPagina,
        'documentoEncabezado.empresa.nombre',
        'ASC'
      );
    }
  }

  recalcularCostoPromedio() {
    this.esperarRespuesta();
    this.popUpService.open({ codigoError: 'advertenciaRecalculoCostoPromedio', severidad: SeveridadEnum.QUESTION, botonSiNo: true });
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (this.respuestaCostoPromedio) {
          this.respuestaCostoPromedio = false;
          setTimeout(() => {
            this.cargarChart();
            this.validarCostoPromedio(this.id);
          }, 500)
        } else if (respuesta.tipo == 'respuestaPopup') {
          this.respuestaPopup(respuesta.event);
        }
      }
    )
  }

  respuestaPopup(e) {
    if (e) {
      let mensaje = {
        clase: 'Inventario',
        id: this.id,
        accion: 'recalcularCostoPromedioInventario',
        atributo: '',
        valor: this.id,
        prioridad: true,
        tipoDato: TipoDato[TipoDato.LONG],
        permisoGrueso: 'inventario'
      };
      let observable = this.serviceColas.agregarACola(mensaje);

      if (observable) {
        let sub = observable.pipe(take(1)).subscribe(
          response => {
            if (response.length > 0) {
              if (response[0].codigoError) {
                if (response[0].codigoError == 'exito') {
                  let codigo = 'recalculoExitosoCostoPromedioInventario';
                  let severidad = SeveridadEnum.SUCCESS;
                  this.respuestaCostoPromedio = true;
                  this.respuestaModal.emit(this.respuestaCostoPromedio);
                  this.esperarRespuesta();
                  this.popUpService.open({ codigoError: codigo, severidad: severidad });
                } else {
                  this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });
                }
              }
            }
          }, (error) => {
            this.errorHandlerComponent.handler(error);
          });
        this.subscriptions.add(sub);
      }
    }
  }

}
