import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { DocumentoTipo, DocumentoTipoEnum, Opciones } from '@wo/modelo';
import { Subscription } from 'rxjs';
import { PopoverService } from '../servicios/popover/popover.service';
import { TranslateUtilService } from '../utils/i18n/translate-util.service';
import { TabsService, TourAndVideoService } from '@wo/servicios';
import { PopUpService } from '../servicios/popUp/pop-up.service';

@Component({
  selector: 'boton-flotante',
  templateUrl: './boton-flotante.component.html',
  styleUrls: ['./boton-flotante.component.css']
})
export class BotonFlotanteComponent implements OnDestroy, OnChanges {

  @Input('opciones') opciones: Opciones;
  @Input('tipoDocumento') tipoDocumento = undefined;
  @Input('idDocumento') idDocumento = undefined;
  @Input('documentoVentas') documentoVentas: boolean;
  @Input('nombreGenerador') nombreGenerador = 'generador';
  @Input('tipoLiqVac') tipoLiqVac: any;

  @Output() respuestaBotonF: EventEmitter<string> = new EventEmitter<string>();

  subscription = new Subscription();

  informesVacaciones = []
  informesNomina = [
    {
      id: 3,
      nombre: 'Auditoría Auxilio de Transporte',
      fontSize: '13px',
    },
    // {
    //   id: 4,
    //   nombre: 'Resumen de Aportes, Retenciones y Prestaciones Sociales',
    //   fontSize: '10px',
    //   class: 'iconoImprimirReport-6'
    // },
    // {
    //   id: 5,
    //   nombre: 'Auditoría Aportes Sociales',
    //   fontSize: '13px',
    // },
    {
      id: 6,
      nombre: 'Auditoría ReteFuente',
      fontSize: '13px'
    },
    {
      id: 7,
      nombre: 'Informe Contabilización',
      fontSize: '13px'
    },
  ];

  informePrimaServicio = [
    {
      id: 1,
      nombre: 'Informe Salario Promedio',
      fontSize: '13px',
    }
  ];

  documentoNomina = DocumentoTipoEnum.NOMINA;

  mostrarOpciones: boolean = false;
  nombreBotonGenerar = '';
  nombreBotonImpresion: string = '';
  nombreBotonSecundarioImpresion: string = '';
  documentoImpresionTicket: boolean = false;
  documentoLiquidacionNomina: string[] = [
    DocumentoTipoEnum.VACACIONES,
    DocumentoTipoEnum.LIQUIDACION_CONTRATO,
    DocumentoTipoEnum.CESANTIAS_INTERESES,
    DocumentoTipoEnum.PRIMA_SERVICIOS
  ];

  documentosImpresionTicket: string[] = [
    DocumentoTipoEnum.PEDIDO,
    DocumentoTipoEnum.COTIZACION,
    DocumentoTipoEnum.REMISION,
    DocumentoTipoEnum.FACTURA_VENTA,
    DocumentoTipoEnum.DEVOLUCION_REMISION,
    DocumentoTipoEnum.NOTA_DEBITO_VENTA,
    DocumentoTipoEnum.NOTA_CREDITO_VENTA,
    DocumentoTipoEnum.SALIDA_ALMACEN,
    DocumentoTipoEnum.ENTRADA_ALMACEN,
    DocumentoTipoEnum.RECIBO_CAJA,
    DocumentoTipoEnum.COMPROBANTE_EGRESO,
    DocumentoTipoEnum.NOMINA,
    DocumentoTipoEnum.PRIMA_SERVICIOS,
    DocumentoTipoEnum.VACACIONES,
    DocumentoTipoEnum.LIQUIDACION_CONTRATO,
    DocumentoTipoEnum.CESANTIAS_INTERESES
  ]

  constructor(private popUpService: PopUpService,
    private popoverService: PopoverService,
    public translateUtilService: TranslateUtilService,
    private tabsService: TabsService,
    private tourAndVideoService: TourAndVideoService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.nombreGenerador) {
      if (changes.nombreGenerador.currentValue) {
        this.nombreBotonGenerar = this.translateUtilService.getTranslateText(
          'botonFlotante.' + changes.nombreGenerador.currentValue
        );
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  validacionImpresion() {
    if (this.documentosImpresionTicket.some(t => t === this.tipoDocumento)) {
      this.documentoImpresionTicket = true;
      this.nombreBotonImpresion = this.translateUtilService.getTranslateText('botonFlotante.' + this.tipoDocumento);
      this.validacionNombresTicket();
    }
    if(this.opciones.nuevo == true && this.opciones.creacionUsuarios == true){
      this.opciones.nuevo = false;
    }
  }

  validacionNombresTicket() {
    this.tipoDocumento != DocumentoTipoEnum.NOMINA ?
      this.nombreBotonSecundarioImpresion = this.translateUtilService.getTranslateText('botonFlotante.ticket') :
      this.nombreBotonSecundarioImpresion = this.translateUtilService.getTranslateText('botonFlotante.nomina');

    this.tipoDocumento == DocumentoTipoEnum.PRIMA_SERVICIOS && (this.nombreBotonSecundarioImpresion = this.translateUtilService.getTranslateText('botonFlotante.AdotoriaReteFuente'))
    this.tipoDocumento == DocumentoTipoEnum.VACACIONES && (this.nombreBotonSecundarioImpresion = this.translateUtilService.getTranslateText('botonFlotante.AdotoriaReteFuente'))
    this.tipoDocumento === DocumentoTipoEnum.VACACIONES && this.validacionLiqVacaciones()
    this.tipoDocumento === DocumentoTipoEnum.CESANTIAS_INTERESES && this.validacionLiqPrimasCesantia()
    this.tipoDocumento === DocumentoTipoEnum.PRIMA_SERVICIOS && this.validacionLiqPrimasCesantia()
    this.tipoDocumento === DocumentoTipoEnum.LIQUIDACION_CONTRATO && this.liquidacionContratos()

    if (this.opciones.esListado) {
      if (this.tipoDocumento === DocumentoTipoEnum.VACACIONES)
        this.nombreBotonImpresion = this.translateUtilService.getTranslateText('botonFlotante.libroVacaciones')
      if (this.tipoDocumento === DocumentoTipoEnum.LIQUIDACION_CONTRATO)
        this.nombreBotonImpresion = this.translateUtilService.getTranslateText('botonFlotante.liquidacionContrato')
    }
  }

  validacionLiqPrimasCesantia() {
    
    if(this.tipoDocumento == DocumentoTipoEnum.PRIMA_SERVICIOS){
      this.informesVacaciones = [
      {
        id: 2,
        nombre: 'Auditoría Retefuente'
      },
      {
        id: 6,
        nombre: 'Informe Salario Promedio',
        fontSize: '14px'
      }
    ]
    } else {
      this.informesVacaciones = [
      {
        id: 6,
        nombre: 'Informe Salario Promedio',
        fontSize: '14px'
      }
    ]
    }
  }

  liquidacionContratos() {
    this.informesVacaciones = [
      {
        id: 2,
        nombre: 'Auditoría Retefuente'
      },
      {
        id: 3,
        nombre: 'Auditoría de Aportes'
      },
      {
        id: 5,
        nombre: 'Auditoría de Indemnización',
        fontSize: '14px'
      },
      {
        id: 6,
        nombre: 'Informe Salario Promedio',
        fontSize: '14px'
      }
    ];
  }

  validacionLiqVacaciones() {
    switch (this.tipoLiqVac) {
      case 'Disfrutadas':
        this.informesVacaciones = [
          {
            id: 2,
            nombre: 'Auditoría Retefuente'
          },
          {
            id: 3,
            nombre: 'Auditoría de Aportes'
          },
          {
            id: 6,
            nombre: 'Informe Salario Promedio',
            fontSize: '14px'
          }
        ];
        break;
      case 'Compensadas':
        this.informesVacaciones = [
          {
            id: 2,
            nombre: 'Auditoría Retefuente'
          },
          {
            id: 3,
            nombre: 'Auditoría de Aportes'
          },
          {
            id: 6,
            nombre: 'Informe Salario Promedio',
            fontSize: '14px'
          }
        ];
        break;
      case 'Ajuste de Provisión':
        this.informesVacaciones = [
          {
            id: 6,
            nombre: 'Informe Salario Promedio',
            fontSize: '14px'
          }
        ];
        break;
      default:
        break;
    }
  }

  clickBotonAyuda() {
    let nombreTab = this.tabsService.getCurrenTabName();
    if (nombreTab.includes("copia")) {
      nombreTab = nombreTab.replace('copia', '');
    }
    this.tourAndVideoService.getVideo(nombreTab).subscribe((video: any) => {
      this.popUpService.popUpAyuda.next({ tabName: nombreTab, videoAndQuestions: video });
    });
  }

  click(valor, itemIdClicked = null) {
    if (this.tipoDocumento === DocumentoTipoEnum.NOMINA && (valor === "ticket" || valor === "imprimir")) {
      valor = valor === "ticket" ? "imprimir" : "desprendible";
    }
    if (this.esNominaPrestaciones() && (valor === "ticket" || valor === "imprimir")) {
      valor = valor === "ticket" ? "imprimir" : "prestacionesSociales";
    }

    if (this.opciones.esListado && this.documentoLiquidacionNomina.includes(this.tipoDocumento)) {
      valor = valor
    }

    if (itemIdClicked && !this.opciones.esListado) {
      valor = `${valor}|${itemIdClicked}`
    }

    this.respuestaBotonF.emit(valor);
    this.mostrarOpciones = false;
  }

  desplegarOpciones() {
    this.mostrarOpciones = !this.mostrarOpciones;
    let sub = this.popoverService.subscribe(this.clickout.bind(this));
    this.subscription = new Subscription();
    this.subscription.add(sub);
    this.validacionImpresion();
  }

  clickout(event) {
    if (event.target.id != "boton-amarillo" && event.target.id != "a-buton-amarillo" && event.target.id != "span-buton-amarillo") {
      this.mostrarOpciones = false;
      this.subscription.unsubscribe();
    }
  }

  esNominaPrestaciones() {
    return [DocumentoTipoEnum.CESANTIAS_INTERESES, DocumentoTipoEnum.PRIMA_SERVICIOS,
    DocumentoTipoEnum.VACACIONES, DocumentoTipoEnum.LIQUIDACION_CONTRATO]
      .includes(this.tipoDocumento);
  }

}
