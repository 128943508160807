import { FormaPagoService } from './../../../../../libs/servicios/src/lib/formaPago/forma-pago.service';
import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  Canal,
  ClasificacionInventarioEnum,
  Columna,
  CrearDocumentoPojo,
  DocumentoTipo,
  DocumentoTipoEnum,
  DocumentoTipoNotaCreditoEnum,
  FiltroWo,
  FormaPagoClasificacionEnum,
  InventarioClasificacionEnum,
  MovimientoTipoEnum,
  Operador,
  PaginacionWo,
  PlataformaEnum,
  RecalcularEnBaseAEnum,
  SeveridadEnum,
  Tab,
  TallaColorSerialLoteError,
  TipoDato,
  TipoFiltro,
  TipoInput
} from '@wo/modelo';
import {
  ColaService,
  CopiarDocumentosService,
  MedioPagoService,
  MonedaService,
  PosService,
  TabsService
} from '@wo/servicios';
import { Subscription } from 'rxjs';
import { ErrorHandlerComponent } from '../error-handler/error-handler.component';
import { LoaderService } from '../servicios/loader/loader.service';
import { TranslateUtilService } from '../utils/i18n/translate-util.service';
import { PopUpService } from '../servicios/popUp/pop-up.service';
import { take } from 'rxjs/operators';
import { TipoDevolucionEnum } from "../../../../../libs/modelo/src/lib/enumeraciones/tipoDevolucionEnum";
import * as printToPDF from "print-js"

//import { TipoDevolucionEnum } from '@wo/modelo';

@Component({
  selector: 'app-copiar-documento',
  templateUrl: './copiar-documento.component.html',
  styleUrls: [
    './copiar-documento.component.css',
    '../wo-tabla/wo-tabla.component.css'
  ]
})
export class CopiarDocumentoComponent implements OnInit, OnDestroy {
  inicio: boolean = true;
  tercero: any;
  direccion: any;
  prefijo = { codigo: -1, id: -1, nombre: '' };
  formaPago: any;
  fechaEmision: string;
  allElement: any;
  focoFirst: any = true;
  completo: boolean;
  fechaAnterior: string;
  fechaEmisionValidacion: Boolean = false;
  fechaEmisionAnterior: string;
  crearDocumentoPojo: CrearDocumentoPojo;
  tipoPopUpResolverExistencias;
  datosPopUpCopia: any[] = [];
  temaDocumento = 'themeCont themeOrange';
  abrirPopUpErrores = false;
  dataEnvio;
  nombreEsco: string;
  prefijoNotas: string;
  numeroNotas: string;

  ocultarForm: boolean = true;
  notaCreditoError: boolean;
  notaCreditoCompra: boolean;
  notaCreditoVenta: boolean;
  POS: boolean;

  prefijoNumeroNota: any;

  constructor(
    private copiarDocumentosService: CopiarDocumentosService,
    private formaPagoService: FormaPagoService,
    private serviceCola: ColaService,
    private tabsService: TabsService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private translateUtilService: TranslateUtilService,
    private monedaService: MonedaService,
    private medioPagoService: MedioPagoService,
    public _LoaderService: LoaderService,
    private posService: PosService,
    @Inject('plataforma') plataforma: PlataformaEnum,
    public popUpService: PopUpService) {
    this.POS = plataforma == PlataformaEnum.POS;
  }

  @Input('documentoOrigen') documentoOrigen;
  @Input('monedaOrigen') monedaOrigen;
  @Input('idEncabezado') idEncabezado;
  @Input('tipoMovimiento') tipoMovimiento;
  @Input('empresa') empresa;
  @Input('fecha') fecha;
  @Input('tipoDocumento') tipoDocumento;
  @Input('urlDetalleDocumento') urlDetalleDocumento;
  @Input('dataInicializar') dataInicializar;
  @Input() formaPagoOrigen;

  //activar pestaña de cruces o renglones
  estaActive_1Copia: string = '';
  estaActive_2Copia: string = '';
  estaActive_3Copia: string = '';
  estaActive_4Copia: string = '';

  @Output() mostrarPopupCont = new EventEmitter<Array<any>>();
  @Output() respuestaPopupCopiarDoc: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyTallaSerialLote: EventEmitter<any> = new EventEmitter<any>();

  private subscriptions = new Subscription();

  mostrarDocumentoDestino: boolean = true;
  mostrarDocumentoOrigen: boolean = true;
  mostrarClasificacionNotaCredito: boolean = false;
  mostrarMotivoDevolucion: boolean = false;
  mostrarRecalcularOpciones: boolean = false;
  mostrarFechaEmision: boolean = false;
  focoElement: Element = undefined;

  mostrarTercero: boolean = false;
  mostrarDireccion: boolean = false;
  mostrarBeneficiario: boolean = false;
  mostrarPrefijo: boolean = false;
  beneficiacio: any;
  tituloBoton: string;
  mostrarOpcionesAdicionales: boolean;
  positionA = { x: 0, y: 0 };
  newInnerWidth = 0;
  isDragging = false;
  draggable = true;
  moneda: any;
  inBounds = true;
  edge = { top: true, bottom: true, left: true, right: true };
  accion: string;
  tipoDocumentoCrear;
  fechaOrigen;

  paisLocal = JSON.parse(sessionStorage.getItem('PAISLOCAL'));
  itemsSeleccionados: Array<any> = [];
  documentoTipo: any = null;
  docOrigen: any = '';
  idDocumento: any = null;
  inventarios: Array<any> = [];
  documentosTipos: Array<DocumentoTipo>;
  usuario = JSON.parse(sessionStorage.getItem('USUARIOSESION'));

  seCopianTodos = false;
  conceptoAs = false;
  monedaExtranjera: boolean;
  cantidadesValidas = new Array();
  titulo: string;
  mensaje: string;
  woTablaEnum = 'WT_COPIAR_DOCUMENTO';
  motivoDevolucion: any;
  clasificacionNotaCredito;
  motivoDev: any;
  recalcularFechaDocumento = true;
  recalcularFechaEmision = false;
  recalcularDocumentoOrigen = false;
  recalcularValores = RecalcularEnBaseAEnum.FECHA_NUEVO_DOCUMENTO;
  hayRenglonActivoFijo: boolean = false;

  saldosSeleccionados = [];
  saldosContablesSeleccionados = [];
  tipoSaldo: any;
  isDocumentosNoCopiaFp: boolean;


  prefijoExterno = "";
  documentoExterno = "";
  tipoDocumentoLabel = "";

  /**
   * Filtros
   */
  filtroFormaPago: FiltroWo[] = [];
  filtroPrefijoVacio: FiltroWo = new FiltroWo('descripcion', '', null, TipoFiltro.IGUAL, TipoDato.NOT_IN, null, ['VACIO'], 'Prefijo', Operador.AND);
  filtroPrefijoActivo: FiltroWo = new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'Prefijo', Operador.AND);
  filtrosPrefijo: Array<FiltroWo> = [this.filtroPrefijoVacio, this.filtroPrefijoActivo];
  filtroUsuarioLogueado: FiltroWo = new FiltroWo('id', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.usuario], 'usuarios', Operador.AND);
  filtrosUsuarioLogueado: Array<FiltroWo> = [this.filtroUsuarioLogueado];
  filtrosMotivoDevolucion: Array<FiltroWo> = [];
  filtrosDocumentoTipoNotaCredito: Array<FiltroWo> = [];

  filtrosPrefijoExterno: Array<FiltroWo> = [];
  filtrosDocumentoExterno: Array<FiltroWo> = [];

  dataMensaje;
  sinValidarTRM: boolean = false;

  mostrarConfirmacion: boolean; // Mostrar botones de confirmación
  cuentaSel: boolean;

  motivoClasificacionNotas: any;

  /**
   * getFiltroPaisLocal: retorna un filtro para el pais local.
   */
  getFiltroPaisLocal(): FiltroWo {
    return new FiltroWo('ubicacionPais.id', this.paisLocal.id, null, TipoFiltro.IGUAL, TipoDato.LONG, null, null, 'ubicacionPais', Operador.AND);
  }

  /**
   * getFiltroMotivoDevolucion: retorna un filtro para que se muestren solamente los motivos de devolución configurados para
   * el documento en el que se encuentra el usuario
  */
  getFiltroMotivoDevolucion(documentoTipo): FiltroWo {
    return new FiltroWo('codigoDocumento', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [documentoTipo], 'documentoTipos', Operador.AND
    );
  }

  get linkDocumento() {
    let doc;
    if (this.documentoTipo) doc = this.documentoTipo.codigoDocumento;
    if (doc === 'FV') {
      return '/panel/ventas/facturas-venta/detalle';
    } else if (doc === 'REM') {
      return '/panel/ventas/remisiones/detalle';
    } else if (doc === 'PD') {
      return '/panel/ventas/pedidos/detalle';
    } else if (doc === 'CZ') {
      return '/panel/ventas/cotizaciones/detalle';
    } else if (doc === 'RC') {
      return '/panel/contabilidad/recibos-caja/detalle';
    } else if (doc === 'CE') {
      return '/panel/contabilidad/comprobante-egreso/detalle';
    } else if (doc === 'NDV') {
      return '/panel/ventas/notas-debito/detalle';
    } else if (doc === 'NCV') {
      return '/panel/ventas/notas-credito/detalle';
    } else if (doc === 'DREM') {
      return '/panel/ventas/devolucion-remisiones/detalle';
    } else if (doc === 'NC') {
      return '/panel/contabilidad/nota-contabilidad/detalle';
    } else if (doc === 'NOMC') {
      return '/panel/contabilidad/nomina-contable/detalle';
    } else if (doc === 'FC') {
      return '/panel/compras/facturas-compra/detalle';
    } else if (doc === 'OC') {
      return '/panel/compras/orden-compra/detalle';
    } else if (doc === 'REMC') {
      return '/panel/compras/remision-compra/detalle';
    } else if (doc === 'DREMC' || this.woTablaEnum == 'WT_CREAR_DEVOLUCION_REMISION_COMPRA') {
      return '/panel/compras/devolucion-remision-compra/detalle';
    } else if (this.woTablaEnum == 'WT_CREAR_NOTA_CREDITO_COMPRA') {
      return '/panel/compras/notas-credito-proveedores/detalle';
    } else if (doc === 'CV') {
      return '/panel/contabilidad/costo-ventas/detalle';
    } else if (doc === 'SI') {
      return '/panel/contabilidad/saldos-iniciales/detalle';
    } else if (doc === 'CB') {
      return '/panel/contabilidad/consignacion-bancaria/detalle';
    } else if (doc === 'AI') {
      return '/panel/productos-y-servicios/ajuste-inventario/detalle';
    } else if (doc === 'SA') {
      return '/panel/productos-y-servicios/salida-almacen/detalle';
    } else if (doc === 'EA') {
      return '/panel/productos-y-servicios/entrada-almacen/detalle';
    } else if (doc === 'EPT') {
      return '/panel/productos-y-servicios/entrada-prod-terminado/detalle';
    } else if (doc === 'CCR') {
      return '/panel/contabilidad/cancelacion-cuentas/detalle';
    } else if (doc === 'DP') {
      return '/panel/contabilidad/depreciacion/detalle';
    }
    return '/panel/ventas/facturas-venta/detalle';
  }

  get nombrePestana() {
    let doc;
    if (this.documentoTipo) doc = this.documentoTipo.codigoDocumento;
    if (doc === 'FV') {
      return 'FacturaVentaDetalle';
    } else if (doc === 'REM') {
      return 'RemisionDetalle';
    } else if (doc === 'PD') {
      return 'PedidoDetalle';
    } else if (doc === 'CZ') {
      return 'CotizacionDetalle';
    } else if (doc === 'RC') {
      return 'ReciboCajaDetalle';
    } else if (doc === 'CE') {
      return 'ComprobanteDeEgresoDetalle';
    } else if (doc === 'DREM') {
      return 'DevolucionRemisionDetalle';
    } else if (doc === 'NC') {
      return 'NotaContabilidadDetalle';
    } else if (doc === 'NOMC') {
      return 'NominaContableDetalle';
    } else if (doc === 'FC') {
      return 'FacturaCompraDetalle';
    } else if (doc === 'OC') {
      return 'OrdenCompraDetalle';
    } else if (doc === 'REMC') {
      return 'RemisionCompraDetalle';
    } else if (doc === 'DREMC' || this.woTablaEnum == 'WT_CREAR_DEVOLUCION_REMISION_COMPRA') {
      return 'DevolucionRemisionCompraDetalle';
    } else if (this.woTablaEnum == 'WT_CREAR_NOTA_CREDITO_COMPRA') {
      return 'NotaCreditoProveedoresDetalle';
    } else if (doc === 'CV') {
      return 'CostoVentaDetalle';
    } else if (doc === 'SI') {
      return 'SaldosInicialesDetalle';
    } else if (doc === 'CB') {
      return 'ConsignacionBancariaDetalle';
    } else if (doc === 'AI') {
      return 'AjusteInventarioDetalle';
    } else if (doc == 'SA') {
      return 'SalidaAlmacenDetalle'
    } else if (doc == 'EA') {
      return 'EntradaAlmacenDetalle'
    } else if (doc == 'EPT') {
      return 'EntradaDeProductoTerminadoDetalle'
    } else if (doc == 'CCR') {
      return 'DocumentoCancelacionDetalle'
    } else if (doc === 'DP') {
      return 'Depreciacion';
    } else return 'FacturaVentaDetalle';
  }

  public columnas: Columna[] = [];

  @Input('dataMensajeEnviar') dataMensajeEnviar;

  public opcionesTabla: any = { editable: true, paginado: false, ordenar: false, crear: false, vacio: false };

  /**
   * manejaMonedaExtranjera: compara si el documento tiene moneda extranjera.
   * @param monedaCruce: moneda del renglon que se va a cruzar.
   */
  manejaMonedaExtranjera(): boolean {

    let monedaLocal = JSON.parse(sessionStorage.getItem('MONEDALOCAL'));
    if (monedaLocal && this.monedaOrigen && this.monedaOrigen.codigo != monedaLocal.codigo) {
      return true;
    }
    return false;
  }

  ngOnInit() {
    console.log('pasa')
    this.inicializarColumnas();
    this.verificarTipoDocumentoFechaEmision();
    this.iniciarConfSI();
    // this.iniciarPosicionPopUp();


    this.paginacion = {
      totalPaginas: 0,
      totalElementos: 0,
      pagina: 0,
      registrosPorPagina: 0,
      numeroElementos: 0
    };
    this.tipoDocumentoLabel = this.translateUtilService.getTranslateText('copiaDocumentos.recalcularDocumentoOrigen') + '(' + this.tipoDocumento + '): ';

    setTimeout(() => {
      if (this.dataInicializar.cargarTiposDocumento) {
        this.cargarTiposDocumento(this.dataInicializar.cargarTiposDocumento);
      }

      if (this.dataInicializar.cargarItems) {
        this.cargarItems(this.dataInicializar.cargarItems.accion, this.dataInicializar.cargarItems.tipoDocumentoCrear);
      } else {
        this.ocultarForm = false;
      }

      if (this.dataInicializar.cargarData) {
        this.cargarData(this.dataInicializar.cargarData);
      }

      if (this.dataInicializar.cargarDataMensaje) {
        this.cargarDataMensaje(this.dataInicializar.cargarDataMensaje);
      }
    }, 200);
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup') {
          this.respuestaPopup(respuesta.event);
        }
        else if (respuesta.tipo == 'respuestaFeaturePopup') {
          this.respuestaFeaturePopup(respuesta.event);
        }
      }
    )
  }

  verificarTipoDocumentoFechaEmision() {
    let sigla = this.documentoOrigen.split(" ")[0];
    if (sigla == DocumentoTipoEnum.FATURA_COMPRA || sigla == DocumentoTipoEnum.REMISION_COMPRA || sigla == DocumentoTipoEnum.REMISION_COMPRA || sigla == DocumentoTipoEnum.DEVOLUCION_REMISION_COMPRA) {
      this.fechaEmisionValidacion = true;
    }
  }

  iniciarConfSI() {
    this.recalcularFechaDocumento = true;
    this.estaActive_1Copia = 'tabActive';
    this.estaActive_2Copia = '';
    this.estaActive_3Copia = '';
    this.estaActive_4Copia = '';
    this.columnas = this.columnas.map(x => {
      if (x.atributo === 'seleccionado') x.checkHeader.valor = false;
      return Object.assign(x, {});
    });
    this.itemsSeleccionados = [];
  }




  ngOnChanges(changes: SimpleChanges) {
    if (changes.documentoOrigen) {
      this.docOrigen = changes.documentoOrigen.currentValue;
    }
    if (changes.idEncabezado) {
      this.idDocumento = changes.idEncabezado.currentValue;
    }
    if (changes.fecha) {
      if (changes.fecha.currentValue) {
        this.fechaOrigen = changes.fecha.currentValue;
      }
    }

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    $('.picker').remove();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;
  }


  getLabelCopiasReciboCaja() {
    this.columnas = [];
    this.columnas.push({
      header: null,
      checkHeader: { valor: false },
      atributo: 'seleccionado',
      tipoDato: TipoDato.BOOLEAN,
      tipoInput: TipoInput.CHECK,
      requerido: true,
      estilo: 'colWidth50',
      ordenar: false,
      soloLectura: false,
      hidden: false,
      editable: true,
      exportarExcel: false
    });
    this.columnas.push({
      header: this.translateUtilService.getTranslateText(
        'copiaDocumentos.columnasMovContable.concepto'
      ),
      atributo: 'concepto',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      requerido: true,
      soloLectura: true,
      ordenar: false,
      hidden: false,
      editable: false
    });
    this.columnas.push({
      header: this.translateUtilService.getTranslateText(
        'copiaDocumentos.columnasMovContable.cuentas'
      ),
      atributo: 'cuentaContable',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      requerido: true,
      soloLectura: true,
      ordenar: false,
      hidden: false,
      editable: false
    });
    this.columnas.push({
      header: this.translateUtilService.getTranslateText(
        'copiaDocumentos.columnasMovContable.debito'
      ),
      atributo: 'valorDebito',
      tipoDato: TipoDato.NUMERIC,
      tipoInput: TipoInput.NUMBER,
      requerido: true,
      soloLectura: true,
      tipoNumeric: 'moneda',
      ordenar: false,
      hidden: false,
      editable: false
    });
    this.columnas.push({
      header: this.translateUtilService.getTranslateText(
        'copiaDocumentos.columnasMovContable.credito'
      ),
      atributo: 'valorCredito',
      tipoDato: TipoDato.NUMERIC,
      tipoInput: TipoInput.NUMBER,
      requerido: true,
      soloLectura: true,
      tipoNumeric: 'moneda',
      ordenar: false,
      hidden: false,
      editable: false
    });

    this.columnas = this.columnas.map(x => Object.assign({}, x));
  }
  /**
   *inicializarColumnas: Según el tipo de movimiento inventario, inicializa las columnas de la tabla de copiar
   */
  inicializarColumnas(tipo?) {
    this.columnas = [];
    this.columnas.push({
      header: null,
      checkHeader: { valor: false },
      atributo: 'seleccionado',
      tipoDato: TipoDato.BOOLEAN,
      tipoInput: TipoInput.CHECK,
      requerido: true,
      estilo: 'colWidth50',
      ordenar: false,
      soloLectura: false,
      hidden: false,
      editable: true,
      exportarExcel: false
    });
    if (this.tipoMovimiento == MovimientoTipoEnum.INVENTARIO || (this.tipoDocumento == DocumentoTipoEnum.SALDOS_INICIALES && tipo != '-1')) {
      this.columnas.push({
        header: this.translateUtilService.getTranslateText(
          'copiaDocumentos.columnasMovInventario.codigo'
        ),
        atributo: 'inventario',
        tipoDato: TipoDato.STRING,
        tipoInput: TipoInput.TEXT,
        requerido: true,
        soloLectura: true,
        hidden: false,
        editable: false
      });
      this.columnas.push({
        header: this.translateUtilService.getTranslateText(
          'copiaDocumentos.columnasMovInventario.descripcion'
        ),
        atributo: 'inventario',
        tipoDato: TipoDato.STRING,
        tipoInput: TipoInput.TEXT,
        requerido: true,
        soloLectura: true,
        hidden: false,
        editable: false
      });
      this.columnas.push({
        header: this.translateUtilService.getTranslateText(
          'copiaDocumentos.columnasMovInventario.unidadMedida'
        ),
        atributo: 'unidadMedida',
        tipoDato: TipoDato.STRING,
        tipoInput: TipoInput.TEXT,
        columnas: ['nombre'],
        requerido: true,
        soloLectura: true,
        hidden: false,
        editable: false
      });
      this.columnas.push({
        header: this.translateUtilService.getTranslateText(
          'copiaDocumentos.columnasMovInventario.cantidad'
        ),
        atributo: 'cantidad',
        tipoDato: TipoDato.NUMERIC,
        tipoInput: TipoInput.NUMBER,
        requerido: true,
        soloLectura: true,
        ordenar: false,
        hidden: false,
        editable: false
      });
      this.columnas.push({
        header: this.translateUtilService.getTranslateText(
          'copiaDocumentos.columnasMovInventario.valorUnitario'
        ),
        atributo: 'valorUnitario',
        tipoDato: TipoDato.NUMERIC,
        tipoInput: TipoInput.NUMBER,
        requerido: true,
        soloLectura: true,
        tipoNumeric: 'moneda',
        ordenar: false,
        hidden: false,
        editable: false
      });
    } else {
      this.columnas.push({
        header: this.translateUtilService.getTranslateText(
          'copiaDocumentos.columnasMovContable.cuentas'
        ),
        atributo: 'cuentaContable',
        tipoDato: TipoDato.STRING,
        tipoInput: TipoInput.TEXT,
        requerido: true,
        soloLectura: true,
        ordenar: false,
        hidden: false,
        editable: false
      });
      this.columnas.push({
        header: this.translateUtilService.getTranslateText(
          'copiaDocumentos.columnasMovContable.concepto'
        ),
        atributo: 'concepto',
        tipoDato: TipoDato.STRING,
        tipoInput: TipoInput.TEXT,
        requerido: true,
        soloLectura: true,
        ordenar: false,
        hidden: false,
        editable: false
      });
      this.columnas.push({
        header: this.translateUtilService.getTranslateText(
          'copiaDocumentos.columnasMovContable.tercero'
        ),
        atributo: 'terceroExterno',
        tipoDato: TipoDato.STRING,
        tipoInput: TipoInput.TEXT,
        requerido: true,
        soloLectura: true,
        ordenar: false,
        hidden: false,
        editable: false
      });
      this.columnas.push({
        header: this.translateUtilService.getTranslateText(
          'copiaDocumentos.columnasMovContable.debito'
        ),
        atributo: 'valorDebito',
        tipoDato: TipoDato.NUMERIC,
        tipoInput: TipoInput.NUMBER,
        requerido: true,
        soloLectura: true,
        tipoNumeric: 'moneda',
        ordenar: false,
        hidden: false,
        editable: false
      });
      this.columnas.push({
        header: this.translateUtilService.getTranslateText(
          'copiaDocumentos.columnasMovContable.credito'
        ),
        atributo: 'valorCredito',
        tipoDato: TipoDato.NUMERIC,
        tipoInput: TipoInput.NUMBER,
        requerido: true,
        soloLectura: true,
        tipoNumeric: 'moneda',
        ordenar: false,
        hidden: false,
        editable: false
      });
    }
    this.columnas = this.columnas.map(x => Object.assign({}, x));
  }

  /**
   * cerrarPopup: cierra el popup, vaciando la información ingresada.
   */
  cerrarPopup() {
    this.respuestaPopupCopiarDoc.emit(false);
    this.completo = false;
    this.itemsSeleccionados = [];
    this.filtrosMotivoDevolucion = [];
    this.documentoTipo = null;
    this.prefijo = { codigo: -1, id: -1, nombre: '' };
    this.seCopianTodos = false;
    this.conceptoAs = false;
    this.recalcularDocumentoOrigen = false;
    this.clasificacionNotaCredito = null;
    this.motivoDevolucion = null;
    if (this.columnas[0].checkHeader) {
      this.columnas[0].checkHeader.valor = false;
    }
    this.prefijoExterno = "";
    this.documentoExterno = "";
    this.mostrarRecalcularOpciones = false;
  }

  formatoFecha(moneda: any) {
    let fechaActual = new Date(Date.now());
    let day: string = fechaActual.getDate() + '';
    let month: string = fechaActual.getMonth() + 1 + '';
    let year: string = fechaActual.getFullYear() + '';
    if (month.length === 1) {
      month = '0' + month;
    }
    if (day.length === 1) {
      day = '0' + day;
    }
    this.fechaAnterior = year + '-' + month + '-' + day;
    this.fechaEmisionAnterior = year + '-' + month + '-' + day;
    this.fecha = year + '-' + month + '-' + day;
    this.fechaEmision = year + '-' + month + '-' + day;
    //this.fechaEmision=Object.assign(this.fechaAnterior);


    if (moneda && moneda != -1) {
      this.verificarTRM(moneda, this.fecha);
    }
  }


  verificarTRM(moneda: any, fechaActual: any) {
    this.copiarDocumentosService.verificarTRM(moneda, fechaActual).subscribe(
      response => {
        if (response.length > 0 && response[0].codigoError) {
          this.verPopup(response[0].codigoError, SeveridadEnum.QUESTION, '', true);
          this.valorAnteriorFecha();
        } else {
          this.fechaAnterior = this.fecha;
          this.fechaEmisionAnterior = this.fechaEmision;
        }
      },
      error => {
        this.errorHandlerComponent.handler(error);
      }
    );
  }

  locale = 'es';

  private valorAnteriorFecha() {
    setTimeout(() => {
      this.fecha = this.fechaAnterior;
      this.fechaEmision = this.fechaEmisionAnterior;
    }, 0);
  }

  /**
   * cargarItems: carga los renglones segun la accion que se va a realizar con el popup
   * @param accion: accion por la cual se abrio el popup (copiar el documento u otras)
   */
  cargarItems(accion, tipoDocumentoCrear) {
    this.cuentaSel = null;
    this.accion = accion;
    this.tipoDocumentoCrear = tipoDocumentoCrear;
    this.inventarios = [];
    if (tipoDocumentoCrear == "CE") {
      this.mostrarBeneficiario = true;
      this.mostrarDireccion = true;
    } else {
      this.mostrarBeneficiario = false;
      this.mostrarDireccion = false;
    }
    if (tipoDocumentoCrear == 'RC') {
      this.getLabelCopiasReciboCaja();
      this.mostrarDireccion = true;
      this.mostrarTercero = true;
    } else {
      this.mostrarDireccion = false;
      this.mostrarTercero = false;
      this.inicializarColumnas();
    }
    switch (accion) {
      case 'copiar':
        this.accionCopiar();
        break;
      case 'crearDocumento':
        this.accionCrearDocumento(tipoDocumentoCrear);
        break;
    }
  }

  cargarData(data) {
    this.inventarios = data;
    this.paginacion = {
      totalPaginas: Math.ceil(this.inventarios.length / 5),
      totalElementos: this.inventarios.length,
      registrosPorPagina: 5,
      numeroElementos: this.inventarios.length,
      orden: 'ASC',
      columnaOrdenar: 'id',
      filtros: []
    };
  }

  cargarDataMensaje(mensaje) {
    this.dataMensaje = mensaje;
  }

  inicialzarFiltroPrefijo(tipoDocumentoCrear) {
    let filtroPrefijoDocumento = new FiltroWo('codigoDocumento', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [tipoDocumentoCrear], 'documentoTipoes', Operador.AND);
    this.filtrosPrefijo = [this.filtroPrefijoVacio, this.filtroPrefijoActivo, filtroPrefijoDocumento];
  }

  private accionCrearDocumento(tipoDocumentoCrear: any) {
    //this.fechaEmision=Object.assign(this.fechaOrigen);
    //this.fechaAnterior=Object.assign(this.fechaOrigen);
    this.formatoFecha(-1);
    this.inicializarColumnasCrearDocumento(tipoDocumentoCrear);
    this.listarRenglonesCrearDocumento(tipoDocumentoCrear);
    this.inicialzarFiltroPrefijo(tipoDocumentoCrear);
    //this.recalcularFechaDocumento=false;
    //this.recalcularFechaEmision=true;
    this.recalcularTRM('recalcularDocumentoOrigen');
    this.recalcularDocumentoOrigen = true;
    if (tipoDocumentoCrear === DocumentoTipoEnum.DEVOLUCION_REMISION || tipoDocumentoCrear == DocumentoTipoEnum.DEVOLUCION_REMISION_COMPRA) {
      this.sinValidarTRM = true;
    } else {
      this.sinValidarTRM = false;
    }
  }

  /**
   * inicializarColumnasCrearDocumento: inicializa el nombre y si la columna es editable para el tipo de documento
   * @param tipoDocumentoCrear
   **/
  inicializarColumnasCrearDocumento(tipoDocumentoCrear): any {
    this.mostrarDocumentoDestino = false;
    this.mostrarOpcionesAdicionales = false;
    this.mostrarDocumentoOrigen = false;
    this.mostrarClasificacionNotaCredito = false;
    this.mostrarMotivoDevolucion = false;
    this.mostrarRecalcularOpciones = true;
    this.mostrarPrefijo = true;
    this.monedaExtranjera = this.manejaMonedaExtranjera();
    this.ocultarForm = [DocumentoTipoEnum.RECIBO_CAJA, DocumentoTipoEnum.COMPROBANTE_EGRESO].includes(tipoDocumentoCrear) ? true : false;
    this.mensaje = null;
    this.mostrarConfirmacion = false;
    this.mostrarFechaEmision = false;
    this.columnasMonedaExtranjera();
    this.columnasVisualizar(tipoDocumentoCrear);
    if (tipoDocumentoCrear == DocumentoTipoEnum.RECIBO_CAJA) {
      this.titulo = 'crearReciboCaja.titulo';
      this.mensaje = 'crearReciboCaja.mensaje';
      this.tituloBoton = '';
      this.mostrarConfirmacion = true;
      this.mostrarMotivoDevolucion = false;
      this.woTablaEnum = 'WT_CREAR_RECIBO_CAJA';
    } else if (tipoDocumentoCrear == DocumentoTipoEnum.NOTA_DEBITO_VENTA) {
      this.consultaPrefijoFechas(tipoDocumentoCrear);
      this.titulo = 'crearNotaDebito.titulo';
      this.tituloBoton = 'crearNotaDebito.seleccionados';
      this.mostrarMotivoDevolucion = true;
      this.woTablaEnum = 'WT_CREAR_NOTA_DEBITO_VENTA';
    } else if (tipoDocumentoCrear == DocumentoTipoEnum.NOTA_CREDITO_VENTA) {
      this.consultaPrefijoFechas(tipoDocumentoCrear);
      this.titulo = 'crearNotaCredito.titulo';
      this.tituloBoton = 'crearNotaCredito.seleccionados';
      this.mostrarMotivoDevolucion = true;
      this.mostrarClasificacionNotaCredito = true;
      this.woTablaEnum = 'WT_CREAR_NOTA_CREDITO_VENTA';
    } else if (tipoDocumentoCrear == DocumentoTipoEnum.DEVOLUCION_REMISION) {
      this.mostrarRecalcularOpciones = false;
      this.titulo = 'crearDevolucionRemision.titulo';
      this.tituloBoton = 'crearDevolucionRemision.seleccionados';
      this.woTablaEnum = 'WT_CREAR_DEVOLUCION_REMISION_VENTA';
    } else if (tipoDocumentoCrear == DocumentoTipoEnum.DEVOLUCION_REMISION_COMPRA) {
      this.mostrarRecalcularOpciones = false;
      this.titulo = 'crearDevolucionRemisionCompra.titulo';
      this.mostrarFechaEmision = true;
      this.tituloBoton = 'crearDevolucionRemisionCompra.seleccionados';
      this.woTablaEnum = 'WT_CREAR_DEVOLUCION_REMISION_COMPRA';
    } else if (tipoDocumentoCrear == DocumentoTipoEnum.COMPROBANTE_EGRESO) {
      this.titulo = 'crearComprobanteEgreso.titulo';
      this.mensaje = 'crearComprobanteEgreso.mensaje';
      this.tituloBoton = '';
      this.mostrarConfirmacion = true;
      this.mostrarMotivoDevolucion = false;
      this.woTablaEnum = 'WT_CREAR_COMPROBANTE_EGRESO';
    } else if (tipoDocumentoCrear == DocumentoTipoEnum.NOTA_DEBITO_COMPRA) {
      this.consultaPrefijoFechas(tipoDocumentoCrear);
      this.titulo = 'crearNotaDebitoProveedores.titulo';
      this.tituloBoton = 'crearNotaDebitoProveedores.seleccionados';
      this.mostrarFechaEmision = true;
      this.woTablaEnum = 'WT_CREAR_NOTA_DEBITO_COMPRA';
    } else if (tipoDocumentoCrear == DocumentoTipoEnum.NOTA_CREDITO_COMPRA) {
      this.consultaPrefijoFechas(tipoDocumentoCrear);
      this.titulo = 'crearNotaCreditoProveedores.titulo';
      this.tituloBoton = 'crearNotaCreditoProveedores.seleccionados';
      this.mostrarClasificacionNotaCredito = true;
      this.mostrarFechaEmision = true;
      this.mostrarMotivoDevolucion = true
      this.woTablaEnum = 'WT_CREAR_NOTA_CREDITO_COMPRA';
      this.filtrosMotivoDevolucion = [new FiltroWo('codigoDocumento', "", null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [tipoDocumentoCrear], 'documentoTipos', Operador.AND, 'filtro')]
    }
    this.titulo = this.translateUtilService.getTranslateText(this.titulo);
    this.inventarios = this.inventarios ? this.inventarios : [];
  }

  columnasVisualizar(tipoDocumentoCrear) {
    if (this.tipoDocumento === 'PD' && tipoDocumentoCrear == DocumentoTipoEnum.RECIBO_CAJA) {
      const COLUMNAS = [
        {
          header: this.translateUtilService.getTranslateText(
            'crearReciboCaja.elegir'
          ),
          atributo: 'seleccionado',
          tipoDato: TipoDato.BOOLEAN,
          tipoInput: TipoInput.CHECK,
          requerido: true,
          estilo: 'colWidth50',
          ordenar: false,
          soloLectura: false,
          hidden: false,
          editable: true,
          exportarExcel: false
        },
        {
          header: this.translateUtilService.getTranslateText(
            'copiaDocumentos.columnasMovContable.concepto'
          ),
          atributo: 'concepto',
          tipoDato: TipoDato.STRING,
          tipoInput: TipoInput.TEXT,
          requerido: true,
          soloLectura: true,
          ordenar: false,
          hidden: false,
          editable: false
        },
        {
          header: this.translateUtilService.getTranslateText(
            'copiaDocumentos.columnasMovContable.cuentaDebito'
          ),
          atributo: 'cuentaDebitoMostrar',
          tipoDato: TipoDato.STRING,
          tipoInput: TipoInput.TEXT,
          requerido: true,
          estilo: 'colWidth100',
          soloLectura: true,
          ordenar: false,
          hidden: false,
          editable: false
        },
        {
          header: this.translateUtilService.getTranslateText(
            'copiaDocumentos.columnasMovContable.cuentaCredito'
          ),
          atributo: 'cuentaCreditoMostrar',
          tipoDato: TipoDato.STRING,
          tipoInput: TipoInput.TEXT,
          requerido: true,
          estilo: 'colWidth100',
          soloLectura: true,
          ordenar: false,
          hidden: false,
          editable: false
        }
      ];
      this.columnas = COLUMNAS;
      this.opcionesTabla.menu = [];
      this.opcionesTabla = Object.assign({}, this.opcionesTabla);
    } else if (tipoDocumentoCrear == DocumentoTipoEnum.RECIBO_CAJA || tipoDocumentoCrear == DocumentoTipoEnum.COMPROBANTE_EGRESO) {
      const COLUMNAS = [
        {
          header: this.translateUtilService.getTranslateText(
            tipoDocumentoCrear == DocumentoTipoEnum.RECIBO_CAJA ? 'crearReciboCaja.elegir' : 'crearComprobanteEgreso.elegir'
          ),
          atributo: 'seleccionado',
          tipoDato: TipoDato.BOOLEAN,
          tipoInput: TipoInput.CHECK,
          requerido: true,
          estilo: 'colWidth50',
          soloLectura: false,
          hidden: false,
          editable: true,
          exportarExcel: false
        },
        {
          header: this.translateUtilService.getTranslateText(
            'copiaDocumentos.columnasMovContable.concepto'
          ),
          atributo: 'concepto',
          tipoDato: TipoDato.STRING,
          tipoInput: TipoInput.TEXT,
          requerido: true,
          soloLectura: true,
          ordenar: false,
          hidden: false,
          editable: false
        },
        {
          header: this.translateUtilService.getTranslateText(
            'copiaDocumentos.columnasMovContable.cuenta'
          ),
          atributo: 'cuentaMostrar',
          tipoDato: TipoDato.STRING,
          tipoInput: TipoInput.TEXT,
          requerido: true,
          estilo: 'colWidth100',
          soloLectura: true,
          ordenar: false,
          hidden: false,
          editable: false
        },
        {
          header: this.translateUtilService.getTranslateText(
            'copiaDocumentos.columnasMovContable.prefijo'
          ),
          atributo: 'prefijo',
          tipoDato: TipoDato.STRING,
          tipoInput: TipoInput.TEXT,
          requerido: true,
          soloLectura: true,
          ordenar: false,
          hidden: false,
          editable: false
        },
      ];
      this.columnas = COLUMNAS;
      this.opcionesTabla.menu = [];
      this.opcionesTabla = Object.assign({}, this.opcionesTabla);
    } else {
      this.columnas[1].atributo = 'codigo';
      this.columnas[1].editable = false;
      this.columnas[2].atributo = 'descripcion';
      this.columnas[2].editable = false;
      this.columnas[3].editable = false;
      this.columnas[4].editable = false;
      this.columnas[5].editable = false;
      this.columnas = this.columnas.map(x => Object.assign({}, x));
    }
  }

  consultaPrefijoFechas(tipoDocumentoCrear) {
    this.copiarDocumentosService.getPrefijoFecha(this.idDocumento, this.tipoDocumento, tipoDocumentoCrear).subscribe((result) => {
      const PREFIJO = {
        id: result ? result.idPrefijo : null,
        codigo: result ? result.idPrefijo : null,
        nombre: result ? result.nombrePrefijo : null
      };
      if (PREFIJO.id && PREFIJO.nombre) {
        this.prefijo = PREFIJO;
      }

      const FECHA = result ? result.fechaConf : null;
      if (FECHA) {
        this.fecha = FECHA;
      }
    });
  }

  /**
   * listarRenglonesCrearDocumento: lista los renglones sobre los cuales se va a crear un nuevo documento
   * @param tipoDocumentoCrear tipo de documento para el cual se listan los renglones
   */
  private listarRenglonesCrearDocumento(tipoDocumentoCrear: any) {
    if (tipoDocumentoCrear == DocumentoTipoEnum.DEVOLUCION_REMISION) {
      this.listarRenglonesDevolucionRemision();
    }
    else if (tipoDocumentoCrear == DocumentoTipoEnum.NOTA_CREDITO_VENTA) {
      this.clasificacionNotaCredito = {
        codigo: DocumentoTipoNotaCreditoEnum.DEVOLUCION_CLIENTES,
        tipoNotaCredito: 'Devolucion'
      };
      this.columnas[3].editable = this.clasificacionNotaCredito.tipoNotaCredito == 'Devolucion'
      this.columnas = this.columnas.map(x => Object.assign({}, x));
      this.inicializarFiltrosMotivoDevolucion(tipoDocumentoCrear);
      this.inicializarFiltrosDocumentoTipoNotaCredito(tipoDocumentoCrear);
      this.listarRenglonesNotaCreditoDebito(this.clasificacionNotaCredito ? this.clasificacionNotaCredito.codigo : -1, tipoDocumentoCrear);
    }
    else if (tipoDocumentoCrear == DocumentoTipoEnum.NOTA_DEBITO_VENTA) {
      this.inicializarFiltrosMotivoDevolucion(tipoDocumentoCrear);
      this.listarRenglonesNotaCreditoDebito(this.clasificacionNotaCredito ? this.clasificacionNotaCredito.codigo : -1, tipoDocumentoCrear);
    }
    else if (tipoDocumentoCrear == DocumentoTipoEnum.DEVOLUCION_REMISION_COMPRA) {
      this.listarRenglonesDevolucionRemisionCompra();
    }
    else if (tipoDocumentoCrear == DocumentoTipoEnum.NOTA_DEBITO_COMPRA) {
      this.listarRenglonesNotaCreditoDebitoCompra(this.clasificacionNotaCredito ? this.clasificacionNotaCredito.codigo : -1, tipoDocumentoCrear);
    }
    else if (tipoDocumentoCrear == DocumentoTipoEnum.NOTA_CREDITO_COMPRA) {
      this.clasificacionNotaCredito = { codigo: DocumentoTipoNotaCreditoEnum.DEVOLUCION_PROVEEDORES, tipoNotaCredito: 'Devolucion' };
      this.inicializarFiltrosDocumentoTipoNotaCredito(tipoDocumentoCrear);
      this.listarRenglonesNotaCreditoDebitoCompra(this.clasificacionNotaCredito ? this.clasificacionNotaCredito.codigo : -1, tipoDocumentoCrear);
    }

  }

  private columnasMonedaExtranjera() {
    if (this.monedaExtranjera) {
      this.columnas[4].header = 'Valor Moneda Extranjero';
      this.columnas[4].moneda = this.monedaOrigen;
    }
    else {
      this.columnas[4].header = 'Valor Unitario';
      this.columnas[4].moneda = null;
    }
  }

  private inicializarFiltrosMotivoDevolucion(documentoTipo) {
    this.filtrosMotivoDevolucion.push(this.getFiltroPaisLocal());
    this.filtrosMotivoDevolucion.push(this.getFiltroMotivoDevolucion(documentoTipo));
    this.filtrosMotivoDevolucion.push(
      new FiltroWo('motivo', '', null, TipoFiltro.IGUAL,
        TipoDato.NOT_IN, null, [TipoDevolucionEnum.NOTA_CREDITO_SIN_REFERENCIA_FACTURAS,
        TipoDevolucionEnum.NOTA_DEBITO_SIN_REFERENCIA_FACTURAS], 'DocumentoMotivosGeneracion', Operador.AND)
    );
  }

  private inicializarFiltrosDocumentoTipoNotaCredito(documentoTipo) {
    this.filtrosDocumentoTipoNotaCredito = [];
    if (documentoTipo == DocumentoTipoEnum.NOTA_CREDITO_VENTA) {
      this.filtrosDocumentoTipoNotaCredito.push(new FiltroWo('codigo', '1', null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'DocumentoTipoNotaCredito', Operador.OR));
      this.filtrosDocumentoTipoNotaCredito.push(new FiltroWo('codigo', '2', null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'DocumentoTipoNotaCredito', Operador.OR));
    } else {
      this.filtrosDocumentoTipoNotaCredito.push(new FiltroWo('codigo', '3', null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'DocumentoTipoNotaCredito', Operador.OR));
      this.filtrosDocumentoTipoNotaCredito.push(new FiltroWo('codigo', '4', null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'DocumentoTipoNotaCredito', Operador.OR));
    }
  }

  paginacion: any;

  /**
  * listarRenglonesNotaCreditoDebito: lista los movimientos inventarios con cantidades disponibles o no
  * cruzados.
  * @param clasificacionNotaCredito clasificacion de la nota credito seleccionada por el usuario.
  * @param tipoDocumento puede ser nota credito o debito.
  */
  private listarRenglonesNotaCreditoDebito(clasificacionNotaCredito: any, tipoDocumento) {
    let paginacion = new PaginacionWo('id', 0, 100000000, 'ASC');
    let sub = this.copiarDocumentosService.getMovimientoInventarioCrearNotaCreditoDebito(paginacion, this.idDocumento, clasificacionNotaCredito, tipoDocumento, this.fecha).subscribe(
      response => {
        this.fechaAnterior = this.fecha.slice();
        this.columnas = this.columnas.map(x => {
          if (x.atributo === 'seleccionado') x.checkHeader.valor = false;
          return Object.assign(x, {});
        });
        this.itemsSeleccionados = [];
        if (response.content.length == 0) {
          this.opcionesTabla.vacio = true;
          this.opcionesTabla = Object.assign({}, this.opcionesTabla);
        }
        let inventario = response.content;
        this.inventarios = inventario.slice();
        this.inventarios.forEach(inv => {
          this.cantidadesValidas.push(inv.cantidadDisponible);
        });
        this.paginacion = {
          totalPaginas: response.totalPages,
          totalElementos: response.totalElements,
          registrosPorPagina: paginacion.registrosPorPagina,
          numeroElementos: response.numberOfElements,
          orden: 'ASC',
          columnaOrdenar: 'id',
          filtros: paginacion.filtros
        };
        this.crearDocumentoPojo = new CrearDocumentoPojo(clasificacionNotaCredito, tipoDocumento, this.fecha);
      }, error => {
        this.errorHandlerComponent.handler(error);
      });
    this.subscriptions.add(sub);
  }

  filtroClassInventario: FiltroWo = new FiltroWo(
    'inventario.inventarioClasificacion.id',
    null,
    null,
    TipoFiltro.IGUAL,
    TipoDato.LONG,
    null,
    null,
    null,
    Operador.AND
  );

  filtroEncabezadoInventario: FiltroWo = new FiltroWo(
    'documentoEncabezado.id',
    '' + this.idDocumento,
    null,
    TipoFiltro.IGUAL,
    TipoDato.LONG,
    null,
    null,
    null,
    Operador.AND
  );

  /**
   * listarRenglonesNotaCreditoDebito: lista los movimientos inventarios con cantidades disponibles o no
   * cruzados.
   * @param clasificacionNotaCredito clasificacion de la nota credito seleccionada por el usuario.
   * @param tipoDocumento puede ser nota credito o debito.
   */
  private listarRenglonesSaldosIniciales(tipo: any) {
    this.inventarios = [];
    let paginacion = new PaginacionWo('id', 0, 0, 'ASC');

    this.filtroEncabezadoInventario.valor = '' + this.idDocumento;
    paginacion.filtros.push(this.filtroEncabezadoInventario);

    if (tipo != '-1') {
      this.filtroClassInventario.valor = tipo;
      paginacion.filtros.push(this.filtroClassInventario);
      this.servicioInventariosSI(tipo, paginacion);
    } else {
      this.servicioContableSI(tipo, paginacion);
    }

  }

  servicioInventariosSI(tipo, paginacion) {
    let sub = this.copiarDocumentosService.getInventariosSI(this.idDocumento, paginacion).subscribe(
      response => {
        this.paginacion = {
          totalPaginas: response.totalPages,
          totalElementos: response.totalElements,
          registrosPorPagina: paginacion.registrosPorPagina,
          numeroElementos: response.numberOfElements,
          orden: 'ASC',
          columnaOrdenar: 'id',
          filtros: paginacion.filtros
        };
        this.inventarios = response.content.slice();
        this.actualizarValoresSaldos();
        this.columnas[0].checkHeader.valor = false;
        if (this.saldosSeleccionados.length > 0) {
          if (this.inventarios.length == this.saldosSeleccionados.length && this.inventarios.findIndex(x => x.id == this.saldosSeleccionados[0].id) != -1) {
            this.columnas[0].checkHeader.valor = true;
          }
        }
      },
      error => {
        this.errorHandlerComponent.handler(error);
      }
    );
    this.subscriptions.add(sub);
  }


  servicioContableSI(tipo, paginacion) {
    let sub = this.copiarDocumentosService.getContableSI(this.idDocumento, paginacion).subscribe(
      response => {
        this.inventarios = response.content.slice();
        this.actualizarValoresSaldos();
        if (this.saldosContablesSeleccionados.length > 0) {
          if (this.inventarios.length == this.saldosContablesSeleccionados.length && this.inventarios.findIndex(x => x.id == this.saldosContablesSeleccionados[0].id) != -1) {
            this.columnas[0].checkHeader.valor = true;
          }
        }
      },
      error => {
        this.errorHandlerComponent.handler(error);
      }
    );
    this.subscriptions.add(sub);
  }

  /**
   * listarRenglonesNotaCreditoDebitoCompra: lista los movimientos inventarios con cantidades disponibles o no
   * cruzados.
   * @param clasificacionNotaCredito clasificacion de la nota credito seleccionada por el usuario.
   * @param tipoDocumento puede ser nota credito o debito.
   */
  private listarRenglonesNotaCreditoDebitoCompra(clasificacionNotaCredito: any, tipoDocumento) {
    let paginacion = new PaginacionWo('id', 0, 100000000, 'ASC');
    let sub = this.copiarDocumentosService.getMovimientoInventarioCrearNotaCreditoDebitoProveedores(paginacion, this.idDocumento, clasificacionNotaCredito, tipoDocumento, this.fecha).subscribe(
      response => {
        this.columnas = this.columnas.map(x => {
          if (x.atributo === 'seleccionado') x.checkHeader.valor = false;
          return Object.assign(x, {});
        });
        this.itemsSeleccionados = [];
        if (response.content.length == 0) {
          this.opcionesTabla.vacio = true;
          this.opcionesTabla = Object.assign({}, this.opcionesTabla);
        }
        let inventario = response.content;
        this.inventarios = inventario.slice();
        this.inventarios.forEach(inv => {
          this.cantidadesValidas.push(inv.cantidadDisponible);
        });
        this.paginacion = {
          totalPaginas: response.totalPages,
          totalElementos: response.totalElements,
          registrosPorPagina: paginacion.registrosPorPagina,
          numeroElementos: response.numberOfElements,
          orden: 'ASC',
          columnaOrdenar: 'id',
          filtros: paginacion.filtros
        };
        this.crearDocumentoPojo = new CrearDocumentoPojo(clasificacionNotaCredito, tipoDocumento, this.fecha);
      }, error => {
        this.errorHandlerComponent.handler(error);
      });
    this.subscriptions.add(sub);
  }

  /**
   * listarRenglonesDevolucionRemision:
   */
  private listarRenglonesDevolucionRemision() {
    let paginacion = new PaginacionWo('id', 0, 100000000, 'ASC');
    let filtro = new FiltroWo('documentoMovimientoInventario.documentoEncabezado.id', this.idDocumento, null, TipoFiltro.IGUAL, TipoDato.LONG, 'documentoMovimientoInventario.documentoEncabezado.id', null, 'DocumentoCruceInventario', Operador.AND);
    paginacion.filtros = [];
    paginacion.filtros.push(filtro);
    let sub = this.copiarDocumentosService.getMovimientoInventarioCrearDevolucionRemisionVentas(paginacion, this.idDocumento).subscribe(response => {
      if (response.content.length == 0) {
        this.opcionesTabla.vacio = true;
        this.opcionesTabla = Object.assign({}, this.opcionesTabla);
      }
      let inventario = response.content;
      this.inventarios = inventario.slice();
      this.inventarios.forEach(inv => {
        this.cantidadesValidas.push(inv.cantidadDisponible);
      });
      this.paginacion = {
        totalPaginas: response.totalPages,
        totalElementos: response.totalElements,
        registrosPorPagina: paginacion.registrosPorPagina,
        numeroElementos: response.numberOfElements,
        orden: 'ASC',
        columnaOrdenar: 'id',
        filtros: paginacion.filtros
      };
    }, error => {
      this.errorHandlerComponent.handler(error);
    });
    this.subscriptions.add(sub);
  }

  /**
   * listarRenglonesDevolucionRemisionCompra:
   */
  listarRenglonesDevolucionRemisionCompra(): any {
    let paginacion = new PaginacionWo('id', 0, 100000000, 'ASC');
    let filtro = new FiltroWo('documentoMovimientoInventario.documentoEncabezado.id', this.idDocumento, null, TipoFiltro.IGUAL, TipoDato.LONG, 'documentoMovimientoInventario.documentoEncabezado.id', null, 'DocumentoCruceInventario', Operador.AND);
    paginacion.filtros = [];
    paginacion.filtros.push(filtro);
    let sub = this.copiarDocumentosService.getMovimientoInventarioCrearDevolucionRemisionCompras(paginacion, this.idDocumento).subscribe(response => {
      if (response.content.length == 0) {
        this.opcionesTabla.vacio = true;
        this.opcionesTabla = Object.assign({}, this.opcionesTabla);
      }
      let inventario = response.content;
      this.inventarios = inventario.slice();
      this.inventarios.forEach(inv => {
        this.cantidadesValidas.push(inv.cantidadDisponible);
      });
      this.paginacion = {
        totalPaginas: response.totalPages,
        totalElementos: response.totalElements,
        registrosPorPagina: paginacion.registrosPorPagina,
        numeroElementos: response.numberOfElements,
        orden: 'ASC',
        columnaOrdenar: 'id',
        filtros: paginacion.filtros
      };
    }, error => {
      this.errorHandlerComponent.handler(error);
    });
    this.subscriptions.add(sub);

  }

  private accionCopiar() {
    this.mostrarConfirmacion = false;
    this.mostrarFechaEmision = false;
    this.ocultarForm = false;
    this.mensaje = null;
    this.columnas = [];
    this.saldosSeleccionados = [];
    this.saldosContablesSeleccionados = [];
    this.inicializarColumnas();
    this.editarColumnasCopiar();
    this.cargarItemsAccionCopiar();
    this.formatoFecha(-1);
  }

  /**
   * editarColumnasCopiar: coloca en modo no editable algunas columnas de la tabla.
   */
  private editarColumnasCopiar() {
    this.mostrarDocumentoDestino = true;
    this.mostrarDocumentoOrigen = true;
    this.mostrarClasificacionNotaCredito = false;
    this.mostrarMotivoDevolucion = false;
    this.mostrarPrefijo = false;
    this.mostrarOpcionesAdicionales = true;
    this.titulo = 'copiaDocumentos.titulo';
    this.tituloBoton = 'copiaDocumentos.seleccionados';
    this.titulo = this.translateUtilService.getTranslateText(this.titulo);
  }

  /**
   * cargarItemsAccionCopiar: llena la tabla del popup segun el movimiento inventario del documento.
   */
  private cargarItemsAccionCopiar() {

    if (this.tipoDocumento == DocumentoTipoEnum.SALDOS_INICIALES) {
      this.listarRenglonesSaldosIniciales(ClasificacionInventarioEnum.PRODUCTO);
    }
    else if (this.tipoMovimiento == MovimientoTipoEnum.INVENTARIO) {
      this.getMovimientosInventario();
    } else {
      this.getMovimientosContables();
    }
  }

  /**
   * getMovimientosInventario: invoca un servicio para llenar la tabla con movimientos inventario.
   */
  private getMovimientosInventario() {
    let paginacion = new PaginacionWo("id", 0, 0, "ASC", [], Canal.WEB)
    this.monedaExtranjera = this.manejaMonedaExtranjera();
    if (this.monedaExtranjera) {
      this.columnas[4].header = 'Valor Moneda Extranjero';
      this.columnas[4].moneda = this.monedaOrigen;
    } else {
      this.columnas[4].header = 'Valor Unitario';
      this.columnas[4].moneda = null;
    }
    let sub = this.copiarDocumentosService.getMovimientoInventario(this.idDocumento, paginacion).subscribe(
      response => {
        this.inventarios = response.content.slice();
        this.paginacion = {
          totalPaginas: response.totalPages,
          totalElementos: response.totalElements,
          registrosPorPagina: paginacion.registrosPorPagina,
          numeroElementos: response.numberOfElements,
          orden: 'ASC',
          columnaOrdenar: 'id',
          filtros: paginacion.filtros
        };
        if (this.tipoDocumento != DocumentoTipoEnum.DEPRECIACION) {
          this.validarRenglonActivoFijo();
        }
      },
      error => {
        this.errorHandlerComponent.handler(error);
      }
    );
    this.subscriptions.add(sub);
  }

  validarRenglonActivoFijo() {
    this.hayRenglonActivoFijo = false;
    this.inventarios.forEach(element => {
      if (element.clasificacion == InventarioClasificacionEnum.ACTIVO_FIJO) {
        this.hayRenglonActivoFijo = true;
        element.mapaBloqueado = new Map<number, Array<number>>();
        let index = this.columnas.findIndex(element => element.atributo == 'seleccionado');
        element.mapaBloqueado.set(element.id, [index]);
      }
    })
  }

  /**
   * getMovimientosContables: invoca un servicio para llenar la tabla con movimientos contables.
   */
  private getMovimientosContables() {
    let paginacion = new PaginacionWo("id", 0, 0, "ASC", [], Canal.WEB)
    let sub = this.copiarDocumentosService
      .getMovimientoContable(this.idDocumento, paginacion)
      .subscribe(
        response => {
          this.paginacion = {
            totalPaginas: response.totalPages,
            totalElementos: response.totalElements,
            registrosPorPagina: paginacion.registrosPorPagina,
            numeroElementos: response.numberOfElements,
            orden: 'ASC',
            columnaOrdenar: 'id',
            filtros: paginacion.filtros
          };
          this.inventarios = response.content.slice();
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
    this.subscriptions.add(sub);
  }

  respuestaConfirmacion(event) {
    if (event) {
      this.enviarMensaje();
    } else {
      this.cerrarPopup();
    }
  }

  afterChange(e) {
    if (e.columna === 'cantidad' && e.edita) {
      let valorDevuelto = false;
      this.inventarios.forEach(inv => {
        if (parseFloat(inv.cantidad) > parseFloat(inv.cantidadDisponible)) {
          valorDevuelto = true;
          this.popUpService.open({ codigoError: '090', severidad: SeveridadEnum.WARNING });
          setTimeout(() => {
            this.inventarios[e.fila].cantidad = e.valorAnterior;
          }, 200);
        }
      });

      if (valorDevuelto) {
        setTimeout(() => {
          this.inventarios = this.inventarios.map(x => Object.assign({}, x));
        }, 500);
      }
    }
    if (e.columna === 'valorUnitario') {
      if (parseFloat(e.valorNuevo) > parseFloat(e.valorAnterior)) {
        this.popUpService.open({ codigoError: 'validacionValorUnitarioenNCVNDV', severidad: SeveridadEnum.WARNING })
        setTimeout(() => {
          this.inventarios[e.fila].valorUnitario = e.valorAnterior;
          this.inventarios = this.inventarios.map(x => Object.assign({}, x));
        }, 200);
      }
    }
    if (e.checkHeader) {
      this.seleccionarTodos(e.columna.checkHeader.valor ? true : false);
    } else if (e.columna === 'seleccionado') {
      const COL_SEL = this.columnas.find(({ atributo }) => atributo === 'seleccionado');
      const TODOS_SEL = COL_SEL && COL_SEL.checkHeader ? COL_SEL.checkHeader.valor : false;
      if (TODOS_SEL && !e.valorNuevo) {
        COL_SEL.checkHeader.valor = false;
      }
    }

    if (e.edita) {
      let actualPrincipal = this.buscarElementoListaSeleccionados(e);
      if (e.columna == 'seleccionado') {
        if ((this.tipoDocumento === 'PD' && this.tipoDocumentoCrear == DocumentoTipoEnum.RECIBO_CAJA) || (this.tipoDocumentoCrear == DocumentoTipoEnum.RECIBO_CAJA || this.tipoDocumentoCrear == DocumentoTipoEnum.COMPROBANTE_EGRESO)) {
          if (this.inventarios[e.fila].seleccionado) {
            this.cuentaSel = this.inventarios[e.fila].id;
            this.inventarios.map((element) => {
              if (element.seleccionado && element.id != this.cuentaSel) {
                element.seleccionado = false;
              }
              return element;
            });
          } else {
            this.cuentaSel = null;
          }
        } else {
          this.agregarSeleccionado(e, actualPrincipal, e.fila);
        }
      } else {
        if (this.accion == 'crearDevolucionRemision') {
          this.validarCantidadMenor(e);
        } else {
          this.inventarios[e.fila][e.columna] = e.valorNuevo;
        }
      }
    }
    if (e.ordenar === true) {
      this.cargarItems(this.accion, this.tipoDocumentoCrear);
    }
    if (e.pag === true) {
      // this.cargarItems(this.accion, this.tipoDocumentoCrear);
    }
  }

  enviarMensaje() {
    this._LoaderService.colocarMascara("popUpCopiar-Documento", { tabla: false }, "loadingDialog", "dialog-copiarDocumentoFoco");
    if (!this.dataMensaje) {
      this.dataMensaje = {
        accion: this.tipoDocumentoCrear == DocumentoTipoEnum.RECIBO_CAJA ? 'crearRCDesdeFV' : 'crearCEDesdeFC',
        clase: 'DocumentoEncabezado',
        id: this.idDocumento,
        atributo: 'id',
        prioridad: true,
        valor: { idCuentaContableConf: this.cuentaSel },
        tipoDato: TipoDato[TipoDato.LONG],
        permisoGrueso: this.tipoDocumento
      };
    } else {
      this.dataMensaje.valor.idCuentaContableConf = this.cuentaSel;
    }

    const COMPROBAR = this.serviceCola.agregarACola(this.dataMensaje);
    if (COMPROBAR) {
      COMPROBAR.subscribe(result => {
        const response = result[0];
        this.cerrarPopup();
        this._LoaderService.quitarMascara("popUpCopiar-Documento", "tabla", "loadingDialog", "dialog-copiarDocumentoFoco");
        if (response) {
          if (response.codigoError) {
            if (response.valor) {
              this.esperarRespuesta();
              this.popUpService.open({ codigoError: response.codigoError, severidad: response.severidad, conBoton: undefined, detalle: undefined, detalles: [response.valor] });
            } else {
              this.esperarRespuesta();
              this.popUpService.open({ codigoError: response.codigoError, severidad: response.severidad });
            }
            this.inventarios.forEach(element => {
              element.seleccionado = false;
            });
            this.inventarios = this.inventarios.map(x => Object.assign({}, x));
          } else if (response.valor) {
            if (!this.POS) {
              const URL = this.tipoDocumentoCrear == DocumentoTipoEnum.RECIBO_CAJA ? '/panel/contabilidad/recibos-caja/detalle' : '/panel/contabilidad/comprobante-egreso/detalle';
              const NOMBRE = this.tipoDocumentoCrear == DocumentoTipoEnum.RECIBO_CAJA ? 'ReciboCajaDetalle' : 'ComprobanteDeEgresoDetalle';
              this.tabsService.openTab(new Tab(URL, NOMBRE, ['' + response.id], { crea: false }));
            } else {
              result.forEach(res => {
                if (res.accion === 'crearRCDesdeFV' && res.atributo) {
                  this.popUpService.open({
                    codigoError: 'mensajeReciboDeCaja', detalles: [res.atributo],
                    severidad: SeveridadEnum.SUCCESS,
                    conBoton: true,
                    botonSiNo: true
                  });
                  this.esperarRespuestaPopUp(res.id);
                }
              });
            }
          }
        }
      });
    } else {
      this._LoaderService.quitarMascara("popUpCopiar-Documento", "tabla", "loadingDialog", "dialog-copiarDocumentoFoco");
    }
  }

  /**
   * vevent
   * alida  rCantidadMenor: para la accion de devolucion de remision, se valida que la cantidad
   * que se va a cruzar sea inferior a la del documento original, de lo contrario se le muestra al usuario une error.
   * @param e evento del afterchange con los valores ingresados por el usuario.
   */
  private validarCantidadMenor(e: any) {
    if (this.cantidadesValidas[e.fila] >= e.valorNuevo) {
      this.inventarios[e.fila][e.columna] = e.valorNuevo;
    } else {
      this.inventarios[e.fila][e.columna] = this.cantidadesValidas[e.fila];
      this.inventarios = this.inventarios.map(x => Object.assign({}, x));
      this.verPopup('090', SeveridadEnum.ERROR, '');
    }
  }

  /**

event  * agregarSeleccionado: Agrega o elimina un elemento de la lista de seleccionados.
   *   @param e evento del afterchange
   * @param actualPrincipal valor 0 o -1 que indica si el elemento ya se encontraba dentro de la lista de
   * seleccionados
   * @param fila fila que se esta agregando o sacando de la lista de seleccionados
   */
  private agregarSeleccionado(e: any, actualPrincipal: number, fila: any) {
    if (e.valorNuevo) {
      if (actualPrincipal == -1) {
        this.itemsSeleccionados.push(this.inventarios[fila]);
        //Validacion adicional para saldos iniciales
        if (this.tipoDocumento == DocumentoTipoEnum.SALDOS_INICIALES && this.tipoSaldo != '-1') {
          this.saldosSeleccionados.push(this.inventarios[fila]);
        } else if (this.tipoDocumento == DocumentoTipoEnum.SALDOS_INICIALES && this.tipoSaldo == '-1') {
          this.saldosContablesSeleccionados.push(this.inventarios[fila]);
        }
      }
    } else {
      if (actualPrincipal != -1) {
        this.itemsSeleccionados.splice(actualPrincipal, 1);
        //Validacion adicional para saldos iniciales
        if (this.tipoDocumento == DocumentoTipoEnum.SALDOS_INICIALES && this.tipoSaldo != '-1') {
          let idInv = this.inventarios[fila].id;
          let seleccionado = this.saldosSeleccionados.findIndex(x => x.id == idInv); //-1
          if (seleccionado != -1) {
            this.saldosSeleccionados.splice(seleccionado, 1);
          }
        } else if (this.tipoDocumento == DocumentoTipoEnum.SALDOS_INICIALES && this.tipoSaldo == '-1') {
          let idInv = this.inventarios[fila].id;
          let seleccionado = this.saldosContablesSeleccionados.findIndex(x => x.id == idInv); //-1
          if (seleccionado != -1) {
            this.saldosContablesSeleccionados.splice(seleccionado, 1);
          }
        }
      }
      if (this.seCopianTodos) {
        this.columnas[0].checkHeader.valor = false;
        this.seCopianTodos = false;
      }
    }
    // si se seleccionan todos de forma manual se activa el check de la columna de todos
    if (this.columnas[0].checkHeader) {
      this.columnas[0].checkHeader.valor = this.itemsSeleccionados.length === this.inventarios.length;
    }
  }

  /**
   * bevent
   * uscar  ElementoListaSeleccionados: busca el elemento dentro de la lista de seleccionados, para que posteriormente sea agregado o eliminado
   * de la lista.
   * @param e evento del afterchange
   */
  private buscarElementoListaSeleccionados(e: any) {
    let idAgregar = this.inventarios[e.fila].id;
    let actualPrincipal = this.itemsSeleccionados.findIndex(
      x => x.id == idAgregar
    );
    return actualPrincipal;
  }

  /**
   * deshabilitarBoton: deshabilita el botón de copiar o crear devolución de remisión, hasta que se llenen algunos campos
   * obligatorios del encabezado hasta que se seleccione almenos un elemento de la tabla de copiar.
   */
  deshabilitarBoton() {
    if (this.accion == 'copiar' && this.tipoDocumento != DocumentoTipoEnum.SALDOS_INICIALES) {
      if (this.itemsSeleccionados.length > 0 && this.empresa != null && this.fecha != null && this.documentoTipo != null) {
        return false;
      } else {
        return true;
      }
    } else if (this.accion == 'copiar' && this.tipoDocumento == DocumentoTipoEnum.SALDOS_INICIALES) {
      if ((this.saldosSeleccionados.length > 0 || this.saldosContablesSeleccionados.length > 0) && this.empresa != null && this.fecha != null && this.documentoTipo != null) {
        return false;
      } else {
        return true;
      }
    } else if (this.tipoDocumentoCrear == DocumentoTipoEnum.NOTA_DEBITO_VENTA) {
      if (this.itemsSeleccionados.length > 0 && this.empresa != null && this.fecha != null && this.motivoDevolucion) {
        return false;
      } else {
        return true;
      }
    } else if (this.tipoDocumentoCrear == DocumentoTipoEnum.NOTA_CREDITO_VENTA) {
      if (this.itemsSeleccionados.length > 0 && this.empresa != null && this.fecha != null && this.motivoDevolucion && this.clasificacionNotaCredito != null) {
        return false;
      } else {
        return true;
      }
    } else {
      if (this.itemsSeleccionados.length > 0 && this.empresa != null && this.fecha != null) {
        return false;
      } else {
        return true;
      }
    }
  }

  /**
   * ejecutarAccion: invoca el metodo de copiar o crear devolucion de remision cuando el usuario oprime el botón.
   */
  ejecutarAccion() {
    this._LoaderService.colocarMascara("popUpCopiar-Documento", { tabla: false }, "loadingDialog", "dialog-copiarDocumentoFoco");
    if (!this.deshabilitarBoton()) {
      const ES_DEVOLUCION_REMISION = this.tipoDocumentoCrear == DocumentoTipoEnum.DEVOLUCION_REMISION;
      const ES_REMISION_COMPRA = this.tipoDocumentoCrear == DocumentoTipoEnum.DEVOLUCION_REMISION_COMPRA;
      const ES_NOTA_CREDITO = this.tipoDocumentoCrear == DocumentoTipoEnum.NOTA_CREDITO_VENTA;
      const ES_NOTA_DEBITO = this.tipoDocumentoCrear == DocumentoTipoEnum.NOTA_DEBITO_VENTA;
      const ES_NOTA_DEBITO_PROVEEDOR = this.tipoDocumentoCrear == DocumentoTipoEnum.NOTA_DEBITO_COMPRA;
      const ES_NOTA_CREDITO_PROVEEDOR = this.tipoDocumentoCrear == DocumentoTipoEnum.NOTA_CREDITO_COMPRA;

      if (this.accion == 'copiar' && this.tipoDocumento != DocumentoTipoEnum.SALDOS_INICIALES) {
        this.copiarSeleccionados();
      } else if (this.accion == 'copiar' && this.tipoDocumento == DocumentoTipoEnum.SALDOS_INICIALES) {
        this.copiarSeleccionadosSI();
      } else if (ES_DEVOLUCION_REMISION) {
        this.crearDevolucionRemisionVentas();
      } else if (ES_REMISION_COMPRA) {
        this.crearDevolucionRemisionCompras()
      } else if (ES_NOTA_CREDITO || ES_NOTA_DEBITO) {
        this.crearNotaCreditoDebito(false)
      } else if (ES_NOTA_DEBITO_PROVEEDOR || ES_NOTA_CREDITO_PROVEEDOR) {
        this.crearNotaCreditoDebitoProveedores();
      }
    } else {
      const COL_SEL = this.columnas.find(({ atributo }) => atributo === 'seleccionado');
      if (COL_SEL && COL_SEL.checkHeader) {
        COL_SEL.checkHeader.valor = false;
      }
      this._LoaderService.quitarMascara("popUpCopiar-Documento", "tabla", "loadingDialog", "dialog-copiarDocumentoFoco")

      let error = '';
      if (this.accion == 'copiar') {
        error = 'llenarFormularioCopiar'
      } else {
        error = 'llenarFormularioCrearDoc'
      }
      this.esperarRespuesta();
      this.popUpService.open({ codigoError: error, severidad: SeveridadEnum.ERROR });
      this.completo = true;
    }
  }


  /**
   * copiarSeleccionados: copia los inventarios seleccinados a un nuevo documento.
   */
  copiarSeleccionados() {
    let fecha = this.fecha;
    let empresa = this.empresa.id;
    let documentoTipo = this.documentoTipo.id;
    let prefijo = this.prefijo;
    let conceptoAs = this.conceptoAs;
    // -1 SI EL DOCUMENTO NO TIENE FORMA DE PAGO
    let formaPago = this.formaPago ? this.formaPago.id : -1;
    let recalcularValores = this.manejaMonedaExtranjera ? this.recalcularValores : RecalcularEnBaseAEnum.FECHA_DOCUMENTO_ORIGEN;
    let renglones = new Array();

    this.itemsSeleccionados.forEach(element => {
      if (element.id != undefined) {
        renglones.push(element.id);
      }
    });

    let data = {
      fecha: fecha,
      empresa: empresa,
      documentoTipo: documentoTipo,
      prefijo: prefijo.id,
      formaPago,
      renglones: renglones,
      ponerConcepto: conceptoAs,
      recalcularValores: recalcularValores,
      cantidades: [],
      prefijoExterno: (this.prefijoExterno == undefined || this.prefijoExterno == null) ? "" : this.prefijoExterno,
      documentoExterno: (this.documentoExterno == undefined || this.documentoExterno == null) ? "" : this.documentoExterno,
      todos: this.seCopianTodos ? true : false
    };

    this.dataEnvio = data;

    let mensaje = {
      clase: 'DocumentoEncabezado',
      id: this.idEncabezado,
      accion: 'copiarDocumento',
      atributo: 'copia',
      valor: data,
      prioridad: true,
      tipoDato: 'POJO',
      permisoGrueso: this.tipoDocumento
    };

    let observable = this.serviceCola.agregarACola(mensaje);
    if (observable) {
      let sub = observable.subscribe(
        response => {
          this._LoaderService.quitarMascara("popUpCopiar-Documento", "tabla", "loadingDialog", "dialog-copiarDocumentoFoco")


          if (response[0].codigoError) {

            if (['106', '039'].includes(response[0].codigoError)) {
              let severidad = response[0].codigoError == '106' ? SeveridadEnum.QUESTION : response[0].severidad;
              let conBoton = response[0].codigoError == '106' ? true : false;
              let detalleRespuesta = response[0].codigoError ? { atributo: 'crearNuevaResolucion' } : null;
              this.verPopup(response[0].codigoError, severidad, ' ', conBoton, detalleRespuesta);
            } else if (['350', '141'].includes(response[0].codigoError)) {
              this.verPopup(response[0].codigoError, response[0].severidad, ' ', false);
            } else if (['1024','965', '1201'].includes(response[0].codigoError)) {
              this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, detalles: response[0].valor });
            } else {
              if (response[0].valor.length > 1) {


                this.datosPopUpCopia = this.crearDatosPopUp(response[0].valor, "copia");
                this.abrirPopUpErrores = true;
                this.tipoPopUpResolverExistencias = "Copia";

                this.nombreEsco = "popupResolverExistenciasCopiar";
              } else {

                let codError, severidad;

                if ((response[0].valor && response[0].valor.length === 0) || response[0].valor == "") {

                  codError = response[0].codigoError;
                  severidad = response[0].severidad;

                } else {

                  codError = response[0].valor[0].codigoError;
                  severidad = response[0].valor[0].severidad;

                }



                if (codError !== '147' && codError !== '268' && codError !== '256' && codError !== '365' && codError !== '366' && codError !== '160' && codError !== '613') {
                  let detalles = response[0].valor[0].valor.existencias;
                  let detalleRespuesta = {
                    fila: response[0].id,
                    atributo: codError,
                    valorAnterior: response[0].valor[0],
                    feature: true
                  }
                  this.esperarRespuesta();
                  this.popUpService.open({ codigoError: codError, severidad, conBoton: true, detalle: null, detalles: [detalles], mensajes: null, detalleRespuesta });

                } else {
                  this.verPopup(codError, severidad, ' ', false);

                }
              }

            }

          } else {
            if (response[0].accion === 'copiarDocumento') {
              const linkDocumento = this.linkDocumento;
              const nombrePestana = this.nombrePestana;
              const idCopia = response[0].id;
              this.cerrarPopup();
              this.tabsService.openTab(new Tab(linkDocumento, 'copia' + nombrePestana, [idCopia], { crea: false }));
            }
          }
        },
        error => {
          this._LoaderService.quitarMascara("popUpCopiar-Documento", "tabla", "loadingDialog", "dialog-copiarDocumentoFoco")

          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  /**
   * copiarSeleccionados: copia los inventarios seleccinados a un nuevo documento.
   */
  copiarSeleccionadosSI() {
    let fecha = this.fecha;
    let empresa = this.empresa.id;
    let documentoTipo = this.documentoTipo.id;
    let prefijo = this.prefijo;
    let conceptoAs = this.conceptoAs;
    let recalcularValores = this.manejaMonedaExtranjera ? this.recalcularValores : RecalcularEnBaseAEnum.FECHA_DOCUMENTO_ORIGEN;
    let renglonesInv = new Array();
    let renglonesCont = new Array();

    this.saldosSeleccionados.forEach(element => {
      if (element.id != undefined) {
        renglonesInv.push(element.id);
      }
    });

    this.saldosContablesSeleccionados.forEach(element => {
      if (element.id != undefined) {
        renglonesCont.push(element.id);
      }
    });

    let data = {
      fecha: fecha,
      empresa: empresa,
      documentoTipo: documentoTipo,
      prefijo: prefijo.id,
      renglonesInv: renglonesInv,
      renglonesCont: renglonesCont,
      ponerConcepto: conceptoAs,
      recalcularValores: recalcularValores,
      todos: this.seCopianTodos ? true : false
    };

    let mensaje = {
      clase: 'DocumentoEncabezado',
      id: this.idEncabezado,
      accion: 'copiarSaldosIniciales',
      atributo: 'copia',
      valor: data,
      prioridad: true,
      tipoDato: 'POJO',
      permisoGrueso: this.tipoDocumento
    };

    let observable = this.serviceCola.agregarACola(mensaje);
    if (observable) {
      let sub = observable.subscribe(
        response => {
          this._LoaderService.quitarMascara("popUpCopiar-Documento", "tabla", "loadingDialog", "dialog-copiarDocumentoFoco")

          if (response[0].codigoError) {
            this.verPopup(response[0].codigoError, response[0].severidad, ' ');
          } else {
            if (response[0].accion === 'copiarSaldosIniciales') {
              const linkDocumento = this.linkDocumento;
              const nombrePestana = this.nombrePestana;
              const idCopia = response[0].id;
              this.cerrarPopup();
              this.tabsService.openTab(new Tab(linkDocumento, 'copia' + nombrePestana, [idCopia], { crea: false }));
            }
          }
        },
        error => {
          this._LoaderService.quitarMascara("popUpCopiar-Documento", "tabla", "loadingDialog", "dialog-copiarDocumentoFoco")

          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }


  /**
   *crearDevolucionRemision: Crea una devolucion de remisión, que continene la información que el usuario selecciona en
   el formulario del popup.
   */
  crearDevolucionRemisionVentas() {
    let fecha = this.fecha;
    let empresa = this.empresa.id;
    let recalcularValores = this.manejaMonedaExtranjera ? this.recalcularValores : RecalcularEnBaseAEnum.FECHA_DOCUMENTO_ORIGEN;
    let prefijo = this.prefijo;

    let renglones = new Array();
    this.itemsSeleccionados.forEach(element => {
      if (element.id != undefined) {
        renglones.push({
          id: element.id,
          cantidad: element.cantidad
        });
      }
    });
    let data = { recalcularValores: recalcularValores, fecha: fecha, empresa: empresa, prefijo: prefijo.id, renglones: renglones, todos: this.seCopianTodos ? true : false };

    let mensaje = {
      clase: 'DocumentoEncabezado',
      id: this.idDocumento,
      accion: 'crearDevolucionRemision',
      atributo: 'copia',
      valor: data,
      prioridad: true,
      tipoDato: 'POJO',
      permisoGrueso: this.tipoDocumentoCrear
    };

    let observable = this.serviceCola.agregarACola(mensaje);
    if (observable) {
      let sub = observable.subscribe(
        response => {
          this._LoaderService.quitarMascara("popUpCopiar-Documento", "tabla", "loadingDialog", "dialog-copiarDocumentoFoco")

          if (response[0].codigoError) {
            if (response[0].codigoError == "183")
              this.fecha = response[0].valor;

            if (['957','968'].includes(response[0].codigoError)) {
              let data: TallaColorSerialLoteError = TallaColorSerialLoteError.createWithDefaults(response[0].valor, this.idEncabezado, response[0].codigoError);
              this.notifyTallaSerialLote.emit({ data, tipo: data.tipo });
            } else {
              this.verPopup(response[0].codigoError, response[0].severidad, '', response[0].codigoError === '039');
            }

          } else {
            if (response[0].accion === 'crearDevolucionRemision') {
              const idNuevoDocumento = response[0].id;
              this.cerrarPopup();
              const linkDocumento = '/panel/ventas/devolucion-remisiones/detalle';
              const nombrePestana = 'DevolucionRemisionDetalle';
              this.tabsService.openTab(
                new Tab(linkDocumento, nombrePestana, [idNuevoDocumento], { crea: false })
              );
            }
          }
        },
        error => {
          this._LoaderService.quitarMascara("popUpCopiar-Documento", "tabla", "loadingDialog", "dialog-copiarDocumentoFoco")

          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  /**
   * crearDevolucionRemisionCompras: crea una devolución de remisión de compras, a partir de la remisión de compras
   */
  crearDevolucionRemisionCompras(): any {
    let fecha = this.fecha;
    let empresa = this.empresa.id;
    let prefijo = this.prefijo;
    let recalcularValores = this.manejaMonedaExtranjera ? this.recalcularValores : RecalcularEnBaseAEnum.FECHA_DOCUMENTO_ORIGEN;
    let fechaEmision = this.fechaEmision;
    let prefijoExterno = this.prefijoExterno;
    let documentoExterno = this.documentoExterno;


    let renglones = new Array();
    let cantidades = new Array();

    this.itemsSeleccionados.forEach(element => {
      if (element.id != undefined) {
        renglones.push({
          id: element.id,
        });

        cantidades.push(element.cantidad);
      }
    });

    let data = { recalcularValores, fecha, fechaEmision, empresa, prefijo: prefijo.id, renglones, cantidades, prefijoExterno, documentoExterno, todos: this.seCopianTodos ? true : false };


    this.dataEnvio = data;

    let mensaje = {
      clase: 'DocumentoEncabezado',
      id: this.idDocumento,
      accion: 'crearDevolucionRemisionCompra',
      atributo: 'copia',
      valor: data,
      prioridad: true,
      tipoDato: 'POJO',
      permisoGrueso: this.tipoDocumentoCrear
    };

    let observable = this.serviceCola.agregarACola(mensaje);
    if (observable) {
      let sub = observable.subscribe(
        response => {
          this._LoaderService.quitarMascara("popUpCopiar-Documento", "tabla", "loadingDialog", "dialog-copiarDocumentoFoco")

          if (response[0].codigoError) {
            if (response[0].valor.length > 1 && typeof (response[0].valor) === "object" && !['1201'].includes(response[0].codigoError)) {

              this.datosPopUpCopia = this.crearDatosPopUp(response[0].valor, "crear");
              this.abrirPopUpErrores = true;
              this.tipoPopUpResolverExistencias = "Crear";
              this.nombreEsco = "popupResolverExistenciasCrear";

            } else if (['957','968'].includes(response[0].codigoError)) {
              let data: TallaColorSerialLoteError = TallaColorSerialLoteError.createWithDefaults(response[0].valor, this.idEncabezado, response[0].codigoError);
              this.notifyTallaSerialLote.emit({ data, tipo: data.tipo });

            } else if (response[0].valor.length == 1 && response[0].valor[0].codigoError == '367') {
              if (response[0].valor[0].codigoError) {
                let detalles = response[0].valor[0].valor.existencias;
                let detalleRespuesta = {
                  fila: response[0].id,
                  atributo: response[0].valor[0].codigoError,
                  valorAnterior: response[0].valor[0],
                  feature: true
                }
                this.esperarRespuesta();
                this.popUpService.open({ codigoError: response[0].valor[0].codigoError, severidad: response[0].valor[0].severidad, conBoton: true, detalle: null, detalles: [detalles], mensajes: null, detalleRespuesta });
              }
            }else if (['1201'].includes(response[0].codigoError)) {
              this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, detalles: response[0].valor });
            } else {

              if (response[0].valor[0] && response[0].valor[0].codigoError) {

                this.verPopup(response[0].valor[0].codigoError, response[0].valor[0].severidad, '', false);

              } else {

                if (response[0].codigoError == "184") {
                  this.fecha = response[0].valor;
                  this.verPopup(response[0].codigoError, response[0].severidad, '', false);
                } else {
                  this.verPopup(response[0].codigoError, response[0].severidad, '', response[0].codigoError === '039');
                }

              }
            }
          } else {
            if (response[0].accion === 'crearDevolucionRemisionCompra') {
              const idNuevoDocumento = response[0].id;
              this.cerrarPopup();
              const linkDocumento = '/panel/compras/devolucion-remision-compra/detalle';
              const nombrePestana = 'DevolucionRemisionCompraDetalle';
              this.tabsService.openTab(
                new Tab(linkDocumento, nombrePestana, [idNuevoDocumento], { crea: false })
              );
            }
          }
        },
        error => {
          this._LoaderService.quitarMascara("popUpCopiar-Documento", "tabla", "loadingDialog", "dialog-copiarDocumentoFoco")

          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  /**
  *crearNotaCreditoDebito: Crea una nota crédito o débito, que continene la información que el usuario selecciona en
  el formulario del popup.
  */
  crearNotaCreditoDebito(notaCredito?: boolean) {
    let data = this.getDataCrearNotaCreditoDebito(notaCredito);
    let mensaje = {
      clase: 'DocumentoEncabezado',
      id: this.idDocumento,
      accion: 'crearNotaCreditoDebito',
      atributo: 'copia',
      valor: data,
      prioridad: true,
      tipoDato: 'POJO',
      permisoGrueso: this.tipoDocumentoCrear
    }

    let observable = this.serviceCola.agregarACola(mensaje);
    if (observable) {
      let sub = observable.subscribe(
        response => {
          this._LoaderService.quitarMascara("popUpCopiar-Documento", "tabla", "loadingDialog", "dialog-copiarDocumentoFoco")
          if (response[0].codigoError) {
            if (response[0].codigoError == "182") {
              this.fecha = response[0].valor;
              this.fechaEmision = response[0].valor;
            } else {
              if (response[0].codigoError == "125") {
                this.fecha = response[0].valor;
              } else {
                if (response[0].codigoError == "181") {
                  this.fechaEmision = response[0].valor;
                }
              }
            }
            if (response[0].codigoError == '486') {
              this.notaCreditoError = true;
              this.notaCreditoVenta = true;
              let opciones = [this.monedaService.formato(response[0].valor)]
              this.esperarRespuesta();
              this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, conBoton: true, detalle: null, detalles: opciones });
            } else {
              this.esperarRespuesta();
              this.verPopup(response[0].codigoError, response[0].severidad, response[0].valor, response[0].codigoError === '039');
            }

          } else {
            this.prefijoNotas = response[1].atributo;
            this.numeroNotas = response[1].valor;
            this.medioPago(response[0].id);
          }
        },
        error => {
          this._LoaderService.quitarMascara("popUpCopiar-Documento", "tabla", "loadingDialog", "dialog-copiarDocumentoFoco")
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  /**
  *crearNotaCreditoDebitoProveedores: Crea una nota crédito o débito de proveedores, que continene la información que el usuario selecciona en
  el formulario del popup.
  */

  crearNotaCreditoDebitoProveedores(notaCredito?: boolean) {
    let data = this.getDataCrearNotaCreditoDebitoProveedores(notaCredito);
    let mensaje = {
      clase: 'DocumentoEncabezado',
      id: this.idDocumento,
      accion: 'crearNotaCreditoDebitoProveedores',
      atributo: 'copia',
      valor: data,
      prioridad: true,
      tipoDato: 'POJO',
      permisoGrueso: this.tipoDocumentoCrear
    }

    this.dataEnvio = data;


    let observable = this.serviceCola.agregarACola(mensaje);
    if (observable) {
      let sub = observable.pipe(take(1)).subscribe(
        response => {
          this._LoaderService.quitarMascara("popUpCopiar-Documento", "tabla", "loadingDialog", "dialog-copiarDocumentoFoco")

          if (response[0].codigoError) {

            if (Array.isArray(response[0].valor) && response[0].valor.length > 1 && !['965','1024', '1201'].includes(response[0].codigoError)) {
              this.tipoPopUpResolverExistencias = "Crear";
              this.datosPopUpCopia = this.crearDatosPopUp(response[0].valor, "crear");
              this.abrirPopUpErrores = true;
              this.notaCreditoError = false;
              this.nombreEsco = "popupResolverExistenciasCreditoDebitoProveedores";
            } else if (['965','1024', '1201'].includes(response[0].codigoError)) {
              this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, detalles: response[0].valor });
            } else if (response[0].valor.length == 1 && response[0].valor[0].codigoError == '367') {
              if (response[0].valor[0].codigoError) {
                let detalles = response[0].valor[0].valor.existencias;
                let detalleRespuesta = {
                  fila: response[0].id,
                  atributo: response[0].valor[0].codigoError,
                  valorAnterior: response[0].valor[0],
                  feature: true
                }
                this.esperarRespuesta();
                this.popUpService.open({ codigoError: response[0].valor[0].codigoError, severidad: response[0].valor[0].severidad, conBoton: true, detalle: null, detalles: [detalles], mensajes: null, detalleRespuesta });
              }
            } else {

              if (response[0].codigoError == "182") {
                this.fecha = response[0].valor;
                this.fechaEmision = response[0].valor;
              } else {
                if (response[0].codigoError == "125") {
                  this.fecha = response[0].valor;
                } else {
                  if (response[0].codigoError == "181") {
                    this.fechaEmision = response[0].valor;
                  }
                }
              }

              if (response[0].codigoError == '486') {
                this.notaCreditoError = true;
                this.notaCreditoCompra = true;
                let opciones = [this.monedaService.formato(response[0].valor)];
                this.esperarRespuesta();
                this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, conBoton: true, detalle: null, detalles: opciones });
              }

              else if (response[0].codigoError == '367') {
                this.esperarRespuesta();
                this.popUpService.open({ codigoError: 'errorExistenciasPopup', severidad: SeveridadEnum.ERROR });
              } else {
                this.esperarRespuesta();
                this.verPopup(response[0].codigoError, response[0].severidad, '', response[0].codigoError === '039');
              }

            }

          } else {

            let linkDocumento = '';
            let nombrePestana = '';
            if (this.tipoDocumentoCrear == DocumentoTipoEnum.NOTA_CREDITO_COMPRA) {
              linkDocumento = '/panel/compras/notas-credito-proveedores/detalle';
              nombrePestana = 'NotaCreditoProveedoresDetalle';
            } else if (this.tipoDocumentoCrear == DocumentoTipoEnum.NOTA_DEBITO_COMPRA) {
              linkDocumento = '/panel/compras/notas-debito-proveedores/detalle';
              nombrePestana = 'NotaDebitoProveedoresDetalle';
            }
            this.cerrarPopup();
            this.tabsService.openTab(new Tab(linkDocumento, nombrePestana, [response[0].id], { crea: false }));
          }
        },
        error => {
          this._LoaderService.quitarMascara("popUpCopiar-Documento", "tabla", "loadingDialog", "dialog-copiarDocumentoFoco")

          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  /**
  * getDataCrearNotaCreditoDebito: retorna la información con la cual se va a crear la nota débito o crédito.
  */
  /**
  * getDataCrearNotaCreditoDebito: retorna la información con la cual se va a crear la nota débito o crédito.
  */
  private getDataCrearNotaCreditoDebito(notaCredito?: boolean) {
    let fecha = this.fecha;
    let empresa = this.empresa.id;
    let prefijo = this.prefijo;
    let recalcularValores = this.manejaMonedaExtranjera ? this.recalcularValores : RecalcularEnBaseAEnum.FECHA_DOCUMENTO_ORIGEN;
    let renglones = new Array();
    this.itemsSeleccionados.forEach(element => {
      if (element.id != undefined) {
        renglones.push({ id: element.id, cantidad: element.cantidad, valorUnitario: element.valorUnitario });
      }
    });
    const motivoDevolucion = this.motivoDevolucion ? this.motivoDevolucion : -1;
    const clasificacionNotaCredito = this.clasificacionNotaCredito;
    const tipoDocumentoCrear = this.tipoDocumentoCrear;
    const crearDuplicado = notaCredito;
    let data = {
      crearDuplicado,
      fecha: fecha, empresa: empresa, prefijo: prefijo.id, motivoDevolucion: motivoDevolucion,
      clasificacionNotaCredito: clasificacionNotaCredito, tipoDocumento: tipoDocumentoCrear, renglones: renglones,
      recalcularValores: recalcularValores, todos: this.seCopianTodos ? true : false
    };
    return data;
  }

  /**
  * getDataCrearNotaCreditoDebitoProveedores: retorna la información con la cual se va a crear la nota débito o crédito de proveedores.
  */
  private getDataCrearNotaCreditoDebitoProveedores(notaCredito?: boolean) {
    let fecha = this.fecha;
    let fechaEmision = this.fechaEmision;
    let empresa = this.empresa.id;
    let prefijo = this.prefijo;
    let recalcularValores = this.manejaMonedaExtranjera ? this.recalcularValores : RecalcularEnBaseAEnum.FECHA_DOCUMENTO_ORIGEN;
    let renglones = new Array();
    let cantidades = new Array();
    let prefijoDocExterno = this.prefijoExterno;
    let noDocExterno = this.documentoExterno;
    const motivoDevolucion = this.motivoDevolucion ? this.motivoDevolucion : -1;

    this.itemsSeleccionados.forEach(element => {
      if (element.id != undefined) {
        renglones.push({
          id: element.id,
        });
        cantidades.push(element.cantidad);
      }
    });
    const clasificacionNotaCredito = this.clasificacionNotaCredito;
    const tipoDocumentoCrear = this.tipoDocumentoCrear;
    const crearDuplicado = notaCredito;
    let data = {
      motivoDevolucion,
      crearDuplicado,
      fecha, fechaEmision, empresa, prefijo: prefijo.id,
      clasificacionNotaCredito, tipoDocumento: tipoDocumentoCrear, renglones,
      recalcularValores, cantidades, prefijoDocExterno: prefijoDocExterno ? prefijoDocExterno : '', noDocExterno: noDocExterno ? noDocExterno : '', todos: this.seCopianTodos ? true : false
    };
    return data;
  }

  /**
   *verPopup: muestra un popup cuando hay un error tras haber realizado una transacción.
   * @param codigoError codigo de error lanzado por el backend
   * @param opciones mensaje adicional
   */
  verPopup(codigoError, severidad, opciones?, boton?, detalleRespuesta?) {
    if (opciones && boton) {
      this.esperarRespuesta();
      this.popUpService.open({ codigoError, severidad, conBoton: boton, detalle: opciones, detalleRespuesta: detalleRespuesta });
    }
    else {
      this.esperarRespuesta();
      this.popUpService.open({ codigoError, severidad });
    }

  }

  /**
   * seleccionarTodos: se seleccionan o deseleccionan todos los renglones de la tabla.
   * @param valor booleano que indica si marcan o desmarcan todos los renglones.
   */
  seleccionarTodos(valor) {
    this.seCopianTodos = valor;
    if (this.seCopianTodos) {
      this.inventarios.forEach(element => {
        let actualPrincipal = this.itemsSeleccionados.findIndex(
          x => x.id == element.id
        );
        if (actualPrincipal == -1) {
          if (this.tipoDocumento == DocumentoTipoEnum.DEPRECIACION || element.clasificacion != InventarioClasificacionEnum.ACTIVO_FIJO) {
            element.seleccionado = true;
            this.itemsSeleccionados.push(element);
            if (this.tipoDocumento == DocumentoTipoEnum.SALDOS_INICIALES && this.tipoSaldo != '-1') {
              this.saldosSeleccionados.push(element);
            } else if (this.tipoDocumento == DocumentoTipoEnum.SALDOS_INICIALES && this.tipoSaldo == '-1') {
              this.saldosContablesSeleccionados.push(element);
            }
          }
        }
      });
    } else {
      if (this.tipoDocumento == DocumentoTipoEnum.SALDOS_INICIALES && this.tipoSaldo != '-1') {
        this.saldosSeleccionados = [];
      } else if (this.tipoDocumento == DocumentoTipoEnum.SALDOS_INICIALES && this.tipoSaldo == '-1') {
        this.saldosContablesSeleccionados = [];
      }
      this.inventarios.forEach(element => {
        let actualPrincipal = this.itemsSeleccionados.findIndex(
          x => x.id == element.id
        );
        if (actualPrincipal != -1) {
          element.seleccionado = false;
          this.itemsSeleccionados.splice(actualPrincipal, 1);
        }
      });
    }
  }

  /**
   * consulta los tipos de documento alos cuales se puede copiar el documento encabezado actual.
   * @param moneda
   */
  cargarTiposDocumento(moneda: any) {
    if (this.tipoDocumento === DocumentoTipoEnum.SALDOS_INICIALES)
      this.iniciarConfSI();
    this.moneda = moneda;
    // this.formatoFecha(moneda.id);
    let paginacion = new PaginacionWo('id', 0, 0, 'ASC');
    let sub = this.copiarDocumentosService.cargarTipos(this.idDocumento, paginacion).subscribe(
      response => {
        this.documentosTipos = response.content.slice();
      },
      error => {
        this.errorHandlerComponent.handler(error);
      }
    );
    this.subscriptions.add(sub);
  }

  /**
   * Envia los cambios detectados en la pantalla del usuario para ser enviados a la cola de mensajes
   * @param atributo
   * @param valor
   */
  cambio(atributo: string, valor: any, tipo: any) {
    if (atributo == 'clasificacionNotaCredito') {
      this.motivosNotas();
    }
    if (atributo == 'motivoNotas' && this.tipoDocumentoCrear == 'NDV') {
      this.columnas[4].editable = true;
      this.columnas = this.columnas.map(x => Object.assign({}, x));
      this.motivosNotas();
    }
    let valido = true;
    if (atributo == 'documentoTipo') {
      this.inicializarFiltrosFormaPago(valor.codigoDocumento);
      let filtroPrefijoDocumento = new FiltroWo('codigoDocumento', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [valor.codigoDocumento], 'documentoTipoes', Operador.AND);
      this.filtrosPrefijo = [this.filtroPrefijoVacio, this.filtroPrefijoActivo, filtroPrefijoDocumento];
      this.verificarTrmCambioFecha();
      this.prefijo = {
        codigo: -1,
        id: -1,
        nombre: ''
      };
    } else if (atributo == 'fechaCopia') {
      if (valor < this.fechaOrigen && this.accion == 'crearDocumento') {
        valido = false;
        this.verPopup('184', SeveridadEnum.ERROR, '', true);
        this.valorAnteriorFecha();
      }
      if (valido) {
        this.verificarTrmCambioFecha();
      }
      if (this.accion == 'crearDocumento') {
        if (this.tipoDocumentoCrear == DocumentoTipoEnum.NOTA_CREDITO_VENTA && valido) {
          this.listarRenglonesNotaCreditoDebito(this.clasificacionNotaCredito.codigo, this.tipoDocumentoCrear);
        } else {
          this.listarRenglonesNotaCreditoDebitoCompra(this.clasificacionNotaCredito.codigo, this.tipoDocumentoCrear);
        }
      }
    } else if (atributo == 'fechaEmision') {
      this.verificarTrmCambioFecha();
    } else if (atributo == 'clasificacionNotaCredito') {
      if (this.tipoDocumentoCrear == DocumentoTipoEnum.NOTA_CREDITO_VENTA) {
        this.listarRenglonesNotaCreditoDebito(this.clasificacionNotaCredito.codigo, this.tipoDocumentoCrear);
      } else {
        this.listarRenglonesNotaCreditoDebitoCompra(this.clasificacionNotaCredito.codigo, this.tipoDocumentoCrear);
      }
    } else if (atributo == 'recalcular') {
      this.recalcularTRM(tipo);
    }
  }

  isDocumentosNoCopiaFormaPago(codigoDocumento: string): boolean {
    return [DocumentoTipoEnum.SALDOS_INICIALES, DocumentoTipoEnum.RECIBO_CAJA, DocumentoTipoEnum.COMPROBANTE_EGRESO, DocumentoTipoEnum.NOTA_CONTABILIDAD, DocumentoTipoEnum.AJUSTE_INVENTARIO,
    DocumentoTipoEnum.COSTO_VENTA, DocumentoTipoEnum.NOMINA_CONTABLE, DocumentoTipoEnum.DEPRECIACION, DocumentoTipoEnum.DOCUMENTO_CANCELACION].includes(codigoDocumento);
  }
  /**
   * verificarFormaPagoDocSeleccionado: verifica si existe la forma de pago del documento origen en el documento destino.
   */
  verificarFormaPagoDocSeleccionado() {
    let pag = new PaginacionWo('nombre');
    pag.filtros.push(...this.filtroFormaPago);
    pag.filtros.push(new FiltroWo('id', this.formaPagoOrigen.id, null, TipoFiltro.IGUAL, TipoDato.LONG, null, null, '', Operador.AND));
    this.formaPagoService.getFPOrdenado(pag).subscribe(data => this.formaPago = data.content.length > 0 ? data.content[0] : undefined)
  }

  /**
   * inicializarFiltrosFormaPago: agrega filtros para forma pago.
   */
  inicializarFiltrosFormaPago(codigoDocumento: string) {
    this.isDocumentosNoCopiaFp = this.isDocumentosNoCopiaFormaPago(codigoDocumento);
    this.filtroFormaPago = [];
    if (!this.isDocumentosNoCopiaFp) {
      this.filtroFormaPago.push(new FiltroWo('codigoDocumento', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [codigoDocumento], 'documentoTipos', Operador.AND));
      this.filtroFormaPago.push(new FiltroWo('senDisponible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, '', Operador.AND));
      this.filtroFormaPago.push(new FiltroWo('formaPagoClasificacion.id', '', null, TipoFiltro.CONTIENE, TipoDato.IN, null, [FormaPagoClasificacionEnum.CONTABILIZACION, FormaPagoClasificacionEnum.CONTABILIZACION_PAGO], 'FormaPago', Operador.AND))
      this.verificarFormaPagoDocSeleccionado();
    }
  }

  /**
   * verificarTrmCambioFecha: verifica la TRM para la moneda extranjera en la fecha seleccionada.
   */
  private verificarTrmCambioFecha() {
    let monedaId = this.accion == 'copiar' ? this.moneda.id : this.monedaOrigen.id;
    if (this.manejaMonedaExtranjera()) {
      if (!this.sinValidarTRM) {
        this.verificarTRM(monedaId, this.fecha);
      }
    }
  }

  private recalcularTRM(tipo: any) {
    if (tipo == 'recalcularFechaDocumento') {
      this.recalcularValores = RecalcularEnBaseAEnum.FECHA_NUEVO_DOCUMENTO;
      this.recalcularFechaEmision = false;
      this.recalcularDocumentoOrigen = false;
    }
    else if (tipo == 'recalcularFechaEmision') {
      this.recalcularValores = RecalcularEnBaseAEnum.FECHA_EMISION;
      this.recalcularFechaDocumento = false;
      this.recalcularDocumentoOrigen = false;
    }
    else if (tipo == 'recalcularDocumentoOrigen') {
      this.recalcularValores = RecalcularEnBaseAEnum.FECHA_DOCUMENTO_ORIGEN;
      this.recalcularFechaDocumento = false;
      this.recalcularFechaEmision = false;
    }
  }

  respuestaPopup(e) {
    if (e && this.notaCreditoError) {
      if (this.notaCreditoCompra) {
        this.crearNotaCreditoDebitoProveedores(true)
      }
      if (this.notaCreditoVenta) {
        this.crearNotaCreditoDebito(true)
      }
    } else if (e && !this.notaCreditoError) {
      this.abrirCrearTRM();
    }
  }

  abrirCrearTRM() {
    let monedaId = this.accion == 'copiar' ? this.moneda.id : this.monedaOrigen.id;
    this.tabsService.openTab(new Tab('/panel/contabilidad/configuracion/documentos/monedas/trm', 'AsociarMonedaTRM', [], {
      valoresIniciales: JSON.stringify({
        fecha: this.fecha,
        monedaId: monedaId
      })
    }));
  }

  /* INICIO Métodos para drag and drop del popup */
  onDragBegin(e) {
    this.isDragging = true;
  }

  evento() {
    if (this.isDragging) this.isDragging = false;
  }

  iniciarPosicionPopUp() {
    // if (this.newInnerWidth != 0) {
    //   this.positionA.x = Math.round(-this.newInnerWidth / 4);
    // } else {
    //   this.positionA.x = Math.round(-window.screen.width / 4);
    // }
  }

  onClickSelect(e) {
    this.cargarTiposDocumento(this.moneda);
  }

  checkEdge(event) {
    this.edge = event;
  }

  cambioTab(id: number) {
    if (id == 1) {
      this.tipoSaldo = ClasificacionInventarioEnum.PRODUCTO;
      this.inicializarColumnas(ClasificacionInventarioEnum.PRODUCTO);
      this.listarRenglonesSaldosIniciales(ClasificacionInventarioEnum.PRODUCTO);
      this.estaActive_1Copia = 'tabActive';
      this.estaActive_2Copia = '';
      this.estaActive_3Copia = '';
      this.estaActive_4Copia = '';
    }
    if (id == 2) {
      this.tipoSaldo = ClasificacionInventarioEnum.ACTIVO_FIJO;
      this.inicializarColumnas(ClasificacionInventarioEnum.ACTIVO_FIJO);
      this.listarRenglonesSaldosIniciales(ClasificacionInventarioEnum.ACTIVO_FIJO);
      this.estaActive_1Copia = '';
      this.estaActive_2Copia = 'tabActive';
      this.estaActive_3Copia = '';
      this.estaActive_4Copia = '';
    }
    if (id == 3) {
      this.tipoSaldo = ClasificacionInventarioEnum.DIFERIDO;
      this.inicializarColumnas(ClasificacionInventarioEnum.DIFERIDO);
      this.listarRenglonesSaldosIniciales(ClasificacionInventarioEnum.DIFERIDO);
      this.estaActive_1Copia = '';
      this.estaActive_2Copia = '';
      this.estaActive_3Copia = 'tabActive';
      this.estaActive_4Copia = '';
    }
    if (id == 4) {
      this.tipoSaldo = '-1';
      this.inicializarColumnas('-1');
      this.listarRenglonesSaldosIniciales('-1');
      this.estaActive_1Copia = '';
      this.estaActive_2Copia = '';
      this.estaActive_3Copia = '';
      this.estaActive_4Copia = 'tabActive';
    }
  }

  actualizarValoresSaldos() {
    if (this.tipoDocumento == DocumentoTipoEnum.SALDOS_INICIALES && this.tipoSaldo != '-1') {
      this.saldosSeleccionados.forEach(element => {
        let seleccionado = this.inventarios.findIndex(x => x.id == element.id); //-1
        if (seleccionado != -1) {
          this.inventarios[seleccionado].seleccionado = true;
        }
      })
    } else if (this.tipoDocumento == DocumentoTipoEnum.SALDOS_INICIALES && this.tipoSaldo == '-1') {
      this.saldosContablesSeleccionados.forEach(element => {
        let seleccionado = this.inventarios.findIndex(x => x.id == element.id); //-1
        if (seleccionado != -1) {
          this.inventarios[seleccionado].seleccionado = true;
        }
      })
    }
  }


  respuestaPopUpCruce(event) {


    if (event && event.copiar && event.nombreEsco === "popupResolverExistenciasCopiar") {
      const linkDocumento = this.linkDocumento;
      const nombrePestana = this.nombrePestana;
      const idCopia = event.id;
      this.tabsService.openTab(new Tab(linkDocumento, 'copia' + nombrePestana, [idCopia], { crea: false }));
      this.abrirPopUpErrores = false;
      this.cerrarPopup();
    }

    if (event && event.copiar && event.nombreEsco === "popupResolverExistenciasCrear") {

      const linkDocumento = '/panel/compras/devolucion-remision-compra/detalle';
      const nombrePestana = 'DevolucionRemisionCompraDetalle';
      const idCopia = event.id;
      this.tabsService.openTab(new Tab(linkDocumento, 'copia' + nombrePestana, [idCopia], { crea: false }));
      this.abrirPopUpErrores = false;
      this.cerrarPopup();
    }


    if (event && event.copiar && event.nombreEsco === "popupResolverExistenciasCreditoDebitoProveedores") {


      const linkDocumento = '/panel/compras/notas-credito-proveedores/detalle';
      const nombrePestana = "NotaCreditoProveedoresDetalle";
      const idCopia = event.id;
      this.tabsService.openTab(new Tab(linkDocumento, 'copia' + nombrePestana, [idCopia], { crea: false }));
      this.abrirPopUpErrores = false;
      this.cerrarPopup();
    }



    this.abrirPopUpErrores = false;
  }

  /* clasificacionReciboCaja(){

    //Pendiente por probar, ya conecta con esta peticion, falta Ajustar la tabla
    //y subir cambios para verificar

    let paginacion = new PaginacionWo('id', 0, 100, 'ASC');
    let sub=this.copiarDocumentosService.getMovimientosReciboCajaFacturaVenta(this.idDocumento,9,paginacion).subscribe(response => {
      this.itemsSeleccionados = [];
      if (response.content.length == 0) {
        this.opcionesTabla.vacio = true;
        this.opcionesTabla = Object.assign({}, this.opcionesTabla);
      }
      let inventario = response.content;
      this.inventarios = inventario.slice();
      this.inventarios.forEach(inv => {
        this.cantidadesValidas.push(inv.cantidadDisponible);
      });
      this.paginacion = {
        totalPaginas: response.totalPages,
        totalElementos: response.totalElements,
        registrosPorPagina: paginacion.registrosPorPagina,
        numeroElementos: response.numberOfElements,
        orden: 'ASC',
        columnaOrdenar: 'id',
        filtros: paginacion.filtros
      };
      this.crearDocumentoPojo = new CrearDocumentoPojo(this.clasificacionNotaCredito, this.tipoDocumento, this.fecha);
    }, error => {
      this.errorHandlerComponent.handler(error);
    });
  this.subscriptions.add(sub);
    } */

  mensajeCrearDevolucionRemisionCompras(cantidad, renglon) {
    let fecha = this.fecha;
    let empresa = this.empresa.id;
    let prefijo = this.prefijo;
    let recalcularValores = this.manejaMonedaExtranjera ? this.recalcularValores : RecalcularEnBaseAEnum.FECHA_DOCUMENTO_ORIGEN;
    let fechaEmision = this.fechaEmision;
    let prefijoExterno = this.prefijoExterno;
    let documentoExterno = this.documentoExterno;


    let renglones = new Array();
    let cantidades = new Array();

    cantidades.push(cantidad)
    renglones.push({ id: renglon });

    let data = { recalcularValores, fecha, fechaEmision, empresa, prefijo: prefijo.id, renglones, cantidades, prefijoExterno, documentoExterno };


    this.dataEnvio = data;


    let mensaje = {
      clase: 'DocumentoEncabezado',
      id: this.idDocumento,
      accion: 'crearDevolucionRemisionCompra',
      atributo: 'copia',
      valor: data,
      prioridad: true,
      tipoDato: 'POJO',
      permisoGrueso: this.tipoDocumentoCrear
    };
    return mensaje
  }
  mensajeCrearNotaCreditoDebitoProveedores(cantidad, renglon) {
    let data = this.getDataCrearNotaCreditoDebitoProveedores();
    data.cantidades = [cantidad]
    data.renglones = [{ id: renglon }]
    let mensaje = {
      clase: 'DocumentoEncabezado',
      id: this.idDocumento,
      accion: 'crearNotaCreditoDebitoProveedores',
      atributo: 'copia',
      valor: data,
      prioridad: true,
      tipoDato: 'POJO',
      permisoGrueso: this.tipoDocumentoCrear
    }
    return mensaje
  }

  abrirCrearNuevaResolucion() {
    let dataEnviarResoluciones = { empresa: this.empresa, fecha: this.fecha, prefijo: this.prefijo }
    sessionStorage.setItem('filtrarResoluciones', JSON.stringify(dataEnviarResoluciones));
    this.tabsService.openTab(new Tab('/panel/contabilidad/configuracion/documentos/documento-resoluciones', 'DocumentoResoluciones', []))
  }

  respuestaFeaturePopup(event) {
    if (event.isTrue) {
      let mensaje = null;
      if (this.accion != 'copiar' && this.tipoDocumento == DocumentoTipoEnum.REMISION_COMPRA) mensaje = this.mensajeCrearDevolucionRemisionCompras(event.valorAnterior.valor.cantidadActual, event.valorAnterior.valor.idRenglon)
      if (this.accion != 'copiar' && this.tipoDocumento == DocumentoTipoEnum.FATURA_COMPRA) mensaje = this.mensajeCrearNotaCreditoDebitoProveedores(event.valorAnterior.valor.cantidadActual, event.valorAnterior.valor.idRenglon)

      if (mensaje == null && event.atributo != 'crearNuevaResolucion') {
        let renglones = new Array();
        let cantidades = new Array();
        renglones.push(event.valorAnterior.valor.idRenglon);
        cantidades.push(event.valorAnterior.valor.cantidadActual)
        this.dataEnvio.renglones = renglones;
        this.dataEnvio.cantidades = cantidades;
        mensaje = {
          clase: 'DocumentoEncabezado',
          id: event.fila,
          accion: 'copiarDocumento',
          atributo: 'copia',
          valor: this.dataEnvio,
          prioridad: true,
          tipoDato: 'POJO',
          permisoGrueso: this.tipoDocumento
        };
      } else if (event.atributo == 'crearNuevaResolucion') {
        this.abrirCrearNuevaResolucion();
      }

      let variable = this.serviceCola.agregarACola(mensaje);
      if (variable) {
        let sub = variable.subscribe(
          response => {
            if (response.length > 0) {
              if (response[0].codigoError) {
                if (['486'].includes(response[0].codigoError)) {
                  this.notaCreditoError = true;
                  this.notaCreditoVenta = true;
                  let opciones = [this.monedaService.formato(response[0].valor)]
                  this.esperarRespuesta();
                  this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, conBoton: true, detalle: null, detalles: opciones });
                } else {
                  this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });
                }
              } else {
                const linkDocumento = this.linkDocumento;
                const nombrePestana = this.nombrePestana;
                const idCopia = response[0].id;
                this.tabsService.openTab(new Tab(linkDocumento, 'copia' + nombrePestana, [idCopia], { crea: false }));
                this.cerrarPopup();

              }
            }
          });
      }
    }
  }


  crearDatosPopUp(datos, tipo) {



    let auxDat = [];

    datos.forEach((element, index) => {


      let renglonFila = {
        id: element.valor.idRenglon,
        seleccionado: false,
        documento: element.valor.documento,
        descripcion: element.valor.descripcionDocumento,
        descripcionError: "",
        codigoError: element.codigoError,
        cantidadSolicitada: element.valor.solicitado,
        cantidadDisponible: element.valor.existencias,
        cantidadCruce: element.valor.cantidadActual,
      }


      renglonFila.descripcionError = this.translateUtilService.getTranslateText(
        'popupResolverExistencias.errores.' + element.codigoError
      );



      auxDat.push(renglonFila);

      if (element.codigoError === "365") {
        auxDat[index].mapaBloqueado = new Map<number, Array<number>>();
        auxDat[index].mapaBloqueado.set(element.valor.idRenglon, [0]);
      }

      if (element.codigoError === null) {
        auxDat[index].seleccionado = true;
        auxDat[index].descripcionError = "";
        auxDat[index].mapaBloqueado = new Map<number, Array<number>>();
        auxDat[index].mapaBloqueado.set(element.valor.idRenglon, [0]);
      }

    });


    return auxDat;

  }

  /**
   * Ajustamos las columnas con la validación de las clasificaciones y motivos de devolución
   * @Author Oscar Ospina
   */
  motivosNotas() {
    this.columnas[3].editable = this.clasificacionNotaCredito.tipoNotaCredito == 'Devolución';
    this.columnas[4].editable = this.clasificacionNotaCredito.tipoNotaCredito == 'Descuento' || !this.clasificacionNotaCredito;
    this.columnas = this.columnas.map(x => Object.assign({}, x));
  }


  /**
   * Enviamos a guardar los medios de pago para la contabilización
   * @Author Oscar Ospina Lastname
   * @param idNota
   */
  medioPago(idNota) {
    this._LoaderService.colocarMascara("popUpCopiar-Documento", { tabla: false }, "loadingDialog", "dialog-copiarDocumentoFoco");
    let formaPago;
    this.posService.getDocumentoEncabezado(this.idDocumento).subscribe((data: any) => {
      formaPago = data.formaPago.id;
    })
    this.medioPagoService.getTotalesDocumentoEncabezadoFormaPagoPorGrupo(idNota).subscribe(data => {
      let diferencia = 0;
      diferencia = data.diferencia;
      const mensajeCrear = {
        id: idNota,
        accion: 'crearDocumentoEncabezadoFormaPago',
        clase: 'DocumentoEncabezadoFormaPago',
        valor: 'crear',
        prioridad: true,
        atributo: '',
        permisoGrueso: this.tipoDocumento
      };
      this.serviceCola.agregarACola(mensajeCrear).subscribe(dataCrear => {
        if (dataCrear.length > 0 && dataCrear[0].codigoError) {
          this._LoaderService.quitarMascara('panel/punto-de-venta', 'tabla', 'loadingIndex', 'paneTabs');
          this.popUpService.open({ codigoError: dataCrear[0].codigoError, severidad: dataCrear[0].severidad });
        }
        const mensajeFormaPago = {
          clase: 'DocumentoEncabezadoFormaPago',
          id: dataCrear[0].id,
          accion: 'asociarDocumentoEncabezadoFormaPagoFormaPago',
          atributo: 'formaPago',
          valor: String(formaPago) +
            "",
          prioridad: false,
          tipoDato: TipoDato[TipoDato.STRING],
          permisoGrueso: this.tipoDocumento
        }
        this.serviceCola.agregarACola(mensajeFormaPago);
        const mensajeValor: any = { ...mensajeFormaPago };
        mensajeValor.accion = 'editarDocumentoEncabezadoFormaPago';
        mensajeValor.atributo = 'valor';
        mensajeValor.valor = `${diferencia}`;
        mensajeValor.prioridad = true;
        mensajeValor.tipoDato = TipoDato[TipoDato.NUMERIC];
        this.serviceCola.agregarACola(mensajeValor).subscribe(async dataValor => {
          if (dataValor.length > 0 && dataValor[0].codigoError) {
            this._LoaderService.quitarTodos();
            let tipoDoc = 'nota débito';
            this.prefijoNumeroNota.atributo == 'NCV' ? tipoDoc = 'nota crédito' : tipoDoc;
            this.popUpService.open({
              codigoError: 'notaDebitoCreditoCreadaConPrefijoNoMediosDePago',
              severidad: SeveridadEnum.WARNING,
              detalles: [this.prefijoNotas, this.numeroNotas]
            });
          } else {
            this.contabilizar(idNota);
          }
        }
        );
      }
      );
    }, () => {
      this.popUpService.open({
        codigoError: 'notaDebitoCreditoCreadaConPrefijoNoMediosDePago',
        severidad: SeveridadEnum.WARNING,
        detalles: this.prefijoNumeroNota !== true ? [this.prefijoNotas, this.numeroNotas] : null
      });
      this.cerrarPopup();
    });
  }

  /**
   * Contabilizamos automaticamente el documento
   * @Author Oscar Ospina
   * @param idNota
   */
  contabilizar(idNota) {
    const mensajeContabilizar = {
      clase: 'Contabilizacion',
      accion: 'contabilizacionContabilizar',
      valor: {
        validarInventariosIVA: true,
        validarInventariosImpoConsumo: true
      },
      prioridad: true,
      id: idNota,
      atributo: 'contabilizar',
      permisoGrueso: this.tipoDocumento
    };
    let variable = this.serviceCola.agregarACola(mensajeContabilizar);
    if (variable) {
      const sub = variable.pipe(take(1)).subscribe(response => {
        if (response.length > 0 && response[0].codigoError) {
          this._LoaderService.quitarTodos();
          let tipoDoc = 'nota débito';
          this.prefijoNumeroNota.atributo == 'NCV' ? tipoDoc = 'nota crédito' : tipoDoc;
          const notasPrefijo = this.prefijoNumeroNota.valor;
          this.popUpService.open({
            codigoError: 'notaDebitoCreditoCreadaConPrefijoNoContabilizado',
            severidad: SeveridadEnum.WARNING,
            detalles: this.prefijoNumeroNota !== true ? [this.prefijoNotas, this.numeroNotas] : null
          });
        } else {
          let linkDocumento = '';
          let nombrePestana = '';
          if (this.tipoDocumentoCrear == DocumentoTipoEnum.NOTA_CREDITO_VENTA) {
            linkDocumento = '/panel/ventas/notas-credito/detalle';
            nombrePestana = 'NotaCreditoDetalle';
          } else if (this.tipoDocumentoCrear == DocumentoTipoEnum.NOTA_DEBITO_VENTA) {
            linkDocumento = '/panel/ventas/notas-debito/detalle';
            nombrePestana = 'NotaDebitoDetalle';
          }
          this.tabsService.openTab(new Tab(linkDocumento, nombrePestana, [idNota], { crea: false }));
          this.popUpService.open({
            codigoError: 'notaDebitoCreditoCreadaConPrefijoContabilizado',
            severidad: SeveridadEnum.SUCCESS,
            detalles: [this.prefijoNotas, this.numeroNotas]
          });
          this._LoaderService.quitarTodos()
        }
      }, () => {
        this._LoaderService.quitarTodos();
        let tipoDoc = 'nota débito';
        this.prefijoNumeroNota.atributo == 'NCV' ? tipoDoc = 'nota crédito' : tipoDoc;
        const notasPrefijo = this.prefijoNumeroNota.valor;
        this.popUpService.open({
          codigoError: 'notaDebitoCreditoCreadaConPrefijoNoContabilizado',
          severidad: SeveridadEnum.WARNING,
          detalles: this.prefijoNumeroNota !== true ? [this.prefijoNotas, this.numeroNotas] : null
        });
      });
    }
  }

  /**
   * Ajustamos las columnas con la validación de las clasificaciones y motivos de devolución
   * @Author Oscar Ospina
   */
  imprimirReciboCaja(idRC) {
    this.posService.getConfiguracionImprimir(DocumentoTipoEnum.RECIBO_CAJA, idRC).subscribe(response => {
      this.posService.getDocumentoImpreso(idRC, response.valorCombo != null ? response.valorCombo : 'TICKET_RC').subscribe(
        res => {
          this._LoaderService.quitarTodos();
          printToPDF({ printable: res.pdf, type: 'pdf', base64: true })
        })
    })
  }

  /**
   * Contabilizamos automaticamente el documento
   * @Author Oscar Ospina
   * @param idNota
   */
  /**
   * Esperamos respuesta para hacer la consulta de los documentos
   * @param id
   */
  esperarRespuestaPopUp(id) {
    const resp = this.popUpService.respuesta.subscribe((respuesta) => {
      resp.unsubscribe();
      if (respuesta.event) {
        this._LoaderService.colocarMascara('panel/punto-de-venta', { tabla: false }, 'loadingIndex', 'paneTabs');
        this.imprimirReciboCaja(id);
      }
    });
  }

}
