<app-menu-contextual></app-menu-contextual>
<div id="titlePrincipalDoc" class="titlePrincipal" (click)="cerrarMenuContextual()">
  <h1>
    <span class="contTit">{{'puntosVenta.titulo' | translate}}</span>
    <span
      *ngIf="(documentoEncabezado.contabilizado || documentoEncabezado.anulado || documentoEncabezado.bloqueado) || documentoEncabezado.contabilizando"
      class="tooltipInfo">
      <span class="tooltipIcon fas fa-exclamation-circle"></span>
      <div class="tooltipBox">
        <div class="tooltipMenu">
          <div *ngIf="documentoEncabezado.contabilizado">
            <span class="tooltipIconCont"></span>
            <h1>{{'documentoEncabezado.contabilizado' | translate}}</h1>
            <p>{{'documentoEncabezado.contabilizadoMensaje'| translate}}</p>
          </div>
          <div *ngIf="documentoEncabezado.anulado">
            <span class="tooltipIconAnul"></span>
            <h1>{{'documentoEncabezado.anulado' | translate}}</h1>
            <p>{{'documentoEncabezado.anuladoMensaje' | translate}}</p>
          </div>
          <div *ngIf="documentoEncabezado.bloqueado">
            <span class="tooltipIconAnul"></span>
            <h1>{{'documentoEncabezado.bloqueado' | translate}}</h1>
            <p>{{'documentoEncabezado.bloqueadoMensaje' | translate}}</p>
          </div>
          <div *ngIf="documentoEncabezado.estaPresentadoElectronicamente">
            <span class="tooltipIconAnul"></span>
            <h1>{{'documentoEncabezado.presentadoElectronicamente' | translate}}</h1>
            <p>{{'documentoEncabezado.presentadoElectronicamenteMensaje' | translate}}</p>
          </div>
        </div>
      </div>
    </span>
  </h1>

  <span class="serialTit">
    <form *ngIf="documentoEncabezado.historialPrefijoPos" id="Form2" class="form-inputs">
      <fieldset [disabled]="!onlineOffline || !documentoEncabezado.enLinea || documentoEncabezado.contabilizado">
        <div class="form-group">
          <fieldset disabled>
            <wo-text-field-seg class="inputNumDoc" id="prefijoEncabezado" name="prefijoEncabezado"
              [tipoInput]="'number'" [completo]="requeridosEncabezadoPendientes"
              [valorActual]="documentoEncabezado.historialPrefijoPos.nombrePrefijo" [pantalla]="tipoDocumento"
              [label]="'puntosVenta.encabezado.prefijoAnterior'" [noDelete]="true"
              (activarLabelEmit)="documentoEncabezado.historialPrefijoPos.nombrePrefijo=$event"
              [noFormatearBlur]="true">
            </wo-text-field-seg>
          </fieldset>
        </div>
        <div class="form-group prefijoAnterior">
          <fieldset disabled>
            <wo-text-field-seg class="inputNumDoc" id="icon_numdoc" name="icon_numdoc" [tipoInput]="'number'"
              [valorActual]="documentoEncabezado.historialPrefijoPos.numero" [pantalla]="tipoDocumento"
              [label]="'puntosVenta.encabezado.numAnterior'" [noDelete]="true"
              (activarLabelEmit)="documentoEncabezado.historialPrefijoPos.numero=$event" [noFormatearBlur]="true">
            </wo-text-field-seg>
          </fieldset>
        </div>
      </fieldset>
    </form>
    <form id="Form2" class="form-inputs">
      <fieldset
        [disabled]="!onlineOffline || !documentoEncabezado.enLinea || documentoEncabezado.contabilizado || documentoEncabezado.bloqueado || documentoEncabezado.anulado">
        <div class="form-group" style="width: 120px;">
          <select-pag-seg id="prefijoEncabezado" name="prefijoEncabezado" [completo]="requeridosEncabezadoPendientes"
            [valorActual]="documentoEncabezado.prefijo" [columnas]="['nombre']" [pag]="true" [registrosPagina]="'10'"
            [tieneIconImage]="false" [lista]="listaPrefijosUsuario" [filtro]="filtrosPrefijo" [pantalla]="tipoDocumento"
            [minChars]="1" [nombre]="'prefijoEncabezado'" [label]="'puntosVenta.encabezado.prefijo'"
            (onClickSelect)="consultarTerminal()"
            (respuesta)="documentoEncabezado.prefijo=$event; cambioPrefijoNumero(documentoEncabezado.prefijo, 'prefijo')">
          </select-pag-seg>
        </div>
        <div class="form-group">
          <fieldset
            [disabled]="borrador || !onlineOffline || !documentoEncabezado.enLinea || documentoEncabezado.contabilizado || documentoEncabezado.bloqueado || documentoEncabezado.anulado">
            <wo-text-field-seg [tablaEnum]="'prefijo'" class="inputNumDoc" id="icon_numdoc" name="icon_numdoc"
              [tipoInput]="'number'" [completo]="requeridosEncabezadoPendientes"
              [valorActual]="documentoEncabezado.numero" [pantalla]="tipoDocumento"
              [label]="'puntosVenta.encabezado.num'" [noDelete]="true"
              (activarLabelEmit)="documentoEncabezado.numero=$event" (respuesta)="cambioPrefijoNumero($event, 'numero')"
              [noFormatearBlur]="true" [requeridoLocalImportante]="onlineOffline && documentoEncabezado.enLinea">
            </wo-text-field-seg>
          </fieldset>
        </div>
      </fieldset>
    </form>
  </span>

</div>

<div class="themeCont accordeonTabla" style="width:100%" (click)="cerrarMenuContextual()">
  <div class="tabsVertical">
    <div class="tabContent">
      <input [ngClass]="{'active': editarDatos}" class="checkTab" type="checkbox">
      <label class="labelMenu" (click)="abrirCerrarEditarEncabezado()">
        <i class="fas icoEditar {{editarDatos ? 'fa-times':'fa-edit'}}" [ngClass]="{'active': editarDatos}"></i>
        <span
          *ngIf="!editarDatos && (documentoEncabezado && documentoEncabezado.empresa && documentoEncabezado.terceroExterno && documentoEncabezado.terceroInterno)">
          <a id="GrupoPermiso" [ngClass]="{'active': editarDatos}">
            <i class="fas fa-building"></i> {{documentoEncabezado.empresa.nombre}}
            <i class="fas fa-user"></i>{{documentoEncabezado.terceroExterno.nombreCompleto}}
            <i class="fas fa-id-card"></i>{{documentoEncabezado.terceroInterno.nombreCompleto}}
          </a>
        </span>
        <span
          *ngIf="editarDatos || (!documentoEncabezado.empresa || !documentoEncabezado.terceroExterno || !documentoEncabezado.terceroInterno)">
          <a id="GrupoPermiso" [ngClass]="{'active': editarDatos}">
            <i class="fas fa-file-invoice"></i>
            {{'puntosVenta.editarEncabezado' | translate}}
          </a>
        </span>
      </label>
      <div class="tabV">
        <div *ngIf="editarDatos" style="width: 100%;">
          <div id="datosEncabezado" class="contTabletheme noBorder" style="box-shadow: none">
            <app-encabezado [borrador]="encabezadoIncompleto" [parametros]="parametros" [online]="onlineOffline"
              (verMasOpciones)="abrirMasOpciones($event)" [datosEncabezado]="datosEncabezado"
              [nuevoCliente]="nuevoCliente" [nuevaDireccion]="nuevaDireccion"
              [crearTerceroOffline]="crearTerceroOffline" [completo]="requeridosEncabezadoPendientes"
              [disableBox]="(documentoEncabezado.contabilizado || documentoEncabezado.anulado || documentoEncabezado.bloqueado) || documentoEncabezado.contabilizando" [camposPersonalizadosIncompletos]="camposPersonalizadosIncompletos"
              [permisosPOS]="permisosPOS" [sincronizando]="sincronizando"
              (cambioDatosEncabezado)="cambioDatosEncabezado($event)" (crearTercero)="crearTerceroPopup($event)"
              (crearDireccion)="crearDireccionPopup()">
            </app-encabezado>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="editarDatos || abrirResumen" id="background"
  (click)="cerrarEditarDatos(); abrirResumen = false; cerrarMenuContextual()" (keydown.enter)="$event.preventDefault()">
</div>

<div id="menuPuntoVenta" (click)="cerrarEditarDatos(); cerrarMenuContextual()">
  <button *ngIf="permisosPOS['POS-botonera/crear'] != 'INVISIBLE'" class="opcionMenu flex"
    (click)="facturacionService.crearDocumentoPOS.next(documentoEncabezado.contabilizado)"
    (keydown.enter)="$event.preventDefault()"
    [ngClass]="{'activo': permisosPOS['POS-botonera/crear'] == 'VISIBLE_EDITABLE'}">
    <i class="fas fa-plus"></i>
    <span class="textoOpcion">{{'puntosVenta.menu.crearNuevo' | translate}}</span>
  </button>
  <button *ngIf="permisosPOS['POS-botonera/cruzar'] != 'INVISIBLE'" class="opcionMenu flex" (click)="cruzarDocumentos()"
    (keydown.enter)="$event.preventDefault()"
    [ngClass]="{'activo': permisosPOS['POS-botonera/cruzar'] == 'VISIBLE_EDITABLE' && !documentoEncabezado.contabilizado}">
    <i class="fas fa-random"></i>
    <span class="textoOpcion">{{'puntosVenta.menu.cruzarDocumentos' | translate}}</span>
  </button>
  <button *ngIf="permisosPOS['POS-botonera/imprimir'] != 'INVISIBLE'" class="opcionMenu flex"
    [ngClass]="{'activo': documentoEncabezado | encabezadoCompleto:documentoEncabezado.id:documentoEncabezado.empresa:documentoEncabezado.terceroExterno:documentoEncabezado.terceroInterno:documentoEncabezado.formaPago:documentoEncabezado.numero:documentoEncabezado.enLinea:(permisosPOS['POS-botonera/imprimir'] == 'VISIBLE_EDITABLE')}"
    (click)="imprimir()" (keydown.enter)="$event.preventDefault()">
    <i class="fas fa-print"></i>
    <span class="textoOpcion">{{'puntosVenta.menu.imprimir' | translate}}</span>
  </button>
  <button *ngIf="permisosPOS['POS-botonera/imprimir'] != 'INVISIBLE'" class="opcionMenu flex"
    [ngClass]="{'activo': documentoEncabezado | encabezadoCompleto:documentoEncabezado.id:documentoEncabezado.empresa:documentoEncabezado.terceroExterno:documentoEncabezado.terceroInterno:documentoEncabezado.formaPago:documentoEncabezado.numero:documentoEncabezado.enLinea:(permisosPOS['POS-botonera/imprimir'] == 'VISIBLE_EDITABLE')}"
    (click)="imprimir(true)" (keydown.enter)="$event.preventDefault()">
    <i class="fas fa-receipt"></i>
    <span class="textoOpcion">{{'puntosVenta.menu.ticket' | translate}}</span>
  </button>
  <button
    *ngIf="(documentoEncabezado.contabilizado ? permisosPOS['POS-botonera/verContBotonera'] != 'INVISIBLE' : !documentoEncabezado.contabilizado && permisosPOS['POS-botonera/contabilizarBotonera'] != 'INVISIBLE')"
    class="opcionMenu flex" (click)="ejecutarContabilizacion()"
    [ngClass]="{'activo': (documentoEncabezado | encabezadoCompleto:documentoEncabezado.id:documentoEncabezado.empresa:documentoEncabezado.terceroExterno:documentoEncabezado.terceroInterno:documentoEncabezado.formaPago:documentoEncabezado.numero:documentoEncabezado.enLinea:(permisosPOS['POS-botonera/contabilizarBotonera'] == 'VISIBLE_EDITABLE')) && !documentoEncabezado.contabilizado && data?.length > 0 && !documentoEncabezado.anulado && !documentoEncabezado.bloqueado , 'contabilizado': documentoEncabezado.contabilizado && onlineOffline && documentoEncabezado.enLinea && permisosPOS['POS-botonera/verContBotonera'] == 'VISIBLE_EDITABLE'}"
    (keydown.enter)="$event.preventDefault()">
    <i class="fas fa-calculator"></i>
    <span class="textoOpcion">{{(documentoEncabezado.contabilizado ? (!onlineOffline || !documentoEncabezado.enLinea ||
      permisosPOS['POS-botonera/verContBotonera'] == 'INVISIBLE') ?
      'puntosVenta.menu.contabilizado':'puntosVenta.menu.verContabilizacion' :
      'puntosVenta.menu.contabilizar') | translate}}</span>
  </button>
  <button *ngIf="permisosPOS['POS-botonera/crearDocsNota'] != 'INVISIBLE'" class="opcionMenu flex"
    (click)="crearDocumentos()"
    [ngClass]="{'activo': (documentoEncabezado | encabezadoCompleto:documentoEncabezado.id:documentoEncabezado.empresa:documentoEncabezado.terceroExterno:documentoEncabezado.terceroInterno:documentoEncabezado.formaPago:documentoEncabezado.numero:documentoEncabezado.enLinea:(permisosPOS['POS-botonera/crearDocsNota'] == 'VISIBLE_EDITABLE')) && documentoEncabezado.contabilizado && !documentoEncabezado.anulado && !documentoEncabezado.bloqueado}"
    (keydown.enter)="$event.preventDefault()">
    <i class="fas fa-file-medical"></i>
    <span class="textoOpcion">{{'puntosVenta.menu.crearDocumentos' | translate}}</span>
  </button>
  <button class="opcionMenu flex" (click)="presentarElectronicamente()"
    [ngClass]="{'activo': (documentoEncabezado | encabezadoCompleto:documentoEncabezado.id:documentoEncabezado.empresa:documentoEncabezado.terceroExterno:documentoEncabezado.terceroInterno:documentoEncabezado.formaPago:documentoEncabezado.numero:documentoEncabezado.enLinea:true) && documentoEncabezado.contabilizado && !documentoEncabezado.anulado && !documentoEncabezado.bloqueado && !documentoEncabezado.estaPresentadoElectronicamente && prefijoElectronico && resolucionVigente}"
    [disabled]="!((documentoEncabezado | encabezadoCompleto:documentoEncabezado.id:documentoEncabezado.empresa:documentoEncabezado.terceroExterno:documentoEncabezado.terceroInterno:documentoEncabezado.formaPago:documentoEncabezado.numero:documentoEncabezado.enLinea:true) && documentoEncabezado.contabilizado && !documentoEncabezado.anulado && !documentoEncabezado.bloqueado && !documentoEncabezado.estaPresentadoElectronicamente && prefijoElectronico && resolucionVigente)"
    (keydown.enter)="$event.preventDefault()">
    <i class="fas fa-share-square"></i>
    <span class="textoOpcion">{{'puntosVenta.menu.comprobanteElectronico' | translate}}</span>
  </button>
  <!-- <button class="opcionMenu flex" (click)="notaCreditoDebito('NCV')"
    [ngClass]="{'activo': (documentoEncabezado | encabezadoCompleto:documentoEncabezado.id:documentoEncabezado.empresa:documentoEncabezado.terceroExterno:documentoEncabezado.terceroInterno:documentoEncabezado.formaPago:documentoEncabezado.numero:documentoEncabezado.enLinea) && documentoEncabezado.contabilizado && !documentoEncabezado.anulado && !documentoEncabezado.bloqueado }"
    (keydown.enter)="$event.preventDefault()">
    <i class="fas fa-file-invoice"></i>
    <span class="textoOpcion">{{'puntosVenta.menu.notaCredito' | translate}}</span>
  </button>
  <button class="opcionMenu flex" (click)="notaCreditoDebito('NDV')"
    [ngClass]="{'activo': (documentoEncabezado | encabezadoCompleto:documentoEncabezado.id:documentoEncabezado.empresa:documentoEncabezado.terceroExterno:documentoEncabezado.terceroInterno:documentoEncabezado.formaPago:documentoEncabezado.numero:documentoEncabezado.enLinea) && documentoEncabezado.contabilizado && !documentoEncabezado.anulado && !documentoEncabezado.bloqueado}"
    (keydown.enter)="$event.preventDefault()">
    <i class="fas fa-file-invoice-dollar"></i>
    <span class="textoOpcion">{{'puntosVenta.menu.notaDebito' | translate}}</span>
  </button>
  <button class="opcionMenu flex" (click)="reciboDeCaja()" (keydown.enter)="$event.preventDefault()"
    [ngClass]="{'activo': (documentoEncabezado | encabezadoCompleto:documentoEncabezado.id:documentoEncabezado.empresa:documentoEncabezado.terceroExterno:documentoEncabezado.terceroInterno:documentoEncabezado.formaPago:documentoEncabezado.numero:documentoEncabezado.enLinea) && !documentoEncabezado.anulado && documentoEncabezado.contabilizado && !documentoEncabezado.bloqueado && onlineOffline}">
    <i class="fas fa-newspaper"></i>
    <span class="textoOpcion">{{'puntosVenta.menu.reciboCaja' | translate}}</span>
  </button> -->
  <button *ngIf="permisosPOS['POS-botonera/anular'] != 'INVISIBLE'" class="opcionMenu flex" (click)="preguntaAnular()"
    (keydown.enter)="$event.preventDefault()"
    [ngClass]="{'activo': (documentoEncabezado | encabezadoCompleto:documentoEncabezado.id:documentoEncabezado.empresa:documentoEncabezado.terceroExterno:documentoEncabezado.terceroInterno:documentoEncabezado.formaPago:documentoEncabezado.numero:documentoEncabezado.enLinea:permisosPOS['POS-botonera/anular'] == 'VISIBLE_EDITABLE') && !documentoEncabezado.anulado && !documentoEncabezado.bloqueado}">
    <i class="fas fa-ban"></i>
    <span class="textoOpcion">{{'puntosVenta.menu.anular' | translate}}</span>
  </button>
  <button *ngIf="permisosPOS['POS-botonera/eliminar'] != 'INVISIBLE'" class="opcionMenu flex"
    (click)="preguntaEliminar()" (keydown.enter)="$event.preventDefault()"
    [ngClass]="{'activo': !documentoEncabezado.bloqueado && permisosPOS['POS-botonera/eliminar'] != 'INVISIBLE'}">
    <i class="fas fa-trash"></i>
    <span class="textoOpcion">{{'puntosVenta.menu.eliminar' | translate}}</span>
  </button>
</div>

<div class="contentPOS" (click)="cerrarMenuContextual()">
  <!--aplicar la clase "contentPOS2" para el contenedor sin botonera izquierda-->
  <div
    *ngIf="documentoEncabezado | encabezadoCompleto:documentoEncabezado.id:documentoEncabezado.empresa:documentoEncabezado.terceroExterno:documentoEncabezado.terceroInterno:documentoEncabezado.formaPago:documentoEncabezado.numero:documentoEncabezado.enLinea: true"
    class="posLeft" (click)="abrirResumen = false" (keydown.enter)="$event.preventDefault()">
    <app-productos [idEnBD]="idDocDB" [idTerceroExternoSel]="documentoEncabezado?.terceroExterno?.id"
      [idEmpresaSel]="documentoEncabezado?.empresa.id"
      [noAgregarProductos]="documentoEncabezado.contabilizado || documentoEncabezado.anulado || documentoEncabezado.bloqueado"
      (agregarProducto)="agregarProducto($event)"></app-productos>
  </div>
  <div mwlResizable [resizeEdges]="{ left: true, right: false }" [resizeSnapGrid]="{ left: 10, right: 10 }"
    (resizeEnd)="onResizeEnd($event)" (resizing)="resizing($event)" class="contenedorRight"
    *ngIf="documentoEncabezado | encabezadoCompleto:documentoEncabezado.id:documentoEncabezado.empresa:documentoEncabezado.terceroExterno:documentoEncabezado.terceroInterno:documentoEncabezado.formaPago:documentoEncabezado.numero:documentoEncabezado.enLinea:true">
    <div id="accionAbrirResumen" class="flex" (click)="abrirResumen = true; editarDatos = false;"
      (keydown.enter)="$event.preventDefault()">
      <i class="fas fa-clipboard-list"></i>
    </div>
    <div class="posRight themeVioletGrape flex column" [ngClass]="{'abrirResumen':abrirResumen}">
      <div class="titlePrincipal">
        <h3 class="flex flexStart">
          <i id="cerrarResumen" class="fas fa-times-circle" (click)="abrirResumen = false"
            (keydown.enter)="$event.preventDefault()"></i>
          <span class="contTit">{{'puntosVenta.tituloResumen' | translate}} <span
              id="text2TituloResumen">{{'puntosVenta.tituloResumen2' | translate}}</span></span>
        </h3>
        <!-- <div id="descuentoTotalFactura" class="flex">
          <button class="fas fa-funnel-dollar" type="button" (click)="descuentoFactura()"></button>
        </div> -->
        <div class="flex column" [title]="'puntosVenta.textAgruparProductos' | translate"
          [ngClass]="{'noVisibleBloqueado': permisosPOS['POS-movimientos/agrupar'] == 'INVISIBLE', 'bloqueado': permisosPOS['POS-movimientos/agrupar'] == 'BLOQUEADO'}">
          <span id="textAgrupar">{{'puntosVenta.agruparProductos' | translate}}</span>
          <div class="checkboxAgrupar">
            <input type="checkbox" id="checkAgrupar" [(ngModel)]="agruparProductos" (ngModelChange)="cambioAgrupar()"
              name="checkAgrupar" [disabled]="permisosPOS['POS-movimientos/agrupar'] == 'BLOQUEADO'" />
            <label for="checkAgrupar"
              [ngClass]="{'bloqueado': permisosPOS['POS-movimientos/agrupar'] == 'BLOQUEADO'}"></label>
          </div>
        </div>
        <div class="opcionesVisualizacion flex">
          <i class="fas fa-list-ul" [ngClass]="{'visualizacionActive': visualizacionList}"
            [title]="'POS.puntosVenta.opciones.verListaResumen' | translate" (click)="cambioVisualizacion('Lista')"
            (keydown.enter)="$event.preventDefault()"></i>
          <i class="fas fa-table" [ngClass]="{'visualizacionActive': !visualizacionList}"
            [title]="'POS.puntosVenta.opciones.verItemsResumen' | translate" (click)="cambioVisualizacion('Tabla')"
            (keydown.enter)="$event.preventDefault()"></i>
        </div>
      </div>
      <div id="contenedorVerEnLista" class="contenedorResumen">
        <div class="verEnLista" *ngIf="tablaActiva && visualizacionList">
          <div class="productoListaResumen" *ngFor="let producto of data, let i=index">
            <div class="flex">
              <img
                [src]="producto.imagen ? producto.imagen : '/assets/images/pos/imgProducto.jpg'"
                alt="Producto" onerror="this.onerror=null;this.src='/assets/images/pos/imgProducto.jpg'" />
            </div>
            <div class="flex column contenedorTextoProductoResumen">
              <p class="nombreProducto" [title]="producto.nombre">{{producto.nombre}}</p>
              <div class="contenedorNotaProducto flex"
                *ngIf="producto.concepto && permisosPOS['POS-movimientos/concepto'] != 'INVISIBLE'"
                [title]="producto.concepto" (click)="abrirNotal(producto.id)" (keydown.enter)="$event.preventDefault()"
                [ngClass]="{'noPointer': documentoEncabezado.contabilizado}">
                <i [id]="'nota'+producto.id" class="fas fa-sticky-note"></i>
                <p>{{producto.concepto}}</p>
              </div>
              <p [id]="'cantidad'+producto.id" class="cantidadProducto" (click)="abrirCantidad(producto)"
                (keydown.enter)="$event.preventDefault()">
                {{'puntosVenta.resumen.cantidadTexto' | translate}} {{producto.cantidad}}</p>
              <p class="precioProducto flex" (contextmenu)="onRightClick($event, producto)">
                <span>{{'puntosVenta.resumen.valorUnitario' | translate}} {{producto.unidadMedida.nombre}}</span>
                <span>{{producto.valorUnitario | formatearNumero:'':null:'moneda'}}</span>
              </p>
              <p [id]="'descuento'+producto.id" class="descuento"
                [title]="'POS.puntosVenta.opciones.editarDescuento' | translate"
                [ngClass]="{'hayDescuento':producto.porcentajeDescuento != 0, 'noVisible': permisosPOS['POS-movimientos/porcentajeDescuento'] == 'INVISIBLE', 'noEditable': permisosPOS['POS-movimientos/porcentajeDescuento'] == 'BLOQUEADO'}"
                (click)="abrirDescuento(producto)" (keydown.enter)="$event.preventDefault()">
                <i *ngIf="producto.senObsequio" class="fas fa-gift"></i>
                {{producto.senObsequio ? 'Obsequio' : producto.porcentajeDescuento != 0 ? ('Descuento ' +
                (producto.porcentajeDescuento | percent:'1.0-2')): 'Sin descuentos'}}
              </p>
            </div>
            <div class="flex column controlCantidades">
              <i class="fas fa-plus" (click)="cambiarCantidad('aumentar', producto)"
                (keydown.enter)="$event.preventDefault()"
                [ngClass]="{'noPointer': documentoEncabezado.contabilizado}"></i>
              <p [title]="producto.cantidad | formatearNumero:'':null:''">{{producto.cantidad |
                compactNumero}}
              </p>
              <i class="fas fa-minus" (click)="cambiarCantidad('disminuir', producto)"
                (keydown.enter)="$event.preventDefault()"
                [ngClass]="{'noPointer': documentoEncabezado.contabilizado}"></i>
            </div>
            <p class="flex subTotalProducto column" (contextmenu)="onRightClick($event, producto)">
              <span>
                {{(producto.valorTotalRenglon | formatearNumero:'':null:'moneda')}}
              </span>
              <span class="impuestosEnRenglon">
                + {{(producto.impuestoTotalRenglon | formatearNumero:'':null:'moneda')}}
              </span>
            </p>
            <div class="flex accionesProducto" style="justify-content: end !important;">
              <i class="editarProducto fas fa-tshirt" (click)="afterChange({verTallaColor: true, data: producto})"
                (keydown.enter)="$event.preventDefault()" title="Tallas y colores" *ngIf="producto.senManejaTallaColor"
                [ngClass]="{'noVisible': permisosPOS['POS-movimientos/menu'] == 'INVISIBLE'}"></i>
              <i class="editarProducto fas fa-barcode" (click)="afterChange({verSeriales: true, data: producto})"
                (keydown.enter)="$event.preventDefault()" title="Serial" *ngIf="producto.senManejaSeriales"
                [ngClass]="{'noVisible': permisosPOS['POS-movimientos/menu'] == 'INVISIBLE'}"></i>
              <i class="editarProducto fas fa-boxes" (click)="afterChange({verLotes: true, data: producto})"
                (keydown.enter)="$event.preventDefault()" title="Lotes" *ngIf="producto.senManejaLotes"
                [ngClass]="{'noVisible': permisosPOS['POS-movimientos/menu'] == 'INVISIBLE'}"></i>
              <i class="editarProducto fas fa-edit" (click)="abrirEditarProducto(producto)"
                (keydown.enter)="$event.preventDefault()" [title]="'POS.puntosVenta.opciones.editar' | translate"
                [ngClass]="{'noVisible': permisosPOS['POS-movimientos/editar'] == 'INVISIBLE'}"></i>
              <i class="eliminarProducto fas fa-trash" (click)="eliminarProducto(producto)"
                (keydown.enter)="$event.preventDefault()" [title]="'POS.puntosVenta.opciones.eliminar' | translate"
                [ngClass]="{'noVisible': permisosPOS['POS-movimientos/eliminar'] == 'INVISIBLE'}"></i>
            </div>
          </div>
        </div>

        <div *ngIf="tablaActiva && !visualizacionList" class="resumenTabla">
          <wo-tabla id="resumen" [tablaEnum]="'WT_RESUMEN'" (onClickTable)=onClickTable($event,data[$event.fila].id)
            [datos]="data" [columnas]="columnas" [opciones]="opcionesTabla" (afterChange)="afterChange($event)"
            [paginacion]="paginacion" [ocultarNumeracion]="true" [tablaResumenPOS]="true" [permisos]="permisos"
            [pantalla]="'POS-movimientos'" [tablaResize]="true" (onClickOpciones)="onClickOpcionesTable($event)">
          </wo-tabla>
        </div>

        <div *ngIf="!tablaActiva || !data || data.length == 0" class="flex finFactura">
          <i class="fas fa-tasks"></i>
          <p>{{'puntosVenta.resumen.noItems' | translate}}</p>
        </div>
      </div>
      <div class="colGrid-4 totalResumen">
        <table width="100%" border="0" cellspacing="0" cellpadding="0" class="contTheme marginBottomTotal contTotales">
          <thead>
            <tr>
              <td></td>
              <td>{{monedaLocal?.simbolo ? monedaLocal?.simbolo:'$'}}</td>
            </tr>
          </thead>
          <input class="openTotal" type="checkbox" [ngModel]="openTotal" [checked]="openTotal"
            (click)="openTotal = !openTotal" (keydown.enter)="$event.preventDefault()"
            title="{{'puntosVenta.resumen.detalleTotal' | translate}}">
          <i class="verMasTotales fas {{openTotal ? 'fa-minus-square':'fa-plus-square'}}"></i>
          <tbody *ngIf="openTotal">
            <tr id="campo-cantidades">
              <td>{{'puntosVenta.resumen.cantidades' | translate}}</td>
              <td class="informacion" attr.data-valorCampo="{{cantidadesMostrar}}">
                {{cantidadesMostrar}}
              </td>
            </tr>
            <tr id="campo-subtotal">
              <td>{{'puntosVenta.resumen.subtotal' | translate}}</td>
              <td class="c" attr.data-valorCampo="{{subtotalMostrar}}">
                {{subtotalMostrar | formatearNumero:'':null:'moneda'}}
              </td>
            </tr>
            <tr id="campo-descuentos">
              <td>{{'puntosVenta.resumen.descuentos' | translate}}</td>
              <td class="informacion" attr.data-valorCampo="{{descuentosMostrar}}">
                {{descuentosMostrar | formatearNumero:'':null:'moneda'}}
              </td>
            </tr>
            <tr id="campo-impuestos" *ngIf="impuestosMostrar">
              <td>{{'puntosVenta.resumen.impuestos' | translate}}</td>
              <td class="c" attr.data-valorCampo="{{impuestosMostrar}}">
                {{impuestosMostrar | formatearNumero:'':null:'moneda'}}
              </td>
            </tr>
            <input class="openImpuestos" type="checkbox">
            <blockquote></blockquote>
            <tr class="impuestosBody" *ngFor="let i of listaImpuestos">
              <td class="subtitTotal" title="{{i.nombreImpuesto}}">{{i.nombreImpuesto}}</td>
              <td>{{i.valor | formatearNumero:'':null:'moneda'}}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr id="campo-netoPagar">
              <td>{{'puntosVenta.resumen.total' | translate}}</td>
              <td class="informacion flex" attr.data-valorCampo="{{netoPagarMostrar}}">
                <!-- <div class="tool" data-tip='De acuerdo a la Resolución 1092 de DIAN, este documento superó las 5 UVT.'>
                  <i *ngIf="mostrarAlertaUVT" id="icoAlertaUVT" class="fas fa-exclamation-triangle"></i>
                </div> -->
                {{netoPagarMostrar | formatearNumero:'':null:'moneda'}}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div
        *ngIf="permisosPOS['POS-movimientos/descuentoTotal'] != 'INVISIBLE' || permisosPOS['POS-movimientos/detallesDelPago'] != 'INVISIBLE' || permisosPOS['POS-movimientos/pagoRapido'] != 'INVISIBLE'"
        class="botones"
        [ngClass]="{'dosBotones': (permisosPOS['POS-movimientos/descuentoTotal'] != 'INVISIBLE' && permisosPOS['POS-movimientos/detallesDelPago'] != 'INVISIBLE' && permisosPOS['POS-movimientos/pagoRapido'] == 'INVISIBLE') || (permisosPOS['POS-movimientos/descuentoTotal'] != 'INVISIBLE' && permisosPOS['POS-movimientos/detallesDelPago'] == 'INVISIBLE' && permisosPOS['POS-movimientos/pagoRapido'] != 'INVISIBLE') || (permisosPOS['POS-movimientos/descuentoTotal'] == 'INVISIBLE' && permisosPOS['POS-movimientos/detallesDelPago'] != 'INVISIBLE' && permisosPOS['POS-movimientos/pagoRapido'] != 'INVISIBLE'), 'unBoton': (permisosPOS['POS-movimientos/descuentoTotal'] != 'INVISIBLE' && permisosPOS['POS-movimientos/detallesDelPago'] == 'INVISIBLE' && permisosPOS['POS-movimientos/pagoRapido'] == 'INVISIBLE') || (permisosPOS['POS-movimientos/descuentoTotal'] == 'INVISIBLE' && permisosPOS['POS-movimientos/detallesDelPago'] != 'INVISIBLE' && permisosPOS['POS-movimientos/pagoRapido'] == 'INVISIBLE') || (permisosPOS['POS-movimientos/descuentoTotal'] == 'INVISIBLE' && permisosPOS['POS-movimientos/detallesDelPago'] == 'INVISIBLE' && permisosPOS['POS-movimientos/pagoRapido'] != 'INVISIBLE') }">
        <a *ngIf="permisosPOS['POS-movimientos/descuentoTotal'] != 'INVISIBLE'" class="pagar"
          (click)="descuentoFactura()" (keydown.enter)="$event.preventDefault()"
          [ngClass]="{'disablePagar': !data || data.length == 0 || documentoEncabezado.anulado || documentoEncabezado.bloqueado || permisosPOS['POS-movimientos/descuentoTotal'] == 'BLOQUEADO'}">
          <i class="fas fa-funnel-dollar"></i>
          <span>{{'puntosVenta.menu.descuento' | translate}}</span>
        </a>
        <a *ngIf="permisosPOS['POS-movimientos/detallesDelPago'] != 'INVISIBLE'" class="pagar"
          (click)="formaPagoNormal()" (keydown.enter)="$event.preventDefault()"
          [ngClass]="{'disablePagar': !data || data.length == 0 || documentoEncabezado.anulado || permisosPOS['POS-movimientos/detallesDelPago'] == 'BLOQUEADO' }">
          <i class="fas fa-search-dollar"></i>
          <span>{{'puntosVenta.resumen.pagar' | translate}}</span>
        </a>
        <a *ngIf="permisosPOS['POS-movimientos/pagoRapido'] != 'INVISIBLE'" class="pagoRapido"
          (click)="formaPagoRapido()" (keydown.enter)="$event.preventDefault()"
          [ngClass]="{'finalizarFactura' : documentoEncabezado?.diferenciaCero || documentoEncabezado.anulado ||
            documentoEncabezado.bloqueado, 'disablePagar': ((!data || data.length == 0) && !documentoEncabezado.anulado && !documentoEncabezado.bloqueado) || (!documentoEncabezado?.diferenciaCero && permisosPOS['POS-movimientos/pagoRapido'] == 'BLOQUEADO')}">
          <img
            *ngIf="(!documentoEncabezado?.diferenciaCero || !data || data.length == 0) && !documentoEncabezado.anulado && !documentoEncabezado.bloqueado"
            src="/assets/images/pos/botonPagoRapido.png" alt="World Office POS" />
          <i *ngIf="documentoEncabezado.diferenciaCero && data?.length > 0 || documentoEncabezado.anulado || documentoEncabezado.bloqueado"
            class="fas fa-file-upload"></i>
          <span>{{((documentoEncabezado?.diferenciaCero && data?.length > 0) || documentoEncabezado.anulado ||
            documentoEncabezado.bloqueado ?
            'puntosVenta.resumen.finalizarFactura' :
            'puntosVenta.resumen.pagoRapido') | translate}}</span>
        </a>
      </div>
      <div id="loadingResumen" *ngIf="loadingResumen" class="flex column loadingContenedorResumen">
        <i class="fas fa-spinner fa-spin"></i>
        <p>{{'puntosVenta.cargandoResumen' | translate}}</p>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="mostrarImprimirFactura">
  <app-imprimir-documento [dataInicializarImprimir]="dataInicializarImprimir" [envioFactura]="envioFactura"
    (respuestaPopup)="cerrarImprimirElegirPlantilla()">
  </app-imprimir-documento>
</ng-container>

<ng-container *ngIf="mostrarPopupImprimir">
  <app-imprimir-pos [id]="documentoEncabezado?.encabezadoId" [idOffline]="documentoEncabezado?.id"
    [codigoPlantilla]="dataImprimir?.codigo" [accion]="dataImprimir?.accion" [paginacion]="dataImprimir?.filtro"
    (cerrar)="cerrarImprimir()">
  </app-imprimir-pos>
</ng-container>

<ng-container *ngIf="popUpCantidad">
  <app-cantidad-pop-up [position]="positionClickTabla" [detalle]="detalleRenglon"
    (cambiarCantidad)="cambiarCantidadTabla($event)" (cerrar)="cerrarPopup($event)">
  </app-cantidad-pop-up>
</ng-container>

<ng-container *ngIf="popUpHabilitarDIAN" >
  <app-validacion-documentos-electronicos-pop-up
  [detalle]="detalleDIAN"
  (respuestaPopUp)="respuestaPopUpDIAN($event)"
  ></app-validacion-documentos-electronicos-pop-up>
</ng-container>

<ng-container *ngIf="popUpPorcentajeFactura">
  <app-porcentaje-factura-popup (cerrar)="popUpPorcentajeFactura = false"
    (cambiarDescuentoTotal)="cambiarDescuentoTotal($event)"></app-porcentaje-factura-popup>
</ng-container>

<ng-container *ngIf="popUpPorcentaje">
  <app-porcentaje-producto-popup [position]="positionClickTabla" [detalle]="detalleRenglon"
    (cerrar)="cerrarPopup($event)" (cambiarDescuento)="cambiarDescuento($event)">
  </app-porcentaje-producto-popup>
</ng-container>

<ng-container *ngIf="popUpNota">
  <app-nota-pop-up [position]="positionClickTabla" [detalle]="detalleRenglon" [sincronizando]="sincronizando"
    (cerrar)="cerrarPopup($event)">
  </app-nota-pop-up>
</ng-container>

<ng-container *ngIf="popUpProducto">
  <app-detalle-popup [documentoEncabezado]="documentoEncabezado" [detalle]="detalleProducto" [editar]="editarProducto"
    [moneda]="documentoEncabezado.moneda" [sincronizando]="sincronizando" (cerrar)="cerrarPopup($event)" (verPersonalizados)="verPersonalizados()"
    [permisosPOS]="permisosPOS">
  </app-detalle-popup>
</ng-container>

<ng-container *ngIf="formaPago">
  <app-detalle-pago [diferenciaCero]="documentoEncabezado.diferenciaCero" [documentoEncabezado]="documentoEncabezado"
    (cerrar)="cerrarDetallePago($event)" [onlineOffline]="onlineOffline" [sincronizando]="sincronizando"
    (mostrarOpcionesFinales)="mostrarOpcionesFinales($event)" (respuestaPopup)="cerrarDetallePago($event)">
  </app-detalle-pago>
</ng-container>

<ng-container *ngIf="nota">
  <app-copiar-documento-pos [fechaMinima]="documentoEncabezado.fecha" [empresa]="documentoEncabezado.empresa"
    [data]="data" [documentoEncabezadoIdDB]="idDocDB" [tipoDocumento]="notaCreditoDebido"
    (respuestaPopupCopiarDoc)="respuestaNotaCreditoDebito($event)">
  </app-copiar-documento-pos>
</ng-container>

<ng-container *ngIf="verOpcionesFinales">
  <app-opciones-finales [documentoEncabezado]="documentoEncabezado"
    [docContabilizado]="documentoEncabezado.contabilizado" [mostrarTextoFinal]="mostrarTextoFinal"
    [loadingContabilizar]="loadingContabilizar" [pagoRapidoExitoso]="pagoRapidoExitoso" [onlineOffline]="onlineOffline"
    [netoPagarMostrar]="netoPagarMostrar" [sincronizado]="documentoEncabezado.enLinea"
    (cerrar)="respuestaOpcionesFinales($event)" [permisosPOS]="permisosPOS"
    [bloquearOpciones]="documentoEncabezado.bloqueado || documentoEncabezado.anulado"
    (abrirSelPlantillas)="abrirSelPlantillas($event)"></app-opciones-finales>
</ng-container>

<ng-container *ngIf="verContabilizacion">
  <app-contabilizacion [tipoDocumento]="tipoDocumento" [tipoMovimiento]="tipoMovimiento"
    [empresa]="documentoEncabezado.empresa" (eliminoContabilizacion)="eliminoContabilizacion($event)"
    [idDocumento]="documentoEncabezado.encabezadoId" [moneda]="monedaLocal"
    (detalleCrucesEmit)="detalleCrucesEvent($event)" (cerrarPopUpContabilizaciones)="cerrarContabilizaciones()">
  </app-contabilizacion>
</ng-container>

<ng-container *ngIf="mostrarDetalleAbona">
  <app-detalle-abona [idElemento]="idElementoDetalleAbona" (respuestaPopup)="mostrarDetalleAbona = false">
  </app-detalle-abona>
</ng-container>

<ng-container *ngIf="mostrarPopUpMasOpciones">
  <app-mas-opciones (cerrarEsc2)="respuestaMasOpciones('cerrarEsc2',$event)"
    (notify)="respuestaMasOpciones('onNotifyClicked')"
    (notifyDocVerificado)="respuestaMasOpciones('onNotifyDocVerificado',$event)"
    [disabledNegocio]="documentoEncabezado.anulado || documentoEncabezado.contabilizado || documentoEncabezado.bloqueado"
    [idCliente]="datosEncabezado?.terceroExterno?.id" [cambios]="masOpciones.cambios" [idEncabezado]="idDocumento"
    [validacionBotonMas]="masOpciones.validacionBotonMas" [docVerificado]="masOpciones.docVerificado"
    [tipoDocumento]="'POS'" [docExportado]="masOpciones.docExportado" [elaboradoPor]="documentoEncabezado?.elaboradoPor"
    [atributosEncabezado]="masOpciones.atributosEncabezado">
  </app-mas-opciones>
</ng-container>

<ng-container *ngIf="habilitarListado && !popUpProducto">
  <app-pop-up-listado [color]="'themePOS'" [arregloDocumento]="arregloDocumento" [POS]="true"
    (valorUnitario)="ActualizarValorUnitario($event)"></app-pop-up-listado>
</ng-container>

<ng-container *ngIf="abrirSinConexion">
  <sin-conexion (cerrarMensaje)="abrirSinConexion = false"></sin-conexion>
</ng-container>

<ng-container *ngIf="crearTercero">
  <app-crear-tercero-popup [color]="'themePOS'" [vendedor]="crearVendedor" [terminal]="terminal"
    [documentoEncabezado]="documentoEncabezado" [sincronizando]="sincronizando" [onlineOffline]="onlineOffline"
    (cerrar)="cerrarCrearTercero($event)">
  </app-crear-tercero-popup>
</ng-container>

<ng-container *ngIf="crearDireccion">
  <app-crear-direccion-tercero-popup [color]="'themePOS'" [tercero]="tercero" [idDireccion]="idDireccion"
    [terminal]="terminal" [documentoEncabezado]="documentoEncabezado" [sincronizando]="sincronizando"
    [crearPrimeraDireccionTercero]="crearPrimeraDireccionTercero" [onlineOffline]="onlineOffline"
    [primeraDireccion]="primeraDireccion" (cerrar)="cerrarCrearDireccion($event)">
  </app-crear-direccion-tercero-popup>
</ng-container>


<ng-container *ngIf="crearReciboCaja">
  <app-copiar-documento [dataInicializar]="dataIniCopiarDocumentos" [monedaOrigen]="moneda"
    [tipoMovimiento]="tipoMovimiento" [documentoOrigen]="documentoActual" [idEncabezado]="idDocumento"
    [empresa]="documentoEncabezado.empresa" [fecha]="documentoEncabezado.fecha" [tipoDocumento]="tipoDocumento"
    [formaPagoOrigen]="documentoEncabezado.formaPago" (respuestaPopupCopiarDoc)="crearReciboCaja = false">
  </app-copiar-documento>
</ng-container>

<ng-container *ngIf="verCrearDocumento">
  <app-crear-documentos [docContabilizado]="documentoEncabezado.contabilizado" [mostrarTextoFinal]="mostrarTextoFinal"
    [loadingContabilizar]="loadingContabilizar" [pagoRapidoExitoso]="pagoRapidoExitoso" [onlineOffline]="onlineOffline"
    [sincronizado]="documentoEncabezado.enLinea" (cerrar)="respuestaCrearDocumentos($event)"
    [tipoResolucion]="this.tipoResolucion" [tipoLicencia]="tipoLicencia"
    [bloquearOpciones]="documentoEncabezado.bloqueado || documentoEncabezado.anulado"></app-crear-documentos>
</ng-container>

<ng-container *ngIf="verCruzarDocumentos">
  <app-cruzar-documentos [documentoEncabezado]="documentoEncabezado" [tipoDocumento]="'POS'"
    (cerrar)="cerrarCruzarInventarios()"></app-cruzar-documentos>
</ng-container>

<ng-container *ngIf="popUpObsequio">
  <app-obsequio-pop-up [documentoEncabezado]="documentoEncabezado" [esObsequioValor]="esObsequioValor"
    (respuestaObsequio)="esObsequio($event)" (cerrar)="popUpObsequio = false"></app-obsequio-pop-up>
</ng-container>

<ng-container *ngIf="abrirSelResolucion">
  <app-elegir-resoluciones-pop-up [documentoEncabezado]="documentoEncabezado"
    [listaMostrarValores]="listaPrefijosSeleccionables" (cerrar)="cerrarElegirResolucion()"
    (respuestaValorElegido)="cambioPrefijoDocElectronico($event)"></app-elegir-resoluciones-pop-up>
</ng-container>

<ng-container *ngIf="mostrarPopupSerial">
  <app-pop-up-seriales [tipoDocumento]="tipoDocumento" [estaContabilizado]="documentoEncabezado.contabilizado" (cerrarSeriales)="cerrarPopup($event)" [data]="dataSeriales"></app-pop-up-seriales>
</ng-container>

<ng-container *ngIf="mostrarPopUpTallaColor">
  <app-pop-up-tallacolor [tipoDocumento]="tipoDocumento" [estaContabilizado]="documentoEncabezado.contabilizado" (cerrarTallaColor)="cerrarPopup($event)" [data]="dataTallaColor"></app-pop-up-tallacolor>
</ng-container>

<ng-container *ngIf="mostrarPopUpLotes">
  <app-pop-up-lotes [tipoDocumento]="tipoDocumento" [estaContabilizado]="documentoEncabezado.contabilizado" (cerrarLotes)="cerrarPopup($event)" [renglon]="dataLotes"></app-pop-up-lotes>
</ng-container>
<ng-container *ngIf="mostrarPersonalizadoRenglon">
  <app-documento-pesonalizado-renglon [dataInicializarPersonalizados]="dataInicializarPersonalizados"
    [tipoDocumento]="tipoDocumento"
    [disabledNegocio]="(estadoAnulado | isDisabledEncabezado:contabilizo:contabilizando:estadoBloqueado:estadoPresentadoElectronicamente:estadoAjustadoInventario:estiloDisabled:'')"
    (respuestaPopupCerrar)="mostrarPersonalizadoRenglon = false">
  </app-documento-pesonalizado-renglon>
</ng-container>
