import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, OnDestroy } from '@angular/core';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { CentroCosto, ClasificacionInventarioEnum, Columna, CuentaContableTipoEnum, DocumentoTipoEnum, EmpresaPaginacion as Empresa, FiltroWo, Moneda, MovimientoTipoEnum, Nodo, OpcionTabla, Operador, PaginacionWo, SeveridadEnum, Tab, TipoDato, TipoFiltro, TipoInput } from '@wo/modelo';
import { ColaService, ContabilizacionService, FormatoUtilService, InventarioService, PermisosTablaService, ScrollInfinitoService, TabsService } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { ValorAnteriorService } from '@wo/frontend/servicios/valorAnterior/valor-anterior.service';
import { take, tap } from 'rxjs/operators';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';

@Component({
  selector: 'app-contabilizacion-avanzada',
  templateUrl: './contabilizacion-avanzada.component.html',
  styleUrls: [
    './contabilizacion-avanzada.component.css',
    '../../../../../wo-tabla/wo-tabla.component.css'
  ]
})
export class ContabilizacionAvanzadaComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input('anulado') anulado;
  @Input('presentadoElectronicamente') presentadoElectronicamente;
  @Input('moneda') moneda: Moneda;
  @Input('tipoDocumento') tipoDocumento;
  @Input('tipoMovimiento') tipoMovimiento;
  @Input('idDocumento') idDocumento;
  @Input('monedaExtranjera') monedaExtranjera;
  @Input('filtrosCuentas') filtrosCuentas;
  @Input('senLiquidacion') senLiquidacion
  @Input('pestana') pestana;
  @Input() empresa: Empresa;

  @Input('filtrosDocumentoAsociado') filtrosDocumentoAsociado;

  @Output() tieneContabilizaciones = new EventEmitter<boolean>();
  @Output() updateContEmitter = new EventEmitter<any>();

  informarContenidoVacio = true;

  contabilizaciones: Array<any> = [];
  pantalla = 'contabilizacionAvanzada';
  documentosAbona = new Array();
  cheques = new Array();
  // activosFijos = new Array();

  centroCostos: Array<Nodo<CentroCosto>> = [];

  monedaLocal: Moneda;
  monedaFormateoDecimales: Moneda;
  monedaExtranjeraDecimales: Moneda;
  decimalesMoneda = sessionStorage.getItem('PREFERENCIADECIMALES');

  senBloqueado = false;
  senAnulado = false;
  senPresentadoElectronicamente = false;
  isFinalClick:boolean = false;
  totalDebito: any;
  totalCredito: any;
  diferencia: any;

  cuentasAbonaA = [
    CuentaContableTipoEnum.CUENTAS_POR_COBRAR, CuentaContableTipoEnum.CUENTAS_POR_PAGAR, CuentaContableTipoEnum.CAJA
  ];

  cuantasAbonaDiferido = [
    CuentaContableTipoEnum.DIFERIDOS_PASIVO, CuentaContableTipoEnum.DIFERIDOS_ACTIVO
  ]

  cuentasCheque = [
    CuentaContableTipoEnum.CAJA, CuentaContableTipoEnum.BANCOS
  ];

  cuentasActivoFijo = [
    CuentaContableTipoEnum.DEPRECIACION, CuentaContableTipoEnum.ACTIVOS_FIJOS
  ];

  cuentasModificarFecha = [
    CuentaContableTipoEnum.CUENTAS_POR_COBRAR, CuentaContableTipoEnum.CUENTAS_POR_PAGAR
  ];

  esMovimientoContable = [
    MovimientoTipoEnum.CONTABLE, MovimientoTipoEnum.FORMA_PAGO, MovimientoTipoEnum.CONTABLE_INVENTARIO
  ];

  customColumnsMap: Map<string, string[]> = new Map([
    ["formaPago", ['formaPago', 'numeroDocumento', 'fechaVencimiento', 'tercero']],
    ["default", ['documentoTipo', 'prefijo', 'numDocumento', 'saldo', 'cuentaContable', 'concepto', 'id']]
  ]);

  mapaEliminarMensaje: Map<string, string> = new Map([
    [MovimientoTipoEnum.CONTABLE, 'eliminarDocumentoMovimientoContable'],
    [MovimientoTipoEnum.FORMA_PAGO, 'eliminarDocMovInvCB'],
    [MovimientoTipoEnum.CONTABLE_INVENTARIO, 'eliminarDMCDP'],
    ["default", 'eliminarDocumentoMovimientoContableContabilizacionAvanzada']
  ]);

  filaActual = 0;
  arregloColumnas = [];
  arregloColumnasEletronica = [];
  filaFecha: any;

  private subscriptions = new Subscription();

  filtroInventario: Array<FiltroWo> = [
    new FiltroWo('inventario.inventarioClasificacion.id', ClasificacionInventarioEnum.ACTIVO_FIJO + '', null, TipoFiltro.IGUAL, TipoDato.LONG, null, null, null, Operador.AND),
    new FiltroWo('inventario.senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, null, Operador.AND)];

  filtroCuentaVisible: FiltroWo = new FiltroWo('senVisible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'CuentaContable', Operador.AND);
  // filtroCuentaActiva: FiltroWo = new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'CuentaContable', Operador.AND);

  filtroDisponible: FiltroWo = new FiltroWo('senDisponible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND);
  filtroActivo: FiltroWo = new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND);
  filtrosTercero: Array<FiltroWo> = [this.filtroDisponible, this.filtroActivo];

  filtroSucursalTercero: FiltroWo = new FiltroWo('tercero.id', '4', null, TipoFiltro.IGUAL, TipoDato.LONG, null, null, null, Operador.AND);
  filtrosSucursal: Array<FiltroWo> = [this.filtroSucursalTercero];

  paginacion: any = {};

  assureDraft = false;
  mostrarErrores = false;

  permisos;
  borrador: boolean;
  idBorrador: any;
  openTotal: boolean;

  /*public opcionesTabla = {
    editable: true,
    ordenar: false,
    paginado: false,
    crear: true,
    menu: []
  };*/

  public opcionesTabla = {
    multimoneda: true,
    crear: true,
    editable: true,
    paginado: false,
    menu: [],
    ordenar: true,
    desabilitado: false,
    vacio: false
  };

  private advanceOptions = {
    filters: [],
    currentAttribute: null,
    currentPageAbona: 0,
    currentPageCheque: 0,
    currentPageAF: 0,
    thirdParty: null,
    accountingAccount: null
  }

  private woTablaPreferences = {
    pagina: 0,
    registrosPorPagina: 50,
    columna: 'cuentaContable.codigo',
    orden: 'ASC',
    registroInicial: 0
  }

  public columnas: Columna[];

  constructor(
    public tabsService: TabsService,
    private scrollInfinitoService: ScrollInfinitoService,
    private serviceContabilizacion: ContabilizacionService,
    private serviceColas: ColaService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private permisosTabla: PermisosTablaService,
    private translateUtilService: TranslateUtilService,
    public _LoaderService: LoaderService,
    private inventarioService: InventarioService,
    public formatoUtilService: FormatoUtilService,
    public _ValorAnteriorService: ValorAnteriorService,
    private popUpService: PopUpService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ocultarColumnasNomina(){
    let index = 0
    for(let item of this.columnas){
      if("trmFact" === item.atributo ||
      "activoFijo" === item.atributo ||
      "clasificacionImpuesto" === item.atributo ||
      "abonaDiferido" === item.atributo ||
      "valorExtranjero" === item.atributo){
        this.columnas.splice(index,1)
      }
      index++
    }
    this.columnas.splice(10,1)
    this.columnas.splice(10,1)
    this.columnas[0].estilo = 'colWidth200'
    this.columnas[1].estilo = 'colWidth100'
    this.columnas[2].estilo = 'colWidth230'
    this.columnas[3].estilo = 'colWidth130'
    this.columnas[4].estilo = 'colWidth130'
    this.columnas[5].estilo = 'colWidth100'
    this.columnas[7].estilo = 'colWidth150'
  }

  async ngOnInit() {
    await this.initColumns();
    this.getCCostos(this.columnas.findIndex(col => col.atributo === 'centroCosto'));
    this.getArregloColumnas();
    this.permisos = new Map(JSON.parse(sessionStorage.getItem('contabilizacionAvanzada')));
    this.permisosTabla.permisosTablaEditable(this.opcionesTabla, 'CA', this.permisos);
    if(this.tipoDocumento === DocumentoTipoEnum.NOMINA){
      this.ocultarColumnasNomina()
      // this.opcionesTabla.menu.push(OpcionTabla.AUDITORIA_CUENTA)
    }
    if(this.senLiquidacion){
      this.opcionesTabla.menu = this.opcionesTabla.menu.filter(opcion => opcion != 'Eliminar');
      this.opcionesTabla.crear = false
      this.opcionesTabla = Object.assign({}, this.opcionesTabla);
    }
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaFeaturePopup') this.respuestaFeaturePopup(respuesta.event)
      }
    )
  }

  ngAfterViewInit() {
    this.llenarTabla();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.filtrosCuentas) {
      if (changes.filtrosCuentas.currentValue) {
        changes.filtrosCuentas.currentValue.length === 0 ? null : this.filtrosCuentas = Array.isArray(changes.filtrosCuentas.currentValue) ? changes.filtrosCuentas.currentValue : [];
        this.filtrosCuentas.push(this.filtroCuentaVisible);
        // this.filtrosCuentas.add(this.filtroCuentaActiva);
        if (this.columnas) {
          this.columnas[this.columnas.findIndex(col => col.atributo === 'cuentaContable')].filtros = this.filtrosCuentas;
        }
      }
    }

    if (changes.filtrosDocumentoAsociado) {
      if (changes.filtrosDocumentoAsociado.currentValue) {
        if (this.columnas) {
          const colDocs = this.columnas.findIndex(col => col.atributo === 'abonaA');
          this.columnas[colDocs].filtros = changes.filtrosDocumentoAsociado.currentValue;
          this.columnas = this.columnas.map(x => Object.assign({}, x));
        }
      }
    }
    if (changes.moneda) {
      if (this.moneda) {
          this.monedaLocal = JSON.parse(sessionStorage.getItem("MONEDALOCAL"));
          this.monedaFormateoDecimales = new Moneda(this.monedaLocal.id, this.monedaLocal.codigo, this.monedaLocal.nombre, this.monedaLocal.simbolo,
            this.monedaLocal.separadorMiles, this.monedaLocal.separadorDecimales, this.moneda.cantidadDecimales);
      }
    }
    if (changes.monedaExtranjera) {
      if (changes.monedaExtranjera.currentValue) {
        if(this.tipoDocumento != DocumentoTipoEnum.NOMINA){
          this.setMonedaExtranjera(changes.monedaExtranjera.currentValue);
        }
      }
    }
    if (changes.empresa) {
      if (changes.empresa.currentValue) {
        this.filtroInventario.push(new FiltroWo('empresa.id', `${this.empresa.id}`, null, TipoFiltro.IGUAL, TipoDato.LONG, null, null, '', Operador.AND));
      }
    }
  }


  setMonedaExtranjera(monedaExtranjera) {
    this.monedaExtranjeraDecimales = new Moneda(monedaExtranjera.id, monedaExtranjera.codigo, monedaExtranjera.nombre, monedaExtranjera.simbolo,
      monedaExtranjera.separadorMiles, monedaExtranjera.separadorDecimales, monedaExtranjera.cantidadDecimales);
    this.columnas[this.columnas.findIndex(col => col.atributo === 'valorExtranjero')].moneda = this.monedaExtranjeraDecimales;
  }


  getArregloColumnas() {
    for (let i = 0; i < this.columnas.length; i++) {
      this.arregloColumnas.push(i);
    }
  }

  getArregloColumnasElectronicamente() {
    for (let i = 0; i < this.columnas.length; i++) {
      if (this.columnas[i].atributo !== 'clasificacionIva' && this.columnas[i].atributo !== 'centroCosto') {
        this.arregloColumnasEletronica.push(i);
      }
    }
  }

  getCCostos(columna) {
    let sub = this.inventarioService.getCostos().subscribe(
      response => {
        this.centroCostos = response.children;
        this.columnas[columna].arbol = this.centroCostos;
        this.columnas = this.columnas.map(x => Object.assign({}, x));
      },
      error => {
        this.errorHandlerComponent.handler(error);
      }
    );
    this.subscriptions.add(sub);
  }

  validateChange(fila, atributo) {
    if (atributo != 'cuentaContable') {
      return this.contabilizaciones[fila].cuentaContable == undefined ? false : true;
    }
    return true
  }

  afterChange(e) {
    if (e.columna === 'clasificacionImpuestoPojo') {
      const comando = this.comandosMap.get('asociarImpuestosClasificacionDocMovContable');
      const mensajeAsociarImpuesto = {
        clase: 'ClasificacionDefectoCuentaContable',
        id: this.contabilizaciones[e.fila].id,
        accion: comando.accion,
        atributo: e.columna,
        valor: e.valorNuevo.id,
        prioridad: true,
        tipoDato: comando.tipoDato,
        permisoGrueso: this.pantalla
      }
      this.enviar(mensajeAsociarImpuesto, e, null);
    } else if (e.crea || e.edita || e.acordeon) {
      let fila = e.fila;
      let atributo = e.columna;
      let valorAnterior = e.valorAnterior;
      let valorNuevo;
      e.valorNuevo.id ? valorNuevo = e.valorNuevo : valorNuevo = e.valorNuevo;
      let mensajeCrear;
      let mensajeModificar;
      let tipoDato = TipoDato[TipoDato.STRING];
      let accion = 'editarClasificacionImpuestoMovimientoContableContabilizacionAvanzada';
      let valido = true;
      let comprobado = true;
      comprobado = this.validateChange(fila, atributo)
      if (comprobado) {
        valido = this.validateAttribute(atributo, valorNuevo, fila);

        if (this.comandosMap.get(atributo)) {
          accion = this.comandosMap.get(atributo).accion;
          tipoDato = this.comandosMap.get(atributo).tipoDato;
          accion.startsWith('asociar') || accion.startsWith('crear') ? e.crea || e.edita ? valorNuevo = valorNuevo.id : valorNuevo = valorNuevo.data.id : null;
          valido = valorNuevo == -1 ? false : valido;
          if (atributo != 'activoFijo' && atributo != 'abonaDiferido') valorNuevo = valorNuevo == undefined ? -1 : valorNuevo
        }

        mensajeModificar = {
          clase: 'DocumentoMovimientoContable',
          id: this.contabilizaciones[fila].id,
          accion: accion,
          atributo: atributo,
          valor: valorNuevo,
          prioridad: true,
          tipoDato: tipoDato,
          permisoGrueso: this.pantalla
        };

        if (e.crea) {
          this._LoaderService.colocarMascara("contabilizacion", { tabla: false }, "loadingIndex", "contabilizacionAvanzada");
          mensajeCrear = {
            id: +this.idDocumento,
            accion: 'crearDocumentoMovimientoContableContabilizacionAvanzada',
            clase: 'DocumentoMovimientoContable',
            valor: 'crear',
            prioridad: true,
            atributo: '',
            permisoGrueso: this.pantalla
          };
          this.enviar(mensajeCrear, e, mensajeModificar);
        } else if (this.contabilizaciones[e.fila].id == undefined && e.columna != "cuentaContable") {
          this.esperarRespuesta();
          this.popUpService.open({ codigoError: 'elegirCuentaPrimero', severidad: SeveridadEnum.ERROR, conBoton: false });
          this.contabilizaciones[e.fila][e.columna] = undefined
        } else if (valido) {
          this.enviar(mensajeModificar, e, null);
        }

      } else {
        this.esperarRespuesta();
        this.popUpService.open({ codigoError: 'formaPagoDebeSeleccionarse', severidad: SeveridadEnum.ERROR });
        this.contabilizaciones[fila][atributo] = null;
        this.contabilizaciones = this.contabilizaciones.map(x => Object.assign({}, x));
      }
    }
    if (e.ordenar === true) {
      this.contabilizaciones = []
      this.llenarTabla2(e.columna, e.orden);
      // this.data = [];
      // this.llenarTablaPeticion(e.pagActual, e.registrosPorPagina, e.columna, e.orden);
      // this.paginaActual = e.pagActual;
      // this.registrosPaginaMostrar = e.registrosPorPagina;
      // this.contabilizaciones = [];
      // this.woTablaPreferences.pagina = e.pagActual;
      // this.woTablaPreferences.registrosPorPagina = e.registrosPorPagina
      // this.woTablaPreferences.columna = e.columna;
      // this.woTablaPreferences.orden = e.orden;
      // this.llenarTabla();
    }
    if (e.eliminar === true) {
      this.eliminarFila(e.id);
    }
    if (e.pag === true) {
      const regiInicial = !this.isFinalClick ? this.contabilizaciones.reduce((acc, e) => (e !== null && Object.keys(e).length !== 0) ? acc + 1 : acc, 0): e.registroInicial;
      this.woTablaPreferences.pagina = e.pagActual;
      this.woTablaPreferences.registrosPorPagina = e.registrosPorPagina
      this.woTablaPreferences.columna = e.columna;
      this.woTablaPreferences.orden = e.orden;
      this.woTablaPreferences.registroInicial = regiInicial;
      this.llenarTabla();
    }
    if (e.crearVacio) {
      this.contabilizaciones = this.contabilizaciones.map(x => Object.assign({}, x));
      this._LoaderService.quitarMascara("contabilizacion", "tabla", "loadingIndex", "contabilizacionAvanzada")
    }
    if(e.auditoriaCuenta){
      this.tabsService.openTab(
        new Tab(`/panel/visor/${e.data.id}/AUDITORIA_CUENTA_CONTABLE/reporteAuditoriaContabilizacion/valor=true&idMovContable=${e.data.id}`, 'imprimir ' + this.idDocumento + 'AUDITORIA_CUENTA_CONTABLE', [], {}, true, null, [], 'AUDITORIA_CUENTA_CONTABLE'));
    }
  }

  llenarTabla2(columna, orden) {
    this._LoaderService.colocarMascara("contabilizacion", { tabla: false }, "loadingIndex", "contabilizacionAvanzadaTabla");
    const paginacion = new PaginacionWo(columna);
    paginacion.orden = orden;
    paginacion.registrosPorPagina = 50;
    if(!this.isFinalClick || (this.isFinalClick && !(paginacion.registroInicial > this.paginacion.totalElementos-paginacion.registrosPorPagina))){
      let sub = this.serviceContabilizacion.getContabilizacionesAvanzadas(paginacion,this.idDocumento).subscribe((response:any) => {


         // Validacion monedas igual que en contabilizacion basica ?
            //fILTRO PARA SUCURSAL DEPENDIENDO DEL TERCERO
            //let contabilizaciones = response.renglones.content;
            // response.monedaPojo ? this.setMonedaExtranjera(response.monedaPojo) : null;
            //this.contabilizaciones = contabilizaciones.slice();

            this.senBloqueado = response.senBloqueado;
            this.senAnulado = response.senAnulado;
            this.senPresentadoElectronicamente = response.senEnviadoElectronicamente;

            response.renglones.content.forEach(element => {
              element.abonaA ? element.abonaA.fechaVencimiento ? this.formatoUtilService.formatoFecha(element.abonaA.fechaVencimiento) : null : null;
              element.abonaA ? element.abonaA.id ? element.abonaA.id = `[${element.abonaA.id}]` : element.abonaA.id = '' : null;
              element.customColumns = new Map<number, Array<string>>();
              let featureColumns = this.customColumnsMap.get('default');
              if (this.cuentasValidasCheque(element.cuentaContable.cuentaContableTipo)) {
                featureColumns = this.customColumnsMap.get('formaPago');
              }
              element.customColumns.set('abonaA', featureColumns);
            })

            this.paginacion = {
              totalPaginas: response.renglones.totalPages,
              totalElementos: response.renglones.totalElements,
              //pagina: pagina,
              pagina: paginacion.pagina,
              registrosPorPagina: this.woTablaPreferences.registrosPorPagina,
              numeroElementos: response.renglones.numberOfElements,
              registroInicial: this.woTablaPreferences.registroInicial,
              orden: this.woTablaPreferences.orden,
              columna: this.woTablaPreferences.columna,
              filtros: paginacion.filtros
            };


        this.contabilizaciones = response.renglones.content.slice();
        this.contabilizaciones = this.scrollInfinitoService.crearArregloScrollInfinito(this.paginacion, this.contabilizaciones, response.renglones.content,this.isFinalClick);
        if (!(this.senBloqueado || this.senAnulado)) {
          if (this.senPresentadoElectronicamente) {
            this.getArregloColumnasElectronicamente();
            this.bloqueadoElectronicamente();
          } else {
            this.contabilizaciones.forEach(
              element => {
                if (element) {
                  if (element.id) {
                    element.generado ? this.bloquearTodos(element) : this.validacionesBloqueado(element);
                  }
                }
              });
          }
        } else {
          this.docBloqueado();
        }
        if(this.senLiquidacion){
          for(let item of this.contabilizaciones){
            if(Object.keys(item).length >0){
              item.mapaBloqueado = new Map<number, Array<number>>();
              item.mapaBloqueado.set(item.id, [0, 2, 3, 4]);
            }
          }
        }
        this._LoaderService.quitarMascara("contabilizacion", "tabla", "loadingIndex", "contabilizacionAvanzadaTabla");
      }, e => {
        this.errorHandlerComponent.handler(e);
        this._LoaderService.quitarMascara("contabilizacion", "tabla", "loadingIndex", "contabilizacionAvanzadaTabla");
      });
      this.subscriptions.add(sub);
    }
  }

  setFinalClick(e){
    this.isFinalClick = e
    if(!e){
      this.contabilizaciones = [];
    }
    this._LoaderService.colocarMascara("Contabilizacion", { tabla: false }, "loadingIndex", "paneTabs");
    this.contabilizaciones = this.scrollInfinitoService.crearArregloScrollInfinito(this.paginacion, this.contabilizaciones,[],this.isFinalClick);
    this.contabilizaciones = this.contabilizaciones.map(x => Object.assign({}, x));
  }

  llenarTabla() {
    if (['id', 'cuentaContable.codigo'].includes(this.woTablaPreferences.columna)) {
      this.woTablaPreferences.columna = this.tipoDocumento == DocumentoTipoEnum.NOMINA ? 'id,cuentaContable.codigo' : 'cuentaContable.codigo';
      this.woTablaPreferences.orden = 'ASC';
    }
    const paginacion = new PaginacionWo(this.woTablaPreferences.columna);
    if (this.woTablaPreferences.registroInicial !== undefined) {
      paginacion.registroInicial = this.woTablaPreferences.registroInicial;
    }
    paginacion.registrosPorPagina = 50;
    paginacion.pagina = 0;
    paginacion.orden = this.woTablaPreferences.orden;

    this.actualizarTotales();
    if(!this.isFinalClick || (this.isFinalClick && !(paginacion.registroInicial > this.paginacion.totalElementos-paginacion.registrosPorPagina))){
      let sub2 = this.serviceContabilizacion
        .getContabilizacionesAvanzadas(paginacion, this.idDocumento)
        .subscribe(
          response => {
            // TO DO
            // Validacion monedas igual que en contabilizacion basica ?
            //fILTRO PARA SUCURSAL DEPENDIENDO DEL TERCERO
            //let contabilizaciones = response.renglones.content;
            if(this.tipoDocumento != DocumentoTipoEnum.NOMINA){
              response.monedaPojo ? this.setMonedaExtranjera(response.monedaPojo) : null;
            }
            //this.contabilizaciones = contabilizaciones.slice();
            this._LoaderService.quitarMascara("Contabilizacion","tabla","loadingIndex","paneTabs");
            this.senBloqueado = response.senBloqueado;
            this.senAnulado = response.senAnulado;
            this.senPresentadoElectronicamente = response.senEnviadoElectronicamente;

            response.renglones.content.forEach(element => {
              element.abonaA ? element.abonaA.fechaVencimiento ? this.formatoUtilService.formatoFecha(element.abonaA.fechaVencimiento) : null : null;
              element.abonaA ? element.abonaA.id ? element.abonaA.id = `[${element.abonaA.id}]` : element.abonaA.id = '' : null;
              element.customColumns = new Map<number, Array<string>>();
              let featureColumns = this.customColumnsMap.get('default');
              if (this.cuentasValidasCheque(element.cuentaContable.cuentaContableTipo)) {
                featureColumns = this.customColumnsMap.get('formaPago');
              }
              element.customColumns.set('abonaA', featureColumns);
            })

            this.paginacion = {
              totalPaginas: response.renglones.totalPages,
              totalElementos: response.renglones.totalElements,
              //pagina: pagina,
              pagina: paginacion.pagina,
              registrosPorPagina: this.woTablaPreferences.registrosPorPagina,
              numeroElementos: response.renglones.numberOfElements,
              registroInicial: this.woTablaPreferences.registroInicial,
              orden: this.woTablaPreferences.orden,
              columna: this.woTablaPreferences.columna,
              filtros: paginacion.filtros
            };

            this.contabilizaciones = this.scrollInfinitoService.crearArregloScrollInfinito(this.paginacion, this.contabilizaciones, response.renglones.content, this.isFinalClick);

            if (!(this.senBloqueado || this.senAnulado)) {
              if (this.senPresentadoElectronicamente) {
                this.getArregloColumnasElectronicamente();
                this.bloqueadoElectronicamente();
              } else {
                this.contabilizaciones.forEach(
                  element => {
                    if (element) {
                      if (element.id) {
                        element.generado ? this.bloquearTodos(element) : this.validacionesBloqueado(element);
                      }
                    }
                  });
              }
            } else {
              this.docBloqueado();
            }
            if(this.senLiquidacion){
              for(let item of this.contabilizaciones){
                if(Object.keys(item).length >0){
                  item.mapaBloqueado = new Map<number, Array<number>>();
                  item.mapaBloqueado.set(item.id, [0, 2, 3, 4]);
                }
              }
            }
            this.contabilizaciones = this.contabilizaciones.map(x => Object.assign({}, x));
          },
          error => {
            this.errorHandlerComponent.handler(error);
          }
        );
      this.subscriptions.add(sub2);
    }
  }
  //Inicio - Total moneda local
  async actualizarTotales() {
    const response = await this.obtenerTotales();

    this.totalCredito = response && response.totalCredito ? this.formatoUtilService.formatoMoneda(response.totalCredito, this.monedaFormateoDecimales) : '';
    this.totalDebito = response && response.totalDebito ? this.formatoUtilService.formatoMoneda(response.totalDebito, this.monedaFormateoDecimales) : '';
    this.diferencia = response && response.diferencia ? this.formatoUtilService.formatoMoneda(response.diferencia, this.monedaFormateoDecimales) : '';
  }

  obtenerTotales() {
    const paginacion = new PaginacionWo(this.woTablaPreferences.columna);
    return this.serviceContabilizacion.verContabilizacion(this.idDocumento.toString(), paginacion).toPromise();
  }
  // Fin- Total moneda local

  bloquearTodos(element) {
    element.mapaBloqueado = new Map<number, Array<number>>();
    element.mapaBloqueado.set(element.id, this.arregloColumnas);
  }

  bloquearElectronica(element) {
    element.mapaBloqueado = new Map<number, Array<number>>();
    element.mapaBloqueado.set(element.id, this.arregloColumnasEletronica);
  }

  docBloqueado() {
    this.contabilizaciones.forEach(
      element => {
        this.bloquearTodos(element);
      });

    this.opcionesTabla.menu = [];
    this.opcionesTabla.crear = false;
  }

  bloqueadoElectronicamente() {
    this.contabilizaciones.forEach(
      element => {
        this.bloquearElectronica(element);
      });

    this.opcionesTabla.menu = [];
    this.opcionesTabla.crear = false;
  }

  validacionesBloqueado(element) {
    if (!this.cuentasValidasAbonaA(element.cuentaContable.cuentaContableTipo)) { //|| element.senAbonaGenera
      element.mapaBloqueado = new Map<number, Array<number>>();
      let seleccionado = this.columnas.findIndex(x => x.atributo == 'abonaA');
      element.mapaBloqueado.set(element.id, [seleccionado]);
    }

    // bloqueo los campos trmFact,moneda,valorExtranjero
    if(this.tipoDocumento != DocumentoTipoEnum.NOMINA){
      this.bloquearMonedaExtranjera(element);
    }

    if (!this.cuentasValidasActivoFijo(element.cuentaContable.cuentaContableTipo)) {
      let seleccionado = this.columnas.findIndex(x => x.atributo == 'activoFijo');
      if (element.mapaBloqueado) {
        element.mapaBloqueado.set(element.id, element.mapaBloqueado.get(element.id).concat([seleccionado]))
      } else {
        element.mapaBloqueado = new Map<number, Array<number>>();
        element.mapaBloqueado.set(element.id, [seleccionado]);
      }
    }
    if (!this.cuentasValidasAbonaDiferido(element.cuentaContable.cuentaContableTipo)) {
      let seleccionado = this.columnas.findIndex(x => x.atributo == 'abonaDiferido');
      if (element.mapaBloqueado) {
        element.mapaBloqueado.set(element.id, element.mapaBloqueado.get(element.id).concat([seleccionado]))
      } else {
        element.mapaBloqueado = new Map<number, Array<number>>();
        element.mapaBloqueado.set(element.id, [seleccionado]);
      }
    }
  }

  private bloquearMonedaExtranjera(element: any) {
    element.mapaBloqueado = new Map<number, Array<number>>();
    let seleccionados: number[] = [];
    let atributoABloquear: string[] = ['trmFact', 'moneda', 'valorExtranjero'];
    for (const key of atributoABloquear) {
      let index = this.columnas.findIndex(x => x.atributo == key);
      (index != -1) && (seleccionados.push(index));
    }

    element.mapaBloqueado.set(element.id, seleccionados);
  }

  cuentasValidasAbonaA(cuentaContableTipo: any) {
    return cuentaContableTipo && this.cuentasAbonaA.includes(cuentaContableTipo.codigo);
  }

  cuentasValidasAbonaDiferido(cuentaContableTipo: any) {
    return cuentaContableTipo && this.cuantasAbonaDiferido.includes(cuentaContableTipo.codigo);
  }

  cuentasValidasContable() {
    return this.esMovimientoContable.includes(this.tipoMovimiento);
  }

  cuentasValidasFechaInventario(cuentaContableTipo: any) {
    return cuentaContableTipo && this.cuentasModificarFecha.includes(cuentaContableTipo.codigo);
  }

  cuentasValidasCheque(cuentaContableTipo: any) {
    return cuentaContableTipo && this.cuentasCheque.includes(cuentaContableTipo.codigo);
  }

  cuentasValidasActivoFijo(cuentaContableTipo: any) {
    return cuentaContableTipo && this.cuentasActivoFijo.includes(cuentaContableTipo.codigo);
  }

  convertirMonedaANum(moneda) {
    moneda = moneda.toString();
    let mon: Moneda;
    mon = this.monedaLocal;
    let re = new RegExp('\\' + mon.separadorMiles, 'g');
    let sinMiles = moneda.replace(re, '');
    if (mon.separadorDecimales == ',') {
      sinMiles = sinMiles.replace(/\,/gi, '.');
    }
    return sinMiles;
  }

  /**
 * retornarValorAnterior: En caso de recibir un mensaje de error del backend,
 * se retorna al valor anterior valido el atributo recibido
 * @param atributo
 * @param fila
 */
  retornarValorAnterior(atributo: string, fila: number) {
    if (atributo == 'valorDebito') {
      this.contabilizaciones[fila].valorDebito = '0';
    } else if (atributo == 'valorCredito') {
      this.contabilizaciones[fila].valorCredito = '0';
    }
    this.contabilizaciones = this.contabilizaciones.map(x => Object.assign({}, x));
  }


  validateAttribute(atributo, valorNuevo, fila) {
    switch (atributo) {
      case 'valorCredito':
        let debito = this.convertirMonedaANum(this.contabilizaciones[fila].valorDebito | 0);
        if (+valorNuevo !== 0 && +debito !== 0) {
          this.esperarRespuesta();
          this.popUpService.mostrarConDetalles({ codigoError: '272', detalles: [this.translateUtilService.getTranslateText('contabilizacion.avanzada.columnas.debito')] });
          this.retornarValorAnterior(atributo, fila);
          return false;
        }
        return true;
      case 'valorDebito':
        let credito = this.convertirMonedaANum(this.contabilizaciones[fila].valorCredito | 0);
        if (+valorNuevo !== 0 && +credito !== 0) {
          this.esperarRespuesta();
          this.popUpService.mostrarConDetalles({ codigoError: '272', detalles: [this.translateUtilService.getTranslateText('contabilizacion.avanzada.columnas.credito')] });
          this.retornarValorAnterior(atributo, fila);
          return false;
        }
        return true;
      default:
        return true;
    }
  }

  cuentaAnterior(valor, fila, error, severidad) {
    this.esperarRespuesta();
    this.popUpService.open({ codigoError: error, severidad, conBoton: false });
    this.contabilizaciones[fila].cuentaContable = valor;
  }

  enviar(mensaje, e, mensajeAfterCreate) {
    let atributo, columna, fila;
    e ? fila = e.fila : null;
    e ? atributo = e.columna : null;
    e ? columna = e.columna : null;
    let tablaEnum = 'WT_CONTABILIZACION_AVANZADA';

    let indexColumna = this.columnas.findIndex(c => c.atributo === e.columna);
    (indexColumna != -1) && (!this.columnas[indexColumna].requerido) && (mensaje.atributo == 'centroCosto' && mensaje.valor == undefined) && (mensaje.valor = -1);

    /* let observable = this.serviceColas.agregarACola(mensaje).pipe(
      tap(response => {
        this._ValorAnteriorService.setValorAnterior(tablaEnum, e, response, this.columnas.find(x => x.atributo === columna), this);
      })
    ); */

    let observable$ = this.serviceColas.agregarACola(mensaje)
      .pipe(
        take(1),
        tap(response => {
          this._ValorAnteriorService.setValorAnterior(tablaEnum, e, response, this.columnas.find(x => x.atributo === columna), this);
        }))
      .subscribe(
        response => {
          this._LoaderService.quitarMascara("contabilizacion", "tabla", "loadingIndex", "contabilizacionAvanzada")
          if (response.length > 0) {
            if (response[0].codigoError) {
              if (!['278', '288', '294', '293', '295', '296'].includes(response[0].codigoError)) {
                this.esperarRespuesta();
              }
              response[0].codigoError === '272' ? this.contabilizaciones[fila][mensaje.atributo] = '0' : null;
              response[0].codigoError === '274' ? this.llenarTabla() : null;
              // response[0].codigoError === '072' ? this.cuentaAnterior(response[0].valor, fila, response[0].codigoError, response[0].severidad) : null;

              response[0].codigoError === '272' ? this.popUpService.mostrarConDetalles({ codigoError: response[0].codigoError, detalles: [mensaje.atributo] }) :
                response[0].codigoError === '274' ? this.popUpService.mostrarConDetalles({ codigoError: response[0].codigoError, detalles: [response[0].valor[0], response[0].valor[1]] }) :
                  response[0].codigoError === '278' ? this.procesoFechaContable(response[0].codigoError, fila, atributo, response[0].valor, response[0].severidad) :
                    response[0].codigoError === '288' ? this.procesoFechaContable(response[0].codigoError, fila, atributo, response[0].valor, response[0].severidad) :
                      response[0].codigoError === '294' || response[0].codigoError === '293' || response[0].codigoError === '295' || response[0].codigoError === '296' ? this.procesarActivoFijo(response[0].codigoError, fila, atributo, response[0].valor, response[0].severidad) : this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });

              if (response[0].codigoError != '288') {
                this._ValorAnteriorService.regresarValorAnterior(tablaEnum, e.fila, e.columna, this.columnas.find(x => x.atributo === e.columna), this);
              }
              if(['225'].includes(response[0].codigoError)){
                this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, detalles: response[0].valor });
              }
            } else {

              if (response[0].accion === this.mapaEliminarMensaje.get(this.tipoMovimiento)) {
                this._ValorAnteriorService.eliminarFilaCompleta(tablaEnum, this.columnas, e);
              } else {
                this._ValorAnteriorService.confirmarValor(tablaEnum, e, this.columnas.find(x => x.atributo === e.columna), this);
              }

              if (response[0].accion === 'crearDocumentoMovimientoContableContabilizacionAvanzada') {
                response.forEach(
                  element => {
                    if (element.atributo) {
                      if (mensaje.atributo !== element.atributo) {
                        this.contabilizaciones[fila][element.atributo] = element.valor;
                      } else {
                        this.contabilizaciones[fila][element.atributo] = null;
                      }
                    }
                  });

                response.forEach(
                  element => {
                    this.tieneContabilizaciones.emit(true);
                    this.contabilizaciones[fila].id = response[0].id;
                    if (mensajeAfterCreate != null) {
                      mensajeAfterCreate.id = this.contabilizaciones[fila].id;
                    }
                  });

                this.contabilizaciones[fila].valorCredito = '0';
                this.contabilizaciones[fila].valorDebito = '0';

                this.assureDraft == false ? this.assureDraft = true : this.assureDraft = false;
                this.enviar(mensajeAfterCreate, e, null);

              } else if (response[0].accion === 'asociarCuentaContableDocMovContContabilizacionAvanzada') {

                response.forEach(
                  element => {
                    if (element.atributo) {
                      if (mensaje.atributo !== element.atributo) {
                        this.contabilizaciones[fila][element.atributo] = element.valor;
                      } else {
                        this.contabilizaciones[fila][element.atributo] = null;
                      }
                    }
                  });

                this.contabilizaciones[fila].cuentaContable.cuentaContableTipo = response[0].valor;
                this.contabilizaciones.forEach(element => {
                  if (element.id) {
                    element.customColumns = new Map<number, Array<string>>();
                    let featureColumns = this.customColumnsMap.get('default');
                    if (this.cuentasValidasCheque(element.cuentaContable.cuentaContableTipo)) {
                      featureColumns = this.customColumnsMap.get('formaPago');
                    }
                    element.customColumns.set('abonaA', featureColumns);
                  }
                })

                if (!(this.senBloqueado || this.senAnulado)) {
                  this.contabilizaciones[fila].generado || this.contabilizaciones[fila].senAnulado ? this.bloquearTodosAsociar(fila) : this.bloqueadoCambio(response, fila);
                } else {
                  this.docBloqueado();
                }
                this.assureDraft == false ? this.assureDraft = true : this.assureDraft = false;
              } else if (response[0].accion === 'asociarDocumentoCompromisoDocumentoMovimientoContable') {
                this.llenarTabla();
              } else if (response[0].accion === 'editarDocumentoMovimientoContableContabilizacionAvanzada') {
                response.forEach(
                  element => {
                    if (element.atributo && fila) {
                      mensaje.atributo !== element.atributo ? this.contabilizaciones[fila][element.atributo] = element.valor : null;
                    }
                  });
              }
              this.llenarTabla();
              this.updateContEmitter.emit();
            }
          } else {
            if (mensaje.accion == "fechaPasadaDocumento") {
              let e = { fila, atributo, valor: mensaje.valor }
              this.fechaVencimientoManageError(e)
            } else {
              this._ValorAnteriorService.confirmarValor(tablaEnum, e, this.columnas.find(x => x.atributo === e.columna), this);
              this.updateContEmitter.emit();
            }
          }
        })
    this.subscriptions.add(observable$);
  }

  bloquearTodosAsociar(fila) {
    this.contabilizaciones[fila].mapaBloqueado = new Map<number, Array<number>>();
    this.contabilizaciones[fila].mapaBloqueado.set(this.contabilizaciones[fila].id, this.arregloColumnas);
  }

  bloqueadoCambio(response, fila) {
    if (!this.cuentasValidasAbonaA(response[0].valor)) {
      this.contabilizaciones[fila].mapaBloqueado = new Map<number, Array<number>>();
      let seleccionado = this.columnas.findIndex(x => x.atributo == 'abonaA');
      this.contabilizaciones[fila].mapaBloqueado.set(this.contabilizaciones[fila].id, [seleccionado]);
    }
    if (!this.cuentasValidasContable() && !this.cuentasValidasFechaInventario(this.contabilizaciones[fila].cuentaContable.cuentaContableTipo)) {
      let seleccionadoFecha = this.columnas.findIndex(x => x.atributo == 'fechaVencimiento');
      if (this.contabilizaciones[fila].mapaBloqueado) {
        //this.contabilizaciones[fila].mapaBloqueado.set(this.contabilizaciones[fila].id, this.contabilizaciones[fila].mapaBloqueado.get(this.contabilizaciones[fila].id).concat([seleccionadoFecha]))
      } else {
        //this.contabilizaciones[fila].mapaBloqueado = new Map<number, Array<number>>();
        //this.contabilizaciones[fila].mapaBloqueado.set(this.contabilizaciones[fila].id, [seleccionadoFecha]);
      }
    }
    // if (!this.cuentasValidasCheque(response[0].valor)) {
    //   let seleccionado = this.columnas.findIndex(x => x.atributo == 'cheque');
    //   if (this.contabilizaciones[fila].mapaBloqueado) {
    //     this.contabilizaciones[fila].mapaBloqueado.set(this.contabilizaciones[fila].id, this.contabilizaciones[fila].mapaBloqueado.get(this.contabilizaciones[fila].id).concat([seleccionado]))
    //   } else {
    //     this.contabilizaciones[fila].mapaBloqueado = new Map<number, Array<number>>();
    //     this.contabilizaciones[fila].mapaBloqueado.set(this.contabilizaciones[fila].id, [seleccionado]);
    //   }
    // }
    if (!this.cuentasValidasActivoFijo(response[0].valor)) {
      let seleccionado = this.columnas.findIndex(x => x.atributo == 'activoFijo');
      if (this.contabilizaciones[fila].mapaBloqueado) {
        this.contabilizaciones[fila].mapaBloqueado.set(this.contabilizaciones[fila].id, this.contabilizaciones[fila].mapaBloqueado.get(this.contabilizaciones[fila].id).concat([seleccionado]))
      } else {
        this.contabilizaciones[fila].mapaBloqueado = new Map<number, Array<number>>();
        this.contabilizaciones[fila].mapaBloqueado.set(this.contabilizaciones[fila].id, [seleccionado]);
      }
    }
    if (!this.cuentasValidasAbonaDiferido(response[0].valor)) {
      let seleccionado = this.columnas.findIndex(x => x.atributo == 'abonaDiferido');
      if (this.contabilizaciones[fila].mapaBloqueado) {
        this.contabilizaciones[fila].mapaBloqueado.set(this.contabilizaciones[fila].id, this.contabilizaciones[fila].mapaBloqueado.get(this.contabilizaciones[fila].id).concat([seleccionado]))
      } else {
        this.contabilizaciones[fila].mapaBloqueado = new Map<number, Array<number>>();
        this.contabilizaciones[fila].mapaBloqueado.set(this.contabilizaciones[fila].id, [seleccionado]);
      }
    }
  }

  procesarActivoFijo(codError: any, fila: any, atributo: any, valorAnterior: any, severidad) {
    this.esperarRespuesta();
    this.popUpService.open({ codigoError: codError, severidad, conBoton: false });
    if (codError != '295') {
      atributo === 'activoFijo' ? this.contabilizaciones[fila][atributo] = valorAnterior : this.contabilizaciones[fila][atributo] = valorAnterior;
    }
  }

  procesoFechaContable(codError: any, fila: any, atributo: any, valorAnterior: any, severidad: any) {
    this.esperarRespuesta();
    this.popUpService.open({ codigoError: codError, severidad, conBoton: true, detalle: null, detalles: null, mensajes: null, detalleRespuesta: { fila, atributo, valorAnterior } });
  }

  procesoFechaNoContable(codError: any, fila: any, atributo: any, valorAnterior: any, severidad) {
    this.esperarRespuesta();
    this.popUpService.open({ codigoError: codError, severidad });
    this.contabilizaciones[fila][atributo] = valorAnterior;
  }

  eliminarFila(id) {

    let accionEliminar = this.mapaEliminarMensaje.get('default');
    if (this.mapaEliminarMensaje.get(this.tipoMovimiento)) {
      accionEliminar = this.mapaEliminarMensaje.get(this.tipoMovimiento);
    }

    let mensaje = {
      clase: 'ContabilizacionDetalle',
      accion: accionEliminar,
      valor: '',
      prioridad: true,
      id: id,
      atributo: '',
      permisoGrueso: this.tipoDocumento
    };
    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1)).subscribe(
        response => {
          if (response[0].codigoError) {
            if (response[0].codigoError === '629') {
              this.popUpService.open({ codigoError: 'eliminarContabilizacionConCruceDocumentoContableInventario', severidad: response[0].severidad, conBoton: false, detalle: response[0].valor });
            } else if(['225'].includes(response[0].codigoError)){
              this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, detalles: response[0].valor });
            }else {
              let valor = '';
              // response[0].codigoError === '23503' ? valor = response[0].valor : valor = '';
              response[0].codigoError === '223' ? response[0].codigoError = 'eliminacionRenglon' : response[0].codigoError;
              this.verPopup(response[0].codigoError, response[0].severidad, response[0].valor);
            }
          } else {
            let indexToDelete = this.contabilizaciones.findIndex(cont => cont.id === id);
            this.contabilizaciones.splice(indexToDelete, 1);
            // if (response[0].valor) {
            //   this.eliminoContabilizacion.emit();
            // }
            let hasConts = this.contabilizaciones.findIndex(x => x.id !== undefined);
            hasConts === -1 ? this.tieneContabilizaciones.emit(false) : null;
            this.contabilizaciones = this.contabilizaciones.map(x => Object.assign({}, x));
            this.updateContEmitter.emit();
            this.actualizarTotales();
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  verPopup(codigoError, severidad, detalles) {
    this.esperarRespuesta();
    this.popUpService.open({ codigoError, severidad, conBoton: undefined, detalle: detalles });
  }

  respuestaFeaturePopup(e: any) {
    if (e.isTrue) {
      if (e.atributo === 'fechaVencimiento') {
        this.fechaVencimientoManageError(e);
      }
    } else {
      if (e.atributo === 'fechaVencimiento') {
        this.contabilizaciones[e.fila][e.atributo] = e.valorAnterior;
      }
    }
  }

  fechaVencimientoManageError(e) {
    let mensajeModificar = {
      clase: 'DocumentoMovimientoContable',
      id: this.contabilizaciones[e.fila].id,
      accion: 'editarDocumentoMovimientoContableContabilizacionAvanzada',
      atributo: e.atributo,
      valor: this.contabilizaciones[e.fila][e.atributo],
      prioridad: true,
      tipoDato: TipoDato[TipoDato.FECHA],
      permisoGrueso: this.pantalla
    };
    let observable = this.serviceColas.agregarACola(mensajeModificar);
    if (observable) {
      const sub = observable.pipe(take(1)).subscribe(
        response => {
          if (response.length > 0) {
            if (response[0].codigoError) {
              this.esperarRespuesta();
              this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });
            }
          } else {
            this.updateContEmitter.emit();
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  onClickOpcionesTable(e){
      this.opcionesTabla.menu = []
      if(this.contabilizaciones[e].senReporteAuditoria){
        this.opcionesTabla.menu.push(OpcionTabla.AUDITORIA_CUENTA,OpcionTabla.ELIMINAR)
      }else{
        this.opcionesTabla.menu.push(OpcionTabla.ELIMINAR)
      }
      this.opcionesTabla = Object.assign([], this.opcionesTabla);
  }

  onClickTable(e) {
    this.filaActual = e.fila;
    if (e.enabled) {
      if (e.atributo === 'abonaA' || e.atributo === 'abonaDiferido') {
        this.advanceOptions.thirdParty = this.contabilizaciones[this.filaActual].terceroExterno.id;
        this.advanceOptions.accountingAccount = this.contabilizaciones[this.filaActual].cuentaContable.codigo;
        this.advanceOptions.currentAttribute = e.atributo;
        this.cargarDocumentos(null, e.fila, e.atributo);
      } else if (e.atributo === 'cheque') {
        this.advanceOptions.thirdParty = this.contabilizaciones[this.filaActual].terceroExterno.id;
        this.advanceOptions.accountingAccount = this.contabilizaciones[this.filaActual].cuentaContable.codigo;
        this.cargarCheques();
      } else if (e.atributo === 'sucursal') {
        this.filtrosSucursal[0].valor = this.contabilizaciones[this.filaActual].terceroExterno.id + '';
        this.columnas[this.columnas.findIndex(col => col.atributo === 'suscursal')].filtros = this.filtrosSucursal;
        this.columnas = this.columnas.map(col => Object.assign({}, col));
      }
    } else {
      if (e.atributo === 'fechaVencimiento') {
        //this.popUpServices.open({ codigoError: 'fechaBloqueadaCA', severidad: SeveridadEnum.ERROR });
      } else if (e.atributo === 'abonaA') {
        this.esperarRespuesta();
        !this.cuentasValidasAbonaA(this.contabilizaciones[this.filaActual].cuentaContable.cuentaContableTipo) ? this.popUpService.open({ codigoError: 'abonaABloqueadoCA', severidad: SeveridadEnum.ERROR, conBoton: false }) : this.popUpService.open({ codigoError: 'abonaABloqueadoAbonaCA', severidad: SeveridadEnum.ERROR, conBoton: false });
      } else if (e.atributo === 'activoFijo') {
        this.esperarRespuesta();
        !this.cuentasValidasActivoFijo(this.contabilizaciones[this.filaActual].cuentaContable.cuentaContableTipo) ? this.popUpService.open({ codigoError: 'activoFijoBloqueadoCA', severidad: SeveridadEnum.ERROR, conBoton: false }) : this.popUpService.open({ codigoError: 'abonaABloqueadoAbonaCA', severidad: SeveridadEnum.ERROR, conBoton: false });
      } else if (e.atributo === 'abonaDiferido') {
        this.esperarRespuesta();
        !this.cuentasValidasAbonaDiferido(this.contabilizaciones[this.filaActual].cuentaContable.cuentaContableTipo) ? this.popUpService.open({ codigoError: 'abonaDiferidoBloqueadoCA', severidad: SeveridadEnum.ERROR, conBoton: false }) : this.popUpService.open({ codigoError: 'abonaABloqueadoAbonaCA', severidad: SeveridadEnum.ERROR, conBoton: false });
      }
    }

  }

  filtroEscritura(e) {
    if (e.atributo === 'abonaA') {
      this.advanceOptions.currentPageAbona = e.paginaActual - 1;
      this.advanceOptions.filters = e.filtros;
      // this.cargarDocumentos();
    } else if (e.atributo === 'cheque') {
      this.advanceOptions.currentPageCheque = e.paginaActual - 1;
      // this.cargarCheques();
    }
    // else if (e.atributo === 'activoFijo') {
    //   this.advanceOptions.currentPageAF = e.paginaActual - 1;
    //   // this.cargarActivosFijos();
    // }
  }

  cambioPestana(e) {
    if (e.atributo === 'abonaA') {
      this.advanceOptions.currentPageAbona = e.paginaActual - 1;
      this.advanceOptions.filters = e.filtros;
      this.cargarDocumentos(e.paginaActual - 1, e.fila, e.atributo);
    } else if (e.atributo === 'cheque') {
      this.advanceOptions.currentPageCheque = e.paginaActual - 1;
      this.cargarCheques();
    }
    // else if (e.atributo === 'activoFijo') {
    //   this.advanceOptions.currentPageAF = e.paginaActual - 1;
    //   this.cargarActivosFijos();
    // }
  }

  // cargarActivosFijos() {

  //   const paginacion = new PaginacionWo('id', this.advanceOptions.currentPageAF, 10, 'ASC');
  //   if (this.advanceOptions.filters) {
  //     paginacion.filtros = this.advanceOptions.filters;
  //   }

  //   this.serviceContabilizacion.getActivosFijosSaldo(paginacion).subscribe(
  //     response => {
  //       this.activosFijos = response;

  //       if (this.activosFijos) {
  //         const indesAF = this.columnas.findIndex(col => col.atributo === 'activoFijo');
  //         this.columnas[indesAF].lista = this.activosFijos;
  //         this.columnas[indesAF].totalPaginas = 10;
  //         this.columnas = this.columnas.map(x => Object.assign({}, x));
  //       }

  //     },
  //     error => {
  //       this.errorHandlerComponent.handler(error);
  //     }
  //   );

  // }

  cargarDocumentos(pagina?, fila?, atributo?) {
    // this.documentosAbona = []
    // const indexDocs = this.columnas.findIndex(col => col.atributo === this.advanceOptions.currentAttribute);
    // this.columnas[indexDocs].lista = []
    const paginacion = new PaginacionWo('id', this.advanceOptions.currentPageAbona, 10, 'ASC');
    if (this.advanceOptions.filters) {
      paginacion.filtros = this.advanceOptions.filters;
    }
    this.serviceContabilizacion.getListaDocsAbona(paginacion, this.advanceOptions.thirdParty, this.advanceOptions.accountingAccount, this.contabilizaciones[this.filaActual].id).subscribe(
      response => {
        if (response.codigoError) {
          this.verPopup(response.codigoError, response.severidad, false);
        }
        this.documentosAbona = response.valor;

        this.documentosAbona.length > 0 ? null : this.popUpService.open({ codigoError: 'noHayRegistros', severidad: SeveridadEnum.INFO, conBoton: false });

        if (this.documentosAbona) {
          // const indexDocs = this.columnas.findIndex(col => col.atributo === this.advanceOptions.currentAttribute);
          // if (pagina) {
          //   if (pagina == -1) {
          //     this.columnas[indexDocs].lista = this.documentosAbona.slice(0, this.columnas[indexDocs].registrosPagina);
          //   } else {
          //     this.columnas[indexDocs].lista = this.documentosAbona.slice(pagina * this.columnas[indexDocs].registrosPagina, (pagina * this.columnas[indexDocs].registrosPagina) + this.columnas[indexDocs].registrosPagina);
          //   }
          // } else {
          //   this.columnas[indexDocs].lista = this.documentosAbona.slice(0, this.columnas[indexDocs].registrosPagina);
          // }
          // this.columnas[indexDocs].totalPaginas = parseInt((this.documentosAbona.length / this.columnas[indexDocs].registrosPagina) + "", 10);
          // abonaA
          this.contabilizaciones[fila][atributo] ? this.contabilizaciones[fila][atributo].lista = this.documentosAbona : this.contabilizaciones[fila][atributo] = { lista: this.documentosAbona };
          this.contabilizaciones = this.contabilizaciones.map((x) => Object.assign({}, x));
        }
      },
      error => {
        this.errorHandlerComponent.handler(error);
      }
    );
  }

  cargarCheques() {

    const paginacion = new PaginacionWo('id', this.advanceOptions.currentPageCheque, 10, 'ASC');

    this.serviceContabilizacion.getListaCheques(paginacion, this.advanceOptions.thirdParty).subscribe(
      response => {
        this.cheques = response;

        if (this.cheques) {
          const indexDocs = this.columnas.findIndex(col => col.atributo === 'cheque');
          this.columnas[indexDocs].lista = this.cheques;
          this.columnas[indexDocs].totalPaginas = 10;
          this.columnas = this.columnas.map(x => Object.assign({}, x));
        }

      },
      error => {
        this.errorHandlerComponent.handler(error);
      }
    );
  }

  comprobarBorrador(e) {
    let borrador = e.esBorrador;
    this.idBorrador = e.idBorrador;
    if (!borrador && this.idBorrador != null) {
      let mensaje = {
        clase: 'DocumentoMovimientoContable',
        id: this.idBorrador,
        accion: 'editarDocumentoMovimientoContableContabilizacionAvanzada',
        atributo: 'borrador',
        valor: borrador,
        prioridad: true,
        tipoDato: TipoDato[TipoDato.BOOLEAN]
      };
      this.enviar(mensaje, e, null);
    }
    if (this.idBorrador != null && this.idBorrador != -1) {
      this.borrador = borrador;
      if (!this.borrador) {
        this.mostrarErrores = false;
      }
    }
  }

  comandosMap: Map<string, any> = new Map(
    [
      ['clasificacionImpuesto', { accion: 'asociarCuentaContableDocMovContContabilizacionAvanzada', tipoDato: TipoDato[TipoDato.STRING] }],
      ['cuentaContable', { accion: 'asociarCuentaContableDocMovContContabilizacionAvanzada', tipoDato: TipoDato[TipoDato.STRING] }],
      ['concepto', { accion: 'editarDocumentoMovimientoContableContabilizacionAvanzada', tipoDato: TipoDato[TipoDato.STRING] }],
      ['terceroExterno', { accion: 'asociarTerceroDocumentoMovimientoContableContAvanzada', tipoDato: TipoDato[TipoDato.STRING] }],
      ['valorDebito', { accion: 'editarDocumentoMovimientoContableContabilizacionAvanzada', tipoDato: TipoDato[TipoDato.NUMERIC] }],
      ['valorCredito', { accion: 'editarDocumentoMovimientoContableContabilizacionAvanzada', tipoDato: TipoDato[TipoDato.NUMERIC] }],
      ['cheque', { accion: 'asociarDocumentoDePagoDocumentoMovimientoContable', tipoDato: TipoDato[TipoDato.STRING] }],
      ['abonaA', { accion: 'asociarDocumentoCompromisoDocumentoMovimientoContable', tipoDato: TipoDato[TipoDato.STRING] }],
      ['centroCosto', { accion: 'asociarCentroCostoDocumentoMovimientoContableContAvanzada', tipoDato: TipoDato[TipoDato.STRING] }],
      ['fechaVencimiento', { accion: 'fechaPasadaDocumento', tipoDato: TipoDato[TipoDato.FECHA] }],
      ['abonaADocumento', { accion: '', tipoDato: TipoDato[TipoDato.STRING] }],

      ['abonaCheque', { accion: '', tipoDato: TipoDato[TipoDato.STRING] }],
      ['activoFijo', { accion: 'asociarActivoFijoDocumentoMovimientoContable', tipoDato: TipoDato[TipoDato.STRING] }],
      ['clasificacionRenglon', { accion: '', tipoDato: TipoDato[TipoDato.STRING] }],
      ['retencionPorcentaje', { accion: 'editarDocumentoMovimientoContableContabilizacionAvanzada', tipoDato: TipoDato[TipoDato.NUMERIC] }],
      ['retencionBase', { accion: 'editarDocumentoMovimientoContableContabilizacionAvanzada', tipoDato: TipoDato[TipoDato.NUMERIC] }],
      ['imptoBancoExcento', { accion: '-', tipoDato: TipoDato[TipoDato.STRING] }],
      ['valorExtranjero', { accion: '', tipoDato: TipoDato[TipoDato.STRING] }],
      ['trmFact', { accion: '', tipoDato: TipoDato[TipoDato.STRING] }],
      ['moneda', { accion: '', tipoDato: TipoDato[TipoDato.STRING] }],
      ['ivaCosto', { accion: '-', tipoDato: TipoDato[TipoDato.STRING] }],

      ['abonaDiferido', { accion: 'crearDocumentoCompromiso', tipoDato: TipoDato[TipoDato.STRING] }],
      ['sucursal', { accion: 'asociarDocMovContableTerceroDireccion', tipoDato: TipoDato[TipoDato.STRING] }],
      ['pagoRetencion', { accion: '-', tipoDato: TipoDato[TipoDato.STRING] }],
      ['tablaReteFuenta', { accion: '-', tipoDato: TipoDato[TipoDato.STRING] }],
      ['importacion', { accion: '-', tipoDato: TipoDato[TipoDato.STRING] }],
      ['cajaMenor', { accion: '-', tipoDato: TipoDato[TipoDato.STRING] }],
      ['excluirNIIF', { accion: '-', tipoDato: TipoDato[TipoDato.STRING] }],
      ['idClasificacionRereFuente', { accion: '-', tipoDato: TipoDato[TipoDato.STRING] }],
      ['impoConsumoCosto', { accion: '-', tipoDato: TipoDato[TipoDato.STRING] }],
      ['terceroLibranza', { accion: '-', tipoDato: TipoDato[TipoDato.STRING] }],

      ['senDifCambio', { accion: '-', tipoDato: TipoDato[TipoDato.STRING] }],
      ['noDeducible', { accion: '-', tipoDato: TipoDato[TipoDato.STRING] }],
      ['clasificacionIva', { accion: '?', tipoDato: TipoDato[TipoDato.STRING] }],
      ['asociarImpuestosClasificacionDocMovContable', { accion: 'asociarImpuestosClasificacionDocMovContable', tipoDato: TipoDato[TipoDato.NUMERIC] }],
    ]
  );

  async initColumns() {
    this.columnas = [
      {
        header: this.translateUtilService.getTranslateText(
          'contabilizacion.avanzada.columnas.cuenta'
        ),
        atributo: 'cuentaContable',
        tipoDato: TipoDato.STRING,
        tipoInput: TipoInput.SELECT,
        tipoFiltro: [TipoFiltro.EMPIEZA_CON, TipoFiltro.CONTIENE],
        columnas: ['codigo', 'nombre'],
        entidad: 'CuentaContable',
        filtros: this.filtrosCuentas,
        pag: true,
        registrosPorPagina: 5,
        soloLectura: false,
        hidden: false,
        editable: true
      },
      {
        header: this.translateUtilService.getTranslateText(
          'contabilizacion.avanzada.columnas.nota'
        ),
        atributo: 'concepto',
        maxLen: 500,
        tipoDato: TipoDato.STRING,
        tipoInput: TipoInput.TEXTAREA,
        soloLectura: false,
        hidden: false,
        editable: true,
      },
      {
        header: this.translateUtilService.getTranslateText(
          'contabilizacion.avanzada.columnas.tercero'
        ),
        atributo: 'terceroExterno',
        tipoDato: TipoDato.STRING,
        tipoInput: TipoInput.SELECT,
        columnas: ['nombreCompleto'],
        entidad: 'Tercero',
        pag: true,
        registrosPorPagina: 5,
        filtros: this.filtrosTercero,
        soloLectura: false,
        hidden: false,
        editable: true,
      },
      {
        header: this.translateUtilService.getTranslateText(
          'contabilizacion.avanzada.columnas.debito'
        ),
        atributo: 'valorDebito',
        tipoDato: TipoDato.NUMERIC,
        tipoInput: TipoInput.NUMBER,
        soloLectura: false,
        hidden: false,
        editable: true,
        tipoNumeric: 'moneda',
        moneda: this.monedaFormateoDecimales,
        estilo: "colWidth130"
      },
      {
        header: this.translateUtilService.getTranslateText(
          'contabilizacion.avanzada.columnas.credito'
        ),
        atributo: 'valorCredito',
        tipoDato: TipoDato.NUMERIC,
        tipoInput: TipoInput.NUMBER,
        soloLectura: false,
        hidden: false,
        editable: true,
        tipoNumeric: 'moneda',
        moneda: this.monedaFormateoDecimales,
        estilo: "colWidth130"
      },
      {
        header: this.translateUtilService.getTranslateText(
          'contabilizacion.avanzada.columnas.centroCostos'
        ),
        atributo: 'centroCosto',
        tipoDato: TipoDato.STRING,
        tipoInput: TipoInput.ACORDEON,
        pag: true,
        columnas: ['codigo', 'nombre'],
        arbol: this.centroCostos,
        propiedad: 'codigo',
        registrosPorPagina: 5,
        soloLectura: false,
        hidden: false,
        editable: true
      },
      {
        header: this.translateUtilService.getTranslateText('configuracionImpuestos.clasificacion.columnas.impuestosClasificacion'),
        atributo: 'clasificacionImpuesto',
        tipoDato: TipoDato.STRING,
        tipoInput: TipoInput.SELECT,
        columnas: ['nombre'],
        entidad: 'ImpuestosClasificacion',
        requerido: false,
        soloLectura: false,
        hidden: false,
        editable: true,
        registrosPorPagina: 5
      },
      {
        header: this.translateUtilService.getTranslateText(
          'contabilizacion.avanzada.columnas.fechaVencimiento'
        ),
        atributo: 'fechaVencimiento',
        tipoDato: TipoDato.FECHA,
        tipoInput: TipoInput.DATE,
        estilo: 'colWidthFecha',
        soloLectura: false,
        hidden: false,
        editable: true,
      },
      {
        header: this.translateUtilService.getTranslateText(
          'contabilizacion.avanzada.columnas.abona'
        ),
        atributo: 'abonaA',
        tipoDato: TipoDato.STRING,
        tipoInput: TipoInput.SELECT,
        pag: true,
        columnas: ['documentoTipo', 'prefijo', 'numDocumento', 'saldo', 'cuentaContable', 'concepto'],
        columnasValorInput: ['documentoTipo', 'prefijo', 'numDocumento'],
        columnasMoneda: ['saldo'],
        columnasFecha: ['fechaVencimiento'],
        registrosPorPagina: 5,
        filtros: this.filtrosDocumentoAsociado,
        soloLectura: false,
        hidden: false,
        editable: true,
        requerido: false,
        ordenar: false
      },
      {
        header: this.translateUtilService.getTranslateText(
          'contabilizacion.avanzada.columnas.activoFijo'
        ),
        atributo: 'activoFijo',
        tipoDato: TipoDato.STRING,
        tipoInput: TipoInput.SELECT,
        // pag: true,
        // columnasValorInput: ['inventario.codigo','inventario.descripcion'],
        columnas: ['inventario.codigo', 'inventario.descripcion'],
        // lista: this.activosFijos,
        entidad: 'InventarioActivoFijo',
        registrosPorPagina: 5,
        filtros: this.filtroInventario,
        soloLectura: false,
        hidden: false,
        editable: true,
      },
      {
        header: this.translateUtilService.getTranslateText(
          'contabilizacion.avanzada.columnas.porcentajeRetencion'
        ),
        atributo: 'retencionPorcentaje',
        tipoDato: TipoDato.NUMERIC,
        tipoInput: TipoInput.NUMBER,
        tipoNumeric: 'porcentaje',
        decimales: 3,
        soloLectura: false,
        hidden: false,
        editable: true,
      },
      {
        header: this.translateUtilService.getTranslateText(
          'contabilizacion.avanzada.columnas.baseRetencion'
        ),
        atributo: 'retencionBase',
        tipoDato: TipoDato.NUMERIC,
        tipoInput: TipoInput.NUMBER,
        tipoNumeric: 'moneda',
        moneda: this.monedaLocal,
        estilo: "colWidth100",
        soloLectura: false,
        hidden: false,
        editable: true,
      },
      {
        header: this.translateUtilService.getTranslateText(
          'contabilizacion.avanzada.columnas.moneda'
        ),
        atributo: 'moneda',
        tipoDato: TipoDato.STRING,
        tipoInput: TipoInput.TEXT,
        soloLectura: true,
        hidden: false,
        editable: false,
      },
      {
        header: this.translateUtilService.getTranslateText(
          'contabilizacion.avanzada.columnas.facTRM'
        ),
        atributo: 'trmFact',
        tipoDato: TipoDato.NUMERIC,
        tipoInput: TipoInput.NUMBER,
        estilo: "colWidth100",
        soloLectura: true,
        hidden: false,
        editable: false,
        noFormatearBlur: true
      },
      {
        header: this.translateUtilService.getTranslateText(
          'contabilizacion.avanzada.columnas.valorExtranjero'
        ),
        atributo: 'valorExtranjero',
        tipoDato: TipoDato.NUMERIC,
        tipoInput: TipoInput.NUMBER,
        soloLectura: true,
        hidden: false,
        editable: false,
        tipoNumeric: 'moneda',
        moneda: this.monedaExtranjeraDecimales
      },
      {
        header: this.translateUtilService.getTranslateText(
          'contabilizacion.avanzada.columnas.abonaDiferido'
        ),
        atributo: 'abonaDiferido',
        tipoDato: TipoDato.STRING,
        tipoInput: TipoInput.SELECT,
        // pag: true,
        // columnas: ['documento', 'diferido', 'numeroCuenta', 'valorInicial', 'saldo'],
        columnas: ['documento', 'diferido', 'numeroCuenta'],
        columnasValorInput: ['documento', 'diferido', 'numeroCuenta'],
        // columnasMoneda: ['valorInicial', 'saldo'],
        lista: this.documentosAbona,
        registrosPorPagina: 5,
        filtros: this.filtrosDocumentoAsociado,
        soloLectura: false,
        hidden: false,
        editable: true,
      },
      {
        header: this.translateUtilService.getTranslateText(
          'contabilizacion.avanzada.columnas.sucursal'
        ),
        atributo: 'sucursal',
        ordenar: false,
        tipoDato: TipoDato.STRING,
        tipoInput: TipoInput.SELECT,
        pag: true,
        columnas: ['nombre'],
        entidad: 'TerceroDireccion',
        filtros: this.filtrosSucursal,
        registrosPorPagina: 5,
        soloLectura: false,
        requerido: false,
        hidden: false,
        editable: true,
      }
    ];
  }

  abrirTotales() {
    this.openTotal = !this.openTotal;
  }

}
