import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlService } from '@wo/frontend/environmentDynamicService';
import { CupoCredito, DocumentoAsociadoPojo, DocumentoEncabezado, DocumentoEncabezadoFecha, DocumentoTipoTercero, ImpuestoDocumento, PaginacionResponse, PaginacionWo, Reporte, TallaColorSerialLoteError, TerceroDireccion } from '@wo/modelo';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Services } from '../services.service';

@Injectable({ providedIn: 'root' })
export class DocumentoEncabezadoService extends Services {

  headers = this.GetHttpHeaders();

  constructor(
    private http: HttpClient,
    private url: urlService
  ) {
    super();
    this.url.validarUrl();
  }

  get(paginacion: PaginacionWo): Observable<DocumentoEncabezado> {
    return this.http.post<DocumentoEncabezado>(
      this.url.getUrl() + 'documentoencabezado/filtrarPaginado',
      paginacion,
      { withCredentials: true }
    );
  }



  buscarTerceroLiquidadoFondos(fondos, paginacion) {
    return this.http.post<DocumentoEncabezado>(
      this.url.getUrl() + 'liquidacion/buscarTerceroLiquidado/fondos/' + fondos,
      paginacion,
      {
        withCredentials: true
      }
    );
  }

  getSenTipoLiquidacionesSalarioPromedio(data) {
    return this.http.post<DocumentoEncabezado>(
      this.url.getUrl() + 'liquidacion/getSenTipoLiquidacionesSalarioPromedio',
      data,
      {
        withCredentials: true
      }
    );
  }

  getSenTipoAuditoriaRTFLiquidacionContrato(data) {
    return this.http.post<any>(
      this.url.getUrl() + 'liquidacion/getSenTipoAuditoriaRTFLiquidacionContrato',
      data,
      {
        withCredentials: true
      }
    );
  }

  getAreasEmpleados(idDocumento, paginacion) {
    return this.http.post<DocumentoEncabezado>(
      this.url.getUrl() + 'liquidacion/buscarTerceroLiquidado/areas/' + idDocumento,
      paginacion,
      {
        withCredentials: true
      }
    );
  }

  verCalculoAportesSociales(idRenglon) {
    return this.http.get<DocumentoEncabezado>(
      this.url.getUrl() + 'liquidacion/verCalculoAportesSociales/' + idRenglon,
      {
        withCredentials: true
      }
    );
  }

  buscarTerceroLiquidado(idDocumento) {
    return this.http.get<DocumentoEncabezado>(
      this.url.getUrl() + 'liquidacion/buscarTerceroLiquidado/validarSinFondo/' + idDocumento,
      {
        withCredentials: true
      }
    );
  }

  liquidadosSinFondo(idDocumento: number, fechaLiquiacion?: string,) {
    let url = this.url.getUrl() + 'liquidacion/buscarTerceroLiquidado/liquidadosSinFondo/' + idDocumento;
    if (fechaLiquiacion) {
      url += '/' + fechaLiquiacion;
    }
    return this.http.get<DocumentoEncabezado>(url, { withCredentials: true });
  }

  getAreasGenerarLiqEmpleados(idDocumento) {
    return this.http.get<DocumentoEncabezado>(
      this.url.getUrl() + 'liquidacion/buscarTerceroLiquidacion/areas/' + idDocumento,
      {
        withCredentials: true
      }
    );
  }

  geCentroCostosEmpleados(idDocumento, paginacion: PaginacionWo) {
    return this.http.post<DocumentoEncabezado>(
      this.url.getUrl() + 'liquidacion/buscarTerceroLiquidado/centroCostos/' + idDocumento,
      paginacion,
      {
        withCredentials: true
      }
    );
  }

  getEmpleadosLiquidados(idDocumento, paginacion: PaginacionWo) {
    return this.http.post<DocumentoEncabezado>(
      this.url.getUrl() + 'liquidacion/buscarTerceroLiquidado/liquidados/' + idDocumento,
      paginacion,
      {
        withCredentials: true
      }
    );
  }

  getCentroCostosEmpleados(idDocumento) {
    return this.http.get<DocumentoEncabezado>(
      this.url.getUrl() + 'liquidacion/buscarTerceroLiquidacion/centroCostos/' + idDocumento,
      {
        withCredentials: true
      }
    );
  }

  getNomina(paginacion: PaginacionWo): Observable<DocumentoEncabezado> {
    return this.http.post<DocumentoEncabezado>(
      this.url.getUrl() + 'nomina/filtrarPaginado',
      paginacion,
      { withCredentials: true }
    );
  }
  getliquidacionPila(paginacion: PaginacionWo): Observable<DocumentoEncabezado> {
    return this.http.post<DocumentoEncabezado>(
      this.url.getUrl() + 'liquidacionpila/filtrarPaginado',
      paginacion,
      { withCredentials: true }
    );
  }

  buscarTerceroLiquidacion(idDocumento, data): Observable<DocumentoEncabezado> {
    return this.http.post<DocumentoEncabezado>(
      this.url.getUrl() + 'liquidacion/buscarTerceroLiquidacion/' + idDocumento,
      data,
      { withCredentials: true }
    );
  }

  getTerceroContratosActivos(paginacion): Observable<DocumentoEncabezado> {
    return this.http.post<DocumentoEncabezado>(
      this.url.getUrl() + 'terceroPersonalContrato/getTercerosConContratoActivo',
      paginacion,
      { withCredentials: true }
    );
  }

  getTerceroLibroVacaciones(idEmpresa, estadoContrato, fechaCorte, data): Observable<DocumentoEncabezado> {
    return this.http.post<DocumentoEncabezado>(
      this.url.getUrl() + 'liquidacion/getTercerosLibroVacaciones/' + idEmpresa + '/' + estadoContrato + '/' + fechaCorte,
      data,
      { withCredentials: true }
    );
  }

  getAreaLibroVacaciones(idEmpresa, estadoContrato, fechaCorte, data): Observable<DocumentoEncabezado> {
    return this.http.post<DocumentoEncabezado>(
      this.url.getUrl() + 'liquidacion/getAreasLibroVacaciones/' + idEmpresa + '/' + estadoContrato + '/' + fechaCorte,
      data,
      { withCredentials: true }
    );
  }

  getCentrosLibroVacaciones(idEmpresa, estadoContrato, fechaCorte, data): Observable<DocumentoEncabezado> {
    return this.http.post<DocumentoEncabezado>(
      this.url.getUrl() + 'liquidacion/getCentrosLibroVacaciones/' + idEmpresa + '/' + estadoContrato + '/' + fechaCorte,
      data,
      { withCredentials: true }
    );
  }

  getNominaPrestaciones(paginacion: PaginacionWo): Observable<DocumentoEncabezado> {
    return this.http.post<DocumentoEncabezado>(
      this.url.getUrl() + 'liquidacion/filtrarPaginado',
      paginacion,
      { withCredentials: true }
    );
  }

  getPrefijos(codigo) {
    const url = this.url.getUrl() + 'prefijo/' + codigo;
    const headers = this.headers.set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.get(url, { withCredentials: true, headers });
  }

  getEncabezado(id): Observable<DocumentoEncabezado> {
    return this.http.post<DocumentoEncabezado>(
      this.url.getUrl() + 'documentoencabezado/consultar/' + id,
      null,
      {
        withCredentials: true
      }
    );
  }

  getEncabezadoNomina(id): Observable<DocumentoEncabezado> {
    return this.http.get<DocumentoEncabezado>(
      this.url.getUrl() + 'nomina/getDocumentoEncabezado/' + id,
      {
        withCredentials: true
      }
    );
  }

  getAreas(paginacion: PaginacionWo): Observable<DocumentoEncabezado> {
    return this.http.post<DocumentoEncabezado>(
      this.url.getUrl() + 'terceroPersonalContratoArea/listarPaginado',
      paginacion,
      {
        withCredentials: true
      }
    );
  }


  getLiquidacionEncabezadoNomina(id): Observable<DocumentoEncabezado> {
    return this.http.get<DocumentoEncabezado>(
      this.url.getUrl() + 'liquidacion/getDocumentoEncabezado/' + id,
      {
        withCredentials: true
      }
    );
  }

  public getAuditoria(id): Observable<any> {
    let idDocumento;
    idDocumento = parseInt(id)
    return this.http.post<DocumentoEncabezado>(
      this.url.getUrl() + 'documentoencabezado/sePuedeCerrar', idDocumento,

      {
        withCredentials: true
      }
    );
  }
  getEstados(id): Observable<DocumentoEncabezado> {
    return this.http.get<DocumentoEncabezado>(
      this.url.getUrl() + 'documentoencabezado/consultarEstado/' + id,
      {
        withCredentials: true
      }
    );
  }

  cupoCreditoDisponible(id): Observable<CupoCredito> {
    return this.http.get<CupoCredito>(
      this.url.getUrl() + 'tercero/cupoCredito/' + id,
      {
        withCredentials: true
      }
    );
  }

  getDireccionesCliente(
    id,
    paginacion: PaginacionWo
  ): Observable<TerceroDireccion> {
    return this.http.post<TerceroDireccion>(
      this.url.getUrl() + 'tercero/direccion/listar/' + id,
      paginacion,
      {
        withCredentials: true
      }
    );
  }

  getDocumentosAsociados(
    id,
    paginacion: PaginacionWo
  ): Observable<DocumentoAsociadoPojo> {
    return this.http.post<DocumentoAsociadoPojo>(
      this.url.getUrl() + 'documentoencabezado/listarDocumentosAsociados/' + id,
      paginacion,
      {
        withCredentials: true
      }
    );
  }

  getDocumentosNoAnulados(
    atributos,
    paginacion: PaginacionWo
  ): Observable<DocumentoAsociadoPojo> {
    return this.http.post<DocumentoAsociadoPojo>(
      this.url.getUrl() + 'documentoencabezado/listarDocumentosNoAnulados/' + atributos,
      paginacion,
      {
        withCredentials: true
      }
    );
  }

  getFechas(id): Observable<DocumentoEncabezadoFecha[]> {
    return this.http.get<DocumentoEncabezadoFecha[]>(
      this.url.getUrl() +
      'documentoencabezado/getDocumentoEncabezadoFechas/' +
      id,
      {
        withCredentials: true
      }
    );
  }

  /**
   * Retorna los terceros asociados adicionales que maneja el documento según su tipo
   * @param documentoTipo
   */
  getDocumentoTipoTerceros(documentoTipo): Observable<DocumentoTipoTercero[]> {
    return this.http.get<DocumentoTipoTercero[]>(
      this.url.getUrl() +
      'documentoencabezado/getDocumentoTipoTerceros/' +
      documentoTipo,
      {
        withCredentials: true
      }
    );
  }

  /**
   * Retorna los valores de los terceros asociados adicionales que maneja el documento según su tipo
   * @param documentoTipo
   */
  getValoresTipoTerceros(idEncabezado: number): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() +
      'documentoencabezado/getDocumentoEncabezadoTerceros/' +
      idEncabezado,
      {
        withCredentials: true
      }
    );
  }

  getResumenImpuestos(
    idDocumento: number,
    paginacion: PaginacionWo
  ): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'documentoencabezado/resumenImpuestos/' + idDocumento,
      paginacion,
      {
        withCredentials: true
      }
    );
  }

  getImpuestos(idDocumento: number) {
    return this.http.get<ImpuestoDocumento[]>(
      `${this.url.getUrl()}documentoencabezado/impuestos/${idDocumento}`,
      {
        withCredentials: true
      }
    );
  }

  getResumenImpuesto(
    idDocumento: number,
    idImpuesto: number,
    paginacion: PaginacionWo
  ) {
    return this.http.post<PaginacionResponse>(
      `${this.url.getUrl()
      }documentoencabezado/resumenImpuestos/${idDocumento}/${idImpuesto}`,
      paginacion,
      {
        withCredentials: true
      }
    );
  }

  /**
   * Previamente estaba en inventario.service pero tiene mas sentido que este aca
   * @param tipo el tipo de documento "FV", etc...
   */
  mostrarTecero(tipo: string): Observable<boolean> {
    return this.http.get<boolean>(
      this.url.getUrl() + 'documentoencabezado/mostrarTercero/' + tipo,
      { withCredentials: true, headers: this.headers }
    );
  }

  getInventariosSI(id): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'si/hayRenglonesSI/' + id,
      { withCredentials: true }
    );
  }

  getDiferidoTercero(): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'usuario/getTerceroUsuarioAutenticado',
      { withCredentials: true }
    );
  }

  getDataGenerarNomina(): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'nomina/getTipoPeriodoPago/',
      { withCredentials: true }
    );
  }

  getDiferidoTerceroEmpresa(id): Observable<any> {
    return this.http.get<any>(
      this.url.getUrl() + 'empresa/getTerceroEmpresa/' + id,
      { withCredentials: true }
    );
  }

  validarDocumentoCancelacionBloqueado(filtro): Observable<any> {
    return this.http.post<any>(
      this.url.getUrl() + 'documentoencabezado/validarDocumentoCancelacionBloqueado', filtro,
      { withCredentials: true }
    );
  }

  getDocumentoCamposPersonalizados(paginacion: PaginacionWo): Observable<any> {
    const url = this.url.getUrl() + 'atributopersonalizadovalor/listar';
    return this.http.post<Array<any>>(url, paginacion, { withCredentials: true, headers: this.headers });
  }

  validacionPersonalizadoCompleto(paginacion: PaginacionWo): Observable<boolean> {
    const url = this.url.getUrl() + 'atributopersonalizadovalor/validar';
    return this.http.post<boolean>(url, paginacion, { withCredentials: true, headers: this.headers });
  }

  /**
* Service para obtener el recibo de caja en base 64
* @param idDoc
* @param plantilla
*/
  getDocumentoImpreso(idDoc, plantilla): Observable<any> {
    const url = `${this.url.getUrlReportes()}reporte/getDocumentoImpresoPos/${idDoc}/${plantilla}`;
    return this.http.get(url, { withCredentials: true, headers: this.headers })
  }


  /**
   * Servicio para obteber prefijos
   * @param paginacion
   */
  getTelefonosTercero(paginacion: PaginacionWo, idTercero: any): Observable<any> {
    const url = `${this.url.getUrl()}tercero/getAllMoviles/${idTercero}`;
    return this.http.post(url, paginacion, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }

  /**
   * Servicio para obteber prefijos
   * @param paginacion
   */
  getCorreosTercero(paginacion: PaginacionWo, idTercero: any): Observable<any> {
    const url = `${this.url.getUrl()}tercero/getAllEmails/${idTercero}`;
    return this.http.post(url, paginacion, { withCredentials: true, headers: this.headers }).pipe(
      tap(response => {
      })
    );
  }

  getReporte(mensaje): Observable<Reporte> {
    const headers = this.headers.set('Content-Type', 'text/plain');
    const url = this.url.getUrlReportes() + 'reporte/mensaje';
    return this.http
      .post<Reporte>(url, mensaje, {
        withCredentials: true,
        headers,
        observe: 'response'
      })
      .pipe(map(res => res.body));
  }
  /*VERIFICAR EMAIL*/
  getValidacionEmail(mensaje, idNomina) {
    const headers = this.headers.set('Content-Type', 'application/json');
    const url = `${this.url.getUrl()}nomina/validarEmailEmpleados/${idNomina}`;
    return this.http
      .post<any>(url, mensaje, {
        withCredentials: true,
        headers,
        observe: 'response'
      })
      .pipe(map(res => res.body));
  }

  /*VERIFICAR CANTIDAD DE ENVIOS DESPRENDIBLES*/
  validarCantidadEnvioDesprendible(mensaje, idDocumento) {
    const headers = this.headers.set('Content-Type', 'application/json');
    const url = `${this.url.getUrl()}nomina/validarCantidadEnvioDesprendible/${idDocumento}`;
    return this.http
      .post<any>(url, mensaje, {
        withCredentials: true,
        headers,
        observe: 'response'
      })
      .pipe(map(res => res.body));
  }

  getSePuedeInprimir(idDocumento: any) {
    const url = `${this.url.getUrl()}documentoencabezado/sePuedeImpirmir/${idDocumento}`;
    return this.http.get(url, { withCredentials: true, headers: this.headers });
  }

  getSeValidarTallaSerialLote(idDocumento: any): Observable<TallaColorSerialLoteError>{
    const url = `${this.url.getUrl()}documentoencabezado/validarTallaSerialLote/${idDocumento}`;
    return this.http.get<TallaColorSerialLoteError>(url, { withCredentials: true, headers: this.headers });
  }


}
