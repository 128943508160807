<div class="datePickerCont">
    <ng-container *ngIf="dateType == 'full'">
        <ng-container *ngIf="noPermiteEscritura || isDisabled || !editable">
            <input #inputDate id="{{identificacion}}" [ngStyle]="{'cursor': !isDisabled ? 'pointer':'not-allowed'}"
                attr.data-valor="valorFechaActualInput" class="datePickerMat noPermiteEscritura"
                placeholder="dd/mm/aaaa"
                [ngClass]="{'faltante': faltante, 'hideFormat': hideFormat, 'fechaTabla': intoWoTabla, 'intoTable': intoWoTabla }"
                matInput [matDatepicker]="pickerNoEscritura" name="fecha" [disabled]="isDisabled"
                [(ngModel)]="valorFechaMaterial" [max]="maxDate" [min]="minDate" [value]="valorFechaActualInput"
                (dateChange)="eventoCambioFecha($event)" tabindex="-1" (click)="abrir(pickerNoEscritura)" readonly
                oncontextmenu="return false;">
            <mat-datepicker #pickerNoEscritura [startAt]="startAt">
            </mat-datepicker>
            <div class="estilosDiv-click flex">
                <img class="iconDate" *ngIf="!isDisabled && editable"
                    src="../../../assets/images/iconsForms/icoDate.png" alt="" (click)="abrir(pickerNoEscritura)">
            </div>
        </ng-container>
        <ng-container *ngIf="!noPermiteEscritura && !isDisabled && editable">
            <input [ngClass]="{'fechaTablaEscritura':  intoWoTabla }" class="datePickerMat" matInput
                [matDatepicker]="picker" name="fecha" [(ngModel)]="valorFechaMaterial" max="9999-12-31" min="1990-12-31"
                (dateChange)="eventoCambioFecha($event)" tabindex="-1" (blur)="onBlur(inputDate.value,inputDate)"
                (click)="abrir(picker)" readonly>
            <input #inputDate id="{{identificacion}}" attr.data-valor="valorFechaActualInput" name="fecha" type="date"
                [value]="valorFechaActualInput" (blur)="onBlur(inputDate.value,inputDate)" max="9999-12-31"
                min="1990-12-31"
                [ngClass]="{'faltante': faltante, 'hideFormat': hideFormat, 'fechaTablaEscritura':  intoWoTabla}"
                [disabled]="isDisabled">
            <mat-datepicker #picker [startAt]="startAt">
            </mat-datepicker>
            <div class="estilosDiv-click flex">
                <img class="iconDate" *ngIf="!isDisabled && editable"
                    src="../../../assets/images/iconsForms/icoDate.png" alt="" (click)="abrir(picker)">
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="dateType == 'month'">
        <input [ngClass]="{'fechaTabla':  intoWoTabla, 'faltante': faltante, 'intoTable intoTableMes': intoWoTabla }"
            class="mes noPermiteEscritura" id="{{identificacion}}" matInput placeholder="mes/yyyy"
            [matDatepicker]="pickerNoEscritura" name="fecha" [(ngModel)]="valorFechaMaterial"
            [ngClass]="{'faltante': faltante, 'hideFormat': hideFormat, 'fechaTabla': intoWoTabla, 'intoTable': intoWoTabla }"
            (dateChange)="eventoCambioFecha($event)" [disabled]="isDisabled" (dateInput)="eventoCambioFecha($event)"
            style="text-transform: capitalize" [ngStyle]="{'cursor': !isDisabled ? 'pointer':'default'}"
            autocomplete="off" placeholder="mes/año" [min]="minDate" [max]="maxDate" (click)="abrir(pickerNoEscritura)"
            readonly oncontextmenu="return false;">
        <mat-datepicker #pickerNoEscritura startView="multi-year" panelClass="month-picker-wo"
            (monthSelected)="chosenMonthHandler($event, pickerNoEscritura)" [disabled]="isDisabled">
        </mat-datepicker>
        <div class="estilosDiv-click flex">
            <img class="iconDate" *ngIf="!isDisabled && editable" src="../../../assets/images/iconsForms/icoDate.png"
                alt="" (click)="abrir(pickerNoEscritura)">
        </div>
    </ng-container>

    <ng-container *ngIf="dateType == 'year'">
        <input [ngClass]="{'fechaTabla':  intoWoTabla, 'faltante': faltante, 'intoTable': intoWoTabla }"
            id="{{identificacion}}" class="noPermiteEscritura" placeholder="yyyy" matInput [matDatepicker]="picker"
            name="fecha" [(ngModel)]="valorFechaMaterial" (dateChange)="eventoCambioFecha($event)"
            (dateInput)="eventoCambioFecha($event)" [ngStyle]="{'cursor': !isDisabled ? 'pointer':'default'}"
            autocomplete="off" [min]="minDate" [max]="maxDate" (click)="abrir(picker)" readonly
            oncontextmenu="return false;">
        <mat-datepicker #picker startView="multi-year" panelClass="year-picker-wo"
            (yearSelected)="chosenYearHandler($event, picker)" [disabled]="isDisabled">
        </mat-datepicker>
        <div class="estilosDiv-click flex">
            <img class="iconDate" *ngIf="!isDisabled && editable" src="../../../assets/images/iconsForms/icoDate.png"
                alt="" (click)="abrir(picker)">
        </div>
    </ng-container>
</div>