import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ErrorHandlerComponent } from '@wo/frontend/error-handler/error-handler.component';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { ValorAnteriorService } from '@wo/frontend/servicios/valorAnterior/valor-anterior.service';
import { TranslateUtilService } from '@wo/frontend/utils/i18n/translate-util.service';
import { Columna, DocumentoTipoEnum, FiltroWo, OpcionTabla, Operador, PaginacionWo, SeveridadEnum, TipoDato, TipoFiltro, TipoInput } from '@wo/modelo';
import { ColaService, GeneralUtil, ScrollInfinitoService, SerialesService } from '@wo/servicio/index';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-pop-up-seriales',
  templateUrl: './pop-up-seriales.component.html',
  styleUrls: ['./pop-up-seriales.component.css']
})
export class PopUpSerialesComponent implements OnChanges {
  @Input() color;
  @Input() data;
  @Input() tipoDocumento;
  @Input() estaContabilizado;
  @Output() cerrarSeriales = new EventEmitter<boolean>();



  isDragging = false;
  edge: string;
  draggable = true;

  valorEncabezado: any;
  serialesSeleccionados: any [] = [];
  serialesPorIngresar:any;
  paginasTotales:any;
  public columnas: Columna[] = [
    {
      header: this.translateUtilService.getTranslateText('tallaColor.nombre'),
      atributo: 'codigo',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      maxLen: 30,
      soloLectura: false,
      hidden: false,
      editable: true,
      estilo: 'colWidth300'
    }
  ];
  opcionesTabla = {
    editable: true,
    paginado: true,
    menu: [OpcionTabla.ELIMINAR],
    crear: true
  };
  paginacion: any;
  permisos: any;
  mostrarErrores:any;
  dataTabla:any = [];
  filtrosSerialMultiselect: FiltroWo[] = [];
  filtroEncabezado: FiltroWo[] = [];
  subscriptions: Subscription = new Subscription();
  ultimoOrden = { columna: 'id', orden: 'ASC' };
  esperaCerrarPopUp: boolean;
  esperaEliminarSeriales: boolean;
  
  constructor(private translateUtilService: TranslateUtilService, 
    private serialesService: SerialesService, 
    private errorHandlerComponent: ErrorHandlerComponent,
    public popUpService: PopUpService,
    private scrollInfinitoService: ScrollInfinitoService,
    private _LoaderService: LoaderService,
    private _ValorAnteriorService: ValorAnteriorService,
    private serviceColas: ColaService) { }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes.data);
    
    if(changes.data){
      if (changes.data.currentValue) {
        if (!GeneralUtil.isNullOrUndefined(this.data) && !GeneralUtil.isEmptyObject(this.data)) {
          this.valorEncabezado = {
            id: this.data.renglonId,
            'inventario.codigo': this.data.codigo,
            'inventario.descripcion': this.data.descripcion
          };
          
          this.filtroEncabezado = [
            new FiltroWo('inventario.senManejaSeriales', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, [], 'inventario', Operador.AND),
            new FiltroWo('documentoEncabezado.id', '', null, TipoFiltro.IGUAL, TipoDato.IN, null, [this.data.documentoEncabezadoIdDB], 'documentoEncabezado', Operador.AND)
          ]

          this.getSeriales(0, 50, 'id', 'ASC');

        }
      }
    }

    if (changes.estaContabilizado) {
      if (changes.estaContabilizado.currentValue) {
        if (this.estaContabilizado) {
          this.opcionesTabla.menu = [];
          this.opcionesTabla.editable = false;
          this.opcionesTabla.crear = false;
        }
      }
    }

    if (!this.estaContabilizado) this.cargarMultiSelectSeriales()
    
  }

  getFiltroMovimiento(): FiltroWo {
    return new FiltroWo('documentoMovimientoInventario.id', '', null, TipoFiltro.IGUAL, TipoDato.IN, null, [this.data.renglonId], 'documentoMovimientoInventario', Operador.AND);
  }

  onDragBegin = () => this.isDragging = true;
  checkEdge = (event) => this.edge = event;
  startDraggable = () => this.draggable = true;
  stopDraggable = () => this.draggable = false;


  evento() {
    if (this.isDragging) this.isDragging = false;
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        console.log(respuesta);
        if (respuesta.tipo == 'respuestaPopup' && respuesta.event) {
          if (this.esperaCerrarPopUp)
            this.cerrarSeriales.emit(false);
          else if (this.esperaEliminarSeriales)
            this.eliminarSerialesBloque();
        }

        this.esperaCerrarPopUp = false;
        this.esperaEliminarSeriales = false;
      })
  }

  cerrarPopUp() {
    let cantidad = this.dataTabla.filter(f => !GeneralUtil.isNullOrUndefined(f.id)).length;
    if (cantidad === this.getCantidadPorDocumento()) {
      this.cerrarSeriales.emit(false);
    } else {
      // this.completo = true;
      this.mostrarErrores = true;
      this.esperarRespuesta();
      this.esperaCerrarPopUp = true;
      this.popUpService.open({ codigoError: 'serialesPendientes', severidad: SeveridadEnum.QUESTION, conBoton: true, detalles: [(this.getCantidadPorDocumento() - cantidad), this.getCantidadPorDocumento()] });
    }

  }

  setDefaulValues() {
    this.serialesPorIngresar = [];
    // this.descripcionInventario = '';
    // this.esperaCerrarPopUp = false;
    // this.esperaEliminarSeriales = false;
  }

  cambiaEncabezado(valor: any): void {
    console.log(valor);
    
  }

  cargarMultiSelectSeriales(page = 0) {
    let data = []
    for (let item of this.filtrosSerialMultiselect)
      data.push(item)

    const paginacion = new PaginacionWo('codigo', page, 10, 'ASC');
    paginacion.filtros = data
    this.serialesService.getSerialesCompradosYNoVendidos(this.data.renglonId, paginacion).subscribe(resp => {
      this.serialesPorIngresar = resp.content

      this.paginasTotales = {
        totalElements: resp.totalElements,
        totalPages: resp.totalPages
      }
    }, (error) => {
      this.errorHandlerComponent.handler(error);
    })
  }

  cambia(tipo) {
    let mensaje = {
      clase: 'DocumentoMovimientoSerial',
      id: this.data.id,
      accion: '',
      atributo: 'ventas',
      valor: tipo.codigo,
      prioridad: true,
      tipoDato: TipoDato[TipoDato.LONG],
      permisoGrueso: this.tipoDocumento
    };
    
    let index = this.serialesSeleccionados.findIndex(x => x.id == tipo.id);
    mensaje.accion = index != -1 ? 'desasociarDocMovSerialVenta' : 'asociarDocMovSerialVenta';
    mensaje.id = index != -1 ? this.serialesSeleccionados[index].id : this.data.renglonId;

    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable
        .pipe(take(1))
        .subscribe(
          response => {

            if (response.length > 0) {
              if (response[0].codigoError) {

                this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });

              } else {
                if (mensaje.accion == 'asociarDocMovSerialVenta') {
                  let tipoObjeto = { id: response[0].valor, codigo: tipo.codigo };
                  this.dataTabla = this.dataTabla;
                  this.serialesSeleccionados.push(tipoObjeto);
                  this.dataTabla.push(tipoObjeto);
                  this.dataTabla = this.dataTabla.filter(x => !GeneralUtil.isNullOrUndefined(x.id)).map(x => Object.assign({}, x));
                  this.dataTabla = this.dataTabla.map(x => Object.assign({}, x));
                  this.serialesSeleccionados = this.serialesSeleccionados.map(x => Object.assign({}, x));

                }
                if (mensaje.accion == 'desasociarDocMovSerialVenta') {
                  this.data = GeneralUtil.removeFromList(this.data, tipo.id);
                  this.serialesSeleccionados = GeneralUtil.removeFromList(this.serialesSeleccionados, tipo.id);

                }

              }
            }
          }, (error) => {
            this.errorHandlerComponent.handler(error);
          });

      this.subscriptions.add(sub);
    }
  }

  clickSelectEvent(e) {

  }

  limpiarFiltros(e) {

  }

  selectTodoMultiSelect(e) {
    this.serialesSeleccionados.push(e)
    console.log(this.serialesSeleccionados);
    
  }

  cambioPestana(pagina) {
    this.cargarMultiSelectSeriales(pagina - 1,)
  }

  cambioBuscador(filtro: any) {
    if (!GeneralUtil.isNullOrUndefined(filtro) || filtro !== '') {
      this.filtrosSerialMultiselect = filtro
      this.cargarMultiSelectSeriales()
    }
  }

  getSeriales(pagina, registrosPorPagina, columna, orden, registroInicial?) {

    if (!GeneralUtil.isNullOrUndefined(columna) && !GeneralUtil.isNullOrUndefined(orden))
      this.ultimoOrden = { columna: columna, orden: orden };

    const paginacion = new PaginacionWo(this.ultimoOrden.columna);
    paginacion.pagina = this.paginacion && this.paginacion.pagina ? this.paginacion.pagina : pagina;
    paginacion.registrosPorPagina = this.paginacion && this.paginacion.registrosPorPagina ? this.paginacion.registrosPorPagina : registrosPorPagina;
    paginacion.filtros.push(this.getFiltroMovimiento());

    if (!GeneralUtil.isNullOrUndefined(registroInicial)) paginacion.registroInicial = registroInicial;

    paginacion.orden = this.ultimoOrden.orden;
    this._LoaderService.colocarMascara("inventarioSeriales", { tabla: false }, "loadingIndex", "dialogSeriales");

    this.serialesService.getSeriales(paginacion)
      .pipe(take(1))
      .subscribe(
        response => {
          this.dataTabla = [{}];

          this.paginacion = {
            totalPaginas: response.totalPages,
            totalElementos: response.totalElements,
            registrosPorPagina: paginacion.registrosPorPagina,
            numeroElementos: response.numberOfElements,
            registroInicial: registroInicial,
            orden: orden,
            columna: columna,
            filtros: paginacion.filtros
          };

          this.dataTabla = this.scrollInfinitoService.crearArregloScrollInfinito(this.paginacion, this.dataTabla, response.content);
          this.dataTabla = this.dataTabla.map(x => Object.assign({}, x));
          this._LoaderService.quitarMascara("inventarioSeriales", "tabla", "loadingIndex", "dialogSeriales")
        },
        error => {
          this._LoaderService.quitarMascara("inventarioSeriales", "tabla", "loadingIndex", "dialogSeriales")
          this.errorHandlerComponent.handler(error);
        }
      );
  }

  afterChange(e) {

    if (e.crea || e.edita || e.pegado) {
      let fila = e.fila;
      let columna = e.columna;
      let valorAnterior = e.valorAnterior;
      let valorNuevo = e.valorNuevo;
      if (valorAnterior === valorNuevo) return;

      let id = GeneralUtil.isNullOrUndefined(fila) ? 0 : GeneralUtil.isNullOrUndefined(this.data[fila].id) ? 0 : this.data[fila].id;
      let pego = e.pegado ? e.pegado : false;

      let mensaje = {
        clase: 'DocumentoMovimientoSerial',
        id,
        accion: 'asociarDocMovSerial',
        atributo: GeneralUtil.isNullOrUndefined(columna) ? 'pego' : columna,
        valor: { codigo: GeneralUtil.isArray(valorNuevo) ? valorNuevo : [valorNuevo], idDmi: this.data.renglonId, pego },
        prioridad: true,
        tipoDato: TipoDato[TipoDato.STRING],
        permisoGrueso: this.tipoDocumento
      };
      // this.enviar(mensaje, e, null,);
    }

    if (e.eliminar) {
      this.eliminarFila(e.id);
    }
    if (e.ordenar) {
      this.data = [];
      this.getSeriales(0, 50, e.columna, e.orden);
    }
    if (e.pag) {
      this.paginacion.registrosPorPagina = e.registrosPorPagina;
      this.paginacion.pagina = e.pagActual;
      this.getSeriales(0, 50, e.columna, e.orden, e.registroInicial);
    }

  }

  eliminarFila(id) {

    let mensaje;
    mensaje = {
      clase: 'DocumentoMovimientoSerial',
      accion: 'desasociarDocMovSerialVenta',
      id: id,
      atributo: "",
      valor: 'ok',
      prioridad: true,
      tipoDato: TipoDato[TipoDato.STRING],
      permisoGrueso: this.tipoDocumento
    }
    // if (this.isDocumentoVentas) {
    // } else {
    //   mensaje = {
    //     id: id,
    //     accion: 'eliminarDocMovSerial',
    //     clase: 'DocumentoMovimientoSerial',
    //     valor: 'ok',
    //     prioridad: true,
    //     permisoGrueso: this.tipoDocumento,
    //     atributo: ""
    //   };

    // }

    this.enviar(mensaje, null, null);
  }

  enviar(mensaje, e, mens,) {
    let tablaEnum = `WT_SERIALES`;
    let fila;
    let columna;
    let valorAnterior;
    if (e) {
      fila = e.fila;
      columna = e.columna;
      valorAnterior = e.valorAnterior;
    }
    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1)).pipe(
        tap(response => {
          this._ValorAnteriorService.setValorAnterior(tablaEnum, e, response, this.columnas.find(x => x.atributo === columna), this);
        })
      ).subscribe(
        response => {
          if (response.length > 0) {
            if (response[0].codigoError) {
              if (['1015', '963', '1044', '1117'].includes(response[0].codigoError)) {
                this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, detalles: response[0].valor });
              } else {
                this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad, detalle: response[0].valor });
              }

              if (mensaje.atributo == 'pego')
                this.getSeriales(0, 50, 'id', 'ASC');
              else
                this.volverValorAnterior(response[0].accion, fila, columna, valorAnterior);

            } else {
              if (response[0].accion === 'asociarDocMovSerial' && mensaje.atributo !== 'pego') {
                this.data[fila].id = response[0].valor;
                this.data = this.data.map(x => Object.assign(x));
              }

              if (['desasociarDocMovSerialVenta', 'eliminarDocMovSerial'].includes(response[0].accion)) {
                if (response[0].accion === 'desasociarDocMovSerialVenta')
                  this.serialesSeleccionados = GeneralUtil.removeFromList(this.serialesSeleccionados, mensaje.id);

                this.dataTabla = GeneralUtil.removeFromList(this.dataTabla, mensaje.id);
                this.cargarMultiSelectSeriales(0)
              }
            }

            if (mensaje.atributo == 'pego')
              this.getSeriales(0, 50, 'id', 'ASC');
          }
        }, (error) => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  volverValorAnterior(accion: any, fila: any, columna: any, valorAnterior: any) {
    if (accion === 'asociarDocMovSerial') {
      this.data[fila][columna] = valorAnterior;
      this.data = this.data.map(x => Object.assign({}, x));
    }
  }

  getCantidadPorDocumento(): number {
    return this.tipoDocumento === DocumentoTipoEnum.AJUSTE_INVENTARIO ? Math.abs(this.data.diferencia) : Math.abs(this.data.cantidad);
  }

  eliminarSerialesBloque() {

    let mensaje = {
      clase: 'DocumentoMovimientoSerial',
      id: this.data.renglonId,
      accion: 'eliminarSerialesBloque',
      atributo: '',
      valor: 'ok',
      prioridad: true,
      tipoDato: TipoDato[TipoDato.STRING],
      permisoGrueso: this.tipoDocumento
    };

    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable
        .pipe(take(1))
        .subscribe(
          response => {
            if (response.length > 0) {
              if (response[0].codigoError) {
                this.popUpService.open({ codigoError: response[0].codigoError, severidad: response[0].severidad });
              } else {
                this.getSeriales(0, 50, 'id', 'ASC');
                this.popUpService.open({ codigoError: 'eliminarConfiguracionSerialesExito', severidad: SeveridadEnum.SUCCESS });
              }
            }
          }, (error) => {
            this.errorHandlerComponent.handler(error);
          });

      this.subscriptions.add(sub);
    }
  }
}
