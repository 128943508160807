import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PopUpService} from "@wo/frontend/servicios/popUp/pop-up.service";
import {PopUpAyuda} from "@wo/frontend/popup-general-ayuda/pop-up-ayuda";
import {MultiLicenciaEmpresa} from "@wo/modelo";

@Component({
  selector: 'app-pop-up-login-multi-empresas',
  templateUrl: './pop-up-login-multi-empresas.component.html',
  styleUrls: ['./pop-up-login-multi-empresas.component.css']
})
export class PopUpLoginMultiEmpresasComponent extends PopUpAyuda implements OnInit {

  @Output() respuestaPopup = new EventEmitter<any>();
  @Output() loginEmpresa = new EventEmitter<any>();
  @Input() listadoLicencias: MultiLicenciaEmpresa[]  = [];
  @Input() loadingPopUpMultiempresa: boolean = false;

  listaBanderas = [
    {id: 1, prefijo: 'co', url: 'assets/images/iconsFlag/colombia.png'},
    {id: 2, prefijo: 'ec', url: 'assets/images/iconsFlag/ecuador.png'},
    {id: 3, prefijo: 'pe', url: 'assets/images/iconsFlag/peru.png'},
    {id: 4, prefijo: 'mx', url: 'assets/images/iconsFlag/mexico.png'},
  ];

  constructor(
    public popUpService: PopUpService,
  ) {
    super(popUpService);
  }

  ngOnInit() {
    this.asignacionBanderas();
  }

  asignacionBanderas(){
    this.listadoLicencias.forEach(licencia => {
      this.listaBanderas.forEach(bandera => {
        if(licencia.abreviaturaPaisAmbiente === bandera.prefijo){
          licencia['image'] = bandera.url;
        }
      })
    })
  }

  popUpCerrar(){
    this.respuestaPopup.emit();
  }

  seleccionEmpresa(empresa){
    this.loginEmpresa.emit(empresa);
  }

}
