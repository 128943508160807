// opciones-tabla-buttons.ts
// SE CREA ESTE ARCHIVO PARA MANEAR OPCIONES DE TABLA EN OPCIONES-WO-TABLA
import { OpcionTabla } from "@wo/modelo";
interface OpcionButton {
  icono: string;
  nombreButton: any;
  texto: string;
}

export const diccionarioButtons: Record<string | number | symbol, OpcionButton> = {
  [OpcionTabla.VER_NOVEDADES_PERIODO]: {
    icono: 'fa-list-alt',
    nombreButton: OpcionTabla.VER_NOVEDADES_PERIODO,
    texto: 'verNovedadesPeriodo',
  },
  [OpcionTabla.VISTA_PREVIA_SEGURIDAD]: {
    icono: 'fa-file-alt',
    nombreButton: OpcionTabla.VISTA_PREVIA_SEGURIDAD,
    texto: 'vistaPreviaSeguridadSocial',
  },
  [OpcionTabla.VISTA_PREVIA_SEGURIDAD_GENERAL]: {
    icono: 'fa-file-contract',
    nombreButton: OpcionTabla.VISTA_PREVIA_SEGURIDAD_GENERAL,
    texto: 'vistaPreviaSeguridadSocialGeneral',
  },
  [OpcionTabla.DESCARGAR_ARCHIVO_PLANO]: {
    icono: 'fa-download',
    nombreButton: OpcionTabla.DESCARGAR_ARCHIVO_PLANO,
    texto: 'descargarArchivoPlano',
  },
  [OpcionTabla.PAGAR_SEGURIDAD_SOCIAL]: {
    icono: 'fa-credit-card',
    nombreButton: OpcionTabla.PAGAR_SEGURIDAD_SOCIAL,
    texto: 'pagarSeguridadSocial',
  },
  [OpcionTabla.INFORME_AUDITORIA_NP]: {
    icono: 'fa-file-alt',
    nombreButton: OpcionTabla.INFORME_AUDITORIA_NP,
    texto: 'informeAuditoriaNominaPila',
  },
  [OpcionTabla.CALCULO_REDONDEOS]: {
    icono: 'fa-file-invoice-dollar',
    nombreButton: OpcionTabla.CALCULO_REDONDEOS,
    texto: 'calcularRedondeosNC',
  },
  [OpcionTabla.SECTOR_TRANSPORTE]: {
    icono: 'fas fa-truck',
    nombreButton: OpcionTabla.SECTOR_TRANSPORTE,
    texto: 'verSectorTransporte',
  },
};

export const diccionarioOpcionesButtons = Object.values(diccionarioButtons);
